import PropTypes from "prop-types"
import { components } from "react-select"
import "./styles/option.scss"

const Option = ({ data, ...props }) => (
  <components.Option {...props}>
    <div className="container-op">
      <span className="label-item">{data.label}</span>
      {data.description && (
        <span className="description-item description-op">{data.description}</span>
      )}
    </div>
  </components.Option>
)

Option.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    description: PropTypes.string
  })
}

export default Option
