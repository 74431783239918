import gql from "graphql-tag"

export const trendQuery = gql`
  query TrendQuery($slug: String, $instanceIds: [ID]) {
    trend(slug: $slug) {
      id
      name
      text
      slug
      patternSet {
        edges {
          node {
            id
            altName
            slug
            products(instances: $instanceIds) {
              edges {
                node {
                  id
                  id
                  code
                  color
                  predominantColors
                  hasStock
                  samplesAvailable
                  stock
                  pantone
                  imageThumbSmall
                  imageThumbMedium
                  imageThumbLarge
                  isNew
                }
              }
            }
            inspiration {
              id
              text
              imageThumbMedium
            }
            cardOfColor {
              id
              text
            }
          }
        }
      }
    }
  }
`
