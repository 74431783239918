import Footer from "components/Footer"
import Header from "components/Header"
import Applications from "./components/Applications"
import Banner from "components/Banner"
import ContactForm from "components/ContactForm"
import ColorPalette from "./components/ColorPalette"
import Permablock from "./components/Permablok"
import ProductDetails from "./components/ProductDetails"
import { NAV_ITEMS, FOOTER_ITEMS, GLOBAL_SITE_TAG } from "./constants"
import { SLIDE } from "./components/Banner/constants"
import TheNewWhisper from "./components/TheNewWhisper"
import ExploreNewColors from "./components/ExploreNewColors"
import { FormattedMessage } from "react-intl"
import { COUNTRIES_FOR_USA } from "consts/countryCodes"
import Analytics from "components/Analytics"
import { getCurrentInstanceId } from "services/instances"

const Whisper = () => (
  <>
    <Analytics gtag={GLOBAL_SITE_TAG[getCurrentInstanceId()]} />
    <Header items={NAV_ITEMS} isTransparent />
    <div className="content">
      <Banner slides={SLIDE} noDescription />
      <TheNewWhisper />
      <ExploreNewColors />
      <ColorPalette id={NAV_ITEMS[1]} />
      <Permablock id={NAV_ITEMS[0]} />
      <Applications id={NAV_ITEMS[2]} />
      <ProductDetails id={NAV_ITEMS[3]} />
      <div id={NAV_ITEMS[4]}>
        <div className="container my-2 py-4">
          <ContactForm
            title={
              <FormattedMessage
                id="WhisperNeoPlus.Contact.title"
                description="WhisperNeoPlus section title contact"
                defaultMessage="Want more information about Whisper Neo Plus"
              />
            }
            countryOptions={COUNTRIES_FOR_USA}
            landingSource="whisper-neo-plus"
          />
        </div>
      </div>
    </div>
    <Footer hasItems items={FOOTER_ITEMS} />
  </>
)

export default Whisper
