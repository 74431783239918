import { FormattedMessage } from "react-intl"
import banner from "./img/banner.jpg"
import logo from "./img/logo-ibex.svg"
import { NAV_ITEMS } from "modules/Ibex/constants"
import "components/Banner/components/HomeSlide/styles/home-slide.scss"

const Banner = () => (
  <div
    id="home-slide"
    className="home-slide"
    style={{
      backgroundImage: `url(${banner})`
    }}
  >
    <div className="home-slide__content home-slide__content--center">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <div className="slide-content slide-content--center text-center text-light">
              <img className="w-50 mb-2" src={logo} alt="ibex" />
              <p className="slide-content__description text-weight-medium fs-1">
                <FormattedMessage
                  id="Ibex.Banner.description"
                  description="Ibex buttonText banner"
                  defaultMessage="September 27-29, 2022{br}Tampa, FL{br}Booth 3-819"
                  values={{ br: <br /> }}
                />
              </p>
              <a
                className="slide-content__button btn btn-lg btn-outline-light"
                target="_self"
                href={`#${NAV_ITEMS[NAV_ITEMS.length - 1]}`}
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="Ibex.Banner.buttonText"
                  description="Ibex buttonText banner"
                  defaultMessage="Reserve your appointment now"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Banner
