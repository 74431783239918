import { defineMessages } from "react-intl"

export const MSGS = defineMessages({
  loading: {
    id: "CompanyTypeField.loading",
    description: "Loading label for CompanyType field",
    defaultMessage: "Cargando tipos de compa�ia..."
  },
  placeholder: {
    id: "CompanyTypeField.companyTypeDefault",
    description: "Default of company type field of the Contact box form",
    defaultMessage: "Select a company type"
  }
})
