import Header from "components/Header"
import Footer from "components/Footer"
import Banner from "./components/Banner"
import ColorInspirationSection from "./components/ColorInspirationSection"
import RivuletDescription from "./components/RivuletDescription"
import RpetSection from "./components/RpetSection"
import SamplesDetails from "./components/SamplesDetails"
import Video from "./components/Video"
import { FOOTER_ITEMS, NAV_0, NAV_1, NAV_2, NAV_3, NAV_4, RIVULET_GTAG } from "./constants"
import "./styles/rivulet.scss"
import { REQUEST_SAMPLES_INFO } from "./components/RequestsSection/utils"
import useRequestModal from "./components/RequestsSection/hooks/useRequestModal"
import RequestsSection from "./components/RequestsSection"
import Analytics from "components/Analytics"

const Rivulet = () => {
  const { modalContext, toggable, toggle, handleClickInRequest } = useRequestModal()
  const NAV_OBJ_ITEMS = [
    {
      id: NAV_0,
      text: NAV_0,
      href: NAV_0
    },
    {
      id: NAV_1,
      text: NAV_1,
      href: NAV_1
    },
    {
      id: NAV_2,
      text: NAV_2,
      href: NAV_2
    },
    {
      id: NAV_3,
      text: NAV_3,
      href: NAV_3
    },
    {
      id: NAV_4,
      text: NAV_4,
      href: "",
      callback: () => {
        handleClickInRequest(REQUEST_SAMPLES_INFO)
      }
    }
  ]

  return (
    <>
      <Analytics gtag={RIVULET_GTAG} />
      <Header className="header-background--rv" objItems={NAV_OBJ_ITEMS} />
      <Banner />
      <RivuletDescription id={NAV_OBJ_ITEMS[0].id} />
      <Video />
      <ColorInspirationSection id={NAV_OBJ_ITEMS[1].id} />
      <RpetSection id={NAV_OBJ_ITEMS[2].id} />
      <SamplesDetails id={NAV_OBJ_ITEMS[3].id} />
      <Footer className="footer-background--rv" hasItems items={FOOTER_ITEMS} />
      <RequestsSection modalContext={modalContext} toggable={toggable} toggle={toggle} />
    </>
  )
}

export default Rivulet
