import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import { ITEMS } from "./constants"
import ApplicationsGeneric from "components/Applications"

const Applications = ({ id }) => (
  <div id={id}>
    <div className="container my-2 py-4">
      <div className="row g-0">
        <div className="col-12">
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="WhisperNeoPlus.Applications.title"
              description="WhisperNeoPlus section title applications"
              defaultMessage="Applications"
            />
          </TitleUnderlined>
          <ApplicationsGeneric items={ITEMS} />
        </div>
      </div>
    </div>
  </div>
)

Applications.propTypes = {
  id: PropTypes.string.isRequired
}

export default Applications
