import PropTypes from "prop-types"
import { useStateMachine } from "little-state-machine"
import classNames from "classnames/bind"
import { updateAction } from "app/store"
import "./styles/sample-card.scss"

const SampleCard = ({ staticSampleData, sample, handleClick }) => {
  const { state, actions } = useStateMachine({ updateAction })

  const handleUpdateSampleState = (sample) => {
    if (state.sampleDetails !== sample) {
      actions.updateAction({
        sampleDetails: sample
      })
    }
  }

  const handleChangeSampleDetails = (sample) => {
    const sampleToSaveAndShow =
      staticSampleData?.current !== undefined ? staticSampleData.current : sample

    actions.updateAction({
      sampleDetails: sampleToSaveAndShow
    })
  }

  return (
    <div
      className={classNames({
        "card-color container-card--masiala": true
        // "actived-card--masiala": state?.sampleCount?.some((item) => item === sample)
      })}
      onClick={(event) => handleClick(event, sample)}
      onMouseEnter={() => handleUpdateSampleState(sample)}
      onMouseLeave={() => handleChangeSampleDetails(sample)}
    >
      <div className="d-flex align-items-center justify-content-center">
        <img className="img-card--masiala" src={sample.img} alt="sample-image" />
      </div>
      <div className="card-container-info--masiala">
        <p className="card-label--masiala">{sample.color}</p>
        <p className="card-stock--masiala">{sample.reference}</p>
      </div>
    </div>
  )
}

SampleCard.propTypes = {
  staticSampleData: PropTypes.object.isRequired,
  sample: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default SampleCard
