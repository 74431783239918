import { useState } from "react"

const useCounter = () => {
  const [count, setCount] = useState(1)
  const increment = () => setCount(count + 1)

  const decrement = () => setCount(count > 1 ? count - 1 : 1)

  const resetCounter = () => setCount(1)

  return {
    count,
    increment,
    decrement,
    resetCounter
  }
}

export default useCounter
