import { defineMessages } from "react-intl"

export const COLOR_FADING_MSGS = defineMessages({
  cleaningAgentsUsed: {
    id: "Claims.ColorFading.cleaningAgentsUsed",
    description: "field cleaning agents used in color fading claim reason",
    defaultMessage: "Which cleaning agents are used to clean the material?"
  },
  protectProductCushions: {
    id: "Claims.ColorFading.protectProductCushions",
    description: "field protect product cushions in color fading claim reason",
    defaultMessage: "Please indicate how you protect the product / cushions"
  },
  surfaceContact: {
    id: "Claims.ColorFading.surfaceContact",
    description: "fieldsurface contact in color fading claim reason",
    defaultMessage:
      "Please specify the substances and/or surfaces that the material has been in contact with"
  }
})

const PROTECT_PRODUCT_CUSHIONS_MSG_OPTIONS = defineMessages({
  cover: {
    id: "Claims.ColorFading.option.cover",
    description: "option cover in protect product cushions on color fading claim reason",
    defaultMessage: "Cushions are protected with a cover when not used"
  },
  outsideNotProtection: {
    id: "Claims.ColorFading.option.outsideNotProtection",
    description:
      "option outside not protection in protect product cushions on color fading claim reason",
    defaultMessage: "Cushions are left outside without any protection"
  },
  storedInside: {
    id: "Claims.ColorFading.option.storedInside",
    description: "option stored inside in protect product cushions on color fading claim reason",
    defaultMessage: "Cushions are stored inside when not used"
  }
})

export const PROTECT_PRODUCT_CUSHIONS_OPTIONS = [
  {
    id: "cover",
    label: PROTECT_PRODUCT_CUSHIONS_MSG_OPTIONS.cover,
    value: "cover"
  },
  {
    id: "outsideNotProtection",
    label: PROTECT_PRODUCT_CUSHIONS_MSG_OPTIONS.outsideNotProtection,
    value: "outside not protection"
  },
  {
    id: "storedInside",
    label: PROTECT_PRODUCT_CUSHIONS_MSG_OPTIONS.storedInside,
    value: "stored inside"
  }
]
