import gql from "graphql-tag"

const companyTypesQuery = gql`
  query CompanyTypesQuery($instanceIds: [ID]!) {
    companyTypes(instances: $instanceIds) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export default companyTypesQuery
