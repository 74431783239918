import Analytics from "components/Analytics"
import Header from "components/Header"
import Banner from "components/Banner"
import Footer from "components/Footer"

import ColorPalette from "modules/pandora/components/ColorPalette"
import Applications from "modules/pandora/components/Applications"
import ProductDetails from "modules/pandora/components/ProductDetails"
import WhereToBuy from "modules/petfriendly/components/WhereToBuy"
import { SLIDE } from "modules/pandora/components/Banner/constants"
import { GLOBAL_SITE_TAG } from "modules/pandora/constants"
import { getCurrentInstanceId } from "services/instances"
import { NAV_ITEMS } from "./constants"

const Pandora = () => {
  return (
    <>
      <Analytics gtag={GLOBAL_SITE_TAG[getCurrentInstanceId()]} />
      <Header items={NAV_ITEMS} isTransparent />
      <div className="content">
        <Banner slides={SLIDE} />
        <ColorPalette id={NAV_ITEMS[0]} />
        <Applications id={NAV_ITEMS[1]} />
        <ProductDetails id={NAV_ITEMS[2]} />
        <WhereToBuy id={NAV_ITEMS[3]} />
      </div>
      <Footer />
    </>
  )
}

export default Pandora
