import { FormattedMessage } from "react-intl"
// flooring
import terra from "./imgs/flooring/terra.jpg"
import arka from "./imgs/flooring/arka.jpg"
import hela from "./imgs/flooring/hela.jpg"
import gaudi from "./imgs/flooring/gaudi.jpg"
// outdoorUpholstery
import silvertex from "./imgs/exterior/silvertex.jpg"
import linetex from "./imgs/exterior/linetex.jpg"
import maglia from "./imgs/exterior/maglia.jpg"
// interiorUpholstery
import pranna from "./imgs/interior/pranna.jpg"
import habana from "./imgs/interior/habana.jpg"
import prannatex from "./imgs/interior/prannatex.jpg"
import gaira from "./imgs/interior/gaira.jpg"

export const ITEMS = [
  {
    id: "flooring",
    title: (
      <FormattedMessage id="Segments.Hotel.Products.type.flooring.title" defaultMessage="Pisos" />
    ),
    info: (
      <FormattedMessage
        id="Segments.Hotel.Products.type.flooring.info"
        defaultMessage="Nuestro equipo de diseño global investiga las últimas tendencias en la industria para desarrollar pisos de alta resistencia de la más alta calidad  para  restaurantes, recepciones, auditorios y mucho más."
      />
    ),
    imgs: [
      { name: "Terra", img: terra },
      { name: "Arka", img: arka },
      { name: "Hela", img: hela },
      { name: "Gaudí", img: gaudi }
    ]
  },
  {
    id: "outdoorUpholstery",
    title: (
      <FormattedMessage
        id="Segments.Hotel.Products.type.outdoorUpholstery.title"
        defaultMessage="Tapicería para exteriores"
      />
    ),
    info: (
      <FormattedMessage
        id="Segments.Hotel.Products.type.outdoorUpholstery.info"
        defaultMessage="Contamos con las mejores tecnologías para desarrollar productos que perduren el tiempo. Para todo tipo de clima, frío o calor extremo, nuestros productos responden a esas temperaturas, sin presentar daños."
      />
    ),
    imgs: [
      {
        name: (
          <FormattedMessage
            id="Segments.Hotel.Products.type.outdoorUpholstery.imgs.Linetex"
            defaultMessage="Linetex<sup>®</sup>"
            values={{ sup: (...chunks) => <sup>{chunks}</sup> }}
          />
        ),
        img: linetex
      },
      {
        name: (
          <FormattedMessage
            id="Segments.Hotel.Products.type.outdoorUpholstery.imgs.Silvertex"
            defaultMessage="Silvertex<sup>®</sup>"
            values={{ sup: (...chunks) => <sup>{chunks}</sup> }}
          />
        ),
        img: silvertex
      },
      {
        name: (
          <FormattedMessage
            id="Segments.Hotel.Products.type.outdoorUpholstery.imgs.Maglia"
            defaultMessage="Maglia<sup>®</sup>"
            values={{ sup: (...chunks) => <sup>{chunks}</sup> }}
          />
        ),
        img: maglia
      }
    ]
  },
  {
    id: "interiorUpholstery",
    title: (
      <FormattedMessage
        id="Segments.Hotel.Products.type.interiorUpholstery.title"
        defaultMessage="Tapicería para interiores"
      />
    ),
    info: (
      <FormattedMessage
        id="Segments.Hotel.Products.type.interiorUpholstery.info"
        defaultMessage="Tenemos todo un mundo de tejidos recubiertos que responden a las exigencias técnicas. Habitaciones, restaurantes, bares o espacios de ocio y entretenimiento, son perfectos para cubrir con nuestros productos."
      />
    ),
    imgs: [
      {
        name: (
          <FormattedMessage
            id="Segments.Hotel.Products.type.interiorUpholstery.imgs.Pranna"
            defaultMessage="Pranna<sup>®</sup>"
            values={{ sup: (...chunks) => <sup>{chunks}</sup> }}
          />
        ),
        img: pranna
      },
      {
        name: "Habana",
        img: habana
      },
      {
        name: (
          <FormattedMessage
            id="Segments.Hotel.Products.type.interiorUpholstery.imgs.PrannaTex"
            defaultMessage="Pranna<sup>®</sup>Tex"
            values={{ sup: (...chunks) => <sup>{chunks}</sup> }}
          />
        ),
        img: prannatex
      },
      {
        name: "Gaira",
        img: gaira
      }
    ]
  }
]
