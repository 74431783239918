import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import { useLocation } from "react-router-dom"

const NotFound = () => {
  const { pathname } = useLocation()
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <TitleUnderlined className="m-2" hTag={H2_TAG}>
            <FormattedMessage
              id="NotFound.title"
              description="NotFound title page"
              defaultMessage="Page Not Found"
            />
          </TitleUnderlined>
          <p className="text-center">
            <FormattedMessage
              id="NotFound.description"
              description="NotFound description text"
              defaultMessage="The request URL {url} was not found on this server"
              values={{
                url: <code>{pathname}</code>
              }}
            />
          </p>
        </div>
      </div>
    </div>
  )
}

export default NotFound
