import { useEffect } from "react"
import PropTypes from "prop-types"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useLazyQuery } from "@apollo/client"
import Slider from "react-slick"
import { singlePatternQuery } from "modules/petfriendly/components/References/components/Card/queries"
import { getCurrentInstanceId, isLATAM, isMEXICO } from "services/instances"
import { ANGLO_SAXON_UNITS, INTERNATIONAL_UNITS } from "./constants"
import Modal from "components/Modal"
import TitleUnderlined from "components/TitleUnderlined"
import ColorPalette from "components/ColorPalette"
import { Spinner } from "reactstrap"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "utils/littleStateMachine"
import { H2_TAG } from "components/TitleUnderlined/constants"
import TableAccordion from "components/TableAccordion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import DownloadPatternPDFButton from "./components/DownloadPatternPDFButton"
import UsesList from "components/UsesList"
import { SLIDER_OPTIONS } from "components/Banner/constants"
import "./styles/floor-detail.scss"
import InstalationGuide from "../InstalationGuide/InstalationGuide"
import HTMLContent from "components/HTMLContent"
import { handleManageForContact } from "./utils"
import useToggle from "hooks/useToggle"
import Contact from "modules/floors/components/Contact"
import Specs from "modules/petfriendly/components/References/components/Card/components/Specs"

const FloorDetail = () => {
  const { slug } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { actions, state } = useStateMachine({ updateAction })
  const [toggable, toggle] = useToggle()
  const [getPattern, { data, loading }] = useLazyQuery(singlePatternQuery, {
    variables: {
      slug,
      instanceIds: [getCurrentInstanceId()],
      unitSystem: isLATAM() && isMEXICO() ? ANGLO_SAXON_UNITS : INTERNATIONAL_UNITS
    }
  })

  useEffect(() => {
    getPattern()
    handleManageForContact(pathname, actions)
  }, [])

  return (
    <>
      {data && (
        <div className="container my-4">
          <div className="row my-4 py-4">
            <div className="col-12 col-md-6 order-lg-1 align-self-center">
              <TitleUnderlined hTag={H2_TAG} className="my-4 text-capitalize">
                {data.pattern.altName}
              </TitleUnderlined>
              <ColorPalette
                colors={data.pattern.products.edges}
                align="center"
                actived
                hideWarning
                mainTypeColorDetail="square"
              />
            </div>
            <div className="col-12 col-md-6 mt-4 mt-md-5 pt-4 pt-md-5 order-lg-0">
              {data.pattern?.sliders.length && (
                <Slider {...SLIDER_OPTIONS}>
                  {data.pattern.sliders.map(({ alt, image }) => (
                    <div key={`key_${alt}`}>
                      <img className="applicated-floor" src={image} alt={alt} />
                    </div>
                  ))}
                </Slider>
              )}
              <div className="d-flex justify-content-between align-items-center">
                <a className="btn btn-outline-dark mt-5" onClick={() => navigate(-1)}>
                  <FontAwesomeIcon className="me-2" icon={faArrowLeft} />
                  <FormattedMessage
                    id="FloorDetail.showMore"
                    description="inspiration table title at floor detail page"
                    defaultMessage="Ver más pisos"
                  />
                </a>
                {!state?.floorDetail?.getContact ? (
                  <a
                    className="btn btn-outline-dark mt-5"
                    href={`https://spradling.group/es-la/productos/${slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FormattedMessage
                      id="FloorDetail.showInPortal"
                      description="show In Portal table title at floor detail page"
                      defaultMessage="Ver en portal"
                    />
                    <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
                  </a>
                ) : (
                  <>
                    <button className="btn btn-outline-dark mt-5" onClick={toggle}>
                      <FormattedMessage
                        id="Button.moreInfo"
                        description="Button more info text"
                        defaultMessage="Más información"
                      />
                    </button>
                    <Modal isOpen={toggable} handleToggable={toggle} size="lg">
                      <Contact colorTheme="black" pattern={data.pattern} />
                    </Modal>
                  </>
                )}
              </div>
            </div>
          </div>
          <Specs pattern={data.pattern} />
          <TableAccordion
            title={
              <FormattedMessage
                id="Floors.HeaderButtons.inspiration"
                description="inspiration table title at floor detail page"
                defaultMessage="Inspiración"
              />
            }
            isFullContainer
          >
            <div>
              <div className="py-2">
                <HTMLContent html={data.pattern.inspiration?.text} />
              </div>
              {data.pattern.inspiration?.imageThumbMedium && (
                <div className="text-center">
                  <img
                    className="img-fluid inspiration-img"
                    src={data.pattern.inspiration.imageThumbMedium}
                    alt="inspiration"
                  />
                </div>
              )}
              <h5 className="pt-4">
                <FormattedMessage
                  id="Floors.HeaderButtons.colorCard"
                  description="inspiration table title at floor detail page"
                  defaultMessage="Carta de color"
                />
              </h5>
              <div className="py-2">
                <HTMLContent html={data.pattern.cardOfColor?.text} />
              </div>
            </div>
          </TableAccordion>
          <TableAccordion
            title={
              <FormattedMessage
                id="FloorDetail.uses"
                description="uses table title at floor detail page"
                defaultMessage="Usos"
              />
            }
            isFullContainer
          >
            <UsesList />
          </TableAccordion>
          <TableAccordion
            title={
              <FormattedMessage
                id="FloorDetail.dataSheet"
                description="inspiration table title at floor detail page"
                defaultMessage="Ficha Técnica"
              />
            }
            isFullContainer
          >
            <DownloadPatternPDFButton patternId={data.pattern.id} />
          </TableAccordion>
          <TableAccordion
            title={
              <FormattedMessage
                id="FloorDetail.instalation"
                description="instalation table title at floor detail page"
                defaultMessage="Instalación"
              />
            }
            isFullContainer
          >
            <div className="w-100 h-100">
              <InstalationGuide noTitle />
            </div>
          </TableAccordion>
        </div>
      )}
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner color="dark" />
        </div>
      )}
    </>
  )
}

FloorDetail.propTypes = {
  getContact: PropTypes.bool
}

export default FloorDetail
