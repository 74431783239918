import PropTypes from "prop-types"

const BrandCell = ({ title, logo }) => (
  <>{logo ? <img className="w-50" src={logo} alt="brand" /> : title}</>
)

BrandCell.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired
}

export default BrandCell
