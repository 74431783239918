import Banner from "modules/cleaning-guide-marine/components/Banner"
import Footer from "modules/cleaning-guide-marine/components/Footer"
import Layout from "modules/cleaning-guide-marine/components/Layout"
import { BANNER_INFO, GENERAL_INFO_SECTIONS, NAV_ITEMS } from "./constants"
import useGeneralInfo from "./hooks/useGeneralInfo"
import Header from "modules/cleaning-guide-marine/components/Header"
import useHeaderSectionId from "./hooks/useHeaderSectionId"
import Analytics from "components/Analytics"
import { CLEANING_GUIDE_MARINE_GENERAL_GTAG } from "modules/cleaning-guide-marine/constants"

const General = () => {
  const { GENERAL_SECTION_INFO, handleChange } = useGeneralInfo(GENERAL_INFO_SECTIONS)
  const { headerSectionId, setHeaderSectionId } = useHeaderSectionId(GENERAL_SECTION_INFO.sectionId)

  return (
    <>
      <Analytics gtag={CLEANING_GUIDE_MARINE_GENERAL_GTAG} />
      <Header
        items={NAV_ITEMS}
        sectionId={headerSectionId.id}
        handleChangeSectionId={setHeaderSectionId}
        handleChangeSection={handleChange}
      />
      <Banner {...BANNER_INFO} />
      <Layout
        generalInfo={GENERAL_SECTION_INFO}
        sectionIdToScroll={headerSectionId.id}
        handleChangeSectionId={setHeaderSectionId}
        handleInfo={handleChange}
      />
      <Footer />
    </>
  )
}

export default General
