import PropTypes from "prop-types"
import "./styles/flat-page-info.scss"
import { FormattedMessage } from "react-intl"
import { useQuery } from "@apollo/client"
import Modal from "components/Modal"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import singleFlatpageQuery from "./query"
import { getCurrentInstanceId } from "services/instances"

const FlatPageInfo = ({
  slug = "privacy-policy",
  title,
  isOpen = false,
  handleAcceptTNC
}) => {
  const { data } = useQuery(singleFlatpageQuery, {
    variables: { instanceId: getCurrentInstanceId(), slug }
  })

  return (
    <Modal isOpen={isOpen}>
      <div className="container-data-policy-and-terms overflow-scroll">
        <TitleUnderlined className="text-center" hTag={H2_TAG}>
          <FormattedMessage {...title} />
        </TitleUnderlined>
        <div dangerouslySetInnerHTML={{ __html: data?.flatpage?.content }} />
        <div className="text-center">
          <button className="btn btn-dark text-uppercase" onClick={handleAcceptTNC}>
            <FormattedMessage
              id="termsAndConditions.btn"
              description="terms and conditions button"
              defaultMessage="Accept"
            />
          </button>
        </div>
      </div>
    </Modal>
  )
}

FlatPageInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleToggable: PropTypes.func.isRequired,
  slug: PropTypes.string,
  title: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    defaultValue: PropTypes.string
  }),
  handleAcceptTNC: PropTypes.func
}

export default FlatPageInfo
