import { APPLICATIONS_ELEMENTS } from "./constants"
import "./styles/applications-elements.scss"

const ApplicationsElements = () => (
  <div className="g-0">
    <div className="row mt-3 mb-4">
      {APPLICATIONS_ELEMENTS.map(({ img: { srcImg, style }, description }) => (
        <div
          className="col-4 col-md-2 ps-0 pe-0 col-lg-auto application-container"
          key={`item_${description.props.id}`}
        >
          <img className="application-img--masiala" style={style} src={srcImg} alt={description} />
          <p className="description-feature">{description}</p>
        </div>
      ))}
    </div>
  </div>
)

export default ApplicationsElements
