import { defineMessages } from "react-intl"
import emailBody from "services/email/body"

export const emailMessages = defineMessages({
  titleClaim: {
    id: "Claims.emailTemplate.titleClaim",
    defaultMessage: "Claim Registration"
  },
  dearValuedCustomer: {
    id: "Claims.emailTemplate.dearValuedCustomer",
    defaultMessage: "Dear valued Customer"
  },
  bodyText: {
    id: "Claims.emailTemplate.bodyText",
    defaultMessage:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
  },
  productSummaryTitleNameAndColor: {
    id: "Claims.Registration.check.nameAndColor",
    defaultMessage: "Name & colour of the claimed product"
  },
  productSummaryTitleCustomerNumber: {
    id: "Claims.Registration.check.customerNumber",
    defaultMessage: "Your Customer Number"
  },
  productSummaryTitleAffectedPieces: {
    id: "Claims.Registration.check.quantity",
    defaultMessage: "Quantity of the claimed product or affected pieces"
  },
  productSummaryTitleProductionBatch: {
    id: "Claims.Registration.check.productionBatchNumber",
    defaultMessage: "Production Batch No. of the material"
  },
  addtionalInformation: {
    id: "Claims.emailTemplate.addtionalInformation",
    defaultMessage:
      "For additional information please contact us through our website or your sales representative."
  }
})

export const getEmailBody = (values, intl) => {
  // const messages = [
  //   "titleClaim",
  //   "dearValuedCustomer",
  //   "bodyText",
  //   "productSummaryTitleNameAndColor",
  //   "productSummaryTitleCustomerNumber",
  //   "productSummaryTitleAffectedPieces",
  //   "productSummaryTitleProductionBatch",
  //   "addtionalInformation"
  // ].map((name) => intl.formatMessage(emailMessages[name]))

  return emailBody(values)
}
