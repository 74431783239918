import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { MODAL_TYPES, MODAL_TYPES_DOWNLOAD, REQUEST_SAMPLES_INFO } from "modules/masiala/constants"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"
import "./styles/ThanksYouMessage.scss"
import { getCurrentLanguageAndInstanceCode } from "services/instances"
import { handleDownloadFile } from "./utils/handleDownloadFile"
import classNames from "classnames"

const ThanksYouMessage = ({
  btnClassName = "",
  modalContext,
  toggle,
  callbackToChangeValueOfIsThankYouState,
  handleDownloadPublicPDF,
  patternFolderToDownlod
}) => {
  // WARNING: variable used only for success form data analytics
  const analyticsIdentifier = `thank-you-message--${modalContext.type}`
  const isMobile = useMediaQuery(LG)
  const [lang] = getCurrentLanguageAndInstanceCode()

  const handleClickInBtn = () => {
    if (modalContext.type === MODAL_TYPES.PRODUCT_DETAIL) {
      handleDownloadPublicPDF()
      callbackToChangeValueOfIsThankYouState()
      toggle()
      return
    }

    if (MODAL_TYPES_DOWNLOAD.includes(modalContext.type)) {
      handleDownloadFile({
        lang,
        formType: MODAL_TYPES[modalContext.type],
        patternFolder: patternFolderToDownlod
      })
      callbackToChangeValueOfIsThankYouState()
      toggle()
    } else {
      callbackToChangeValueOfIsThankYouState()
      toggle()
    }
  }

  return (
    <div>
      <Row>
        <Col md={12} className="text-center">
          <h1 className="mx-auto col-6 col-md-5 col-lg-4 col-xl-2 thanks-you-message--title mb-md-3">
            Thank you!
          </h1>
        </Col>
      </Row>
      <Row className="text-center mt-2">
        <p className={`thank-you-message--description ${analyticsIdentifier}`}>
          {MODAL_TYPES_DOWNLOAD.includes(modalContext.type) ? (
            <>
              This product is a testament to your commitment {isMobile && <br />} and
              Spradling&apos;s dedication to a better planet. <br /> {isMobile && <br />}
              To download the <strong>{modalContext.downloadFileName}</strong> click here:
            </>
          ) : (
            <>We received your request {isMobile && <br />} and will be processing it soon!</>
          )}
        </p>
      </Row>
      <div className="text-center py-lg-0">
        <button
          type="button"
          className={classNames({
            "text-white px-4 button-form-masiala py-1 mb-5 cursor-pointer": !btnClassName,
            [btnClassName]: btnClassName
          })}
          btn
          btn-sample
          onClick={handleClickInBtn}
        >
          <span className="thank-you-message--btn">
            {MODAL_TYPES_DOWNLOAD.includes(modalContext.type) && <>Download PDF</>}
            {modalContext.type === REQUEST_SAMPLES_INFO.type && <>Close</>}
          </span>
        </button>
      </div>
    </div>
  )
}

ThanksYouMessage.propTypes = {
  patternFolderToDownlod: PropTypes.string,
  btnClassName: PropTypes.string,
  modalContext: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  callbackToChangeValueOfIsThankYouState: PropTypes.func.isRequired,
  handleDownloadPublicPDF: PropTypes.func
}

export default ThanksYouMessage
