import { yupResolver } from "@hookform/resolvers/yup"
import { useStateMachine } from "little-state-machine"
import Layout from "modules/claims/components/Layout"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { getCurrentLanguageAndInstanceCode } from "services/instances"
import { BUTTON_MESSAGES } from "utils/buttons"
import { VALIDATION_MESSAGES } from "utils/forms"
import { updateAction } from "utils/littleStateMachine"
import * as yup from "yup"
import ProgressBar from "../ProgressBar"
import "../styles/step-form.scss"
import Dropzone from "./components/Dropzone"
import uploadEvidences from "./img/4.upload-evidences.jpg"
import { FILE_TO_UPLOAD } from "consts"

const Step5 = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { actions, state } = useStateMachine({ updateAction })

  const [lang, instance] = getCurrentLanguageAndInstanceCode()

  const defaultValues = { ...state }
  const schema = yup.object({
    files: yup
      .mixed()
      .test("required", intl.formatMessage(VALIDATION_MESSAGES.required), (value) => {
        return value ? value.length > 0 : false
      })
      .test("fileSize", intl.formatMessage(VALIDATION_MESSAGES.fileSize), (files) => {
        if (!files || files.length === 0) {
          // Si no se seleccionaron archivos, la validación pasa
          return true
        }

        // Calcula el tamaño total de los archivos en bytes
        const totalSize = files.reduce((aux, file) => aux + file.size, 0)

        return totalSize <= FILE_TO_UPLOAD.maxSize
      })
    // .test(
    //   "type",
    //   intl.formatMessage(VALIDATION_MESSAGES.fileType),
    //   (value) => value && value[0]?.type <= FILE_TO_UPLOAD.type
    // ))
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data) => {
    actions.updateAction(data)
    navigate(`../${lang}-${instance}/claims/step-6`, { replace: true })
  }

  return (
    <Layout
      title={
        <FormattedMessage
          id="Claims.UploadFile.title"
          description="section original condition title"
          defaultMessage="Upload evidences"
        />
      }
      description={
        <FormattedMessage
          id="Claims.originalCondition.description"
          description="section original condition description"
          defaultMessage=""
          values={{
            sup: (...chunks) => <sup>{chunks}</sup>
          }}
        />
      }
      alignDescription="center"
      backgroundImage={uploadEvidences}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className="step-form--label mb-2">
          <FormattedMessage
            id="Claims.UploadFile"
            description="text label setp upload field of claim"
            defaultMessage="Please upload pictures as evidence for claim"
          />
        </label>
        <Controller
          name="files"
          control={control}
          defaultValue={[]}
          render={({ field: { name, onChange: handleOnChange } }) => (
            <Dropzone name={name} handleOnChange={handleOnChange} error={errors.files?.message} />
          )}
        />
        <div className="btn-container">
          <button className="btn btn-dark" type="submit">
            {intl.formatMessage(BUTTON_MESSAGES.ok)}
          </button>
        </div>
        <ProgressBar
          progress={80}
          handleNext={handleSubmit(onSubmit)}
          RoutePrev={`../${lang}-${instance}/claims/step-4`}
        />
      </form>
    </Layout>
  )
}

export default Step5
