export const updateAction = (state, payload) => {
  console.log("updateAction ... ", { ...state, ...payload })
  return {
    ...state,
    ...payload
  }
}

export const clearAction = (state, payload) => {
  return {}
}
