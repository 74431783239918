import { defineMessages } from "react-intl"

export const MSGS = defineMessages({
  withoutCountry: {
    id: "Form.field.Region.placeholder.withoutCountry",
    description: "placeholder no country selected for SelectRegion",
    defaultMessage: "Select first a country"
  },
  withCountry: {
    id: "Form.field.Region.placeholder.withCountry",
    description: "placeholder for autocomplete SelectRegion",
    defaultMessage: "Type your state"
  }
})
