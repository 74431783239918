import { defineMessages } from "react-intl"
import facilLimpieza from "../img/facil-limpieza.svg"
import impermeabilidad from "../img/impermeabilidad.svg"
import microorganismos from "../img/microorganismos.svg"
import retardanciaFuego from "../img/retardancia-fuego.svg"
import abrasion from "../img/abrasion.svg"
import durabilidadColor from "../img/durabilidad-color.svg"
import estabilidadDimensionalIcon from "../img/estabilidad.svg"
import resistenciaDeslizamientoIcon from "../img/deslizamiento.svg"
import petFriendlyIcon from "../img/pet-friendly.svg"

export const MSGS = defineMessages({
  facilLimpieza: {
    id: "Pranna.ProductDetail.easyCleaning",
    description: "Pranna section product detail item easy cleaning",
    defaultMessage: "Fácil limpieza"
  },
  impermeabilidad: {
    id: "Pranna.ProductDetail.impermeability",
    description: "Pranna section product detail item impermeability",
    defaultMessage: "Impermeabilidad"
  },
  microorganismos: {
    id: "Pranna.ProductDetail.resistanceToMicroorganisms",
    description: "Pranna section product detail item microorganismos",
    defaultMessage: "Resistencia a {br} microorganismos"
  },
  estabilidadDimensional: {
    id: "Floors.ProductDetail.dimensionalStability",
    description: "Floors section product detail item dimensionalStability",
    defaultMessage: "Estabilidad {br} dimensional"
  },
  durabilidadColor: {
    id: "Pranna.ProductDetail.colorDurability",
    description: "Pranna section product detail item color durability",
    defaultMessage: "Durabilidad del {br} color a la luz"
  },
  resistenciaDeslizamiento: {
    id: "Floors.ProductDetail.slipResistance",
    description: "Floors section product detail item slipResistance",
    defaultMessage: "Resistencia al {br} deslizamiento"
  },
  abrasion: {
    id: "Pranna.ProductDetail.abrationRetardancy",
    description: "Pranna section product detail item abration retardancy",
    defaultMessage: "Resistencia a {br} la abrasión"
  },
  retardanciaFuego: {
    id: "Pranna.ProductDetail.flameRetardancy",
    description: "Pranna section product detail item flame retardancy",
    defaultMessage: "Retardante {br} al fuego"
  }
})

export const PRODUCT_DETAILS = [
  {
    id: "facilLimpieza",
    text: MSGS.facilLimpieza,
    icon: facilLimpieza
  },
  {
    id: "impermeabilidad",
    text: MSGS.impermeabilidad,
    icon: impermeabilidad
  },
  {
    id: "microorganismos",
    text: MSGS.microorganismos,
    icon: microorganismos
  },
  {
    id: "estabilidadDimensional",
    text: MSGS.estabilidadDimensional,
    icon: estabilidadDimensionalIcon
  },
  {
    id: "durabilidadColor",
    text: MSGS.durabilidadColor,
    icon: durabilidadColor
  },
  {
    id: "resistenciaDeslizamiento",
    text: MSGS.resistenciaDeslizamiento,
    icon: resistenciaDeslizamientoIcon
  },
  {
    id: "abrasion",
    text: MSGS.abrasion,
    icon: abrasion
  },
  {
    id: "retardanciaFuego",
    text: MSGS.retardanciaFuego,
    icon: retardanciaFuego
  },
  {
    id: "petfrinedly",
    icon: petFriendlyIcon
  }
]
