import gql from "graphql-tag"

const PatternsBySearchQuery = gql`
  query PatternsBySearchQuery($instanceIds: [ID], $slugs: String) {
    patterns(products_Instances: $instanceIds, slug_In: $slugs) {
      edges {
        node {
          id
          name
          altName
          slug
          isNew
          imageThumbSmall
          productcategorization {
            design
          }
          products(instances: $instanceIds) {
            totalCount
            edges {
              node {
                id
                code
                color
                predominantColors
                imageThumbSmall
                isNew
              }
            }
          }
        }
      }
    }
  }
`

export default PatternsBySearchQuery
