import { defineMessages } from "react-intl"
import residentialIcon from "../img/residential.svg"
import officesIcon from "../img/offices.svg"
import educationIcon from "../img/education.svg"
import healthIcon from "../img/health.svg"
import hotelsRestaurantsIcon from "../img/hotelsRestaurants.svg"
import publicSpacesIcon from "../img/publicSpaces.svg"

export const MSGS = defineMessages({
  residential: {
    id: "FloorDetail.uses.residential",
    description: "FloorDetail section uses item residential",
    defaultMessage: "Residencial"
  },
  offices: {
    id: "FloorDetail.uses.offices",
    description: "FloorDetail section uses item offices",
    defaultMessage: "Oficinas"
  },
  education: {
    id: "FloorDetail.uses.education",
    description: "FloorDetail section uses item education",
    defaultMessage: "Educación"
  },
  health: {
    id: "FloorDetail.uses.health",
    description: "FloorDetail section uses item health",
    defaultMessage: "Salud"
  },
  hotelsRestaurants: {
    id: "FloorDetail.uses.hotelsRestaurants",
    description: "FloorDetail section uses item hotels and restaurants",
    defaultMessage: "Hoteles y {br} restaurantes"
  },
  publicSpaces: {
    id: "FloorDetail.uses.publicSpaces",
    description: "FloorDetail section uses item public spaces",
    defaultMessage: "Espacios {br} públicos"
  }
})

export const USES_ITEMS = [
  {
    id: "residential",
    text: MSGS.residential,
    icon: residentialIcon
  },
  {
    id: "offices",
    text: MSGS.offices,
    icon: officesIcon
  },
  {
    id: "education",
    text: MSGS.education,
    icon: educationIcon
  },
  {
    id: "health",
    text: MSGS.health,
    icon: healthIcon
  },
  {
    id: "hotelsRestaurants",
    text: MSGS.hotelsRestaurants,
    icon: hotelsRestaurantsIcon
  },
  {
    id: "publicSpaces",
    text: MSGS.publicSpaces,
    icon: publicSpacesIcon
  }
]
