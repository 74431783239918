import leftArrow from "../img/left-chevron.png"
import rightArrow from "../img/right-arrow.png"

export const SETTINGS_CAROUSEL = (isMobile) => ({
  dots: isMobile,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  arrows: !isMobile,
  nextArrow: <img width={32} src={rightArrow} alt="next-arrow" />,
  prevArrow: <img width={32} src={leftArrow} alt="prev-arrow" />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
})
