import PropTypes from "prop-types"
import { Link } from "react-scroll"
import classNames from "classnames/bind"
import useToggle from "hooks/useToggle"
import RenderObject from "./components/RenderObject"
import Burger from "./components/BurgerBtn"
import "./styles/navbar.scss"

export const Menu = ({ logo, items = [], objItems = [] }) => {
  const [toggable, setToggable] = useToggle()

  const handleRenderArray = () =>
    items.map((item) => (
      <li className="main-menu-sections__section-masiala" key={`key_${item}`}>
        <div className="text-capitalize">
          <Link
            className="header-menu-button-masiala"
            activeClass="header-menu-button-masiala--active"
            to={item}
            duration={100}
            offset={-50}
            smooth
            spy
          >
            {item}
          </Link>
        </div>
      </li>
    ))

  return (
    <div className="align-items-center justify-content-between  container-menu-masiala w-100">
      <div className="row g-0">
        <div className="col-10 col-md-11 ps-5 ps-md-5 ps-lg-0 col-lg-2 order-md-0">
          <Link to="home-slide" className="logo-masiala ps-sm-4 ps-md-4  ps-lg-0  cursor-pointer" duration={100} smooth={true}>
            {logo && (
              <img
                className="img-fluid"
                src={logo}
                alt="Spradling Group"
                title="Spradling Group"
                width="150px"
                height="50px"
              />
            )}
          </Link>
        </div>
        <div className="col-2 col-md-1 col-lg-10 order-md-1">
          <Burger toggable={toggable} setToggable={setToggable} />
          <div
            className={classNames({
              "header-main-menu-masiala": true,
              "header-main-menu-masiala--open": toggable
            })}
          >
            <ul
              className={classNames({
                "main-menu-sections-masiala": true,
                "main-menu-sections-masiala--open": toggable
              })}
            >
              {!items ? <RenderObject items={objItems} /> : handleRenderArray()}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

Menu.propTypes = {
  logo: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  objItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.object,
      externalLink: PropTypes.bool,
      href: PropTypes.string
    })
  )
}

export default Menu
