import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { Container, Modal, ModalBody } from "reactstrap"
import "./styles/ModalPrivacyPolicy.scss"
import classNames from "classnames"

const ModalPrivacyPolicy = ({ btnClassName = "", isOpen, toggle, acceptPrivacyPolicy }) => {
  const handleClickInAcceptPrivacyPolicy = () => {
    acceptPrivacyPolicy(true)
    toggle()
  }

  return (
    <Modal
      size="xl"
      className="modal-lg sampling-request-modal"
      scrollable
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalBody className="p-5">
        <Container>
          <div className="mb-5">
            <h1 className="mb-3">Privacy Policy</h1>
            <div>
              <p>
                Spradling Holdings Inc., and its affiliates and subsidiaries, (collectively,
                “Spradling,” “Company”, “Us”, “Our”, or “We”) recognize that you may be concerned
                about our collection, use, and disclosure of your personal information. We respect
                your privacy and are committed to providing a transparent notice of our privacy
                practices.
              </p>

              <p>
                This policy describes the types of information We may collect from you when you
                visit our website(s), including spradling.group&nbsp;(our “<strong>Websites</strong>
                “). This policy also covers information. We collect about you offline, from third
                parties, or when you contact us by phone. This policy describes our practices for
                collecting, using, maintaining, protecting, and disclosing that information.
              </p>

              <p>
                Please read this policy carefully to understand our policies and practices regarding
                your information and how We will treat it. This policy may change from time to time
                (see Changes to Our Privacy Policy).
              </p>

              <h3>
                <strong>Information Collection</strong>
              </h3>

              <p>
                Spradling does not sell directly to consumers, which means the information we
                collect is typically from companies or individuals in their capacity as employee
                owner, director, officer, or contractor of those companies. We collect several types
                of information from and about you, including:
              </p>

              <ul>
                <li className="list-item-privacy-policy--usa">
                  <p>
                    <strong>Information provided by you</strong>, such as name, address, email
                    address, telephone number, and other personally identifying information. For
                    example, We collect information from you when you fill out a form requesting
                    samples on our website or signing up for our newsletter;
                  </p>
                </li>
                <li className="list-item-privacy-policy--usa">
                  <p>
                    <strong>Information collected automatically</strong>&nbsp;as you navigate
                    through the site. Information collected automatically may include usage details,
                    domain address, IP addresses, internet browser, operating system, your internet
                    connection, the equipment you use to access our Website, and information
                    collected through cookies.
                  </p>
                </li>
              </ul>

              <h3>
                <strong>Information Use</strong>
              </h3>

              <p>We use information that We collect about you or that you provide to us:</p>

              <ul>
                <li className="list-item-privacy-policy--usa">
                  to present our Website and its contents to you and to improve our website&nbsp;(We
                  continually strive to improve our website offerings based on the information and
                  feedback We receive from you);
                </li>
                <li className="list-item-privacy-policy--usa">
                  to provide you with information or services that you request from us;
                </li>
                <li className="list-item-privacy-policy--usa">
                  to carry out Our obligations and enforce our rights arising from any contracts;
                </li>
                <li className="list-item-privacy-policy--usa">
                  to notify you about changes to our Website or any products or services We offer or
                  provide though it;
                </li>
                <li className="list-item-privacy-policy--usa">
                  to enable communications to you regarding our products;
                </li>
                <li className="list-item-privacy-policy--usa">
                  to facilitate other requests such as directing you to an appropriate sales
                  representative;
                </li>
                <li className="list-item-privacy-policy--usa">
                  to fulfill any other purpose for which you provide it and in any other way We may
                  describe when you provide the information; and
                </li>
                <li className="list-item-privacy-policy--usa">
                  for any other purpose with your consent.
                </li>
              </ul>

              <h3>
                <strong>Disclosure Of Your Information</strong>
              </h3>

              <p>
                We may disclose information that We collect about you or that you provide us
                directly as described in this privacy policy:
              </p>

              <ul>
                <li className="list-item-privacy-policy--usa">
                  to our subsidiaries and affiliates;
                </li>
                <li className="list-item-privacy-policy--usa">
                  to contractors, service providers, and other third parties We use to support our
                  business;
                </li>
                <li className="list-item-privacy-policy--usa">
                  to a buyer or other successor in the event of a merger, divestiture,
                  restructuring, reorganization, dissolution, or other sale or transfer of some or
                  all of Company’s assets, whether as a going concern or as part of bankruptcy,
                  liquidation, or similar proceeding, in which personal information held by Company
                  about our Website users is among the assets transferred;
                </li>
                <li className="list-item-privacy-policy--usa">
                  to fulfill the purpose for which you provide it—for example, to process your
                  inquiry regarding our products;
                </li>
                <li className="list-item-privacy-policy--usa">
                  for any other purpose disclosed by us when you provide the information;
                </li>
                <li className="list-item-privacy-policy--usa">
                  to comply with any court order, law, or legal process, including to respond to any
                  government, regulatory, or law enforcement request;
                </li>
                <li className="list-item-privacy-policy--usa">
                  to enforce or apply terms and conditions and other agreements
                </li>
                <li className="list-item-privacy-policy--usa">
                  if We believe disclosure is necessary or appropriate to protect the rights,
                  property, or safety of Company, our customers, or others. This includes exchanging
                  information with other companies and organizations for the purposes of fraud
                  protection and credit risk reduction; and
                </li>
                <li className="list-item-privacy-policy--usa">with your consent.</li>
              </ul>

              <p>
                We may disclose aggregated information and deidentified data or information that
                does not identify an individual.
              </p>

              <h3>
                <strong>Security</strong>
              </h3>

              <p>
                We have implemented reasonable measures designed to secure your personal information
                from accidental loss and from unauthorized access, use, alteration, and disclosure.
                The safety and security of your information also depends on you. Where We have given
                you (or where you have chosen) a password for access to certain parts of our
                Website, you are responsible for keeping this password confidential. We ask you not
                to share your password with anyone.
              </p>

              <h3>
                <strong>Links</strong>
              </h3>

              <p>
                This Website may contain links to other sites. Please be aware that We are not
                responsible for the content or privacy practices of such other sites. We encourage
                our users to be aware when they leave our site and to read the privacy statements of
                any other site that collects personally identifiable information.
              </p>

              <h3>
                <strong>Children Under The Age Of 13</strong>
              </h3>

              <p>
                Our Website is not intended for children under 13 years of age. No one under age 13
                may provide any information to or on the Website. We do not knowingly collect
                personal information from children under 13. If you are under 13, do not use or
                provide any information to or on this Website or through any of its features. If We
                learn We have collected or received personal information from a child under 13
                without verification of parental consent, We will delete that information. If you
                believe We might have any information from or about a child under 13, please contact
                us in one of the ways provided in the Contact Information section, below.
              </p>

              <h3>
                <strong>Do Not Track</strong>
              </h3>

              <p>
                We do not respond to Web browser “do not track” signals or other mechanisms. Other
                parties may collect personally identifiable information about your activities over
                time and across different Web sites when a consumer uses our Web site or service.
              </p>

              <h3>
                <strong>Changes To Our Privacy Policy</strong>
              </h3>

              <p>
                It is our policy to post any changes We make to our privacy policy on this page.
              </p>

              <h3>
                <strong>Contacting Us</strong>
              </h3>

              <p>If there are any questions regarding this privacy policy you may contact us at:</p>

              <p>&nbsp;</p>

              <p>
                <strong>Via telephone</strong> at 800.333.0955
              </p>

              <p>
                <strong>Via e-mail</strong> at info@spradlingvinyl.com
              </p>

              <p>
                <strong>Via mail</strong> at: 200 Cahaba Valley Pkwy, Pelham, AL 35124
              </p>

              <p>
                <strong>Attn:</strong> Privacy Policy
              </p>
            </div>
          </div>
          <div className="text-center">
            <button
              className={classNames({
                "text-white button-form-masiala": !btnClassName,
                [btnClassName]: btnClassName
              })}
              onClick={handleClickInAcceptPrivacyPolicy}
            >
              <FormattedMessage
                id="Kizuna.acceptPrivacyPolicyTerms"
                defaultMessage="Accept terms"
              />
            </button>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  )
}

ModalPrivacyPolicy.propTypes = {
  btnClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  acceptPrivacyPolicy: PropTypes.func
}

export default ModalPrivacyPolicy
