import { FormattedMessage } from "react-intl"

const title = (
  <FormattedMessage
    id="CleaningMarine.General.HowItWorks.title"
    defaultMessage="To Keep In Mind!"
  />
)

export const KEEP_IN_MIND_ITEM_CLEANING_CARE = {
  title,
  description: (
    <FormattedMessage
      id="CleaningMarine.Chill.BoatSeats.HowItWorks.descriptions"
      defaultMessage="Failure to care for your vinyl properly, or use of improper cleaners, may void your warranty & damage your vinyl."
    />
  )
}
