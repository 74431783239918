import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import { PRODUCT_DETAILS } from "./constants"
import ProductDetailsGeneric from "components/ProductDetail"

const ProductDetails = ({ id }) => (
  <div id={id}>
    <div className="container my-2 py-4">
      <div className="row justify-content-md-center">
        <div className="col-12 text-center">
          <TitleUnderlined className="mx-2 mt-2 mb-4" hTag={H2_TAG}>
            <FormattedMessage
              id="WhisperNeoPlus.ProductDetail.subtitle"
              description="WhisperNeoPlus section title product detail"
              defaultMessage="Product Details"
            />
          </TitleUnderlined>
        </div>
        <div className="row">
          <ProductDetailsGeneric items={PRODUCT_DETAILS} />
        </div>
        <div className="text-center mt-4">
          <a
            className="btn btn-dark"
            href="https://spradling-misc.s3.us-west-2.amazonaws.com/landing-attachments/Whisper+Neo+Plus.pdf"
            rel="noreferrer"
            target="_blank"
          >
            <FormattedMessage
              id="WhisperNeoPlus.ProductDetail.printProductDetail"
              description="WhisperNeoPlus text btn section product detail"
              defaultMessage="Print product detail"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
)

ProductDetails.propTypes = {
  id: PropTypes.string.isRequired
}

export default ProductDetails
