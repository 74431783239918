import { useState } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import { STATIC_SLIDER, SLIDER_OPTIONS } from "./constants"
import "./styles/applications.scss"

const Applications = ({ items }) => {
  const [slider, setSlider] = useState()
  const [selectSlider, setSelectSlider] = useState()

  return (
    <>
      <Slider
        className="mx-md-5 my-4 p-0 slider-sections"
        {...STATIC_SLIDER}
        asNavFor={slider}
        ref={(slider) => setSelectSlider(slider)}
        slidesToShow={items.length}
        focusOnSelect={true}
      >
        {slider?.props.children.map(({ key, props: { id, title } }) => (
          <span key={`selectable_slider_${key}`}>{title}</span>
        ))}
      </Slider>
      <div>
        <Slider
          className="slider-img img-application"
          {...SLIDER_OPTIONS}
          ref={(ref) => setSlider(ref)}
          asNavFor={selectSlider}
        >
          {items.map(({ id, imageThumb, ...rest }) => (
            <img
              key={`key_${id}`}
              id={id}
              className="img-application"
              src={imageThumb}
              alt="application"
              {...rest}
            />
          ))}
        </Slider>
      </div>
    </>
  )
}

Applications.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.object,
      imageThumb: PropTypes.string
    })
  ).isRequired
}

export default Applications
