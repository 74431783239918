import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"

const Card = ({ id, altName, slug, productcategorization, imageThumbSmall }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleClick = () => {
    navigate(`..${pathname}/piso/${slug}`)
  }

  return (
    <div className="container-reference" key={`key_${id}`} onClick={handleClick}>
      {imageThumbSmall && (
        <img className="img-fluid img-reference" src={imageThumbSmall} alt={slug} />
      )}
      <div className="pt-2 px-2">
        <h3 className="text-capitalize m-0">{altName}</h3>
        {productcategorization?.design && (
          <p className="text-capitalize">
            <FormattedMessage
              id="Petfriendly.References.appearance"
              description="Petfriendly section references appearance text on card"
              defaultMessage="Apariencia"
            />
            <ul>
              {productcategorization?.design.map((item) => (
                <li key={item} className="ms-1">
                  {item.toLowerCase()}
                </li>
              ))}
            </ul>
          </p>
        )}
      </div>
    </div>
  )
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  altName: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  productcategorization: PropTypes.object.isRequired,
  imageThumbSmall: PropTypes.string.isRequired
}

export default Card
