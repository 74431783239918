import { defineMessages } from "react-intl"

export const DIFFERENCES_IN_HAND_MSGS = defineMessages({
  describeTheDifference: {
    id: "Claims.DifferencesInHand.describeTheDifference",
    description: "field describe the difference for difference in hand claim reason",
    defaultMessage: "How can you describe the hand difference?"
  },
  howDetectDifference: {
    id: "Claims.DifferencesInHand.howDetectDifference",
    description: "field how detect the difference for difference in hand claim reason",
    defaultMessage: "How do you detect the difference?"
  },
  howAffectDifference: {
    id: "Claims.DifferencesInHand.howAffectDifference",
    description: "field how affect the difference for difference in hand claim reason",
    defaultMessage: "How does it affect your process?"
  }
})

const DIFFERENCES_MSGS = defineMessages({
  stifferHand: {
    id: "Claims.DifferencesInHand.describeTheDifference.option.stifferHand",
    description:
      "option Stiffer hand for field describe the difference for difference in hand claim reason",
    defaultMessage: "Stiffer hand"
  },
  softerHand: {
    id: "Claims.DifferencesInHand.describeTheDifference.option.softerHand",
    description:
      "option Softer hand for field describe the difference for difference in hand claim reason",
    defaultMessage: "Softer hand"
  }
})

export const DIFFERENCES_OPTIONS = [
  {
    id: "stifferHand",
    label: DIFFERENCES_MSGS.stifferHand,
    value: "stiffer hand"
  },
  {
    id: "softerHand",
    label: DIFFERENCES_MSGS.softerHand,
    value: "softer hand"
  }
]
