import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "react-intl"
import { Controller } from "react-hook-form"
import Select from "react-select"
import { useQuery } from "@apollo/client"
import countriesQuery from "./query"
import { MSGS } from "./constants"
import styles from "components/select/styles"

const SelectCountry = ({
  labelClassName,
  name,
  control,
  disabled = false,
  isSearchable = true,
  options: optionsByProps = []
}) => {
  const intl = useIntl()
  const [countries, setCountries] = useState(null)
  const { loading, data, fetchMore } = useQuery(countriesQuery, {
    variables: {
      first: 100
    }
  })
  const ph = loading ? intl.formatMessage(MSGS.loading) : intl.formatMessage(MSGS.placeholder)
  const options = countries?.edges.map((edge) => ({
    label: edge.node.name,
    value: edge.node.id
  }))

  useEffect(() => {
    if (data && data.countries) {
      setCountries(data.countries)
      if (data.countries.pageInfo.hasNextPage) {
        fetchMore({
          variables: {
            after: data.countries.pageInfo.endCursor
          }
        })
      }
    }
  }, [data])

  return (
    <>
      <label htmlFor={name} className={labelClassName}>
        <FormattedMessage
          id="Form.field.Country"
          description="Form field country"
          defaultMessage="Country"
        />
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange: handleOnChangeForm, ...field } }) => (
          <Select
            styles={styles}
            {...field}
            options={!optionsByProps.length ? options : optionsByProps}
            placeholder={ph}
            isDisabled={disabled}
            isSearchable={isSearchable}
            onChange={handleOnChangeForm}
            noOptionsMessage={() => (
              <FormattedMessage
                id="Form.field.noOptionesMessage"
                description="message when there's no options on a select field"
                defaultMessage="No options"
              />
            )}
          />
        )}
      />
    </>
  )
}

SelectCountry.propTypes = {
  labelClassName: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool
}

export default SelectCountry
