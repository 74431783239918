import { FormattedMessage } from "react-intl"
import VideoCommon from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"
import poster from "./img/poster.jpg"

const Video = () => {
  const isMobile = useMediaQuery(SM)

  return (
    <div className="container my-2 py-4">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="p-4">
            <h1>
              <FormattedMessage
                id="Permacool.Video.title"
                description="title section video Permacool"
                defaultMessage="The sun’s rays have{br}finally be tamed"
                values={{ br: <br /> }}
              />
            </h1>
            <p className="mt-4">
              <FormattedMessage
                id="Permacool.Video.description"
                description="description title section video permacool"
                defaultMessage="At Spradling, we have developed an innovative technology to ensure even more comfortable surfaces.{br}{br}A highly advanced reflective protection, designed to reduce the amount of radiant energy or heat absorbed by surfaces, thereby improving the thermal sensation of upholstery during hot sunny days."
                values={{ br: <br /> }}
              />
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 align-self-center">
          <VideoCommon className="col-12 img-fluid" poster={poster} fromYoutube>
            <iframe
              title="video"
              width="100%"
              height={isMobile ? "250" : "315"}
              src="https://www.youtube.com/embed/kl87XoohYB0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoCommon>
        </div>
      </div>
    </div>
  )
}

export default Video
