import poster from "./assets/media/poster.png"
import VideoCommon from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"

const VIDEO_URL = "https://www.youtube.com/embed/rEqRi92-kvM"

const Video = () => {
  const isMobile = useMediaQuery(SM)
  return (
    <div className="container my-2 py-4">
      <div className="row">
        <div className="col-12 align-self-center">
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="Pranna.Video.title"
              description="title section of Pranna page"
              defaultMessage="Beneficios"
            />
          </TitleUnderlined>
          <p className="text-center">
            <FormattedMessage
              id="Dali.Video.description"
              description="description for title section of Dalí page"
              defaultMessage="Dalí cuenta con una variedad de 13 colores para tapicería interior en tráfico ligero ideal para diferentes sectores.{br}{br}Te invitamos a ver este video donde podrás conocer sus beneficios."
              values={{
                br: <br />
              }}
            />
          </p>
          <VideoCommon className="img-fluid" poster={poster} fromYoutube>
            <iframe
              title="video"
              width="100%"
              height={isMobile ? "250" : "650"}
              src={VIDEO_URL}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoCommon>
        </div>
      </div>
    </div>
  )
}

export default Video
