import { FormattedMessage } from "react-intl"

// EARTHY WARMS IMPORTS
import earthyWarmsLandscape from "../img/earthyWarms/paisaje-960.jpg"
// PLAIN IMG
import scarlet from "modules/rivulet/components/SamplesDetails/img/products/plain/1_scarlet.jpg"
import mesa from "modules/rivulet/components/SamplesDetails/img/products/plain/2_mesa.jpg"
import apricot from "modules/rivulet/components/SamplesDetails/img/products/plain/3_apricot.jpg"
import grapefruit from "modules/rivulet/components/SamplesDetails/img/products/plain/4_grapefruit.jpg"
// MONTAIN IMG
import scarletMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/1_scarlet.jpg"
import mesaMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/2_mesa.jpg"
import apricotMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/3_apricot.jpg"
import grapefruitMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/4_grapefruit.jpg"

// LUSHIOUS GREENS IMPORTS
import lushiousGreensLandscape from "../img/lushiousGreens/IMAGENES-03.jpg"
// PLAIN IMG
import clover from "modules/rivulet/components/SamplesDetails/img/products/plain/6_clover.jpg"
import leaf from "modules/rivulet/components/SamplesDetails/img/products/plain/7_leaf.jpg"
import olive from "modules/rivulet/components/SamplesDetails/img/products/plain/8_olive.jpg"
import pistachio from "modules/rivulet/components/SamplesDetails/img/products/plain/9_pistachio.jpg"
// MONTAIN IMG
import cloverMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/6_clover.jpg"
import leafMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/7_leaf.jpg"
import oliveMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/8_olive.jpg"
import pistachioMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/9_pistachio.jpg"

// FLOWING BlUES IMPORTS
import flowingBluesLandscape from "../img/flowingBlues/IMAGENES-02.jpg"
// PLAIN IMG
import pool from "modules/rivulet/components/SamplesDetails/img/products/plain/12_pool.jpg"
import capri from "modules/rivulet/components/SamplesDetails/img/products/plain/13_capri.jpg"
import mist from "modules/rivulet/components/SamplesDetails/img/products/plain/14_mist.jpg"
import river from "modules/rivulet/components/SamplesDetails/img/products/plain/15_river.jpg"
// MONTAIN IMG
import poolMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/12_pool.jpg"
import capriMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/13_capri.jpg"
import mistMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/14_mist.jpg"
import riverMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/15_river.jpg"

// CALM NATURALS IMPORTS
import calmNaturalsLandscape from "../img/calmNaturals/IMAGENES-01.jpg"
// PLAIN IMG
import darkRoast from "modules/rivulet/components/SamplesDetails/img/products/plain/16_darkRoast.jpg"
import sepia from "modules/rivulet/components/SamplesDetails/img/products/plain/17_sepia.jpg"
import chai from "modules/rivulet/components/SamplesDetails/img/products/plain/18_chai.jpg"
import mushroom from "modules/rivulet/components/SamplesDetails/img/products/plain/19_mushroom.jpg"
// MONTAIN IMG
import darkRoastMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/16_darkRoast.jpg"
import sepiaMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/17_sepia.jpg"
import chaiMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/18_chai.jpg"
import mushroomMontain from "modules/rivulet/components/SamplesDetails/img/products/montain/19_mushroom.jpg"

export const COLORS_INSPIRATION_SAMPLES = [
  {
    inspirationColorTitle: (
      <FormattedMessage id="Rivulet.ColorInspiration.colorEarthy" defaultMessage="Earthy Warms" />
    ),
    inspirationColorDescription: (
      <FormattedMessage
        id="Rivulet.ColorInspiration.colorEarthyDescription"
        defaultMessage="With a palette featuring rich, earthy hues like deep reds, burnt oranges, and golden yellows, this pattern exudes warmth and comfort."
      />
    ),
    inspirationColorImage: earthyWarmsLandscape,
    demostrationSamples: [
      {
        description: "Scarlet",
        imgDetail: scarletMontain,
        imageSample: scarlet
      },
      {
        description: "Mesa",
        imgDetail: mesaMontain,
        imageSample: mesa
      },
      {
        description: "Apricot",
        imgDetail: apricotMontain,
        imageSample: apricot
      },
      {
        description: "Grapefruit",
        imgDetail: grapefruitMontain,
        imageSample: grapefruit
      }
    ]
  },
  {
    inspirationColorTitle: (
      <FormattedMessage
        id="Rivulet.ColorInspiration.colorLushious"
        defaultMessage="Lushious Greens"
      />
    ),
    inspirationColorDescription: (
      <FormattedMessage
        id="Rivulet.ColorInspiration.colorLushiousDescription"
        defaultMessage="Rivulet’s green colors evokes the serenity of a tranquil forest or the refreshing allure of a meandering stream."
      />
    ),
    inspirationColorImage: lushiousGreensLandscape,
    demostrationSamples: [
      {
        description: "Clover",
        imgDetail: cloverMontain,
        imageSample: clover
      },
      {
        description: "Leaf",
        imgDetail: leafMontain,
        imageSample: leaf
      },
      {
        description: "Olive",
        imgDetail: oliveMontain,
        imageSample: olive
      },
      {
        description: "Pistachio",
        imgDetail: pistachioMontain,
        imageSample: pistachio
      }
    ]
  },
  {
    inspirationColorTitle: (
      <FormattedMessage id="Rivulet.ColorInspiration.colorFlowing" defaultMessage="Flowing Blues" />
    ),
    inspirationColorDescription: (
      <FormattedMessage
        id="Rivulet.ColorInspiration.colorFlowingDescription"
        defaultMessage="With a spectrum of blues ranging from soothing pastels to deep, oceanic hues, this pattern invokes a sense of tranquility and depth."
      />
    ),
    inspirationColorImage: flowingBluesLandscape,
    demostrationSamples: [
      {
        description: "Pool",
        imgDetail: poolMontain,
        imageSample: pool
      },
      {
        description: "Capri",
        imgDetail: capriMontain,
        imageSample: capri
      },
      {
        description: "Mist",
        imgDetail: mistMontain,
        imageSample: mist
      },
      {
        description: "River",
        imgDetail: riverMontain,
        imageSample: river
      }
    ]
  },
  {
    inspirationColorTitle: (
      <FormattedMessage id="Rivulet.ColorInspiration.colorCalm" defaultMessage="Calm Naturals" />
    ),
    inspirationColorDescription: (
      <FormattedMessage
        id="Rivulet.ColorInspiration.colorCalmDescription"
        defaultMessage="These timeless shades create a serene and balanced atmosphere, making them perfect for any interior design scheme."
      />
    ),
    inspirationColorImage: calmNaturalsLandscape,
    demostrationSamples: [
      {
        description: "Dark Roast",
        imgDetail: darkRoastMontain,
        imageSample: darkRoast
      },
      {
        description: "Sepia",
        imgDetail: sepiaMontain,
        imageSample: sepia
      },
      {
        description: "Chai",
        imgDetail: chaiMontain,
        imageSample: chai
      },
      {
        description: "Mushroom",
        imgDetail: mushroomMontain,
        imageSample: mushroom
      }
    ]
  }
]
