import PropTypes from "prop-types"
import { FaAngleDown, FaFileArrowDown, FaMinus, FaPlus } from "react-icons/fa6"

const IconAccordion = ({ id, isDownloadIcon, isOpen, onlySpecs = false }) => {
  return (
    <>
      {isDownloadIcon && (
        <FaFileArrowDown id={id} className="float-end mt-1 me-1 size-icon-masiala" />
      )}

      {isOpen && !isDownloadIcon && !onlySpecs && (
        <FaMinus id={id} className="float-end mt-1 me-1 size-icon-masiala" />
      )}

      {!isOpen && !isDownloadIcon && !onlySpecs && (
        <FaPlus id={id} className="float-end mt-1 me-1 size-icon-masiala" />
      )}

      {!isOpen && onlySpecs && !isDownloadIcon && (
        <FaAngleDown id={id} className="float-end mt-1 me-1 size-icon-masiala" />
      )}
    </>
  )
}

IconAccordion.propTypes = {
  id: PropTypes.string,
  isDownloadIcon: PropTypes.bool,
  isOpen: PropTypes.bool,
  onlySpecs: PropTypes.bool
}

export default IconAccordion
