import Header from "components/Header"
import Banner from "components/Banner"
import { GLOBAL_SITE_TAG, NAV_ITEMS } from "./constants"
import { SLIDE } from "./components/Banner/constants"
import ColorPalette from "modules/linetex/components/ColorPalette"
import Applications from "modules/linetex/components/Applications"
import ProductDetails from "modules/linetex/components/ProductDetails"
import Benefits from "modules/linetex/components/Benefits"
import Video from "modules/linetex/components/Video"
import WhereToBuy from "modules/petfriendly/components/WhereToBuy"
import Analytics from "components/Analytics"
import { getCurrentInstanceId } from "services/instances"

const Linetex = () => (
  <>
    <Analytics gtag={GLOBAL_SITE_TAG[getCurrentInstanceId()]} />
    <Header items={NAV_ITEMS} isTransparent />
    <div className="content">
      <Banner slides={SLIDE} />
      <Video />
      <ColorPalette id={NAV_ITEMS[0]} />
      <Applications id={NAV_ITEMS[1]} />
      <ProductDetails id={NAV_ITEMS[2]} />
      <Benefits />
      <WhereToBuy id={NAV_ITEMS[3]} />
    </div>
  </>
)

export default Linetex
