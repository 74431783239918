import { useStateMachine } from "little-state-machine"
import { useIntl } from "react-intl"
import { updateAction } from "utils/littleStateMachine"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { VALIDATION_MESSAGES } from "utils/forms"
import InputTextArea from "components/InputMUI/TextArea"
import { GENERIC_MSGS } from "modules/claims/constants"
import { BUTTON_MESSAGES } from "utils/buttons"
import ProgressBar from "modules/claims/components/StepForm/ProgressBar"
import { DIFFERENCES_IN_DIMENSION_MSGS } from "./constants"
import { useNavigate } from "react-router-dom"
import { redirectToNextStep } from "../../../utils"

const DifferencesInDimension = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { actions, state } = useStateMachine({ updateAction })

  const defaultValues = { ...state }
  const schema = yup.object({
    howMeasuredMaterial: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    indicateDifference: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = (data) => {
    actions.updateAction(data)
    redirectToNextStep(navigate)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputTextArea
        name="howMeasuredMaterial"
        control={control}
        label={DIFFERENCES_IN_DIMENSION_MSGS.howMeasuredMaterial}
        placeholder={GENERIC_MSGS.typeAnswer_placeholder}
        defaultValue={state.howMeasuredMaterial}
        error={errors.howMeasuredMaterial?.message}
      />
      <InputTextArea
        name="indicateDifference"
        control={control}
        label={DIFFERENCES_IN_DIMENSION_MSGS.indicateDifference}
        placeholder={GENERIC_MSGS.typeAnswer_placeholder}
        defaultValue={state.indicateDifference}
        error={errors.indicateDifference?.message}
      />
      <div className="btn-container">
        <button className="btn btn-dark" type="submit">
          {intl.formatMessage(BUTTON_MESSAGES.ok)}
        </button>
      </div>
      <ProgressBar
        progress={60}
        handleNext={handleSubmit(onSubmit)}
        RoutePrev={state.previousStep}
      />
    </form>
  )
}

export default DifferencesInDimension
