import { connect } from "react-redux"
import SelectSearchMethod from "components/StoreFinder/components/SelectSearchMethod"
import { clearCurrentSearch } from "components/StoreFinder/actions"

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearCurrentSearch: () => dispatch(clearCurrentSearch())
  }
}

const SelectSearchMethodContainer = connect(mapStateToProps, mapDispatchToProps)(SelectSearchMethod)

export default SelectSearchMethodContainer
