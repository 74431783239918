import { defineMessages } from "react-intl"

export const SURFACE_DETERIORATION_MSGS = defineMessages({
  suggestedInstructions: {
    id: "Claims.SurfaceDeterioration.suggestedInstructions",
    description: "field suggested instructions in surface deterioration on claim",
    defaultMessage:
      "Are cleaning instructions suggested by the manufacturer followed to clean up the material?"
  },
  describeProcessUsed: {
    id: "Claims.SurfaceDeterioration.describeProcessUsed",
    description: "field describe process used in surface deterioration on claim",
    defaultMessage:
      "Please describe the material cleaning process, frequency and cleaning products used"
  },
  theMaterialContaminated: {
    id: "Claims.SurfaceDeterioration.theMaterialContaminated",
    description: "field material contaminated in surface deterioration on claim",
    defaultMessage:
      "Please specify the substances that the material has been in contact with (e.g. disinfectants, liquids, food, oil)"
  }
})
