import PropTypes from "prop-types"
import ProductInformationDetail from "./components/ProductInformationDetail"
import SpecAccordion from "./components/SpecAccordion"
import {
  CARE_AND_CLEANING,
  DIGITAL_CATALOGUE,
  PRODUCT_DETAILS,
  PRODUCT_SPECS,
  productSpecDetailsInformationToShow
} from "./constants/specs"
import {
  CARE_AND_CLEANING_GUIDE_INFO,
  DIGITAL_CATALOGUE_INFO,
  PRODUCT_DETAIL_INFO
} from "modules/masiala/constants"
import "./components/SustainableElements/styles/sustainable-elements.scss"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"

const ProductSpecs = ({ nav, handleClickInRequest, toggableAccordeon, handleToggleAccordeon }) => {
  const isMobile = useMediaQuery(LG)

  return (
    <section>
      <SpecAccordion
        id={nav[1].id}
        name="collapse1"
        togglable={toggableAccordeon.collapse1}
        handleOnClick={() => handleToggleAccordeon("collapse1")}
        acocordionContainerStyle="container-sustainable-product-profile--masiala"
        title={PRODUCT_SPECS.SUSTAINABLE_PRODUCT_PROFILE}
        contentToShow={
          <ProductInformationDetail
            isSustainableElements
            details={productSpecDetailsInformationToShow().SUSTAINABLE_PRODUCT_PROFILE}
          />
        }
      />
      <SpecAccordion
        id={nav[2].id}
        name="collapse2"
        togglable={toggableAccordeon.collapse2}
        handleOnClick={() => handleToggleAccordeon("collapse2")}
        title={PRODUCT_SPECS.CHARACTERISTICS}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow().CHARACTERISTICS}
          />
        }
      />
      <SpecAccordion
        name="collapse3"
        togglable={toggableAccordeon.collapse3}
        handleOnClick={() => handleToggleAccordeon("collapse3")}
        title={PRODUCT_SPECS.APPLICATIONS}
        contentToShow={
          <ProductInformationDetail details={productSpecDetailsInformationToShow().APPLICATIONS} />
        }
      />
      <SpecAccordion
        name="collapse4"
        togglable={toggableAccordeon.collapse4}
        handleOnClick={() => handleToggleAccordeon("collapse4")}
        title={PRODUCT_SPECS.PERFORMANCE}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow(isMobile).PERFORMANCE}
          />
        }
      />
      <SpecAccordion
        name="collapse5"
        togglable={toggableAccordeon.collapse5}
        handleOnClick={() => handleToggleAccordeon("collapse5")}
        title={PRODUCT_SPECS.FLAME_RETARDANCY}
        contentToShow={
          <ProductInformationDetail
            disclaimerContainerStyle="mt-1 mt-lg-4"
            details={productSpecDetailsInformationToShow().FLAME_RETARDANCY}
          />
        }
      />
      <SpecAccordion
        name="collapse6"
        togglable={toggableAccordeon.collapse6}
        handleOnClick={() => handleToggleAccordeon("collapse6")}
        title={PRODUCT_SPECS.WARRANTY}
        contentToShow={
          <ProductInformationDetail
            disclaimerContainerStyle="mt-1"
            details={productSpecDetailsInformationToShow().WARRANTY}
          />
        }
      />
      <SpecAccordion
        id={DIGITAL_CATALOGUE.toLowerCase()}
        title={PRODUCT_SPECS.DIGITAL_CATALOGUE}
        downloadIcon
        handleOnClick={() => {
          handleClickInRequest(DIGITAL_CATALOGUE_INFO)
        }}
      />
      <SpecAccordion
        id={CARE_AND_CLEANING.toLowerCase()}
        title={PRODUCT_SPECS.CARE_AND_CLEANING}
        downloadIcon
        handleOnClick={() => {
          handleClickInRequest(CARE_AND_CLEANING_GUIDE_INFO)
        }}
      />
      <SpecAccordion
        id={PRODUCT_DETAILS.toLowerCase()}
        title={PRODUCT_SPECS.PRODUCT_DETAILS}
        downloadIcon
        handleOnClick={() => {
          handleClickInRequest(PRODUCT_DETAIL_INFO)
        }}
      />
    </section>
  )
}

ProductSpecs.propTypes = {
  nav: PropTypes.arrayOf([PropTypes.object]),
  handleClickInRequest: PropTypes.func,
  toggableAccordeon: PropTypes.object,
  handleToggleAccordeon: PropTypes.func
}

export default ProductSpecs
