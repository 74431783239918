import { FormattedMessage } from "react-intl"
import ResidencialAndInstitucional from "../img/recidencial-institucional.png"

export const ITEMS = [
  {
    id: "ResidencialAndInstitucional",
    title: (
      <FormattedMessage
        id="Dali.Applications.ResidencialAndInstitucional"
        description="Pranna section applications item residential"
        defaultMessage="Residencial, oficinas y tráfico liviano"
      />
    ),
    imageThumb: ResidencialAndInstitucional
  }
]
