import { useIntl } from "react-intl"
import { useForm, useWatch } from "react-hook-form"
import { useStateMachine } from "little-state-machine"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { updateAction } from "utils/littleStateMachine"
import InputTextArea from "components/InputMUI/TextArea"
import { BASIC_BOOLEAN_OPTIONS, GENERIC_MSGS } from "modules/claims/constants"
import { BUTTON_MESSAGES } from "utils/buttons"
import ProgressBar from "modules/claims/components/StepForm/ProgressBar"
import RadioButton from "components/InputMUI/RadioButton"
import Select from "components/InputMUI/Select"
import { MATERIAL_CURRENT_STATE } from "modules/claims/components/StepForm/Step2/constants/materialCurrentState"
import {
  PLEATS_FOR_ORIGINAL,
  PLEATS_FOR_TRANSFORMED_INSTALLED,
  PLEATS_MSGS,
  WHERE_MARKS_ARE_OPTIONS
} from "./constants"
import { useNavigate } from "react-router-dom"
import { redirectToNextStep } from "../../../utils"
import InputText from "components/InputMUI/Text"

const Pleats = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { actions, state } = useStateMachine({ updateAction })

  const inputPlaceholder = GENERIC_MSGS.typeAnswer_placeholder

  const defaultValues = { ...state }
  const schema = yup.object(
    state.conditions !== MATERIAL_CURRENT_STATE.ORIGINAL
      ? PLEATS_FOR_TRANSFORMED_INSTALLED(intl)
      : PLEATS_FOR_ORIGINAL(intl)
  )

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const whereMarksAreValue = useWatch({ control, name: "whereMarksAre" })

  const onSubmit = (data) => {
    actions.updateAction(data)
    redirectToNextStep(navigate)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {state.conditions !== MATERIAL_CURRENT_STATE.ORIGINAL ? (
        <>
          <RadioButton
            name="materialIsMarked"
            register={register}
            label={PLEATS_MSGS.materialIsMarked}
            options={BASIC_BOOLEAN_OPTIONS}
            defaultValue={state.materialIsMarked}
            error={errors.materialIsMarked?.message}
          />
          <RadioButton
            name="materialIsCoated"
            register={register}
            label={PLEATS_MSGS.materialIsCoated}
            options={BASIC_BOOLEAN_OPTIONS}
            defaultValue={state.materialIsCoated}
            error={errors.materialIsCoated?.message}
          />
          <InputTextArea
            name="foldsInMaterial"
            control={control}
            label={PLEATS_MSGS.foldsInMaterial}
            placeholder={GENERIC_MSGS.typeAnswer_placeholder}
            defaultValue={state.foldsInMaterial}
            error={errors.foldsInMaterial?.message}
          />
        </>
      ) : (
        <>
          <RadioButton
            name="marksOnDelivery"
            register={register}
            label={PLEATS_MSGS.marksOnDelivery}
            options={BASIC_BOOLEAN_OPTIONS}
            defaultValue={state.marksOnDelivery}
            error={errors.marksOnDelivery?.message}
          />
          <Select
            name="whereMarksAre"
            control={control}
            label={PLEATS_MSGS.whereMarksAre}
            placeholder={GENERIC_MSGS.choose_placeholder}
            options={WHERE_MARKS_ARE_OPTIONS}
            defaultValue={state.whereMarksAre}
            error={errors.whereMarksAre?.message}
            isMulti
          />
        </>
      )}
      {whereMarksAreValue?.some(
        ({ value }) => value === WHERE_MARKS_ARE_OPTIONS[WHERE_MARKS_ARE_OPTIONS.length - 1].value
      ) && (
        <InputText
          name="describeWhereMarksAre"
          register={register}
          label={PLEATS_MSGS.describeWhereMarksAre}
          placeholder={inputPlaceholder}
          defaultValue={state.describeWhereMarksAre}
          error={errors.describeWhereMarksAre?.message}
        />
      )}
      <div className="btn-container">
        <button className="btn btn-dark" type="submit">
          {intl.formatMessage(BUTTON_MESSAGES.ok)}
        </button>
      </div>
      <ProgressBar
        progress={60}
        handleNext={handleSubmit(onSubmit)}
        RoutePrev={state.previousStep}
      />
    </form>
  )
}

export default Pleats
