export const handleGetCustomStylesSelectMainPage = (isMobile) => {
  const customStylesSelectMainPage = {
    control: (provided) => ({
      ...provided,
      padding: ".125rem 0rem",
      width: "150px",
      border: "none",
      borderBottom: "1px solid white",
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "#FFF",
      fontFamily: "Gill Sans",
      fontSize: "1.25rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      "&:hover": {
        border: "none",
        borderBottom: "1px solid white"
      }
    }),
    placeholder: (provided) => ({ ...provided, color: "white" }),
    option: (styles) => ({
      ...styles,
      color: "black",
      fontFamily: "Gill Sans",
      borderRadius: "0px",
      fontSize: "1.125rem",
      padding: ".25rem .5rem"
    }),
    indicator: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        color: "white"
      },
      "&:focus": {
        color: "white"
      }
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0 0 10px 10px" // Radio inferior
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0px",
      paddingBottom: "0px",
      overflow: "hidden",
      borderRadius: "0 0 10px 10px" // Radio inferior
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        color: "white"
      }
    })
  }

  return customStylesSelectMainPage
}

export const handleGetCustomStylesSelectHeader = (isMobile) => {
  const customStylesSelectHeader = {
    control: (provided) => ({
      ...provided,
      padding: ".125rem 0rem",
      width: "84px",
      border: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "white",
      fontFamily: "Gill Sans",
      fontSize: "1.25rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      "&:hover": {
        border: "none"
      }
    }),
    placeholder: (provided) => ({ ...provided, color: "white" }),
    option: (styles) => ({
      ...styles,
      color: "black",
      fontFamily: "Gill Sans",
      borderRadius: "0px",
      fontSize: "1.125rem",
      padding: ".25rem .5rem",
      fontWeight: "normal"
    }),
    indicator: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        color: "white"
      }
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0 0 10px 10px", // Radio inferior,
      marginTop: !isMobile && "18px",
      right: !isMobile && "10px",
      width: !isMobile && "170px"
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0px",
      paddingBottom: "0px",
      overflow: "hidden",
      borderRadius: "0 0 10px 10px" // Radio inferior
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        color: "white"
      }
    })
  }

  return customStylesSelectHeader
}
