import { defineMessages } from "react-intl"
import facilLimpieza from "../img/facil-limpieza.svg"
import impermeabilidad from "../img/impermeabilidad.svg"
import microorganismos from "../img/microorganismos.svg"
import retardanciaFuego from "../img/retardancia-fuego.svg"
import abrasion from "../img/abrasion.svg"
import durabilidadColor from "../img/durabilidad-color.svg"
import bronceadores from "../img/bronceadores.svg"
import bajasTemperaturas from "../img/bajas-temperaturas.svg"

export const CHARACTERISTICS_IMGS = {
  facilLimpieza,
  impermeabilidad,
  microorganismos,
  retardanciaFuego,
  abrasion,
  durabilidadColor,
  bronceadores,
  bajasTemperaturas
}

export const MSGS = defineMessages({
  facilLimpieza: {
    id: "Pranna.ProductDetail.easyCleaning",
    description: "Pranna section product detail item easy cleaning",
    defaultMessage: "Fácil {br} Limpieza"
  },
  impermeabilidad: {
    id: "Pranna.ProductDetail.impermeability",
    description: "Pranna section product detail item impermeability",
    defaultMessage: "Impermeabilidad"
  },
  microorganismos: {
    id: "Pranna.ProductDetail.resistanceToMicroorganisms",
    description: "Pranna section product detail item microorganismos",
    defaultMessage: "Resistencia A {br} Microorganismos"
  },
  retardanciaFuego: {
    id: "Pranna.ProductDetail.flameRetardancy",
    description: "Pranna section product detail item flame retardancy",
    defaultMessage: "Retardancia {br} Al Fuego"
  },
  abrasion: {
    id: "Pranna.ProductDetail.abrationRetardancy",
    description: "Pranna section product detail item abration retardancy",
    defaultMessage: "Resistencia {br} a la Abrasión"
  },
  durabilidadColor: {
    id: "Pranna.ProductDetail.colorDurability",
    description: "Pranna section product detail item color durability",
    defaultMessage: "Durabilidad {br} Del Color A La Luz"
  },
  bronceadores: {
    id: "Pranna.ProductDetail.bronzerResistance",
    description: "Pranna section product detail item bronzer resistance",
    defaultMessage: "Resistencia A {br} Bronceadores"
  },
  bajasTemperaturas: {
    id: "Pranna.ProductDetail.lowTemperatureResistance",
    description: "Pranna section product detail item low temperature resistance",
    defaultMessage: "Resistencia A {br} Bajas Temperaturas"
  }
})

export const PRODUCT_DETAILS = [
  {
    id: "facilLimpieza",
    text: MSGS.facilLimpieza,
    icon: facilLimpieza
  },
  {
    id: "impermeabilidad",
    text: MSGS.impermeabilidad,
    icon: impermeabilidad
  },
  {
    id: "microorganismos",
    text: MSGS.microorganismos,
    icon: microorganismos
  },
  {
    id: "retardanciaFuego",
    text: MSGS.retardanciaFuego,
    icon: retardanciaFuego
  },
  {
    id: "abrasion",
    text: MSGS.abrasion,
    icon: abrasion
  },
  {
    id: "durabilidadColor",
    text: MSGS.durabilidadColor,
    icon: durabilidadColor
  },
  {
    id: "bronceadores",
    text: MSGS.bronceadores,
    icon: bronceadores
  },
  {
    id: "bajasTemperaturas",
    text: MSGS.bajasTemperaturas,
    icon: bajasTemperaturas
  }
]
