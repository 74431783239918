import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import Header from "modules/floors/components/Header"
import { MENU_COMEBACK } from "modules/floors/constants"
import cartilla from "./videos/cartilla.mp4"
import catalogue from "./videos/catalogue.mp4"
import "./styles/deliverables.scss"

const Deliverables = () => {
  const navigate = useNavigate()

  return (
    <>
      <Header noInspirations objItems={MENU_COMEBACK(navigate)} />
      <div className="container mt-5 pt-5">
        <div className="row justify-content-center align-items-center">
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="Floors.HeaderButtons.Deliverables"
              description="Deliverables HeaderButtons text"
              defaultMessage="Entregables"
            />
          </TitleUnderlined>
          <h2 className="my-4">
            <FormattedMessage
              id="Floors.Deliverables.catalog"
              description="Deliverables catalog text"
              defaultMessage="Catálogo"
            />
          </h2>
          <video className="img-fluid video-installation" loop autoPlay muted>
            <source src={catalogue} type="video/mp4" />
          </video>
          <h2 className="my-4">
            <FormattedMessage
              id="Floors.Deliverables.installationPrimer"
              description="Deliverables catalog text"
              defaultMessage="Cartilla de instalación"
            />
          </h2>
          <video className="img-fluid video-catalog" loop autoPlay muted>
            <source src={cartilla} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  )
}

export default Deliverables
