import { FormattedMessage } from "react-intl"
import banner from "modules/petfriendly/img/banner.jpg"
import ContactForm from "components/ContactForm"
import { COUNTRIES_FOR_PETFRIENDLY } from "consts/countryCodes"

export const NAV_ITEMS = ["características", "referencias"]

export const SLIDE = [
  {
    id: "slide-petfriendly",
    imageThumb: banner,
    btnClassName: "btn-light",
    title: (
      <FormattedMessage
        id="Petfriendly.Banner.title"
        description="Petfriendly title banner"
        defaultMessage="Telas Pet Friendly"
      />
    ),
    description: (
      <FormattedMessage
        id="Petfriendly.Banner.description"
        description="Petfriendly description banner"
        defaultMessage="Nuestras tapicerías técnicas cuentan con gran variedad de colores, diseños y apariencias para todo tipo de tapicería en espacios interiores o exteriores."
      />
    ),
    form: (
      <ContactForm
        theme="light"
        styleContainer="container-fluid upholstery-glassmorphism my-3"
        styleFormContainer="p-4"
        title={
          <FormattedMessage
            id="Pranna.Contact.title"
            description="Floors section title contact"
            defaultMessage="¿ Quieres más información ?"
          />
        }
        countryOptions={COUNTRIES_FOR_PETFRIENDLY}
        landingSource="petfriendly"
        isBasic
      />
    )
  }
]

export const GLOBAL_SITE_TAG = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "GTM-KD3BN8N",
  [process.env.REACT_APP_MX_INSTANCE_ID]: "GTM-KD3BN8N"
}
