import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { SCENES } from "./constants"
import TitleUnderlined from "components/TitleUnderlined"
import { H1_TAG } from "components/TitleUnderlined/constants"
import "./styles/breeze.scss"
import { useNavigate } from "react-router-dom"
import classNames from "classnames"

const Breeze = ({ id }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate("/en-us/breeze")
  }

  return (
    <div id={id} className="text-center px-4 pt-3 pb-3 pt-lg-4 pb-lg-4 bg-breeze">
      <div className="row justify-content-center pt-3">
        <div className="col-12 col-lg-8">
          <p className="mb-5 pt-md-4 pb-md-3 mb-lg-5 description-breeze">
            <FormattedMessage
              id={"Prannatex.Breeze.description"}
              defaultMessage="Our color chart features 17 exceptional shades, designed to give your spaces an air of sophistication, beauty and balance. From neutral and traditional tones that lay the foundations of elegance and that serve for styles such as 'japandi' or minimalism to trendy accent colors that give life to more avant-garde environments."
            />
          </p>
        </div>
        <div className="row justify-content-center pt-3">
          <div className="d-flex flex-wrap justify-content-center gap-3">
            {SCENES.map(({ title, img }, i) => {
              const isPair = i % 2 === 0
              if (isPair) {
                return (
                  <div key={`key_${i}_${title}`} className="mx-sm-3 mb-5">
                    <div className="vertical-space" />
                    <div
                      className={classNames({
                        "scene--img": true,
                        fabrics: title === SCENES[0].title,
                        chair: title === SCENES[2].title
                      })}
                      style={{
                        backgroundImage: `url(${img})`
                      }}
                    />
                  </div>
                )
              }
              return (
                <div key={`key_${i}_${title}`} className="mx-sm-3">
                  <div
                    className={classNames({
                      "scene--img": true,
                      samples: title === SCENES[3].title
                    })}
                    style={{
                      backgroundImage: `url(${img})`
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

Breeze.propTypes = {
  id: PropTypes.string
}

export default Breeze
