import PropTypes from "prop-types"
import { GlassMagnifier } from "react-image-magnifiers"
import ProductSpecs from "modules/kizuna/components/Designs/components/ProductSpecs"
import SampleCard from "../SampleCard"
import usePatternData from "./usePatternData"
import useSampleCard from "../SampleCard/useSampleCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { useMediaQuery } from "@mui/material"
import { LG } from "consts/breakpoints"
import SampleSliderList from "../SampleSliderList"
import Counter from "./components/Counter"
import useCounter from "./components/Counter/useCounter"
import "./styles/samples-detail.scss"

const SamplesDetail = ({
  state,
  design,
  handleSamplesData,
  handleShowInformationBox,
  handleClickInAddCatalogueToRequest
}) => {
  const isMobile = useMediaQuery(LG)
  const { sampleDetails, SAMPLES } = usePatternData({
    state,
    design,
    handleSamplesData
  })
  const { handleAddSample } = useSampleCard({
    design,
    handleShowInfoBox: handleShowInformationBox
  })
  const { count, increment, decrement, resetCounter } = useCounter()
  const firstSample = SAMPLES[0]

  const handleAddByCounter = (sample) => {
    const samples = Array.from({ length: count }, () => sample)
    handleAddSample(samples, { hasMulti: true })
    resetCounter()
  }

  return (
    <>
      <div className="mt-1 px-lg-4 mx-lg-3">
        <div className="row mb-4">
          <h2 className="kizuna-detail-sample-title">{design}</h2>
          <div className="col-12 col-lg-5 d-xl-flex justify-content-xl-end">
            <div className="container-master-sample">
              <div className="position-relative">
                {!isMobile && (
                  <div className="container-search-icon p-4">
                    <FontAwesomeIcon
                      className="p-2 rounded-circle bg-light"
                      style={{ width: 20, height: 20 }}
                      icon={faSearch}
                      color="gray"
                      fixedWidth
                    />
                  </div>
                )}
                <GlassMagnifier
                  cursorStyle="none"
                  className="large-img--masiala"
                  imageSrc={
                    state?.productDetails?.sampleDetails
                      ? state?.productDetails?.sampleDetails?.img
                      : firstSample.img
                  }
                  imageAlt="sample-master-image"
                  magnifierBorderColor="white"
                  magnifierBackgroundColor="white"
                  magnifierBorderSize={!isMobile ? 2 : 0}
                  magnifierOffsetX={0}
                  magnifierOffsetY={0}
                  magnifierSize={!isMobile ? 150 : 0}
                />
                <p className="sample-detail-selected--masiala m-0">
                  {!state?.productDetails?.sampleDetails
                    ? `${firstSample.color} | ${firstSample.reference}`
                    : `${state?.productDetails.sampleDetails?.color} | ${state?.productDetails.sampleDetails?.reference}`}
                </p>
              </div>
              {!isMobile && (
                <>
                  <div className="d-flex justify-content-end align-items-center text-center mt-4 mb-2">
                    <Counter count={count} increment={increment} decrement={decrement} />
                    <button
                      className="btn btn-sample my-0 ms-3"
                      onClick={() => handleAddByCounter(sampleDetails)}
                    >
                      <p className="btn-label-sample--kizuna">ADD MEMO SAMPLE</p>
                    </button>
                  </div>
                  {count === 9 && (
                    <p className="limit-samples-label">
                      <strong>Limit reached:</strong> Maximum of 10 samples.
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-7 d-xl-flex justify-content-xl-end m-0 mt-lg-4">
            <div className="d-lg-flex p-0 flex-wrap col-12 h-75 pb-0">
              {!isMobile ? (
                <>
                  {SAMPLES.map((sample) => (
                    <SampleCard
                      key={`key_${sample.reference}_${sample.color}`}
                      design={design}
                      handleShowInfoBox={handleShowInformationBox}
                      sample={sample}
                    />
                  ))}
                </>
              ) : (
                <SampleSliderList
                  design={design}
                  samples={SAMPLES}
                  handleShowInfoBox={handleShowInformationBox}
                />
              )}
            </div>
          </div>
          {isMobile && (
            <>
              <div className="d-flex justify-content-center align-items-center text-center my-2">
                <Counter count={count} increment={increment} decrement={decrement} />
                <button
                  className="btn btn-sample my-0 ms-3 ms-md-4"
                  onClick={() => handleAddByCounter(sampleDetails)}
                >
                  <p className="btn-label-sample--kizuna">ADD MEMO SAMPLE</p>
                </button>
              </div>
              {count === 9 && (
                <p className="limit-samples-label">
                  <strong>Limit reached:</strong> Maximum of 10 samples.
                </p>
              )}
            </>
          )}
        </div>
        <div className="mt-5 mt-lg-0 font-color">
          <ProductSpecs />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-5 my-2">
        <button
          type="button"
          className="btn btn-sample btn-free-catalogue--kizuna"
          onClick={handleClickInAddCatalogueToRequest}
        >
          ADD FREE CATALOGUE TO CART!
        </button>
      </div>
    </>
  )
}

SamplesDetail.propTypes = {
  state: PropTypes.object,
  design: PropTypes.string,
  handleSamplesData: PropTypes.func,
  handleShowInformationBox: PropTypes.func,
  handleClickInAddCatalogueToRequest: PropTypes.func
}

export default SamplesDetail
