import { defineMessages } from "react-intl"

export const MATERIAL_CURRENT_STATE = {
  ORIGINAL: "original",
  TRANSFORMED: "transformed",
  INSTALLED: "installed"
}

const MATERIAL_CURRENT_STATE_MSGS = defineMessages({
  original: {
    id: "Claims.OriginalCondition.materialCurrentState.option.original",
    description: "option original state for select",
    defaultMessage: "No, the material is still in its original condition as delivered by Spradling"
  },
  transformed: {
    id: "Claims.OriginalCondition.materialCurrentState.option.transformed",
    description: "option trandformed state for select",
    defaultMessage: "Yes, the material has been transformed but not been installed"
  },
  transformedDescription: {
    id: "Claims.OriginalCondition.materialCurrentState.option.transformed.description",
    description: "option trandformed description state for select",
    defaultMessage:
      "(e.g. seats, covers, cushions or components have been manufactured with the material but not been installed)"
  },
  installed: {
    id: "Claims.OriginalCondition.materialCurrentState.option.installed",
    description: "option installed state for select",
    defaultMessage: "Yes, the material has been transformed and is installed"
  },
  installedDescription: {
    id: "Claims.OriginalCondition.materialCurrentState.option.installed.description",
    description: "option installed state description for select",
    defaultMessage: "(e.g. on on chair in a restaurant, on a boat, etc.)"
  }
})

export const MATERIAL_CURRENT_STATE_OPTIONS = [
  {
    value: MATERIAL_CURRENT_STATE.ORIGINAL,
    label: MATERIAL_CURRENT_STATE_MSGS.original
  },
  {
    value: MATERIAL_CURRENT_STATE.TRANSFORMED,
    label: MATERIAL_CURRENT_STATE_MSGS.transformed,
    description: MATERIAL_CURRENT_STATE_MSGS.transformedDescription
  },
  {
    value: MATERIAL_CURRENT_STATE.INSTALLED,
    label: MATERIAL_CURRENT_STATE_MSGS.installed,
    description: MATERIAL_CURRENT_STATE_MSGS.installedDescription
  }
]
