import { FormattedMessage } from "react-intl"
import { PRODUCT_DETAILS } from "./constants"
import "./styles/characteristics.scss"

const Characteristics = () => (
  <section className="bg-light">
    <div className="container g-xl-0 py-4">
      <div className="row">
        <h2 className="title--segm-hotelery">
          <FormattedMessage id="Segments.Hotel.Products.title" defaultMessage="Productos" />
        </h2>
        <p className="info--segm-hotelery">
          <FormattedMessage
            id="Segments.Hotel.Products.info"
            defaultMessage="Sabemos que el diseño y la calidad son fundamentales para una estancia innolvidable.{br}{br}Nuestros productos cuentan con características técnicas como:"
            values={{
              br: <br />
            }}
          />
        </p>
        {PRODUCT_DETAILS.map(({ id, text, icon }) => (
          <div key={`key_${id}`} className="col-4 col-md-2 g-0 my-md-2 text-center">
            <img className="icon--segm-hotelery my-2" src={icon} />
            {text && (
              <p className="icon-text--segm-hotelery">
                <FormattedMessage
                  {...text}
                  values={{
                    br: <br />
                  }}
                />
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Characteristics
