import { FormattedMessage } from "react-intl"
import bannerMx from "modules/pranna/components/Banner/img/banner-mx.jpg"
import bannerLatam from "modules/pranna/components/Banner/img/banner-la.jpg"
import { isLATAM } from "services/instances"
import ContactForm from "components/ContactForm"
import { COUNTRIES_FOR_MEXICO, COUNTRIES_FOR_PRANNA_LA } from "consts/countryCodes"

export const SLIDE = [
  {
    id: `slide-pranna-${!isLATAM() ? "latam" : "mx"}`,
    imageThumb: !isLATAM() ? bannerMx : bannerLatam,
    title: (
      <FormattedMessage
        id="Pranna.Banner.title"
        description="Pranna mx title banner"
        defaultMessage="Pranna<sup>®</sup>"
        values={{
          sup: (...chunks) => <sup>{chunks}</sup>
        }}
      />
    ),
    description: (
      <FormattedMessage
        id="Pranna.Banner.description"
        description="Pranna mx description banner"
        defaultMessage="Referencia Best Seller con apariencia tipo piel, apta para tapicería interior"
        values={{ br: <br /> }}
      />
    ),
    form: (
      <ContactForm
        theme="light"
        styleContainer="container-fluid upholstery-glassmorphism my-3"
        styleFormContainer="p-4"
        title={
          <FormattedMessage
            id="Pranna.Contact.title"
            description="Floors section title contact"
            defaultMessage="¿ Quieres más información ?"
          />
        }
        countryOptions={!isLATAM() ? COUNTRIES_FOR_MEXICO : COUNTRIES_FOR_PRANNA_LA}
        landingSource="pranna"
        isBasic
      />
    )
  }
]
