import { useQuery } from "@apollo/client"
import { masialaSinglePatternQuery } from "../query"

const useGetPatternQuery = () => {
  const { data } = useQuery(masialaSinglePatternQuery, {
    variables: {
      slug: "masiala"
    }
  })

  return { MASIALA_PATTERN_ID: data?.pattern?.id }
}

export default useGetPatternQuery
