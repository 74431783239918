import { FormattedMessage } from "react-intl"
import banner from "modules/prannatex/img/Banner-inicial.jpeg"
import { getCurrentLanguageAndInstanceCode } from "services/instances"

const contact = getCurrentLanguageAndInstanceCode()[0] === "es" ? "Contacto" : "Contact"
const prannatex = <>Pranna<sup>®</sup>Tex</>

export const NAV_ITEMS = [prannatex, contact]

export const SLIDE = [
  {
    id: "slide-prannatex",
    imageThumb: banner,
    btnClassName: "btn-light",
    title: (
      <FormattedMessage
        id="Prannatex.Banner.title"
        description="Prannatex title banner"
        defaultMessage="Prannatex"
      />
    ),
    description: (
      <FormattedMessage
        id="Prannatex.Banner.description"
        description="Prannatex description banner"
        defaultMessage="The contemporary essence made textile"
      />
    ),
    link: `#${NAV_ITEMS[1]}`,
    buttonText: (
      <FormattedMessage
        id="Button.moreInfo"
        description="Button more info text"
        defaultMessage="Learn more"
      />
    )
  }
]

export const GLOBAL_SITE_TAG = "GTM-N5ZQWQTQ"
