/* eslint-disable no-useless-escape */
const { REASONS } = require("../../Step2/constants")

const REASONS_TEMPLATES = {
  [REASONS.COLOR_DIFFERENCE]: (state) => {
    const { colorDifferenceDescription } = state

    const colorDifferenceObj = {
      title: "4. Color Difference",
      colorDifferenceDescription: {
        label:
          "Please describe how you detect the difference (how it is measured or compared) and under which conditions (light conditions - e.g. indoor/outdoor).",
        value: colorDifferenceDescription
      }
    }

    return colorDifferenceObj
  },
  [REASONS.COLOR_FADING]: (state) => {
    const { cleaningAgentsUsed, protectProductCushions, surfaceContact } = state

    const colorFadingObj = {
      title: "4. Color fading | Color changes",
      cleaningAgentsUsed: {
        label: "Which cleaning agents are used to clean the material?",
        value: cleaningAgentsUsed
      },
      protectProductCushions: {
        label: "how you protect the product / cushions",
        value: protectProductCushions
      },
      surfaceContact: {
        label:
          "Has the material been in contact with any other substances and/or surfaces? If yes, please specify",
        value: surfaceContact
      }
    }

    return colorFadingObj
  },
  [REASONS.DIFFERENCES_IN_DIMENSION]: (state) => {
    const { howMeasuredMaterial, indicateDifference } = state

    const differeceDimensionObj = {
      title: "4. Differences in Material Dimension",
      howMeasuredMaterial: {
        label: "How did you measure the material?",
        value: howMeasuredMaterial
      },
      indicateDifference: {
        label: "Please indicate the difference",
        value: indicateDifference
      }
    }

    return differeceDimensionObj
  },
  [REASONS.DIFFERENCES_IN_HAND]: (state) => {
    const { describeTheDifference, howDetectDifference, howAffectsDifference } = state

    const differenceInHandObj = {
      title: "4. Differences in Hand",
      describeTheDifference: {
        label: "How can you describe the hand difference?",
        value: describeTheDifference
      },
      howDetectDifference: {
        label: "How do you detect the difference?",
        value: howDetectDifference
      },
      howAffectsDifference: {
        label: "How does it affect your process?",
        value: howAffectsDifference
      }
    }

    return differenceInHandObj
  },
  [REASONS.FACIAL_DEFFECTS]: (state) => {
    const { description, defect } = state

    const facilDeffectsObj = {
      title: "4. Facial Defects",
      description: {
        label: "Short description of the defect",
        value: description
      },
      defect: {
        label: " Is the defect isolated or repetitive? (Isolated defect: only once or twice, Repetitive: Eg: every 5cm)",
        value: defect
      }
    }

    return facilDeffectsObj
  },
  [REASONS.MATERIAL_RESISTANCE]: (state) => {
    const {
      adequateProductResistance,
      knowTheRecomendations,
      howDetectedNonConformity,
      howItTransforms
    } = state

    const materialResistanceObj = {
      title: "4. Material Resistance",
      adequateProductResistance: {
        label:
          "What type of resistance is in not in conformity with the product specification / quality?",
        value: adequateProductResistance
      },
      knowTheRecomendations: {
        label:
          "Are you aware of the manufacturer recommendations and/or precautions as per material specification?",
        value: knowTheRecomendations
      },
      howDetectedNonConformity: {
        label: "How is the non-conformity detected and measured?",
        value: howDetectedNonConformity
      },
      howItTransforms: {
        label: "How is the material used/transformed in the process?",
        value: howItTransforms
      }
    }

    return materialResistanceObj
  },
  [REASONS.OTHER]: (state) => {
    const { others } = state

    const otherObj = {
      title: "4. Other",
      others: {
        label: "Description",
        value: others
      }
    }

    return otherObj
  },
  [REASONS.PLEATS]: (state) => {
    const { marksOnDelivery, whereMarksAre, materialIsMarked, materialIsCoated, foldsInMaterial } =
      state

    const pleatsObj = {
      title: "4. Pleats",
      marksOnDelivery: {
        label: "Does the material already show these marks when delivered?",
        value: marksOnDelivery
      },
      whereMarksAre: {
        label: "Where are the marks situated on the roll? Several answers are possible",
        value: whereMarksAre
      },
      materialIsMarked: {
        label: "Is the material on the original roll also marked?",
        value: materialIsMarked
      },
      materialIsCoated: {
        label: "Is the material foam-backed / laminated?",
        value: materialIsCoated
      },
      foldsInMaterial: {
        label:
          "Please indicate where the pleats / marks are on the transformed / installed material?",
        value: foldsInMaterial
      }
    }

    return pleatsObj
  },
  [REASONS.STAINING]: (state) => {
    const { typeOfStain, knowTheInstructions, whichProductUse, protectTheProduct } = state

    const statiningObj = {
      title: "4. Staining",
      typeOfStain: {
        label: "Type of stain",
        value: typeOfStain
      },
      knowTheInstructions: {
        label:
          "Are cleaning instructions suggested by the manufacturer followed to clean up the material?",
        value: knowTheInstructions
      },
      whichProductUse: {
        label: "If not, which cleaning agents are used to clean up the material?",
        value: whichProductUse
      },
      protectTheProduct: {
        label: "how you protect the product / cushions",
        value: protectTheProduct
      }
    }

    return statiningObj
  },
  [REASONS.SURFACE_DETERIORATION]: (state) => {
    const { suggestedInstructions, describeProcessUsed, theMaterialContaminated } = state

    const surfaceDeteriorationObj = {
      title: "4. Surface Deterioration",
      suggestedInstructions: {
        label:
          "Are cleaning instructions suggested by the manufacturer followed <br> to clean up the material?",
        value: suggestedInstructions
      },
      describeProcessUsed: {
        label:
          "Please describe the material cleaning process, frequency and cleaning products used",
        value: describeProcessUsed
      },
      theMaterialContaminated: {
        label:
          "Please specify the substances that the material has been in contact with (e.g. disinfectants, liquids, food, oil)",
        value: theMaterialContaminated
      }
    }

    return surfaceDeteriorationObj
  },
  [REASONS.VISUAL_DEFFECTS]: (state) => {
    const { typeVisualDifference, visualDefectsHowDetectDifference, howCompareMaterial } = state

    const visualDeffectsObj = {
      title: "4. Visual Defects",
      typeVisualDifference: {
        label: "Type of visual difference in the following options",
        value: typeVisualDifference
      },
      visualDefectsHowDetectDifference: {
        label: "How do you detect the difference?",
        value: visualDefectsHowDetectDifference
      },
      howCompareMaterial: {
        label: "To which sample/material do you compare?",
        value: howCompareMaterial
      }
    }

    return visualDeffectsObj
  }
}

export const getReasonTemplate = (state) => {
  const { reasons } = state

  const reasonObject = REASONS_TEMPLATES[reasons](state)

  const tempArray = []

  for (const [key] of Object.entries(state)) {
    if (reasonObject[key] !== undefined && reasonObject[key] !== "title") {
      tempArray.push({
        key: reasonObject[key].label,
        value: reasonObject[key].value
      })
    }
  }

  return `
    <div class="product-information-container" style="
      background-color: #DDDDDD;
      height: auto;
    ">
      <h2 style="margin: 0; margin-bottom: 20px;">
        ${reasonObject.title}
      </h2>
      <table>
          <tbody>
          ${tempArray.reduce((acc, { key, value }, index) => {
            if (index % 2 === 0) {
              acc += "<tr>"
            }
            acc += `
            ${
              tempArray.length === 1
                ? `
            <td style="width: 100%; margin-bottom: 20px;">
              <div style="width: 100%;">
                <p>${key}</p>
                <p class="property">${
                  typeof value === "object" ? value.map(({ label }) => label + " ").join("") : value
                }</p>
              </div>
            </td>`
                : `
            <td style="width: 59%;">
              <div style="width: 100%;">
                <p>${key}</p>
                <p class="property">${
                  typeof value === "object" ? value.map(({ label }) => label + " ").join("") : value
                }</p>
              </div>
            </td>`
            }
            `
            if (index % 2 !== 0) {
              acc += "</tr>"
            }
            return acc
          }, "")}
        </tbody>
      </table>
    </div>

    <hr style="
      width: 95%;
      margin-left: 0;
      margin-top: 40px;
      margin-bottom: 40px;
    "></hr>
    `
}
