import { useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleArrowLeft, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons"
import useScrollBox from "./hooks/useScrollBox"
import "./styles/scrollBox.scss"

const ScrollBox = ({
  children,
  noArrowLeft = false,
  noArrowRight = false,
  boxContainerClassName
}) => {
  const scrollWrapperRef = useRef()
  const { isDragging, handleScrollLeft, handleScrollRight } = useScrollBox(scrollWrapperRef)

  return (
    <div className="scroll-box">
      {!noArrowLeft && (
        <FontAwesomeIcon
          className="scroll-arrow arrow-left"
          icon={faCircleArrowLeft}
          size="2x"
          onClick={handleScrollLeft}
        />
      )}
      <div
        ref={scrollWrapperRef}
        className={classNames({
          "scroll-box__wrapper": true,
          "scroll-smooth": !isDragging
        })}
      >
        <div
          className={classNames({
            "scroll-box__container": true,
            [boxContainerClassName]: boxContainerClassName !== undefined
          })}
          role="list"
          style={{
            pointerEvents: isDragging ? "none" : undefined,
            transform: `translateX(-${scrollWrapperRef?.current?.scrollLeft}px)`,
            transition: "transform 800ms ease-out 0s"
          }}
        >
          {children.map((child, i) => (
            <div role="listitem" key={`scroll-box-item-${i}`} className="scroll-box__item">
              {child}
            </div>
          ))}
        </div>
      </div>
      {!noArrowRight && (
        <FontAwesomeIcon
          className="scroll-arrow arrow-right"
          icon={faCircleArrowRight}
          size="2x"
          onClick={handleScrollRight}
        />
      )}
    </div>
  )
}

ScrollBox.propTypes = {
  children: PropTypes.node.isRequired,
  noArrowLeft: PropTypes.bool,
  noArrowRight: PropTypes.bool,
  boxContainerClassName: PropTypes.string
}

export default ScrollBox
