import { ARTISAN_IMAGES, CHECKER_IMAGES, TWEED_IMAGES } from "./samplesImages"

const CHECKER = "checker"
const ARTISAN = "artisan"
const TWEED = "tweed"

export const SAMPLES = [
  {
    designGroup: CHECKER,
    colors: [
      {
        color: "WINTER",
        reference: "CHK - B013",
        img: CHECKER_IMAGES.WINTER
      },
      {
        color: "CREAMY",
        reference: "CHK - B014",
        img: CHECKER_IMAGES.CREAMY
      },
      {
        color: "OVERCAST",
        reference: "CHK - B005",
        img: CHECKER_IMAGES.OVERCAST
      },
      {
        color: "ACQUA",
        reference: "CHK - B008",
        img: CHECKER_IMAGES.ACQUA
      },
      {
        color: "CEDRUS",
        reference: "CHK - B007",
        img: CHECKER_IMAGES.CEDRUS
      },
      {
        color: "CARDAMOM",
        reference: "CHK - B004",
        img: CHECKER_IMAGES.CARDAMOM
      },
      {
        color: "CRYSTAL",
        reference: "CHK - G010",
        img: CHECKER_IMAGES.CRYSTAL
      },
      {
        color: "KSAMIL",
        reference: "CHK - G009",
        img: CHECKER_IMAGES.KSAMIL
      },
      {
        color: "SALVIA",
        reference: "CHK - N012",
        img: CHECKER_IMAGES.SALVIA
      },
      {
        color: "BOBCAT",
        reference: "CHK - B003",
        img: CHECKER_IMAGES.BOBCAT
      },
      {
        color: "ATLANTIC",
        reference: "CHK - N010",
        img: CHECKER_IMAGES.ATLANTIC
      },
      {
        color: "EMERALD",
        reference: "CHK - N009",
        img: CHECKER_IMAGES.EMERALD
      },
      {
        color: "KHAKI",
        reference: "CHK - G003",
        img: CHECKER_IMAGES.KHAKI
      },
      {
        color: "STORMY",
        reference: "CHK - G001",
        img: CHECKER_IMAGES.STORMY
      },
      {
        color: "SOOT",
        reference: "CHK - N002",
        img: CHECKER_IMAGES.SOOT
      }
    ]
  },
  {
    designGroup: ARTISAN,
    colors: [
      {
        color: "CELESTE",
        reference: "ART - B010",
        img: ARTISAN_IMAGES.CELESTE
      },
      {
        color: "SEAROCK",
        reference: "ART - G010",
        img: ARTISAN_IMAGES.SEAROCK
      },
      {
        color: "SEAFOAM",
        reference: "ART - B008",
        img: ARTISAN_IMAGES.SEAFOAM
      },
      {
        color: "LEADEN",
        reference: "ART - B015",
        img: ARTISAN_IMAGES.LEADEN
      },
      {
        color: "MINT",
        reference: "ART - B007",
        img: ARTISAN_IMAGES.MINT
      },
      {
        color: "BARK",
        reference: "ART - N014",
        img: ARTISAN_IMAGES.BARK
      },
      {
        color: "WHEAT",
        reference: "ART - G014",
        img: ARTISAN_IMAGES.WHEAT
      },
      {
        color: "TWILIGHT",
        reference: "ART - G002",
        img: ARTISAN_IMAGES.TWILIGHT
      }
    ]
  },
  {
    designGroup: TWEED,
    colors: [
      {
        color: "SNOWY",
        reference: "TWD - B013",
        img: TWEED_IMAGES.SNOWY
      },
      {
        color: "OAT",
        reference: "TWD - B014",
        img: TWEED_IMAGES.OAT
      },
      {
        color: "BUFF",
        reference: "TWD - G006",
        img: TWEED_IMAGES.BUFF
      },
      {
        color: "ASHY",
        reference: "TWD - G013",
        img: TWEED_IMAGES.ASHY
      },
      {
        color: "FOGGY",
        reference: "TWD - B016",
        img: TWEED_IMAGES.FOGGY
      },
      {
        color: "OTTER",
        reference: "TWD - B003",
        img: TWEED_IMAGES.OTTER
      },
      {
        color: "MANGROVE",
        reference: "TWD - N004",
        img: TWEED_IMAGES.MANGROVE
      },

      {
        color: "CEDAR",
        reference: "TWD - G003",
        img: TWEED_IMAGES.CEDAR
      },
      {
        color: "DARKEST",
        reference: "TWD - G002",
        img: TWEED_IMAGES.DARKEST
      },
      {
        color: "ZEBRA",
        reference: "TWD - B001",
        img: TWEED_IMAGES.ZEBRA
      },
      {
        color: "CAPRI",
        reference: "TWD - N010",
        img: TWEED_IMAGES.CAPRI
      },
      {
        color: "JANUBIO",
        reference: "TWD - N008",
        img: TWEED_IMAGES.JANUBIO
      }
    ]
  }
]
