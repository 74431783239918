export const NAV_ITEMS = ["section1", "section2", "section3"]

export const DROP_DOWN_ITEMS = [
  { id: "checker", text: "Checker", isRedirect: true },
  { id: "artisan", text: "Artisan", isRedirect: true },
  { id: "tweed", text: "Tweed", isRedirect: true },
  { id: "#inspiration", text: "Inspiration", isRedirect: false },
  { id: "#about-kizuna", text: "About Kizuna", isRedirect: false },
  { id: "#watch-video", text: "Watch Video", isRedirect: false }
]
