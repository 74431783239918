import PropTypes from "prop-types"
import { IntlProvider } from "react-intl"
import { getCurrentLanguageAndInstanceCode } from "services/instances"
import { TRANSLATIONS_MESSAGES } from "consts/translationsMessages"

const [lang, instance] = getCurrentLanguageAndInstanceCode()
let locale

if (lang && instance) {
  locale = `${lang}-${instance}`
} else {
  locale = lang
}

const ConnectedIntlProvider = ({ children }) => (
  <IntlProvider locale={locale} messages={TRANSLATIONS_MESSAGES[locale]}>
    {children}
  </IntlProvider>
)

ConnectedIntlProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default ConnectedIntlProvider
