import { MODAL_TYPES_DOWNLOAD } from "modules/kizuna/components/RequestsSection/utils"

// For handle colors
const handleFormatColorByOrder = ({ colours, patternBase = "KIZUNA" }) =>
  colours.map(({ data }) => {
    let proccessedItem = {}
    if (!data.catalogue) {
      proccessedItem = {
        name: `${data.pattern.toUpperCase()} - ${data.color}, ${patternBase}`,
        stock: data.reference
      }
    } else {
      proccessedItem = {
        name: data.catalogue,
        stock: ""
      }
    }
    return proccessedItem
  })

export const handleSamplesOrder = ({ colours, patternBase }) => {
  const formatColorByOrder = handleFormatColorByOrder({ colours, patternBase })

  return Object.values(
    formatColorByOrder.reduce((acc, item) => {
      const key = `${item.name}|${item.stock}` // Crear una clave única basada en name y stock

      if (!acc[key]) {
        // Si no existe en el acumulador, lo añadimos con quantity 1
        acc[key] = { ...item, quantity: 1 }
      } else {
        // Si ya existe, incrementamos quantity
        acc[key].quantity++
      }

      return acc
    }, {})
  )
}

// For handle Download

export const handleURLPdfAccordingToFormType = ({ formType }) => {
  const fileNames = {
    [MODAL_TYPES_DOWNLOAD[0]]: "digital-catalogue",
    [MODAL_TYPES_DOWNLOAD[1]]: "care_and_cleaning"
  }

  const urlPDFAccordingToFormType = {
    [MODAL_TYPES_DOWNLOAD[0]]:
      "https://spradling-misc.s3.us-west-2.amazonaws.com/landing-attachments/kizuna/digital_catalogue.pdf",
    [MODAL_TYPES_DOWNLOAD[1]]:
      "https://spradling-misc.s3.us-west-2.amazonaws.com/landing-attachments/kizuna/cleaning_guide.pdf"
  }

  return {
    pdfURL: urlPDFAccordingToFormType[formType],
    fileName: fileNames[formType]
  }
}
