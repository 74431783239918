import { useState } from "react"

// Define el estado inicial
const INITIAL_STATE = {
  collapse1: true,
  collapse2: false,
  collapse3: false,
  collapse4: false,
  collapse5: false,
  collapse6: false
}

const useAccordion = () => {
  const [toggable, toggle] = useState(INITIAL_STATE)

  const handleToggle = (collapse) => {
    toggle({ ...toggable, [collapse]: !toggable[collapse] })
  }

  const handleToggleTrue = (collapse) => {
    toggle({ ...toggable, [collapse]: true })
  }

  const handleCloseAllAccordion = () => {
    toggle({
      collapse1: false,
      collapse2: false,
      collapse3: false,
      collapse4: false,
      collapse5: false,
      collapse6: false
    })
  }

  return { toggable, handleToggle, handleToggleTrue, handleCloseAllAccordion }
}

export default useAccordion
