import axios from "axios"

export const handleFormatToBoolean = (value) => value && !!value.includes("yes")
export const handleFormatToArrayOfString = (value) => (value ? value.map(({ value }) => value) : [])

export const CLAIMS_EXCEL_URL =
  "https://proquinal-my.sharepoint.com/:x:/r/personal/juand_coronado_spradling_group/_layouts/15/Doc.aspx?sourcedoc=%7B339F98FE-2E45-4641-BEE2-D267D3F1DCD4%7D&file=Claims.xlsx&action=default&mobileredirect=true"

const URL_ENDPOIND_PAYROLL = process.env.REACT_APP_SERVER_ENDPOIND_PAYROLL_CLAIMS

export const handleSendClaimToPayroll = async (data) => {
  try {
    const sendToPayroll = await axios.post(URL_ENDPOIND_PAYROLL, JSON.stringify(data))
    return sendToPayroll
  } catch (e) {
    console.log("handleSendClaimToPayroll error: ", e)
  }
}
