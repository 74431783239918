import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { useQuery } from "@apollo/client"
import {
  patternNameQuery,
  cityNameQuery
} from "components/StoreFinder/components/LocationsList/components/LocationsListHeader/query"
import Skeleton from "react-loading-skeleton"
import "./styles/LocationsListHeader.scss"

const LocationsListHeader = ({ patternId, cityId, onClickBack }) => {
  const query = !patternId ? cityNameQuery : patternNameQuery
  const variables = !patternId ? { id: cityId } : { id: patternId }

  const { loading, data } = useQuery(query, { variables })

  return (
    <div className="locations-list-header">
      <div className="row">
        <div className="col-4 align-self-center">
          <p className="mb-0" onClick={onClickBack}>
            <i className="fa fa-angle-left" />
            &nbsp;
            <FormattedMessage
              id="LocationsListHeader.goBackButton"
              description="Locations list header go back action"
              defaultMessage="Cambiar de ubicaci�n"
            />
          </p>
        </div>
        <div className="col-8 text-right">
          <p className="mb-0">
            {patternId ? (
              <>
                <FormattedMessage
                  id="LocationsListHeader.product"
                  description="Product word in locations list header"
                  defaultMessage="Producto"
                />
                <span>: {!loading ? data?.pattern.altName : <Skeleton width={100} />}</span>
              </>
            ) : (
              <>
                <FormattedMessage
                  id="LocationsListHeader.city"
                  description="City word in locations list header"
                  defaultMessage="Ciudad"
                />
                <span>: {!loading ? data?.city.name : <Skeleton width={100} />}</span>
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

LocationsListHeader.propTypes = {
  patternId: PropTypes.string,
  cityId: PropTypes.string,
  onClickBack: PropTypes.func
}

export default LocationsListHeader
