import PropTypes from "prop-types"
import cleaning from "modules/dali/components/ProductDetails/img/facil-limpieza.svg"
import cleaningSlash from "modules/dali/components/ProductDetails/img/facil-limpieza-slash.svg"
import "./styles/do-and-do-not-list.scss"
import classNames from "classnames/bind"
import { FormattedMessage } from "react-intl"

const DoAndDoNotList = ({ section, id }) => {
  return <div id={id} className="row g-0 wrapper-list--cleaning-sm px-3 container-cleaning-produre">
    <h3 className="fs-2 fw-bold mb-5 text-center pt-4">
      <FormattedMessage
       id="CleaningMarine.doAndDoNots.title"
       defaultMessage="Do’s & Don’ts"
      />
    </h3>
    <div className={classNames({
      "d-flex justify-content-md-center main-container-list-do-and-dont": section.do && section.doNot
    })}>
      {section && section.do && (
        <div className="col-6 col-md-6">
          <p className="fs-4 col-12 col-md-12 col-lg-5 col-xl-6 text-center text-lg-center">
            <FormattedMessage
              id="CleaningMarine.do.title"
              defaultMessage="Do’s"
            />
          </p>
          <div className="d-lg-flex justify-content-center align-items-center">
            <div className="d-flex col-12 mb-4 mb-lg-0 p-0 m-0 col-8 col-lg-3">
              <img className="clean-img" src={cleaning} />
            </div>
            <ul className="wrapper-items--cleaning-sm col-md-9 mx-auto col-lg-12 mx-lg-0">
              {section?.do.map((item) => (
                <li key={`key_${item.key}`} className="clean-label color-gray">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="col-6 col-md-6 mb-5">
        <p className="fs-4 text-center color-donts col-12 col-md-12 col-lg-5 col-xl-6 text-center text-lg-center">
          <FormattedMessage
            id="CleaningMarine.doNot.title"
            defaultMessage="Don’ts"
          />
        </p>
        <div className="d-lg-flex justify-content-center align-items-center">
          <div className="d-flex col-12 mb-4 mb-lg-0 p-0 m-0 col-8 col-lg-3">
            <img className="clean-img" src={cleaningSlash} />
          </div>
          <ul className="wrapper-items--cleaning-sm col-md-9 col-lg-12 mx-auto mx-lg-0">
            {section?.doNot.map((item) => (
              <li key={`key_${item.key}`} className="clean-label color-donts fw-color-donts">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    {section?.disclaimer && <p className="mt-5 disclaimer mb-5">{section?.disclaimer}</p>}
  </div>
}

DoAndDoNotList.propTypes = {
  section: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
}

export default DoAndDoNotList
