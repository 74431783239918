import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { FormattedMessage } from "react-intl"
import ErrorMessage from "components/ErrorMessage"
import Thumbs from "./components/Thumbs"
import "./styles/Dropzone.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "utils/littleStateMachine"

const Dropzone = ({ name, handleOnChange, error }) => {
  const [files, setFiles] = useState([])
  const { actions, state } = useStateMachine({ updateAction })
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": []
    },
    onDrop: (acceptedFiles) => {
      setFiles((previuosFiles) => files.length <= 9 ? [
        ...previuosFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ] : previuosFiles)
    }
  })

  const handleDeleteFile = (id) => {
    setFiles((prevFiles) => prevFiles.filter((file, index) => index !== id))
  }

  useEffect(() => {
    if (state.files) setFiles(state.files)

    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [])

  // WARNING: setting value files of form
  useEffect(() => {
    handleOnChange(files)
    actions.updateAction({ ...state, files })
  }, [files])

  return (
    <div>
      <section className="container dropzone-container mb-3 text-center rounded">
        <div {...getRootProps({ className: "dropzone" })}>
          <input
            type="file"
            name={name}
            onChange={(e) => {
              setFiles(Array.from(e.target.files))
            }}
            {...getInputProps()}
          />
          <p className="dropzone-placeholder cursor-pointer">
            <FormattedMessage
              id="Claims.Dropzone.placeholder"
              description="Claims Dropzone placeholder"
              defaultMessage="Drag and drop some files here, or click to select files"
              values={{
                br: <br/>
              }}
            />
            <FontAwesomeIcon icon={faFileArrowUp}
              size="2xl"
              className={classNames({
                "d-block mx-auto mt-2 cursor-pointer": true,
                "--fa-primary-color": "#bdbdbd",
                "--fa-secondary-color": "#999999"
              })}
              />
          </p>
        </div>
        <Thumbs files={files} handleDelete={handleDeleteFile} />
      </section>
      <ErrorMessage weight="normal">{error}</ErrorMessage>
    </div>
  )
}

Dropzone.propTypes = {
  name: PropTypes.string,
  handleOnChange: PropTypes.func,
  error: PropTypes.string
}

export default Dropzone
