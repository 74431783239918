import PropTypes from "prop-types"
import { useQuery } from "@apollo/client"
import Select from "components/InputMUI/Select"
import { productsQuery } from "./query"
import { FormattedMessage } from "react-intl"
import { PRODUCT_MSGS } from "../../constants"
import { DISCONTINUED_STYLES } from "./constants"
import { handleOrderArray } from "./utils"

const ProductField = ({ name, control, label, placeholder, defaultValue, error, pattern }) => {
  const { data, loading } = useQuery(productsQuery, { variables: { pattern } })
  const options = data?.products.edges.map(
    ({ node: { color: label, id: value, isDiscontinued } }) => ({
      label: !isDiscontinued ? (
        label
      ) : (
        <FormattedMessage {...PRODUCT_MSGS.discontinued} values={{ color: label }} />
      ),
      value,
      isDiscontinued
    })
  )

  return (
    <Select
      customStyles={DISCONTINUED_STYLES}
      name={name}
      control={control}
      label={label}
      placeholder={placeholder}
      options={handleOrderArray(options)}
      areDrawOptions
      defaultValue={defaultValue}
      disabled={loading || !pattern}
      error={error}
    />
  )
}

ProductField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  placeholder: PropTypes.object,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  error: PropTypes.string,
  pattern: PropTypes.string
}

export default ProductField
