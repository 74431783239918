import { useNavigate } from "react-router-dom"
import { useIntl, FormattedMessage } from "react-intl"
import { useForm, useWatch } from "react-hook-form"
import { useStateMachine } from "little-state-machine"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Layout from "modules/claims/components/Layout"
import InputText from "components/InputMUI/Text"
import Select from "components/InputMUI/Select"
import ProgressBar from "modules/claims/components/StepForm/ProgressBar"
import { getCurrentInstanceId, getCurrentLanguageAndInstanceCode } from "services/instances"
import { updateAction } from "utils/littleStateMachine"
import { BATCH_TOOLTIP, GENERIC_MSGS } from "modules/claims/constants"
import { BUTTON_MESSAGES } from "utils/buttons"
import {
  MATERIAL_CURRENT_STATE,
  MATERIAL_CURRENT_STATE_OPTIONS,
  MSGS,
  PRODUCT_BY_API_SCHEMA,
  PRODUCT_BY_TEXT_SCHEMA,
  REASON_OPTIONS
} from "./constants"
import "../styles/step-form.scss"
import PatternField from "./components/PatternField"
import ProductField from "./components/ProductField"
import dayjs from "dayjs"
import { useEffect } from "react"
import materialDetails from "./img/2.material-details.jpg"

const inputPlaceholder = GENERIC_MSGS.typeAnswer_placeholder
const selectPlaceholder = GENERIC_MSGS.choose_placeholder

const Step2 = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { actions, state } = useStateMachine({ updateAction })
  const [lang, instance] = getCurrentLanguageAndInstanceCode()

  const defaultValues = { ...state }
  const handleSchema = (data, context, options) => {
    return yupResolver(
      yup.object(
        data.patternId !== "OTHER" ? PRODUCT_BY_API_SCHEMA(intl) : PRODUCT_BY_TEXT_SCHEMA(intl)
      )
    )(data, context, options)
  }

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: async (data, context, options) => handleSchema(data, context, options)
  })

  const conditionsValue = useWatch({ control, name: "conditions" })
  const patternIdValue = useWatch({ control, name: "patternId" })

  const onSubmit = (data) => {
    actions.updateAction({
      ...data,
      instanceId: getCurrentInstanceId(),
      date: dayjs()
    })
    if (conditionsValue !== MATERIAL_CURRENT_STATE.ORIGINAL) {
      navigate(`../${lang}-${instance}/claims/step-3`, { replace: true })
    } else {
      navigate(`../${lang}-${instance}/claims/step-4`, { replace: true })
    }
  }

  useEffect(() => {
    actions.updateAction({
      ...state,
      conditions: state.conditions,
      reasons: state.reasons
    })
    if (conditionsValue !== MATERIAL_CURRENT_STATE.ORIGINAL) {
      actions.updateAction({ ...state, previousStep: `../${lang}-${instance}/claims/step-3` })
    } else {
      actions.updateAction({ ...state, previousStep: `../${lang}-${instance}/claims/step-2` })
    }
  }, [state.conditions, state.reasons])

  return (
    <Layout
      title={
        <FormattedMessage
          id="Claims.MaterialDetail.title"
          description="section material details condition title"
          defaultMessage="Material details"
        />
      }
      description={
        <FormattedMessage
          id="Claims.MaterialDetail.description"
          description="section original condition description"
          defaultMessage="Please indicate if your claim concerns material in its original state (rolls) or if the material has already been transformed (e.g. material has been already upholstered or used to manufacture components)"
        />
      }
      alignDescription="center"
      backgroundImage={materialDetails}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <PatternField
          name="patternId"
          control={control}
          label={MSGS.originalCondition_product}
          description={MSGS.originalCondition_productDescription}
          placeholder={selectPlaceholder}
          defaultValue={state.patternId}
          error={errors?.patternId?.message}
        />
        {patternIdValue !== "OTHER" ? (
          <ProductField
            name="productId"
            control={control}
            label={MSGS.originalCondition_color}
            placeholder={selectPlaceholder}
            defaultValue={state.productId}
            error={errors?.productId?.message}
            pattern={patternIdValue}
          />
        ) : (
          // TODO: add this values for model in backend
          <div className="row">
            <div className="col-12 col-md-6">
              <InputText
                name="otherProduct"
                register={register}
                label={MSGS.originalCondition_product}
                placeholder={inputPlaceholder}
                defaultValue={state.otherProduct}
                error={errors?.otherProduct?.message}
              />
            </div>
            <div className="col-12 col-md-6">
              <InputText
                name="otherColor"
                register={register}
                label={MSGS.originalCondition_color}
                placeholder={inputPlaceholder}
                defaultValue={state.otherColor}
                error={errors?.otherColor?.message}
              />
            </div>
          </div>
        )}
        <InputText
          name="quantity"
          register={register}
          label={MSGS.originalCondition_quantityClimedMaterial}
          placeholder={inputPlaceholder}
          defaultValue={state.quantity}
          error={errors?.quantity?.message}
        />
        <InputText
          name="batch"
          register={register}
          label={MSGS.batch}
          description={MSGS.batchDescription}
          tooltipElem={BATCH_TOOLTIP}
          placeholder={inputPlaceholder}
          defaultValue={state.batch}
          error={errors?.batch?.message}
        />
        <Select
          name="conditions"
          control={control}
          label={MSGS.originalCondition_materialCurrentState}
          placeholder={selectPlaceholder}
          options={MATERIAL_CURRENT_STATE_OPTIONS}
          defaultValue={state.conditions}
          error={errors?.conditions?.message}
        />
        <Select
          name="reasons"
          control={control}
          label={MSGS.originalCondition_reason}
          placeholder={selectPlaceholder}
          options={conditionsValue ? REASON_OPTIONS[conditionsValue] : []}
          disabled={!conditionsValue}
          defaultValue={state.reasons}
          error={errors?.reasons?.message}
        />
        <div className="btn-container">
          <button className="btn btn-dark text-uppercase" type="submit">
            {intl.formatMessage(BUTTON_MESSAGES.ok)}
          </button>
        </div>
        <ProgressBar
          progress={20}
          handleNext={handleSubmit(onSubmit)}
          RoutePrev={`../${lang}-${instance}/claims/step-1`}
        />
      </form>
    </Layout>
  )
}

export default Step2
