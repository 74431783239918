import { FormattedMessage } from "react-intl"
import Layout from "modules/claims/components/Layout"
import { useStateMachine } from "little-state-machine"
import Funnel from "./components/Funnel"
import "../styles/step-form.scss"
import {
  CLAIM_REASONS_LEFT_SECTIONS_TITLE_MSGS,
  CLAIM_REASONS_LEFT_SECTIONS_DESCRIPTION_MSGS
} from "./constants"
import { GET_BACKGROUND_IMAGE_ACCORD_TO_REASON } from "../../Layout/constants"

const Step3 = () => {
  const {
    state: { conditions, reasons }
  } = useStateMachine()

  return (
    <Layout
      title={<FormattedMessage {...CLAIM_REASONS_LEFT_SECTIONS_TITLE_MSGS[reasons]} />}
      description={
        !!CLAIM_REASONS_LEFT_SECTIONS_DESCRIPTION_MSGS[reasons] && (
          <FormattedMessage {...CLAIM_REASONS_LEFT_SECTIONS_DESCRIPTION_MSGS[reasons]} />
        )
      }
      alignDescription="center"
      backgroundImage={GET_BACKGROUND_IMAGE_ACCORD_TO_REASON[reasons].backgroundImage}
    >
      <Funnel conditions={conditions} reasons={reasons} />
    </Layout>
  )
}

export default Step3
