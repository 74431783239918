import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem as Item } from "reactstrap"
import useToggle from "hooks/useToggle"

const DropdownItem = ({ title, items = [], handleRedirect = () => {} }) => {
  const [toggable, toggle] = useToggle()

  return (
    <Dropdown className="header-menu-button" isOpen={toggable} toggle={toggle}>
      <DropdownToggle color="" className="header-menu-button">
        {title}
      </DropdownToggle>
      {items && (
        <DropdownMenu>
          {items.map(({ node: { id, name, slug } }) => (
            <Item
              key={`key_${id}`}
              tag="a"
              href={handleRedirect({ slug })}
              rel="noopener noreferrer"
            >
              {name}
            </Item>
          ))}
        </DropdownMenu>
      )}
    </Dropdown>
  )
}

DropdownItem.propTypes = {
  title: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.object,
      href: PropTypes.string
    })
  ),
  handleRedirect: PropTypes.func
}

export default DropdownItem
