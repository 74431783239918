import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import permablokLogo from "./assets/img/permablok-plus.svg"
import Video from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"
import "./assets/styles/permablok.scss"

const Permablok = ({ id }) => {
  const isMobile = useMediaQuery(SM)
  return (
    <div id={id}>
      <div className="container my-2 py-4">
        <div className="row">
          <div className="col-12 col-md-6">
            <Video className="img-fluid" fromYoutube>
              <iframe
                title="video"
                width="100%"
                height={isMobile ? "250" : "315"}
                src="https://www.youtube.com/embed/YNVzqmtRDro"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Video>
          </div>
          <div className="col-12 col-md-6 align-self-center text-md-end">
            <div className="permablok-description float-md-end">
              <img className="my-4 mt-md-0" src={permablokLogo} alt="permablok plus" />
              <h4 className="subtitle">
                <FormattedMessage
                  id="WhisperNeoPlus.Permablok.subtitle"
                  description="WhisperNeoPlus section subtitle permablok"
                  defaultMessage="Improved Dye Resistance And Cleaning"
                />
              </h4>
              <p>
                <FormattedMessage
                  id="WhisperNeoPlus.Permablok.description"
                  description="WhisperNeoPlus section title description"
                  defaultMessage="Permablok<sup>®</sup> Plus+ from Spradling<sup>®</sup> is a newly developed protective
                coating combining the well-known and proven Permablok<sup>®</sup> formula with an
                enhanced resistance to stains: it seals the surface providing a shield against stains,
                making cleaning easier and improves removal of dye-transfer<sup>*</sup>."
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Permablok.propTypes = {
  id: PropTypes.string.isRequired
}

export default Permablok
