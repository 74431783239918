import Footer from "components/Footer"
import UpholsteryForm from "./components/Form"
import background from "./assets/img/background.jpg"
import "./assets/styles/feria-tapiceria.scss"
import { instanceQuery } from "components/Footer/query"
import { useQuery } from "@apollo/client"
import { getCurrentInstanceId } from "services/instances"
import { FormattedMessage } from "react-intl"

const FeriaTapiceria = () => {
  const { data } = useQuery(instanceQuery, {
    variables: {
      instanceId: getCurrentInstanceId()
    }
  })

  return (
    <>
      <div className="container upholstery-container">
        <div className="row">
          <div
            className="col-12 col-md-6 upholstery-background"
            style={{
              backgroundImage: `url(${background})`
            }}
          >
            {data && data.instance.logo && (
              <img
                className="upholstery-logo-instance img-fluid"
                src={data.instance.logo}
                alt="logo"
              />
            )}
          </div>
          <div
            className="col-12 col-md-6 g-0 upholstery-background--form"
            style={{
              backgroundImage: `url(${background})`
            }}
          >
            <div className="container upholstery-glassmorphism">
              <div className="row px-2 py-4">
                <div className="col-12">
                  <div className="text-center text-white">
                    <h1>
                      <FormattedMessage
                        id="UpholsteryFair.title"
                        description="title for upholstery fair page"
                        defaultMessage="Bienvenido"
                      />
                    </h1>
                    <p>
                      <FormattedMessage
                        id="UpholsteryFair.description"
                        description="description for upholstery fair page"
                        defaultMessage="Para conocer más sobre nuestras referencias y obtener catálogos solo debes completar el formulario a continuación. ¡Nos comunicaremos contigo pronto!"
                      />
                    </p>
                  </div>
                  <UpholsteryForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default FeriaTapiceria
