import EN_MESSAGES from "translations/compiled-lang/en"
import EN_US_MESSAGES from "translations/compiled-lang/en-us"
import EN_EU_MESSAGES from "translations/compiled-lang/en-eu"
import ES_MESSAGES from "translations/compiled-lang/es"
import ES_LATAM_MESSAGES from "translations/compiled-lang/es-la"
import ES_MX_MESSAGES from "translations/compiled-lang/es-mx"

export const TRANSLATIONS_MESSAGES = {
  en: EN_MESSAGES,
  "en-us": EN_US_MESSAGES,
  "en-eu": EN_EU_MESSAGES,
  es: ES_MESSAGES,
  "es-la": ES_LATAM_MESSAGES,
  "es-mx": ES_MX_MESSAGES
}
