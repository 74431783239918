/* eslint-disable indent */
import { FormattedMessage } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import { useQuery } from "@apollo/client"
import { getCurrentInstanceId } from "services/instances"
import ColorPalette from "components/ColorPalette"
import { trendQuery } from "./query"
import "./styles/trend.scss"
import HTMLContent from "components/HTMLContent"
import Header from "modules/floors/components/Header"
import { MENU_COMEBACK } from "modules/floors/constants"

const Trend = () => {
  const navigate = useNavigate()
  const { slug } = useParams()
  const { data, loading } = useQuery(trendQuery, {
    variables: {
      slug,
      instanceIds: [getCurrentInstanceId()]
    }
  })

  return (
    <>
      <Header noInspirations objItems={MENU_COMEBACK(navigate)} />
      <div className="container mt-5 pt-5">
        <div className="row justify-content-center align-items-center">
          <TitleUnderlined className="text-capitalize" hTag={H2_TAG}>
            {loading ? <Skeleton width={200} /> : data.trend.name}
          </TitleUnderlined>
          <div>{loading ? <Skeleton count={8} /> : <HTMLContent html={data.trend.text} />}</div>
          {loading
            ? [0, 1].map((item) => (
                <div key={`key_${item}`}>
                  <Skeleton width={150} height={32} />
                  <Skeleton width={100} />
                  <Skeleton count={4} />
                  <div className="text-center">
                    <Skeleton width={200} height={200} />
                  </div>
                  <Skeleton width={100} />
                  <Skeleton count={4} />
                  <div className="text-center">
                    <div className="d-flex flex-row justify-content-center">
                      {[0, 1, 2, 3].map((item) => (
                        <Skeleton key={`key_${item}`} className="m-2" width={100} height={100} />
                      ))}
                    </div>
                  </div>
                </div>
              ))
            : data.trend.patternSet.edges.map(
                ({ node: { id, altName, slug, inspiration, cardOfColor, products } }) => (
                  <div key={`key_${id}`}>
                    <h2
                      className="my-2 cursor-pointer text-capitalize"
                      onClick={() =>
                        navigate(`../es-la/pisos-interno/piso/${slug}`, { replace: true })
                      }
                    >
                      {altName}
                    </h2>
                    <div>
                      <h4>
                        <FormattedMessage
                          id="Floors.HeaderButtons.inspiration"
                          description="inspiration table title at floor detail page"
                          defaultMessage="Inspiración"
                        />
                      </h4>
                      <HTMLContent html={inspiration.text} />
                      <div className="text-center my-4">
                        <img
                          className="img-fluid inspiration-img"
                          src={inspiration.imageThumbMedium}
                          alt="inspiration"
                        />
                      </div>
                    </div>
                    <div>
                      <h4>
                        <FormattedMessage
                          id="Floors.HeaderButtons.colorCard"
                          description="inspiration table title at floor detail page"
                          defaultMessage="Carta de color"
                        />
                      </h4>
                      <HTMLContent html={cardOfColor.text} />
                      <ColorPalette colors={products.edges} align="center" actived hideWarning />
                    </div>
                  </div>
                )
              )}
        </div>
      </div>
    </>
  )
}

export default Trend
