import { FormattedMessage } from "react-intl"

export const MENU = [
  {
    id: "pisos",
    text: (
      <FormattedMessage
        id="Floors.HeaderButtons.floors"
        description="floors HeaderButtons text"
        defaultMessage="Pisos"
      />
    ),
    href: "pisos"
  },
  {
    id: "guide",
    text: (
      <FormattedMessage
        id="Floors.HeaderButtons.instalation"
        description="guide HeaderButtons text"
        defaultMessage="Instalación"
      />
    ),
    internalLink: true,
    href: "/es-la/pisos/guia-instalacion"
  },
  {
    id: "complements",
    text: (
      <FormattedMessage
        id="Floors.HeaderButtons.complements"
        description="complements HeaderButtons text"
        defaultMessage="Complementos"
      />
    ),
    externalLink: true,
    href: "https://api.spradling.group/emailer/asset/es/12/"
  },
  {
    id: "Deliverables",
    text: (
      <FormattedMessage
        id="Floors.HeaderButtons.Deliverables"
        description="Deliverables HeaderButtons text"
        defaultMessage="Entregables"
      />
    ),
    internalLink: true,
    href: "/es-la/pisos-interno/entregables"
  }
]

export const MENU_COMEBACK = (navigate) => [
  {
    id: "come-back",
    text: (
      <FormattedMessage
        id="Button.comeBack"
        description="Button come back text"
        defaultMessage="Come Back"
      />
    ),
    href: "",
    callback: () => navigate(-1)
  }
]

export const MENU_EXTERNAL_FLOORS = [
  {
    id: "pisos",
    text: (
      <FormattedMessage
        id="Floors.HeaderButtons.floors"
        description="floors HeaderButtons text"
        defaultMessage="Pisos"
      />
    ),
    href: "pisos"
  },
  {
    id: "guide",
    text: (
      <FormattedMessage
        id="Floors.HeaderButtons.instalation"
        description="guide HeaderButtons text"
        defaultMessage="Instalación"
      />
    ),
    internalLink: true,
    href: "/es-la/pisos/guia-instalacion"
  },
  {
    id: "complements",
    text: (
      <FormattedMessage
        id="Floors.ProductDetail.title"
        description="Floors section title product detail"
        defaultMessage="Características"
      />
    ),
    href: "complements"
  },
  {
    id: "Deliverables",
    text: (
      <FormattedMessage
        id="Floors.WhereToBuy.label"
        description="Floors section label where to buy"
        defaultMessage="Dónde Comprar"
      />
    ),
    href: "Deliverables"
  }
]

export const GLOBAL_SITE_TAG = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "GTM-TMT7QRV"
}
