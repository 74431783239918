import Banner from "components/Banner"
import { SLIDE } from "modules/floors/components/Banner/constants"
import Video from "../components/Video"
import { GLOBAL_SITE_TAG, MENU } from "../constants"
import Header from "../components/Header"
import References from "../components/References"
import Footer from "components/Footer"
import ProductDetails from "../components/ProductDetails"
import posterHomeVideo from "modules/floors/img/poster-home.jpg"
import Analytics from "components/Analytics"
import { getCurrentInstanceId } from "services/instances"

const Floors = () => {
  return (
    <>
      <Analytics gtag={GLOBAL_SITE_TAG[getCurrentInstanceId()]} />
      <Header objItems={MENU} />
      <Banner slides={SLIDE} noDescription />
      <Video video="https://www.youtube.com/embed/NSxP_CYMrFw" poster={posterHomeVideo} />
      <References id={MENU[0].id} />
      <ProductDetails />
      <Footer />
    </>
  )
}

export default Floors
