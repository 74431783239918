import { FormattedMessage } from "react-intl"
import calzado from "../img/calzado.jpg"
import tela from "../img/tela.jpg"

export const ITEMS = [
  {
    id: "Calzado",
    title: (
      <FormattedMessage
        id="Pandora.Applications.usesAndAplications"
        description="Pandora section application"
        defaultMessage="Usos y aplicaciones"
      />
    ),
    imageThumb: calzado
  },
  {
    id: "Tela",
    title: (
      <FormattedMessage
        id="Pandora.Applications.texture"
        description="Pandora section application texture"
        defaultMessage="Textura"
      />
    ),
    imageThumb: tela
  }
]
