import PropTypes from "prop-types"
import { Button, Col, Row } from "reactstrap"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"
import "./styles/ThanksYouMessage.scss"

const ThanksYouMessageQuickShip = ({ modalContext, toggle }) => {
  // WARNING: variable used only for success form data analytics
  const analyticsIdentifier = `thank-you-message--${modalContext?.type || "REQUEST_SAMPLES_INFO"}`
  const isMobile = useMediaQuery(LG)

  const handleClickInBtn = () => {
    toggle()
  }

  return (
    <div>
      <div className="text-center">
        <h1 className="thanks-you-message--title text-center mb-md-3">Thank you!</h1>
      </div>
      <Row className="text-center mt-2">
        <p className={`thank-you-message--description ${analyticsIdentifier}`}>
          We received your request {isMobile && <br />} and will be processing it soon!
        </p>
      </Row>
      <div className="text-center py-lg-0">
        <Button
          type="button"
          className="text-white px-4 button-form-masiala py-1 cursor-pointer"
          onClick={handleClickInBtn}
        >
          <span className="thank-you-message--btn">Close</span>
        </Button>
      </div>
    </div>
  )
}

ThanksYouMessageQuickShip.propTypes = {
  modalContext: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  callbackToChangeValueOfIsThankYouState: PropTypes.func.isRequired,
  handleDownloadPublicPDF: PropTypes.func.isRequired
}

export default ThanksYouMessageQuickShip
