import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import dogImage from "./img/dog.jpeg"
import "./styles/how-works.scss"

const HowWorks = ({ id }) => (
  <div id={id} className="container how-works-container pt-4 pb-4 pt-lg-5 mb-lg-5">
    <div className="row">
      <div className="col-12 col-md-8">
        <img className="img-fluid mb-0 mb-lg-5" src={dogImage} alt="preview of virtual room" />
      </div>
      <div className="col-12 col-md-4">
        <div className="blue-box p-5">
          <p className="paragraph">
            <FormattedMessage
              id="Prannatex.HowWorks.description"
              description="Launch How does it work page description"
              defaultMessage="Pranna<sup>®</sup>Tex is the culmination of an arduous and dedicated process, which represents an innovation destined to revolutionize the textile world. Inspired by the heritage of Pranna®, this new creation is distinguished by its textile-like finish that combines perfectly with the contemporary and multifunctional style."
              values={{
                br: <br />,
                sup: (...chunks) => <sup>{chunks}</sup>
              }}
            />
          </p>
        </div>
      </div>
    </div>
  </div>
)

HowWorks.propTypes = {
  id: PropTypes.string
}

export default HowWorks
