import { FormattedMessage } from "react-intl"
import banner from "modules/permacool/img/banner.jpg"
export const NAV_ITEMS = ["how it works?", "breeze", "contact"]

export const SLIDE = [
  {
    id: "slide-permacool",
    imageThumb: banner,
    btnClassName: "btn-light",
    title: (
      <FormattedMessage
        id="Permacool.Banner.title"
        description="Permacool title banner"
        defaultMessage="Permacool"
      />
    ),
    description: (
      <FormattedMessage
        id="Permacool.Banner.description"
        description="Permacool description banner"
        defaultMessage="Comfort Technology"
      />
    ),
    link: `#${NAV_ITEMS[0]}`,
    buttonText: (
      <FormattedMessage
        id="Button.moreInfo"
        description="Button more info text"
        defaultMessage="Learn more"
      />
    )
  }
]

export const GLOBAL_SITE_TAG = "GTM-54PGW2V"
