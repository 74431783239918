import { FormattedMessage } from "react-intl"

import hospitality from "./img/hospitality.svg"
import healthcare from "./img/healthcare.svg"
import office from "./img/Groupoffice.svg"
import residential from "./img/residential.svg"
import automotive from "./img/automotive.svg"
import marineInteriors from "./img/Groupmarine.svg"
import transportation from "./img/Grouptransportation.svg"
import caravans from "./img/Groupcaravans.svg"
import education from "./img/education.svg"
import publicSpaces from "./img/public-spaces.svg"

export const APPLICATIONS_ELEMENTS = [
  {
    img: {
      srcImg: hospitality,
      style: { with: 30 }
    },
    description: (
      <FormattedMessage
        id="Valencia.Applications.hospitality"
        defaultMessage="Hospitality"
      />
    )
  },
  {
    img: {
      srcImg: healthcare,
      style: { with: 24 }
    },
    description: (
      <FormattedMessage
        id="Valencia.Applications.healthcare"
        defaultMessage="Healthcare"
      />
    )
  },
  {
    img: {
      srcImg: residential,
      style: { with: 28 }
    },
    description: (
      <FormattedMessage
        id="Valencia.Applications.residential"
        defaultMessage="Residential"
      />
    )
  },
  {
    img: {
      srcImg: education,
      style: { with: 24 }
    },
    description: (
      <FormattedMessage
        id="Valencia.Applications.education"
        defaultMessage="Education"
      />
    )
  },
  {
    img: {
      srcImg: publicSpaces,
      style: { with: 24 }
    },
    description: (
      <FormattedMessage
        id="Valencia.Applications.publicSpaces"
        defaultMessage="Public Spaces"
      />
    )
  },
  {
    img: {
      srcImg: office,
      style: { with: 24 }
    },
    description: (
      <FormattedMessage
        id="Valencia.Applications.office"
        defaultMessage="Office"
      />
    )
  }
]
