import { defineMessages } from "react-intl"

export const BUTTON_MESSAGES = defineMessages({
  send: {
    id: "Button.send",
    description: "Button send text",
    defaultMessage: "Send"
  },
  sending: {
    id: "Button.sending",
    description: "Button sending text",
    defaultMessage: "Sending..."
  },
  ok: {
    id: "Button.ok",
    description: "Button ok text",
    defaultMessage: "OK"
  }
})
