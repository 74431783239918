import { useNavigate } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import classNames from "classnames"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Layout from "modules/claims/components/Layout"
import { getCurrentLanguageAndInstanceCode } from "services/instances"
import { handleFormatItems } from "utils/items"
import Check from "components/InputMUI/Check"
import ModalCheck from "./components/ModalCheck"
import { MODAL_CKECK_SLUGS, MSGS, REGISTRATION_CHECKS, VALIDATION_MESSAGES } from "./constants"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "utils/littleStateMachine"
import "../styles/step-form.scss"
import hero from "./img/hero.png"

const Step0 = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { actions, state } = useStateMachine({ updateAction })
  const [lang, instance] = getCurrentLanguageAndInstanceCode()

  const defaultValues = {
    ...state
  }
  const schema = yup.object({
    check_nameAndColor: yup
      .boolean()
      .oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required)),
    check_quantity: yup.boolean().oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required)),
    check_customerNumber: yup
      .boolean()
      .oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required)),
    check_productionBatchNumber: yup
      .boolean()
      .oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required)),
    check_pictures: yup.boolean().oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required)),
    check_termsAndConditions: yup
      .boolean()
      .oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required)),
    check_privacyPolicy: yup
      .boolean()
      .oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = (data) => {
    actions.updateAction(data)
    navigate(`../${lang}-${instance}/claims/step-1`)
  }

  return (
    <Layout
      title={
        <FormattedMessage
          id="Claims.Registration.title"
          description="section claim registration title"
          defaultMessage="Claim Registration"
        />
      }
      description={
        <FormattedMessage
          id="Claims.Registration.description"
          description="section claim registration description"
          defaultMessage="Dear valued customer,{br}{br}Filling out this form will only take you a few minutes and save a lot of time for processing your claim.{br}{br}Thank for your help. For questions please contact: {link}"
          values={{
            br: <br />,
            link: (
              <a
                className="text-light"
                href="mailto:info.es@spradling.group"
                target="_blank"
                rel="noopener noreferrer"
              >
                info.es@spradling.group
              </a>
            )
          }}
        />
      }
      backgroundImage={hero}
    >
      <div>
        <p className="mb-4 fs-5">{intl.formatMessage(MSGS.descriptionForm)}</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          {handleFormatItems(REGISTRATION_CHECKS, intl).map(
            ({ name, label, description, tooltipElem, showModal }) => (
              <div key={`key_${name}`}>
                {!showModal ? (
                  <Check
                    name={name}
                    register={register}
                    label={label}
                    description={description}
                    tooltipElem={tooltipElem}
                    error={errors[name]?.message}
                  />
                ) : (
                  <ModalCheck
                    control={control}
                    register={register}
                    setValue={setValue}
                    slug={MODAL_CKECK_SLUGS[name].slug}
                    title={MODAL_CKECK_SLUGS[name].title}
                    name={name}
                    label={label}
                    error={errors[name]?.message}
                  />
                )}
              </div>
            )
          )}
          <div className="btn-container">
            <button
              className={classNames({
                "btn btn-dark text-uppercase": true,
                disabled: Object.values(errors).length
              })}
              type="submit"
            >
              {intl.formatMessage(MSGS.textBtn)}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Step0
