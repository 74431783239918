import { FormattedMessage } from "react-intl"
import banner from "../img/banner.jpg"
import ContactForm from "components/ContactForm"
import { COUNTRIES_FOR_MEXICO } from "consts/countryCodes"
import { NAV_ITEMS } from "../../../constants"

export const SLIDE = [
  {
    id: "slide-impala-mx",
    imageThumb: banner,
    title: (
      <FormattedMessage
        id="Impala.Banner.title"
        description="Impala mx title banner"
        defaultMessage="Impala"
      />
    ),
    description: (
      <FormattedMessage
        id="Impala.Banner.description"
        description="Impala mx description banner"
        defaultMessage="Referencia apta para tapicería de cualquier vehículo o mueble"
      />
    ),
    link: `#${NAV_ITEMS[NAV_ITEMS.length - 1]}`,
    buttonText: (
      <FormattedMessage
        id="Impala.Banner.buttonText"
        description="Impala buttonText banner"
        defaultMessage="Dónde comprar"
      />
    ),
    form: (
      <ContactForm
        theme="light"
        styleContainer="container-fluid upholstery-glassmorphism my-3"
        styleFormContainer="p-4"
        title={
          <FormattedMessage
            id="Pranna.Contact.title"
            description="Floors section title contact"
            defaultMessage="¿ Quieres más información ?"
          />
        }
        countryOptions={COUNTRIES_FOR_MEXICO}
        landingSource="impala"
        isBasic
      />
    )
  }
]
