import { FormattedMessage } from "react-intl"
import banner from "modules/whisper/components/Banner/img/new-banner.jpg"

export const SLIDE = [
  {
    id: "slide-whisper-neo-plus",
    imageThumb: banner,
    title: (
      <FormattedMessage
        id="WhisperNeoPlus.Banne.theNewWhisper.title"
        description="WhisperNeoPlus title banner"
        defaultMessage="The Whispers are True"
      />
    ),
    titleClassName: "text-white"
  }
]
