import PropTypes from "prop-types"

export const handleFormatItems = (items, intl) =>
  items.map(({ label, description, ...rest }) => ({
    label: intl.formatMessage(label),
    description: description && intl.formatMessage(description),
    ...rest
  }))

handleFormatItems.propTypes = {
  intl: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.object.isRequired,
      description: PropTypes.object,
      value: PropTypes.string,
      name: PropTypes.string,
      info: PropTypes.string,
      showModal: PropTypes.bool
    })
  ).isRequired
}
