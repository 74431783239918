import { NAV_ITEMS } from "modules/Ibex/constants"
import colorsTheWorld from "./img/colors-the-world.svg"
import "./styles/inspiration.scss"

const Inspiration = () => (
  <div className="py-4">
    <div className="row justify-content-center align-items-center">
      <div className="col text-center">
        <div>
          <span>Let us inspire you with new trends, colors,</span>
          <br />
          <span>textures, and innovations from around the world.</span>
        </div>
        <img className="m-4 logo-inspiration" src={colorsTheWorld} alt="colors-the-world" />
        <div>
          <a
            className="btn btn-outline-light"
            target="_self"
            href={`#${NAV_ITEMS[NAV_ITEMS.length - 1]}`}
            rel="noopener noreferrer"
          >
            <h2 className="text-spradling-green">Reserve</h2>
          </a>
        </div>
        <div>
          <span>your appointment now!</span>
          <br />
          <span>Tuesday, Wednesday, and Thursday</span>
          <br />
          <span className="fw-bold">September 27–29, 2022</span>
        </div>
        <div className="my-2">
          <span>Tampa Marriott Water Street</span>
          <br />
          <span>3rd floor, Room 9</span>
          <br />
          <span>505 Water Street, Tampa, FL 33602</span>
        </div>
        <div className="my-4">
          <span>Call or email Carrie “Crayola” Smith –</span>
          <br />
          <span>carrie@spradling.group – 800.247.9901</span>
        </div>
      </div>
    </div>
  </div>
)

export default Inspiration
