import gql from "graphql-tag"

const storesByPatternQuery = gql`
  query StoresByPatternQuery($patternIds: [ID], $instanceId: ID) {
    stores(patterns: $patternIds, instance: $instanceId) {
      edges {
        node {
          id
          name
          address
          phoneNumbers
          email
          latitude
          longitude
          website
          city {
            id
            name
            country {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default storesByPatternQuery
