import { defineMessages } from "react-intl"
import coolerToTouch from "../img/cooler-to-the-touch.svg"
import soft from "../img/soft.svg"
import denim from "../img/Demin.svg"
import superiorAbraision from "../img/superior-abraision.svg"
import waterResistant from "../img/water-resistant.svg"
import uvStabilized from "../img/uv-stabilized.svg"
import mildewResistant from "../img/mildew-resitant.svg"
import stainResistant from "../img/stain-resistant.svg"

export const MSGS = defineMessages({
  coolerToTouch: {
    id: "Breeze.ProductDetail.coolerToTheTouch",
    description: "Breeze section product detail item Cooler to the Touch",
    defaultMessage: "Cooler to{br}the Touch"
  },
  soft: {
    id: "WhisperNeoPlus.ProductDetail.soft",
    description: "WhisperNeoPlus section product detail item soft",
    defaultMessage: "Soft {br} Hand"
  },
  denim: {
    id: "WhisperNeoPlus.ProductDetail.denim",
    description: "WhisperNeoPlus section product detail item denim",
    defaultMessage: "Denim {br} Cleanable"
  },
  superiorAbraision: {
    id: "Breeze.ProductDetail.superiorAbraision",
    description: "Breeze section product detail item superior abraision",
    defaultMessage: "Superior {br} abraision"
  },
  waterResistant: {
    id: "Breeze.ProductDetail.waterResistant",
    description: "Breeze section product detail item water resistant",
    defaultMessage: "Water {br} resistant"
  },
  uvStabilized: {
    id: "Breeze.ProductDetail.uvStabilized",
    description: "Breeze section product detail item uv stabilized",
    defaultMessage: "UV {br} stabilized"
  },
  mildewResistant: {
    id: "Breeze.ProductDetail.mildewResistant",
    description: "Breeze section product detail item mildew resistant",
    defaultMessage: "Mildew {br} Resistant"
  },
  stainResistant: {
    id: "Breeze.ProductDetail.stainResistant",
    description: "Breeze section product detail item stain resistant",
    defaultMessage: "Stain {br} resistant"
  }
})

export const PRODUCT_DETAILS = [
  {
    id: "coolerToTouch",
    text: MSGS.coolerToTouch,
    icon: coolerToTouch
  },
  {
    id: "soft",
    text: MSGS.soft,
    icon: soft
  },
  {
    id: "denim",
    text: MSGS.denim,
    icon: denim
  },
  {
    id: "superiorAbraision",
    text: MSGS.superiorAbraision,
    icon: superiorAbraision
  },
  {
    id: "waterResistant",
    text: MSGS.waterResistant,
    icon: waterResistant
  },
  {
    id: "uvStabilized",
    text: MSGS.uvStabilized,
    icon: uvStabilized
  },
  {
    id: "mildewResistant",
    text: MSGS.mildewResistant,
    icon: mildewResistant
  },
  {
    id: "stainResistant",
    text: MSGS.stainResistant,
    icon: stainResistant
  }
]
