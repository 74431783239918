import PropTypes from "prop-types"
import { ErrorMessage as FormikErrorMessage } from "formik"

const ErrorMessage = ({ className = "", color = "red", weight = "bold", children, name }) =>
  !name ? (
    <p
      className={`form-error-message form-error-message--color-${color} form-error-message--weight-${weight} ${className}`}
    >
      {children}
    </p>
  ) : (
    <FormikErrorMessage
      className={`mb-0 form-error-message form-error-message--color-${color} ${className}`}
      component="p"
      name={name}
    />
  )

ErrorMessage.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.oneOf(["red", "black", "white"]),
  weight: PropTypes.oneOf(["bold", "normal"]),
  children: PropTypes.node
}

export default ErrorMessage
