import PropTypes from "prop-types"
import { Controller, useWatch } from "react-hook-form"
import { useLazyQuery } from "@apollo/client"
import { FormattedMessage, useIntl } from "react-intl"
import AsyncSelect from "react-select/async"
import regionsQuery from "./query"
import { MSGS } from "./constants"
import styles from "components/select/styles"

const SelectRegion = ({ labelClassName, name, control }) => {
  const intl = useIntl()
  const { value: countryId } = useWatch({ control, name: "country" })

  const placeholder = intl.formatMessage(!countryId ? MSGS.withoutCountry : MSGS.withCountry)
  const [getRegions, { data }] = useLazyQuery(regionsQuery)

  const loadOptions = (_, callback) => {
    const options = data?.regions.edges.map((edge) => ({
      label: edge.node.name,
      value: edge.node.id
    }))

    callback(options)
  }

  const handleOnInputChange = (value) =>
    getRegions({
      variables: {
        countryId,
        query: value
      }
    })

  return (
    <>
      <label htmlFor={name} className={labelClassName}>
        <FormattedMessage
          id="Form.field.Region"
          description="Form field region"
          defaultMessage="State/Province"
        />
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange: handleOnChangeForm, ...field } }) => (
          <AsyncSelect
            styles={styles}
            {...field}
            placeholder={placeholder}
            loadOptions={loadOptions}
            isDisabled={!countryId}
            onInputChange={handleOnInputChange}
            onChange={handleOnChangeForm}
            noOptionsMessage={() => (
              <FormattedMessage
                id="Form.field.noOptionesMessage"
                description="message when there's no options on a select field"
                defaultMessage="No options"
              />
            )}
          />
        )}
      />
    </>
  )
}

SelectRegion.propTypes = {
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired
}

export default SelectRegion
