import mandarina from "../img/1-mandarina.jpg"
import coral from "../img/2-coral.jpg"
import rojoCereza from "../img/3-rojo-cereza.jpg"
import escarlata from "../img/4-escarlata.jpg"
import granate from "../img/5-granate.jpg"
import rojo from "../img/6-rojo.jpg"
import rosado from "../img/7-rosado.jpg"
import alaska from "../img/8-alaska.jpg"
import celeste from "../img/9-celeste.jpg"
import hortensia from "../img/10-hortensia.jpg"
import turquesa from "../img/11-turquesa.jpg"
import azulRey from "../img/12-azul-rey.jpg"
import azul from "../img/13-azul.jpg"
import verdeManzana from "../img/14-verde-manzana.jpg"
import safari from "../img/15-safari.jpg"
import verde from "../img/16-verde.jpg"
import verdeClaro from "../img/17-verde-claro.jpg"
import zafran from "../img/18-zafran.jpg"
import mostaza from "../img/19-mostaza.jpg"
import caramelo from "../img/20-caramelo.jpg"
import capuchino from "../img/21-capuchino.jpg"
import oro from "../img/22-oro.jpg"
import taupe from "../img/23-taupe.jpg"
import ceniza from "../img/24-ceniza.jpg"
import perla from "../img/25-perla.jpg"
import almendra from "../img/26-almendra.jpg"
import champana from "../img/27-champana.jpg"
import bronce from "../img/28-bronce.jpg"
import marron from "../img/29-marron.jpg"
import cobre from "../img/30-cobre.jpg"
import sepia from "../img/31-sepia.jpg"
import tabaco from "../img/32-tabaco.jpg"
import petroleo from "../img/33-petroleo.jpg"
import mocca from "../img/34-mocca.jpg"
import chocolate from "../img/35-chocolate.jpg"
import marfilClaro from "../img/36-marfil-claro.jpg"
import blancoPuro from "../img/37-blanco-puro.jpg"
import hielo from "../img/38-hielo.jpg"
import blancoNieve from "../img/39-blanco-nieve.jpg"
import nacar from "../img/40-nacar.jpg"
import plata from "../img/41-plata.jpg"
import grisMedio from "../img/42-gris-medio.jpg"
import gris from "../img/43-gris.jpg"
import grisOscuro from "../img/44-gris-oscuro.jpg"
import negro from "../img/45-negro.jpg"

export const COLORS = [
  { node: { color: "Mandarina", imageThumbSmall: mandarina, imageThumbMedium: mandarina } },
  { node: { color: "Coral", imageThumbSmall: coral, imageThumbMedium: coral } },
  { node: { color: "Rojo cereza", imageThumbSmall: rojoCereza, imageThumbMedium: rojoCereza } },
  { node: { color: "Escarlata", imageThumbSmall: escarlata, imageThumbMedium: escarlata } },
  { node: { color: "Granate", imageThumbSmall: granate, imageThumbMedium: granate } },
  { node: { color: "Rojo", imageThumbSmall: rojo, imageThumbMedium: rojo } },
  { node: { color: "Rosado", imageThumbSmall: rosado, imageThumbMedium: rosado } },
  { node: { color: "Alaska", imageThumbSmall: alaska, imageThumbMedium: alaska } },
  { node: { color: "Celeste", imageThumbSmall: celeste, imageThumbMedium: celeste } },
  { node: { color: "Hortensia", imageThumbSmall: hortensia, imageThumbMedium: hortensia } },
  { node: { color: "Turquesa", imageThumbSmall: turquesa, imageThumbMedium: turquesa } },
  { node: { color: "AzulRey", imageThumbSmall: azulRey, imageThumbMedium: azulRey } },
  { node: { color: "azul", imageThumbSmall: azul, imageThumbMedium: azul } },
  {
    node: { color: "verde Manzana", imageThumbSmall: verdeManzana, imageThumbMedium: verdeManzana }
  },
  { node: { color: "safari", imageThumbSmall: safari, imageThumbMedium: safari } },
  { node: { color: "verde", imageThumbSmall: verde, imageThumbMedium: verde } },
  { node: { color: "Verde Claro", imageThumbSmall: verdeClaro, imageThumbMedium: verdeClaro } },
  { node: { color: "zafran", imageThumbSmall: zafran, imageThumbMedium: zafran } },
  { node: { color: "mostaza", imageThumbSmall: mostaza, imageThumbMedium: mostaza } },
  { node: { color: "caramelo", imageThumbSmall: caramelo, imageThumbMedium: caramelo } },
  { node: { color: "capuchino", imageThumbSmall: capuchino, imageThumbMedium: capuchino } },
  { node: { color: "oro", imageThumbSmall: oro, imageThumbMedium: oro } },
  { node: { color: "taupe", imageThumbSmall: taupe, imageThumbMedium: taupe } },
  { node: { color: "ceniza", imageThumbSmall: ceniza, imageThumbMedium: ceniza } },
  { node: { color: "perla", imageThumbSmall: perla, imageThumbMedium: perla } },
  { node: { color: "almendra", imageThumbSmall: almendra, imageThumbMedium: almendra } },
  { node: { color: "champaña", imageThumbSmall: champana, imageThumbMedium: champana } },
  { node: { color: "bronce", imageThumbSmall: bronce, imageThumbMedium: bronce } },
  { node: { color: "marron", imageThumbSmall: marron, imageThumbMedium: marron } },
  { node: { color: "cobre", imageThumbSmall: cobre, imageThumbMedium: cobre } },
  { node: { color: "sepia", imageThumbSmall: sepia, imageThumbMedium: sepia } },
  { node: { color: "tabaco", imageThumbSmall: tabaco, imageThumbMedium: tabaco } },
  { node: { color: "petroleo", imageThumbSmall: petroleo, imageThumbMedium: petroleo } },
  { node: { color: "mocca", imageThumbSmall: mocca, imageThumbMedium: mocca } },
  { node: { color: "chocolate", imageThumbSmall: chocolate, imageThumbMedium: chocolate } },
  { node: { color: "Marfil Claro", imageThumbSmall: marfilClaro, imageThumbMedium: marfilClaro } },
  { node: { color: "Blanco Puro", imageThumbSmall: blancoPuro, imageThumbMedium: blancoPuro } },
  { node: { color: "hielo", imageThumbSmall: hielo, imageThumbMedium: hielo } },
  { node: { color: "Blanco Nieve", imageThumbSmall: blancoNieve, imageThumbMedium: blancoNieve } },
  { node: { color: "nacar", imageThumbSmall: nacar, imageThumbMedium: nacar } },
  { node: { color: "plata", imageThumbSmall: plata, imageThumbMedium: plata } },
  { node: { color: "Gris Medio", imageThumbSmall: grisMedio, imageThumbMedium: grisMedio } },
  { node: { color: "gris", imageThumbSmall: gris, imageThumbMedium: gris } },
  { node: { color: "Gris Oscuro", imageThumbSmall: grisOscuro, imageThumbMedium: grisOscuro } },
  { node: { color: "negro", imageThumbSmall: negro, imageThumbMedium: negro } }
]
