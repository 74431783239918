import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getCurrentLanguageAndInstanceCode } from "services/instances"
import { CLEANING_GUIDE_MARINE_CHIL_URL, CLEANING_GUIDE_MARINE_GENERAL_URL } from "../constants"
import {
  AVAILABLE_LANGS,
  NAVIGATE_ACCORDING_LANG
} from "../components/Header/components/Menu/constants"
import { useIntl } from "react-intl"

const useChangeLanguage = (isPresentationPage) => {
  const intl = useIntl()
  const [selectedLang, setSelectedLang] = useState("")
  const navigate = useNavigate()
  const [lang] = getCurrentLanguageAndInstanceCode()

  const handleLanguageChange = (event, isPresentationPage) => {
    const selectedValue = event.value
    const isGeneralPage = window.location.pathname.includes(CLEANING_GUIDE_MARINE_GENERAL_URL)
    setSelectedLang(selectedValue)
    if (isPresentationPage) {
      navigate(`/${NAVIGATE_ACCORDING_LANG[selectedValue.toLowerCase()]}`, { replace: true })
    } else {
      if (isGeneralPage) {
        navigate(
          `/${
            NAVIGATE_ACCORDING_LANG[selectedValue.toLowerCase()]
          }/${CLEANING_GUIDE_MARINE_GENERAL_URL}`,
          { replace: true }
        )
      } else {
        navigate(
          `/${
            NAVIGATE_ACCORDING_LANG[selectedValue.toLowerCase()]
          }/${CLEANING_GUIDE_MARINE_CHIL_URL}`,
          { replace: true }
        )
      }
    }

    window.location.reload()
  }

  useEffect(() => {
    if (lang === AVAILABLE_LANGS[0].headerText.toLowerCase()) {
      setSelectedLang({
        label: isPresentationPage
          ? intl.formatMessage(AVAILABLE_LANGS[0].presentationPageText)
          : AVAILABLE_LANGS[0].headerText,
        value: ""
      })
    } else {
      setSelectedLang({
        label: isPresentationPage
          ? intl.formatMessage(AVAILABLE_LANGS[1].presentationPageText)
          : AVAILABLE_LANGS[1].headerText,
        value: ""
      })
    }
  }, [lang])

  return { selectedLang, handleLanguageChange }
}

export default useChangeLanguage
