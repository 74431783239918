const usePatternData = ({ state, design, handleSamplesData }) => {
  const SAMPLES = handleSamplesData(design)
  const { title, sampleDetails: sampleDetailsByState } = state?.productDetails || {}
  const currentSample = {
    pattern: title || design,
    sampleDetails: sampleDetailsByState || SAMPLES[0]
  }

  const { pattern, sampleDetails } = currentSample

  return { pattern, sampleDetails, SAMPLES }
}

export default usePatternData
