import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { FormattedMessage, useIntl } from "react-intl"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useMutation } from "@apollo/client"
import SelectCountry from "components/select/SelectCountry"
import PrivacyPolicyCheckbox from "components/PrivacyPolicyCheckbox"
import ErrorMessage from "components/ErrorMessage"
import { BUTTON_MESSAGES } from "utils/buttons"
import { DEFAULT_VALUES, THEME, VALIDATION_SCHEMA } from "./constants"
import { getCurrentInstanceId, getCurrentLanguageAndInstanceCode } from "services/instances"
import SelectRegion from "components/select/SelectRegion"
import SelectCompanyType from "components/select/SelectCompanyType"
import createContactMutation from "./mutation"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import { useNavigate } from "react-router-dom"
import { MSGS } from "components/select/SelectCity/constants"

const ContactForm = ({
  id,
  theme = "default",
  styleContainer = "container",
  styleFormContainer,
  title,
  countryOptions,
  patternSlug,
  landingSource,
  isBasic = false
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [lang, instance] = getCurrentLanguageAndInstanceCode()
  const [createContact] = useMutation(createContactMutation)
  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: DEFAULT_VALUES(isBasic),
    resolver: yupResolver(VALIDATION_SCHEMA(intl, isBasic))
  })

  const regionValue = watch("region")

  const handlePayload = (values) => {
    const source = `landing ${landingSource}`

    if (!isBasic) {
      const { privacyPolicy, country, region, city, ...rest } = values

      return {
        instanceId: getCurrentInstanceId(),
        regionId: region.value,
        cityLabel: city,
        patternSlug,
        source,
        ...rest
      }
    } else {
      const { privacyPolicy, country, region, city, sector, ...rest } = values

      return {
        instanceId: getCurrentInstanceId(),
        regionId: region.value,
        cityLabel: city,
        patternSlug,
        source,
        ...rest
      }
    }
  }

  const handleOnCreateContact = (values) =>
    createContact({
      variables: {
        input: handlePayload(values)
      }
    })
      .then(() => {
        navigate(
          `/${lang}-${instance}/${landingSource}/${intl.formatMessage({
            id: "ContactForm.thankYou"
          })}`
        )
      })
      .catch((e) => {
        console.log("ERROR crete contact on CONTACTFORM: ", e)
      })

  return (
    <div id={id} className={styleContainer}>
      <TitleUnderlined hTag={H2_TAG} {...THEME[theme].title}>
        {title}
      </TitleUnderlined>
      <div className="row justify-content-md-center">
        <div className="col-12">
          <form
            className={classNames({
              form: true,
              [styleFormContainer]: styleFormContainer !== undefined
            })}
            onSubmit={handleSubmit(handleOnCreateContact)}
          >
            <div className="row">
              <div
                className={classNames({
                  "col-12 form-group": true,
                  "col-md-6": !isBasic
                })}
              >
                <label htmlFor="firstName" className={THEME[theme].input}>
                  <FormattedMessage
                    id="Form.field.Name"
                    description="Form field name"
                    defaultMessage="Name"
                  />
                </label>
                <input className="form-control" id="firstName" {...register("firstName")} />
                <ErrorMessage {...THEME[theme].error}>{errors.firstName?.message}</ErrorMessage>
              </div>
              {!isBasic && (
                <div className="col-12 col-md-6 form-group">
                  <label htmlFor="lastName" className={THEME[theme].input}>
                    <FormattedMessage
                      id="Form.field.Lastname"
                      description="Form field Last name"
                      defaultMessage="Last name"
                    />
                  </label>
                  <input className="form-control" id="lastName" {...register("lastName")} />
                  <ErrorMessage {...THEME[theme].error}>{errors.lastName?.message}</ErrorMessage>
                </div>
              )}
            </div>
            {!isBasic && (
              <div className="row">
                <div className="col-md-6 form-group">
                  <label className={THEME[theme].input} htmlFor="companyName">
                    <FormattedMessage
                      id="Form.field.Company"
                      description="Form field company"
                      defaultMessage="Company"
                    />
                  </label>
                  <input className="form-control" id="companyName" {...register("companyName")} />
                  <ErrorMessage {...THEME[theme].error}>{errors.companyName?.message}</ErrorMessage>
                </div>
                <div className="col-md-6 form-group">
                  <SelectCompanyType
                    labelClassName={THEME[theme].input}
                    {...register("companyType")}
                  />
                  <ErrorMessage {...THEME[theme].error}>{errors.companyType?.message}</ErrorMessage>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-6 form-group">
                <label htmlFor="email" className={THEME[theme].input}>
                  <FormattedMessage
                    id="Form.field.Email"
                    description="Form field email"
                    defaultMessage="Email"
                  />
                </label>
                <input className="form-control" id="email" {...register("email")} />
                <ErrorMessage {...THEME[theme].error}>{errors.email?.message}</ErrorMessage>
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="phone" className={THEME[theme].input}>
                  <FormattedMessage
                    id="Form.field.Phone"
                    description="Form field phone"
                    defaultMessage="Phone"
                  />
                </label>
                <input className="form-control" id="phone" {...register("phone")} />
                <ErrorMessage {...THEME[theme].error}>{errors.phone?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <SelectCountry
                  labelClassName={THEME[theme].input}
                  name="country"
                  control={control}
                  options={countryOptions}
                  isSearchable
                />
                <ErrorMessage {...THEME[theme].error}>{errors.country?.message}</ErrorMessage>
              </div>
              <div className="col-md-6 form-group">
                <SelectRegion
                  labelClassName={THEME[theme].input}
                  name="region"
                  control={control}
                  isSearchable={false}
                />
                <ErrorMessage {...THEME[theme].error}>{errors.region?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div
                className={classNames({
                  "col-12 form-group": true,
                  "col-md-6": !isBasic
                })}
              >
                <label htmlFor="city" className={THEME[theme].input}>
                  <FormattedMessage
                    id="Form.field.City"
                    description="Form field city"
                    defaultMessage="City"
                  />
                </label>
                <input
                  id="city"
                  className="form-control rounded-0 text-secondary"
                  placeholder={
                    !regionValue
                      ? intl.formatMessage(MSGS.withoutRegion)
                      : intl.formatMessage(MSGS.city)
                  }
                  disabled={!regionValue}
                  {...register("city")}
                />
                <ErrorMessage {...THEME[theme].error}>{errors.city?.message}</ErrorMessage>
              </div>
              {!isBasic && (
                <div className="col-md-6 form-group">
                  <label className={THEME[theme].input} htmlFor="zipCode">
                    <FormattedMessage
                      id="Form.field.ZipCode"
                      description="Form field company"
                      defaultMessage="Zip Code"
                    />
                  </label>
                  <input className="form-control" id="zipCode" {...register("zipCode")} />
                  <ErrorMessage {...THEME[theme].error}>{errors.zipCode?.message}</ErrorMessage>
                </div>
              )}
            </div>
            {!isBasic && (
              <div className="row">
                <div className="col-12 form-group">
                  <label className={THEME[theme].input} htmlFor="address">
                    <FormattedMessage
                      id="Form.field.Address"
                      description="Form field Address"
                      defaultMessage="Address"
                    />
                  </label>
                  <input className="form-control" id="address" {...register("address")} />
                  <ErrorMessage {...THEME[theme].error}>{errors.address?.message}</ErrorMessage>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 form-group text-center">
                <Controller
                  name="privacyPolicy"
                  control={control}
                  render={({ field }) => (
                    <PrivacyPolicyCheckbox {...THEME[theme].privacyLabel} {...field} />
                  )}
                />
                <ErrorMessage {...THEME[theme].error}>{errors.privacyPolicy?.message}</ErrorMessage>
                <button className={`btn ${THEME[theme].button}`} type="submit">
                  {intl.formatMessage(
                    !isSubmitting ? BUTTON_MESSAGES.send : BUTTON_MESSAGES.sending
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

ContactForm.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.oneOf(["default", "light"]),
  styleContainer: PropTypes.string,
  styleFormContainer: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  patternSlug: PropTypes.string,
  landingSource: PropTypes.string,
  isBasic: PropTypes.bool
}

export default ContactForm
