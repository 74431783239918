import { FormattedMessage } from "react-intl"
import banner from "./img/home-1920.jpg"

export const BANNER_INFO = {
  id: "home-slide",
  imageThumb: banner,
  title: "Rivulet",
  description: "Inspired by Nature",
  buttonText: (
    <FormattedMessage id="Riuvlet.Banner.buttonText" defaultMessage="Request Sample Card" />
  )
}
