import { FormattedMessage } from "react-intl"
import { DO_AND_DO_NOT_ITEM_CLEANING_CARE } from "./doAndDoNots"
import { CLEAING_PROCEDURE_ITEM_CLEANING_CARE } from "./cleaningProcedure"
import { KEEP_IN_MIND_ITEM_CLEANING_CARE } from "./keepInMind"
import {
  BIMI_TOPS_COVERS,
  BIMI_TOPS_COVERS_INFO,
  BIMI_TOPS_COVERS_KEY,
  WOVEN_FLOORING,
  WOVEN_FLOORING_INFO,
  WOVEN_FLOORING_KEY
} from "../../General/constants"
import boats from "modules/cleaning-guide-marine/components/Layout/imgs/1-boats.jpg"
import banner from "modules/cleaning-guide-marine/components/Banner/img/banner.jpg"

const CLEANING_CARE_KEY = "Chil Vinyl"

export const CLEANING_CARE = <FormattedMessage id="CleaningMarine.Chil.cleaningCare" defaultMessage="Chil Vinyl" />

export const NAV_ITEMS = [
  {
    headerTitle: CLEANING_CARE,
    key: CLEANING_CARE_KEY
  },
  {
    headerTitle: BIMI_TOPS_COVERS,
    key: BIMI_TOPS_COVERS_KEY
  },
  {
    headerTitle: WOVEN_FLOORING,
    key: WOVEN_FLOORING_KEY
  }
]

export const BANNER_INFO = {
  id: "home-slide",
  imageThumb: banner,
  title: (
    <FormattedMessage
      id="CleaningMarine.Chill.Banner.title"
      defaultMessage="Chil™ Cleaning Guide"
    />
  ),
  description: (
    <FormattedMessage
      id="CleaningMarine.General.Banner.description"
      defaultMessage="By following the recommendations in this guide, you’ll{br}keep all your coated fabrics looking good, preventing
      premature wear and tear."
      values={{
        br: <br />
      }}
    />
  )
}

const BOAT_SEATS_INFO = {
  id: CLEANING_CARE_KEY,
  titleDesktop: CLEANING_CARE,
  titleMobile: CLEANING_CARE,
  img: boats,
  doAndDoNots: DO_AND_DO_NOT_ITEM_CLEANING_CARE,
  cleaningProcedures: CLEAING_PROCEDURE_ITEM_CLEANING_CARE,
  keepInMind: KEEP_IN_MIND_ITEM_CLEANING_CARE
}

export const GENERAL_INFO_SECTIONS = {
  [CLEANING_CARE_KEY]: BOAT_SEATS_INFO,
  [BIMI_TOPS_COVERS_KEY]: BIMI_TOPS_COVERS_INFO,
  [WOVEN_FLOORING_KEY]: WOVEN_FLOORING_INFO
}
