import { useQuery } from "@apollo/client"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import { getCurrentInstanceId } from "services/instances"
import companyTypesQuery from "./query"
import { Controller } from "react-hook-form"
import Select from "react-select"
import styles from "../styles"
import { MSGS } from "./contants"

const SelectCompanyType = ({ name, control }) => {
  const intl = useIntl()
  const { loading, data } = useQuery(companyTypesQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()]
    }
  })
  const ph = loading ? intl.formatMessage(MSGS.loading) : intl.formatMessage(MSGS.placeholder)
  const options = data?.companyTypes.edges.map((edge) => ({
    label: edge.node.name,
    value: edge.node.id
  }))

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: handleOnChangeForm, ...field } }) => (
        <Select
          styles={styles}
          {...field}
          options={options}
          placeholder={ph}
          onChange={handleOnChangeForm}
        />
      )}
    />
  )
}

SelectCompanyType.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired
}

export default SelectCompanyType
