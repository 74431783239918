import PropTypes from "prop-types"
import "./styles/product-information-detail.scss"
import classNames from "classnames"

const ProductInformationDetail = ({
  disclaimerContainerStyle = "info",
  details,
  isSustainableElements = false,
  isPerformanceInfo = false
}) => {
  return (
    <div
      className={classNames({
        "product--information-detail--main-container": !isSustainableElements
      })}
    >
      <table className="col-12 color-letter-rv">
        {details.info.map(({ id, description, value }) => {
          return <tr key={`key_${id}`} className="info">
            {description && (
              <td className={classNames({
                "col-6 col-lg-2 text-capitalize product--information-detail-description": true,
                "product--information-uv-stability": description.props.defaultMessage === "UV Stability",
                "product--information-spacing": isPerformanceInfo
              })}>
                {description}
              </td>
            )}
            <td
              className={classNames({
                "product--information-detail-value": true,
                "col-6": description && value,
                "col-12": !description,
                "product--information-spacing": isPerformanceInfo
              })}
            >
              {value}
            </td>
          </tr>
        })}
      </table>
      {details?.disclaimer && details.disclaimer.length > 0 && (
        <div className={disclaimerContainerStyle}>
          {details.disclaimer.map(({ id, value }) => (
            <p key={`key_${id}`} className="disclaimer">
              {value}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

ProductInformationDetail.propTypes = {
  details: PropTypes.object.isRequired,
  disclaimerContainerStyle: PropTypes.string,
  isPerformanceInfo: PropTypes.bool,
  isSustainableElements: PropTypes.bool
}

export default ProductInformationDetail
