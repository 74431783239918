import PropTypes from "prop-types"
import Slider from "react-slick"
import SampleCard from "../../../SampleCard"
import "./styles/sample-list-mobile.scss"

const SLIDER_OPTIONS = {
  arrows: false,
  dots: true,
  infinite: false,
  lazyLoad: true,
  adaptiveHeight: true,
  rows: 2,
  slidesPerRow: 1,
  slidesToShow: 2,
  slidesToScroll: 2
}
const SamplesListMobile = ({ samples, handleClick }) => {
  return <Slider {...SLIDER_OPTIONS} className="my-4 slider-cards--masiala">
    {samples.map((item) => (
      item.id
        ? <SampleCard sample={item} handleClick={handleClick} key={`item_${item.description}`} />
        : <></>
    ))}
  </Slider>
}

SamplesListMobile.propTypes = {
  samples: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default SamplesListMobile
