/* eslint-disable quotes */
import { FormattedMessage } from "react-intl"
import banner0 from "./img/banner-0.jpg"
import banner0Mobile from "./img/banner-0-mobile.jpg"
import banner1 from "./img/banner-1.jpg"
import banner1Mobile from "./img/banner-1-mobile.jpg"
import banner2 from "./img/banner-2.jpg"
import banner2Mobile from "./img/banner-2-mobile.jpg"

import bioAttributed from "../SamplesDetails/components/ProductSpecs/components/SustainableElements/imgs/bio.svg"
import hiloftRpet from "../SamplesDetails/components/ProductSpecs/components/SustainableElements/imgs/hi-loft-rpet.svg"

const COMMON_BANER_INFO = {
  id: "home-slide",
  title: "Masiala",
  description: (
    <FormattedMessage
      id="Masiala.Banner.description"
      defaultMessage="Sustainablility: Transform your space with Metallic Alchemy"
      values={{
        strong: (...chunks) => <strong>{chunks}</strong>,
        sup: (...chunks) => <sup>{chunks}</sup>
      }}
    />
  ),
  buttonText: (
    <FormattedMessage id="Masiala.Request.catalogue" defaultMessage="REQUEST CATALOGUE HERE!" />
  )
}

export const BANNERS = (isMobile) => [
  {
    key: "masiala_banner_0",
    imageThumb: !isMobile ? banner0 : banner0Mobile,
    ...COMMON_BANER_INFO
  },
  {
    key: "masiala_banner_1",
    imageThumb: !isMobile ? banner1 : banner1Mobile,
    id: "home-slide",
    imgTitle: bioAttributed,
    imgTitleClassName: "image-bio-attributed-banner",
    title: "Bio-attribution for the planet",
    description: (
      <>
        <strong className="sustainable-over-relevant-wrd text-white">
          Over 50%
        </strong> of the polymer {isMobile && <br />} is crafted using from bio-attributed {!isMobile && <br />} components sourced from
        the forestry industry.
      </>
    ),
    buttonText: (
      <FormattedMessage id="Masiala.Request.catalogue" defaultMessage="REQUEST CATALOGUE HERE!" />
    )
  },
  {
    key: "masiala_banner_2",
    imgTitle: hiloftRpet,
    imgTitleClassName: "image-hiloft-rpet-banner",
    imageThumb: !isMobile ? banner2 : banner2Mobile,
    title: (
      <>
        Reducing landfill waste using <br /> 100% recycled yarn
      </>
    ),
    description: (
      <>
        New backing made from PET bottles.
      </>
    ),
    buttonText: (
      <FormattedMessage id="Masiala.Request.catalogue" defaultMessage="REQUEST CATALOGUE HERE!" />
    )
  }
]
