import { connect } from "react-redux"
import LocationsListHeader from "components/StoreFinder/components/LocationsList/components/LocationsListHeader"

const mapStateToProps = (state) => {
  return {
    locationReducer: state.locationReducer
  }
}

const mapDispatchToProps = () => {
  return {}
}

const LocationsListHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsListHeader)

export default LocationsListHeaderContainer
