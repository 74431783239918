import { defineMessages } from "react-intl"

export const STAIN_MSGS = defineMessages({
  typeOfStain: {
    id: "Claims.Stain.typeOfStain",
    description: "field type of stain in Stain claim reason",
    defaultMessage: "Please indicate type of stain"
  },
  knowTheInstructions: {
    id: "Claims.Stain.knowTheInstructions",
    description: "field know the instructions in Stain claim reason",
    defaultMessage:
      "Are cleaning instructions suggested by the manufacturer followed to clean up the material?"
  },
  whichProductUse: {
    id: "Claims.Stain.whichProductUse",
    description: "field which product use in Stain claim reason",
    defaultMessage: "If not, which cleaning agents are used to clean up the material?"
  },
  protectProductCushions: {
    id: "Claims.ColorFading.protectProductCushions",
    description: "field protect product cushions in color fading claim reason",
    defaultMessage: "Please indicate how you protect the product / cushions"
  }
})
