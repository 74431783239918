import { FormattedMessage } from "react-intl"

const CLEANING_CARE_DO = [
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.Do.softBrush"
    id="CleaningMarine.General.BoatSeats.Do.softBrush"
    defaultMessage="Soft bristle brush"
  />,
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.Do.alwaysRinse"
    id="CleaningMarine.General.BoatSeats.Do.alwaysRinse"
    defaultMessage="Always rinse w/water and pat dry"
  />,
  <FormattedMessage
    key="CleaningMarine.Chill.BoatSeats.Do.approvedCleaning"
    id="CleaningMarine.Chill.BoatSeats.Do.approvedCleaning"
    defaultMessage="Use only Approved Cleaners:{br} <space>Mild Non-bleach soap & water{br}Bling Sauce Mild Soap Sauce<sup>®</sup>{br}Babes Seat Soap<sup>®</sup>{br}Formula 409<sup>®</sup> (diluted 1:1){br}Star brite Cleaner<sup>®</sup></space>"
    values={{
      br: <br />,
      space: (...chunks) => <p className="mt-2">{chunks}</p>,
      sup: (...chunks) => <sup>{chunks}</sup>
    }}
  />
]

const CLEANING_CARE_DO_NOT = [
  <FormattedMessage
    key="eraserSpongesCleaning"
    id="CleaningMarine.General.BoatSeats.DoNot.eraserSponges"
    defaultMessage="NO Eraser sponges"
  />,
  <FormattedMessage
    key="powerWashersCleaning"
    id="CleaningMarine.General.BoatSeats.DoNot.powerWashers"
    defaultMessage="NO Power washers"
  />,
  <FormattedMessage
    key="abrasivesCleaning"
    id="CleaningMarine.General.BoatSeats.DoNot.abrasives"
    defaultMessage="NO Abrasives"
  />,
  <FormattedMessage
    key="siliconesCleaning"
    id="CleaningMarine.General.BoatSeats.DoNot.silicones"
    defaultMessage="NO Silicones"
  />,
  <FormattedMessage
    key="fullStrengthBleachCleaning"
    id="CleaningMarine.General.BoatSeats.DoNot.fullStrengthBleach"
    defaultMessage="NO Full Strength Bleach"
  />,
  <FormattedMessage
    key="hardBrushesCleaning"
    id="CleaningMarine.General.BoatSeats.DoNot.hardBrushes"
    defaultMessage="NO Hard brushes"
  />,
  <FormattedMessage
    key="noConditionersCleaning"
    id="CleaningMarine.General.BoatSeats.DoNot.noConditionersCleaning"
    defaultMessage="NO Conditioners or Protectants"
  />
]

export const DO_AND_DO_NOT_ITEM_CLEANING_CARE = {
  do: CLEANING_CARE_DO,
  doNot: CLEANING_CARE_DO_NOT,
  disclaimer: (
    <FormattedMessage
      key="CleaningMarine.Chill.BoatSeats.Do.disclaimer"
      id="CleaningMarine.Chill.BoatSeats.Do.disclaimer"
      defaultMessage="Formula 409<sup>®</sup> is a registered trademark of the Clorox Company<sup>®</sup>.{br}Ivory<sup>®</sup> and Dawn<sup>®</sup> dish soap is a registered trademark of Proctor and Gamble<sup>®</sup>.{br}Bling Sauce<sup>®</sup> Vinyl Sauce is a registered trademark of Bling Sauce<sup>®</sup>, Inc.{br}Babes Seat Soap<sup>®</sup> is a registered trademark of BABES Boat Care Products<sup>®</sup>, Inc.{br}Fantastik<sup>®</sup> & Formula 409<sup>®</sup> are safe to use if cleaner is diluted (1:1), then thoroughly rinsed and used following manufacturers' instructions.{br}Star brite<sup>®</sup> Vinyl cleaner is a registered trademark of Star brite<sup>®</sup> Inc."
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>,
        br: <br />
      }}
    />
  )
}
