import classNames from "classnames"
import { useStateMachine } from "little-state-machine"
import { NAV_3 } from "modules/rivulet/constants"
import PropTypes from "prop-types"
import { Link } from "react-scroll"
import { updateAction } from "utils/littleStateMachine"

const ColorSample = ({ title, description, inspirationImage, samples }) => {
  const { actions } = useStateMachine({ updateAction })

  const handleClickInSample = (description, imageSample, imgDetail) => {
    actions.updateAction({
      sampleDetails: {
        img: imageSample,
        imgDetail,
        description
      }
    })
  }

  return (
    <article>
      <h3 className="subtitle-sample--color-insp-rv">{title}</h3>
      <p className="mb-4 text-start fw-light">{description}</p>
      <div className="container-color-samples">
        <div className="slider__image-large-container">
          <img src={inspirationImage} alt="inspiration-image" />
        </div>
        <div className="slider__container-samples-images">
          {samples.map(({ description, imageSample, imgDetail }, i) => (
            <div key={`item_${description}`}>
              <Link
                to={NAV_3}
                onClick={() => handleClickInSample(description, imageSample, imgDetail)}
              >
                <div
                  className={classNames({
                    "slider__sample-image grow-on-hover": true,
                    "slider__sample-image--even": i % 2
                  })}
                  style={{
                    backgroundImage: `url(${imageSample})`,
                    backgroundSize: "100% 100%"
                  }}
                >
                  <p className="label-slider__sample-image">{description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </article>
  )
}

ColorSample.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  inspirationImage: PropTypes.string.isRequired,
  samples: PropTypes.array.isRequired
}

export default ColorSample
