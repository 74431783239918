import gql from "graphql-tag"

const countriesQuery = gql`
  query CountriesQuery($first: Int, $after: String) {
    countries(first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export default countriesQuery
