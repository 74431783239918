import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, Col, Row } from "reactstrap"
import { useMutation } from "@apollo/client"
import ErrorMessage from "components/ErrorMessage"
import ModalContext from "../../context"
import { getCurrentInstanceId, getCurrentLanguageAndInstanceCode } from "services/instances"
import { REQUEST_SAMPLES_INFO } from "modules/saxony/components/RequestsSection/utils"
import { THEME } from "components/ContactForm/constants"
import { GET_VALIDATION_SCHEMA } from "./constants/validationSchema"
import SelectCountry from "./components/SelectCountry"
import SelectCompanyType from "./components/SelectCompanyType"
import "../../styles/samplingrequest.scss"
import { COUNTRIES_FOR_USA } from "consts/countryCodes"
import { MSGS } from "components/select/SelectCity/constants"
import SelectRegion from "./components/SelectRegion"
import { REGIONS_BY_COUNTRY } from "./components/SelectRegion/constants"
import { createAnonimalOrder } from "./mutation"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "app/store"
import ColorsSelect from "../ColorsSelect"
import ModalPrivacyPolicy from "./components/ModalPrivacyPolicy"
import useToggle from "hooks/useToggle"

const RequestForm = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const context = useContext(ModalContext)
  const [toggle, toggable] = useToggle()
  const [lang, instance] = getCurrentLanguageAndInstanceCode()
  const [createOrder, { error: errorCreateOrder }] = useMutation(createAnonimalOrder)
  const { state, actions } = useStateMachine({ updateAction })
  const [checked, setChecked] = useState(false)

  const initialValues = {
    colors: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companyType: "",
    country: "",
    region: "",
    city: "",
    address: "",
    zipCode: "",
    email: "",
    phone: "",
    type: context.type,
    privacyPolicy: false
  }

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(GET_VALIDATION_SCHEMA(intl, context))
  })

  const countryValue = useWatch({ control, name: "country" })
  const regionValue = useWatch({ control, name: "region" })

  const handleProcessColors = (colors) => {
    const processedColors = !colors.length ? [] : colors.map(({ value }) => value)
    const processedColorsOrder = processedColors.map((item) => {
      const cleanedItem = item.replace(/\n/g, " ")
      const splitItem = cleanedItem.split(" | ")
      if (splitItem.length === 2) {
        return { name: `${splitItem[0]}, SAXONY`, stock: splitItem[1], quantity: 1 }
      } else {
        return { name: item, stock: "", quantity: 1 }
      }
    })

    return processedColorsOrder
  }

  const handlePostSubmit = () => {
    actions.updateAction({
      ...state,
      sampleCount: []
    })
    setChecked(false)
    reset()
  }

  const handlePayload = (values) => {
    const {
      firstName,
      lastName,
      email,
      privacyPolicy,
      type,
      companyName,
      companyType: { value: companyTypeId },
      country: { value: countryId },
      region: { value: regionId },
      city,
      colors,
      zipCode,
      ...rest
    } = values

    return {
      instance: getCurrentInstanceId(),
      source: "landing saxony",
      name: firstName,
      surname: lastName,
      email,
      company: companyName,
      companyType: companyTypeId,
      country: countryId,
      region: regionId,
      city: city.toLowerCase(),
      acceptTerms: privacyPolicy,
      postalCode: zipCode,
      anonimalSamples: handleProcessColors(colors),
      ...rest
    }
  }

  const onSubmit = async (values) => {
    const anonimalOrderPayload = handlePayload(values)

    try {
      const result = await createOrder({ variables: { input: anonimalOrderPayload } })

      if (result?.data?.storeAnonimalOrder?.success) {
        navigate(`/${lang}-${instance}/saxony/thank-you`)
        handlePostSubmit()
      }
    } catch (error) {
      console.log("error crete contact on contactform: ", error)
    }
  }

  return (
    <form
      id={context.id}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="pb-5 mt-2 px-lg-5"
    >
      <Row>
        <Col md={12}>
          <p className="text-center px-5 mb-4">{context.subtitle}</p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="form-group">
            <ColorsSelect id="colors" name="colors" control={control} setValue={setValue} />
            <ErrorMessage weight="normal">{errors?.colors?.message}</ErrorMessage>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelFirstName",
                defaultMessage: "First Name"
              })}
              id="firstName"
              name="firstName"
              type="text"
              className="form-control rounded-0 mt-2 text-secondary"
              {...register("firstName")}
            />
            <ErrorMessage>{errors?.firstName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "Form.field.Lastname",
                defaultMessage: "Lastname"
              })}
              id="lastName"
              name="lastName"
              type="text"
              className="form-control rounded-0 mt-2 text-secondary"
              {...register("lastName")}
            />
            <ErrorMessage>{errors?.lastName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelCompany",
                defaultMessage: "Company"
              })}
              id="companyName"
              name="companyName"
              type="text"
              className="form-control rounded-0 mt-2 text-secondary"
              {...register("companyName")}
            />
            <ErrorMessage>{errors?.companyName?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group mt-2">
            <SelectCompanyType name="companyType" control={control} />
            <ErrorMessage>{errors?.companyType?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <SelectCountry
              labelClassName={THEME.default.input}
              name="country"
              control={control}
              options={COUNTRIES_FOR_USA}
              isSearchable
            />
            <ErrorMessage>{errors?.country?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <SelectRegion
              labelClassName={THEME.default.input}
              name="region"
              control={control}
              isSearchable={false}
              defaultOptions={REGIONS_BY_COUNTRY[countryValue?.value]}
            />
            <ErrorMessage>{errors?.region?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              className="form-control rounded-0 mt-2 text-secondary"
              placeholder={
                !regionValue
                  ? intl.formatMessage(MSGS.withoutRegion)
                  : intl.formatMessage(MSGS.city)
              }
              id="city"
              name="city"
              type="text"
              disabled={!regionValue}
              {...register("city")}
            />
            <ErrorMessage>{errors?.city?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelPostal",
                defaultMessage: "Zip code"
              })}
              id="zipCode"
              name="zipCode"
              type="text"
              className="form-control rounded-0 mt-2 text-secondary"
              {...register("zipCode")}
            />
            <ErrorMessage>{errors?.zipCode?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={12}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelAddress",
                defaultMessage: "Address"
              })}
              id="address"
              name="address"
              type="text"
              className="form-control rounded-0 mt-2 text-secondary"
              {...register("address")}
            />
            <ErrorMessage>{errors?.address?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelEmail",
                defaultMessage: "Email"
              })}
              id="email"
              name="email"
              type="text"
              className="form-control rounded-0 mt-2 text-secondary"
              {...register("email")}
            />
            <ErrorMessage>{errors?.email?.message}</ErrorMessage>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input
              placeholder={intl.formatMessage({
                id: "SamplingRequest.labelPhone",
                defaultMessage: "Phone"
              })}
              id="phone"
              name="phone"
              type="text"
              className="form-control rounded-0 mt-2 text-secondary"
              {...register("phone")}
            />
            <ErrorMessage>{errors?.phone?.message}</ErrorMessage>
          </div>
        </Col>
      </Row>
      <div className="form-group form-check my-4 footer-form-container text-center">
        <input
          type="checkbox"
          name="privacyPolicy"
          id="privacyPolicy"
          className="form-check-input rounded-0"
          checked={checked}
          onClick={toggable}
          {...register("privacyPolicy")}
        />
        <label htmlFor="privacyPolicy" className="form-check-label fw-light text-secondary">
          <FormattedMessage
            id="SamplingRequest.privacyPolicy"
            defaultMessage="I've read and agree the <strong>Privacy Policy</strong>"
            values={{
              strong: (...chunks) => <strong>{chunks}</strong>
            }}
          />
        </label>
        <ErrorMessage weight="normal">{errors?.privacyPolicy?.message}</ErrorMessage>
        <ModalPrivacyPolicy
          isOpen={toggle}
          toggle={toggable}
          acceptPrivacyPolicy={() => {
            setChecked(true)
            setValue("privacyPolicy", true)
          }}
        />
        <div className="text-center mt-3 py-lg-0">
          <Button
            className="btn button-form-saxony py-2 px-5"
            color="gray"
            type="submit"
            disabled={isSubmitting}
          >
            {!isSubmitting ? (
              <p className="m-0 p-0 text-white">Order now</p>
            ) : (
              <p className="m-0 p-0 text-white">
                <FormattedMessage
                  id="SamplingRequest.btnLabelLoading"
                  defaultMessage="Sending..."
                />
              </p>
            )}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default RequestForm
