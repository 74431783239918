import Header from "components/Header"
import Banner from "components/Banner"
import { NAV_ITEMS, GLOBAL_SITE_TAG } from "./constants"
import { SLIDE } from "./components/Banner/constants"
import ColorPalette from "modules/dali/components/ColorPalette"
import Applications from "modules/dali/components/Applications"
import ProductDetails from "modules/dali/components/ProductDetails"
import Benefits from "modules/dali/components/Benefits"
import Video from "modules/dali/components/Video"
import WhereToBuy from "modules/petfriendly/components/WhereToBuy"
import Analytics from "components/Analytics"

const Dali = () => (
  <>
    <Analytics gtag={GLOBAL_SITE_TAG} />
    <Header items={NAV_ITEMS} isTransparent />
    <div className="content">
      <Banner slides={SLIDE} />
      <Video />
      <ColorPalette id={NAV_ITEMS[0]} />
      <Applications id={NAV_ITEMS[1]} />
      <ProductDetails id={NAV_ITEMS[2]} />
      <Benefits />
      <WhereToBuy id={NAV_ITEMS[3]} />
    </div>
  </>
)

export default Dali
