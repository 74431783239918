export const uploadFilesToGenerateUrl = async (files, uploadFilesOfMutation) => {
  let urlFiles = ""

  const { data } = await uploadFilesOfMutation({
    variables: {
      files: files
    }
  })

  if (data) {
    urlFiles = data.uploadFile.url

    return urlFiles
  }
}
