import ProductInformationDetail from "./components/ProductInformationDetail"
import SpecAccordion from "./components/SpecAccordion"
import {
  PRODUCT_SPECS,
  productSpecDetailsInformationToShow
} from "./constants/specs"

const ProductSpecs = () => {
  return (
    <div>
      <SpecAccordion
        title={PRODUCT_SPECS.COMPOSITION}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow().COMPOSITION}
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.CHARACTERISTICS}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow().CHARACTERISTICS}
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.PERFORMANCE}
        contentToShow={
          <ProductInformationDetail
            details={
              productSpecDetailsInformationToShow()
                .PERFORMANCE
            }
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.FLAME_RETARDANCY}
        contentToShow={
          <ProductInformationDetail
            details={
              productSpecDetailsInformationToShow().FLAME_RETARDANCY
            }
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.APPLICATIONS}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow().APPLICATIONS}
          />
        }
      />
      <SpecAccordion
        title={PRODUCT_SPECS.SUSTAINALE_ATTRIBUTES}
        contentToShow={
          <ProductInformationDetail
            details={productSpecDetailsInformationToShow().SUSTAINALE_ATTRIBUTES}
          />
        }
      />
    </div>
  )
}

export default ProductSpecs
