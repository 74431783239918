/* eslint-disable */

import { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Controller } from "react-hook-form"
import Select, { components } from "react-select"
import { FormattedMessage } from "react-intl"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "app/store"
import CollectionContext from "modules/quickship/paramSlugPage/context/CollectionContext"
import "./styles/colors-select.scss"

const CustomMultiValue = (props) => (
  // eslint-disable-next-line react/prop-types
  <components.MultiValue {...props} key={props.data.id}>
    {/* eslint-disable-next-line react/prop-types */}
    {props.children}
  </components.MultiValue>
)

CustomMultiValue.propTypes = {
  props: PropTypes.shape({
    data: PropTypes.any,
    children: PropTypes.any
  })
}

const formatOptionLabel = ({ label, data: { id: key } }) => (
  <div>
    <span>{label}</span>
    {/* <small>({key})</small> */}
  </div>
)

const ColorsSelect = ({ name, id, control, setValue }) => {
  const { state, actions } = useStateMachine({ updateAction })
  const colorsValues = control?._formValues?.colours
  const isDisabled = colorsValues && colorsValues.length >= 10
  const { itemsInFormRequest, setItemsInFormRequest } = useContext(CollectionContext)
  const [baseArray, setBaseArray] = useState(generateOutput())
  let tempArray = []

  function generateOutput() {
    const result = []

    itemsInFormRequest.forEach((item) => {
      for (let i = 0; i < item.data.quantity; i++) {
        const id = _.random(1, 4, true)
        const newObj = {
          label: item.label,
          value: id,
          parsedValue: item.label,
          data: {
            id,
            pattern: item.data.name.split(",")[0],
            color: item.data.name.split(",")[1],
            reference: item.data.stock
          }
        }
        result.push(newObj)
      }
    })

    return result
  }

  const handleChange = (value, context) => {
    let newValues = [...baseArray]

    if (context?.action === "remove-value") {
      newValues = baseArray.filter(({ data }) => {
        return data.id !== context.removedValue.data.id
      })
    }

    tempArray = newValues.map(({ data }) => ({
      id: data.id,
      pattern: data.pattern,
      color: data.color,
      img: data.img,
      reference: data.reference
    }))

    actions.updateAction({
      sampleCount: tempArray
    })

    setValue(name, newValues)
    setBaseArray(newValues)
    setItemsInFormRequest(newValues)
  }

  useEffect(() => {
    if (baseArray && baseArray.length) {
      setValue(name, baseArray)
    }
  }, [baseArray, itemsInFormRequest])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <>
          <Select
            id={id}
            name={name}
            isMulti
            isClearable={false}
            isDisabled={isDisabled}
            value={field.value}
            onChange={handleChange}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: (state.isFocused || !state.isFocused) && "2px solid #bdbdbd",
                ":hover": {
                  borderColor: "gray"
                },
                boxShadow: "none",
                borderRadius: "none",
                padding: "2px 0px"
              })
            }}
            placeholder={
              <p className="p-0 m-0 fw-light fs-5 select-color">
                <FormattedMessage id="SamplingRequest.labelColour" defaultMessage="Color" />
              </p>
            }
            components={{ MultiValue: CustomMultiValue }}
            formatOptionLabel={formatOptionLabel}
          />
        </>
      )}
    />
  )
}

ColorsSelect.propTypes = {
  id: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
}

export default ColorsSelect
