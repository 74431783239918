import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import ErrorMessage from "components/ErrorMessage"
import TooltipElement from "modules/claims/components/TooltipElement"
import "components/InputMUI/styles/own-mui.scss"

const InputText = ({
  register,
  name,
  label,
  description,
  placeholder,
  defaultValue,
  tooltipElem,
  error
}) => {
  const intl = useIntl()

  return (
    <div className="step-form--group">
      <ErrorMessage weight="normal">{error}</ErrorMessage>
      <input
        className="step-form--field"
        placeholder={intl.formatMessage(placeholder)}
        defaultValue={defaultValue}
        {...register(name)}
      />
      {description && <span className="description-item">{intl.formatMessage(description)}</span>}
      <label className="step-form--label" htmlFor={name}>
        {intl.formatMessage(label)}
        {tooltipElem && <TooltipElement renderElement={tooltipElem} />}
      </label>
    </div>
  )
}

InputText.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  placeholder: PropTypes.object.isRequired,
  description: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  tooltipElem: PropTypes.func,
  error: PropTypes.string
}

export default InputText
