import { FormattedMessage } from "react-intl"
import marina from "./img/marina.png"
import outdoor from "./img/outdoor.png"

export const APPLICATIONS_ELEMENTS = [
  {
    id: "marina",
    img: marina,
    description: <FormattedMessage id="Kizuna.specApplicationsMarine" defaultMessage="Marine" />
  },

  {
    id: "outdoor",
    img: outdoor,
    description: <FormattedMessage id="Kizuna.specApplicationsOutdoor" defaultMessage="Outdoor" />
  }
]
