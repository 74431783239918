import { lazy, Suspense } from "react"
import { Step, Steps, Wizard } from "react-albus"
import LocationsListContainer from "components/StoreFinder/components/LocationsList/container"
import SearchByProductContainer from "components/StoreFinder/components/SearchByProduct/container"
import SearchByLocationContainer from "components/StoreFinder/components/SearchByLocation/container"
import SelectSearchMethodContainer from "components/StoreFinder/components//SelectSearchMethod/container"
import SearchOnlyByLocation from "components/StoreFinder/components/SearchOnlyByLocation"
import { isLATAM, isMEXICO } from "services/instances"
import { SCRIPT_JS_BASE_PROPS } from "services/google-maps"
import "./styles/StoreFinder.scss"

const StoresGoogleMapContainer = lazy(() => import("./components/StoresGoogleMap/container"))

const StoreFinder = () => {
  /**
   * This function is called by the wizard and helps me to redirect in case of id
   **/
  return (
    <div className="locations-map-section">
      <div className="locations-map-section__finder-box">
        <Wizard>
          <Steps>
            <Step id="selectMethod">
              {({ push }) => (
                <SelectSearchMethodContainer
                  onClickProduct={() => push("searchByProduct")}
                  onClickLocation={() => push("searchByLocation")}
                />
              )}
            </Step>
            <Step id="searchByProduct">
              {({ push, previous }) => (
                <SearchByProductContainer
                  onClickNext={() => push("locationsList")}
                  onClickPrevious={() => previous()}
                />
              )}
            </Step>
            <Step id="searchByLocation">
              {({ push, previous }) =>
                isLATAM() || isMEXICO() ? (
                  <SearchOnlyByLocation
                    onClickNext={() => push("locationsList")}
                    onClickPrevious={() => previous()}
                  />
                ) : (
                  <SearchByLocationContainer
                    onClickNext={() => push("locationsList")}
                    onClickPrevious={() => previous()}
                  />
                )
              }
            </Step>
            <Step id="locationsList">
              {({ push, previous }) => (
                <LocationsListContainer
                  onClickBack={() => push("selectMethod")}
                  onClickPrevious={() => previous()}
                />
              )}
            </Step>
          </Steps>
        </Wizard>
      </div>
      {/* The empty callback div is enough because I already have an skeleton background for the loading map */}
      <Suspense fallback={<div />}>
        <StoresGoogleMapContainer
          {...SCRIPT_JS_BASE_PROPS}
          center={{
            lat: 34.555941,
            lng: -41.064619
          }}
          zoom={3}
          containerElement={<div id="map" className="locations-map" />}
          mapElement={<div style={{ height: "674px", width: "100%" }} />}
        />
      </Suspense>
    </div>
  )
}

export default StoreFinder
