import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import ScrollBox from "components/ScrollBox"
import Card from "./components/Card"
import "./assets/styles/references.scss"
import { useQuery } from "@apollo/client"
import PatternsBySearchQuery from "./query"

export const SLIDER_OPTIONS = {
  className: "container-slider",
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  autoplay: false
}

const References = ({ id, title }) => {
  const { data, error, loading } = useQuery(PatternsBySearchQuery, {
    variables: {
      slugs: "piso-sticks-ht,piso-carpet,piso-gaudi,piso-arka,piso-terra,piso-hela-ht,piso-lienzo"
    }
  })

  return (
    <div id={id}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <TitleUnderlined align="left" hTag={H2_TAG}>
              <FormattedMessage
                id="Floors.title"
                description="Floors section title floors"
                defaultMessage="Pisos"
              />
            </TitleUnderlined>
            {loading || error ? (
              <></>
            ) : (
              data &&
              data.patterns && (
                <ScrollBox>
                  {data.patterns.edges.map(({ node }) => (
                    <Card key={`key_${node.id}`} {...node} />
                  ))}
                </ScrollBox>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

References.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.object
}

export default References
