import gql from "graphql-tag"
import { singleInstanceFragment, socialMediaInstanceFragment } from "./fragments"

export const instanceQuery = gql`
  query InstanceQuery($instanceId: ID!) {
    instance(id: $instanceId) {
      id
      ...SingleInstanceFragment
    }
  }
  ${singleInstanceFragment}
`

export const socialMediaInstanceQuery = gql`
  query SocialMediaInstanceQuery($instanceId: ID!) {
    instance(id: $instanceId) {
      id
      ...SocialMediaInstanceFragment
    }
  }
  ${socialMediaInstanceFragment}
`
