import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import ScrollBox from "components/ScrollBox"
import Card from "./components/Card"
import "./assets/styles/references.scss"
import { useQuery } from "@apollo/client"
import PatternsPetFriendlyQuery from "./query"
import { getCurrentInstanceId } from "services/instances"

export const SLIDER_OPTIONS = {
  className: "container-slider",
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  autoplay: false
}

const References = ({ id }) => {
  const { data, error, loading } = useQuery(PatternsPetFriendlyQuery, {
    variables: { instanceIds: [getCurrentInstanceId()], petFriendly: true }
  })

  return (
    <div id={id}>
      <div className="container my-2 py-4">
        <div className="row">
          <div className="col-12">
            <TitleUnderlined align="left" hTag={H2_TAG}>
              <FormattedMessage
                id="Petfriendly.References.title"
                description="Petfriendly section title references"
                defaultMessage="Referencias"
              />
            </TitleUnderlined>
            {loading || error ? (
              <></>
            ) : (
              data &&
              data.patterns && (
                <ScrollBox>
                  {data.patterns.edges.map(({ node }) => (
                    <Card key={`key_${node.id}`} {...node} />
                  ))}
                </ScrollBox>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

References.propTypes = {
  id: PropTypes.string.isRequired
}

export default References
