/* eslint-disable */
import { useRef, useState } from "react"
import roll from "./img/roll.svg"
import "./styles/QuickshipBanner.scss"
import { Link as LinkScroll } from "react-scroll"
import quickshipArrowDown from "./img/quickshipArrowDown.svg"
import { MockHowitWorksList } from "./Constants/MockHowitWorksList"
import { HowitWorksList } from "./Components/HowitWorksList"
import imgLeftDesktop from "./img/imgLeft.png"
import imgLeftMobilefrom from "./img/imgLeft_Mobile.png"
import imgRightDesktop from "./img/imgRight.png"
import imgRightMobile from "./img/imgRight_Mobile.png"
import useMediaQuery from "hooks/useMediaQuery"
import { useParams } from "react-router-dom"
import Flicking from "@egjs/react-flicking"
import "@egjs/react-flicking/dist/flicking.css"
import "@egjs/react-flicking/dist/flicking-inline.css"
import { HOME_SECTIONS } from "../consts"

const QuickshipBanner = () => {
  const { language } = useParams()
  const isMobile = useMediaQuery("(max-width: 600px)")
  const isMobileMini = useMediaQuery("(max-width: 360px)")
  const flickingRef = useRef(null)
  const [currentIndexSlide, setCurrentIndexSlide] = useState(0)

  const handleDotClick = (index) => {
    if (flickingRef.current) {
      flickingRef?.current?.moveTo(index)
    }
  }

  const handleChange = (e) => {
    setCurrentIndexSlide(e.index)
  }

  return (
    <div className="main-container-banner-home-quickship-page">
      {isMobile ? (
        // MOBILE
        <div id={HOME_SECTIONS[0]} className="container-slider-quickshippage">
          <Flicking
            ref={flickingRef}
            onChanged={handleChange}
            bound
            preventDefaultOnDrag
            moveType="snap"
            adaptive
            renderOnlyVisible
            className="flicking-viewport quickship--banner-container"
          >
            {/* Slide 1 */}
            <div className="flicking-panel quickship--banner-left-container responsive-banner-quickship">
              <div className="conatiner--img--home-baner">
                <img src={imgLeftMobilefrom} alt="img left" />
              </div>
              <div className="container--andtitle-descriptcion">
                <h6 className="quickship--banner-about-title mb-3">About Quickship</h6>
                <p className="quickship--banner-description md:ml-5">
                  In today’s fast-paced business world, time is crucial. That’s why Spradling has
                  curated a comprehensive selection of our most popular patterns in our Quickship
                  program.
                </p>
                <div className="text-center md-pt-5">
                  <img
                    src={roll}
                    alt="roll-image"
                    className="img-fluid mx-auto"
                    width={169}
                    height={60}
                  />
                </div>
                <p className="quickship--banner-description md:px-5 pt-2 md-pt-5 mt-1 md:mt-3">
                  We maintain an inventory of over <span>500 yards</span> per SKU, ensuring you can
                  fulfill orders promptly. Spradling’s Quickship program is designed to provide
                  expedited shipping for customers needing delivery on a tight schedule.
                </p>

                <div className="quickship--banner-btn-arrow-down d-flex flex-column justify-content-center align-items-center">
                  <LinkScroll
                    to={HOME_SECTIONS[2]}
                    className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
                  >
                    <p className="btn-Mobile-home-quickship-page">View colors</p>
                  </LinkScroll>
                </div>
              </div>
            </div>

            {/* Slide 2 */}
            <div
              id={HOME_SECTIONS[1]}
              className="flicking-panel quickship--banner-right-container responsive-banner-quickship"
            >
              <div className="quickship--content-banner-right-container">
                <h6 className="quickship--banner-how-works-title mb-3">How it works</h6>
                {MockHowitWorksList?.map((item, index) => {
                  if (!item) return null

                  return (
                    <HowitWorksList
                      key={index}
                      img={item.img}
                      alt={item.alt}
                      text={item.text}
                      textEn={item.textEn}
                      lang={language}
                    />
                  )
                })}
              </div>
              <div className="conatiner--img--home-baner">
                <img src={imgRightMobile} alt="img right" />
              </div>
            </div>
          </Flicking>
          <div className="flicking-dots-quickshippage">
            {[...Array(2).keys()].map((index) => (
              <button
                key={index}
                className={`flicking-dot ${currentIndexSlide === index ? "active" : ""}`}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </div>
        </div>
      ) : (
        // DESKTOP
        <div className="quickship--banner-container">
          {/* Columna 1 */}
          <div
            id={HOME_SECTIONS[0]}
            className="quickship--banner-left-container responsive-banner-quickship"
          >
            <div className="conatiner--img--home-baner">
              <img src={imgLeftDesktop} alt="img left" />
            </div>
            <div className="container--andtitle-descriptcion">
              <h6 className="quickship--banner-about-title mb-3">About Quickship</h6>
              <p className="quickship--banner-description md:ml-5">
                In today’s fast-paced business world, time is crucial. That’s why Spradling has
                curated a comprehensive selection of our most popular patterns in our Quickship
                program.
              </p>
              <div className="text-center md-pt-5">
                <img
                  src={roll}
                  alt="roll-image"
                  className="img-fluid mx-auto"
                  width={169}
                  height={60}
                />
              </div>
              <p className="quickship--banner-description md:px-5 pt-2 md-pt-5 mt-1 md:mt-3">
                We maintain an inventory of over <span>500 yards</span> per SKU, ensuring you can
                fulfill orders promptly. Spradling’s Quickship program is designed to provide
                expedited shipping for customers needing delivery on a tight schedule.
              </p>

              <div className="quickship--banner-btn-arrow-down d-flex flex-column justify-content-center align-items-center">
                <LinkScroll
                  to={HOME_SECTIONS[2]}
                  className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
                >
                  {!isMobile && (
                    <img
                      className="animationImgArrowDown"
                      src={quickshipArrowDown}
                      alt="quickshipArrowDown"
                      width={50}
                    />
                  )}
                  <p className={`${isMobile && "btn-Mobile-home-quickship-page"}`}>View colors</p>
                </LinkScroll>
              </div>
            </div>
          </div>

          {/* Columna 2 */}
          <div
            id={HOME_SECTIONS[1]}
            className="quickship--banner-right-container responsive-banner-quickship"
          >
            <div className="quickship--content-banner-right-container">
              <h6 className="quickship--banner-how-works-title mb-3">How it works</h6>
              {MockHowitWorksList?.map((item, index) => {
                if (!item) return null

                return (
                  <HowitWorksList
                    key={index}
                    img={item.img}
                    alt={item.alt}
                    text={item.text}
                    textEn={item.textEn}
                    lang={language}
                  />
                )
              })}
            </div>
            <div className="conatiner--img--home-baner">
              <img src={imgRightDesktop} alt="img right" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default QuickshipBanner
