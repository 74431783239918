/* eslint-disable */

import {useEffect} from 'react';
import {animateScroll} from 'react-scroll';

const ScrollTopOnMount = () => {
  useEffect(() => {
    animateScroll.scrollToTop({
      duration: 300
    });
  }, [])

  return null;
}

export default ScrollTopOnMount