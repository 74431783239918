import { FormattedMessage, useIntl } from "react-intl"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import axios from "axios"
import SelectCompanyType from "components/select/SelectCompanyType"
import SelectCountry from "components/select/SelectCountry"
import SelectRegion from "components/select/SelectRegion"
import SelectCity from "components/select/SelectCity"
import ErrorMessage from "components/ErrorMessage"
import PrivacyPolicyCheckbox from "components/PrivacyPolicyCheckbox"
import { VALIDATION_MESSAGES } from "utils/forms"
import { BUTTON_MESSAGES } from "utils/buttons"
import { REQUEST_ENDPOINT_ZAPPIER_FERIA_TAPICERIA } from "modules/feria-tapiceria/constants"
import { useState } from "react"

const defaultValues = {
  fullName: "",
  email: "",
  phone: "",
  companyType: "",
  country: "",
  region: "",
  city: "",
  privacyPolicy: false
}

const UpholsteryForm = () => {
  const intl = useIntl()
  const [success, setSuccess] = useState(false)
  const schema = yup.object({
    fullName: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    email: yup.string().email().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    phone: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    companyType: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    country: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string()
      })
      .nullable()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    region: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string()
      })
      .nullable()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    city: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string()
      })
      .nullable()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    privacyPolicy: yup.boolean().oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = ({ privacyPolicy, ...values }) => {
    const {
      city: { label: cityLabel }
    } = values

    const data = JSON.stringify({
      ...values,
      city: cityLabel,
      date: new Date().toLocaleString()
    })

    const promise = axios.post(REQUEST_ENDPOINT_ZAPPIER_FERIA_TAPICERIA, data)
    promise.then(() => {
      setSuccess(true)
    })
    promise.catch((error) => {
      console.log(error)
    })
  }

  return !success ? (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-6 form-group">
          <label htmlFor="fullName" className="text-white">
            <FormattedMessage
              id="Form.field.Name"
              description="Form field name"
              defaultMessage="Name"
            />
          </label>
          <input className="form-control" id="fullName" {...register("fullName")} />
          <ErrorMessage color="white">{errors.fullName?.message}</ErrorMessage>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="email" className="text-white">
            <FormattedMessage
              id="Form.field.Email"
              description="Form field email"
              defaultMessage="Email"
            />
          </label>
          <input className="form-control" id="email" {...register("email")} />
          <ErrorMessage color="white">{errors.email?.message}</ErrorMessage>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 form-group">
          <label htmlFor="phone" className="text-white">
            <FormattedMessage
              id="Form.field.Phone"
              description="Form field phone"
              defaultMessage="Phone"
            />
          </label>
          <input className="form-control" id="phone" {...register("phone")} />
          <ErrorMessage color="white">{errors.phone?.message}</ErrorMessage>
        </div>
        <div className="col-md-6 form-group">
          <SelectCompanyType {...register("companyType")} labelClassName="text-white" />
          <ErrorMessage color="white">{errors.companyType?.message}</ErrorMessage>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 form-group">
          <SelectCountry labelClassName="text-white" name="country" control={control} />
          <ErrorMessage color="white">{errors.country?.message}</ErrorMessage>
        </div>
        <div className="col-md-6 form-group">
          <SelectRegion name="region" control={control} labelClassName="text-white" />
          <ErrorMessage color="white">{errors.region?.message}</ErrorMessage>
        </div>
      </div>
      <div className="row">
        <div className="form-group">
          <SelectCity name="city" control={control} labelClassName="text-white" />
          <ErrorMessage color="white">{errors.city?.message}</ErrorMessage>
        </div>
      </div>
      <div className="row m-4 mb-0">
        <div className="col-12 form-group text-center">
          <Controller
            name="privacyPolicy"
            control={control}
            render={({ field }) => <PrivacyPolicyCheckbox color="white" {...field} />}
          />
          <ErrorMessage>{errors.privacyPolicy?.message}</ErrorMessage>
          <button className="btn btn-dark" type="submit">
            {intl.formatMessage(!isSubmitting ? BUTTON_MESSAGES.send : BUTTON_MESSAGES.sending)}
          </button>
        </div>
      </div>
    </form>
  ) : (
    <p className="thankyou-msg">
      <FormattedMessage
        id="ThankYou.description"
        description="ThankYou description text"
        defaultMessage="Thank you for filling out our form, we received your information successfully. Our team will be contacting you very soon."
      />
    </p>
  )
}

export default UpholsteryForm
