
/* eslint-disable */
import PropTypes from "prop-types"
import SampleCard from "../SampleCard"

const SamplesListDesktop = ({ samples }) => {
  return (
    <>
      {
        samples?.map(({ node }) => (
          <SampleCard
            key={`item_${node?.product?.id}`}
            sample={node}
          />
        ))
      }
    </>
  )
}

SamplesListDesktop.propTypes = {
  samples: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default SamplesListDesktop
