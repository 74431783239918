import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { SM, MD, LG, XL } from "consts/breakpoints"

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    window.addEventListener("resize", listener)
    return () => window.removeEventListener("resize", listener)
  }, [matches, query])

  return matches
}

useMediaQuery.propTypes = {
  query: PropTypes.oneOf([SM, MD, LG, XL])
}

export default useMediaQuery
