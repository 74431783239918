/* eslint-disable */
import { Nav } from "reactstrap"
import QuickshipLogoSpradling from "./img/QuickshipLogoSpradling.svg"
import DropdownQuickshipPage from "./img/flowbite-dropdown-quickship-page.svg"
import "./styles/Header.scss"
import { scroller } from "react-scroll"
import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import iconMenuMobileQuickship from "./img/icon-menu-mobile-quickship.svg"
import { TRANSLATED_ROUTES_QUICKSHIP_PAGE } from "./Constants/urlsQuinckshipPage"
import { collectionsQuery } from "modules/quickship/queries"
import useMediaQuery from "hooks/useMediaQuery"
import { useLocation } from "react-router-dom"
import { HOME_SECTIONS } from "../consts"

const HeaderQuickShip = () => {
  const location = useLocation()
  const { language, instance } = useParams()
  const currentInstance = instance ? `-${instance}` : ""

  const { data } = useQuery(collectionsQuery)
  const [isOpen, setIsOpen] = useState(false)
  const collectionData = data?.quickshipCollections?.edges

  const isDesktop = useMediaQuery("(min-width: 1200px)")

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleSelect = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash, {
        duration: 100,
        delay: 100,
        smooth: true,
        spy: true,
        offset: -50
      })
    }
  }, [location.hash])

  return (
    <div className="main-container-navbar-quickship-page">
      <Nav className="quickship--header col-12 p-3 d-flex align-items-center justify-content-between">
        <div className="quickship--container-logoImg">
          <Link
            to={`/${language}${currentInstance}/${TRANSLATED_ROUTES_QUICKSHIP_PAGE[language].route}`}
          >
            <img src={QuickshipLogoSpradling} alt="logo" />
          </Link>
        </div>

        {isDesktop ? (
          <div className="header-links-container-quickship d-flex">
            <Link
              to={`/${language}${currentInstance}/${TRANSLATED_ROUTES_QUICKSHIP_PAGE[language].route}${HOME_SECTIONS[0]}`}
            >
              {language === "es" ? "Sobre Quickship" : "About Quickship"}
            </Link>
            <Link
              to={`/${language}${currentInstance}/${TRANSLATED_ROUTES_QUICKSHIP_PAGE[language].route}${HOME_SECTIONS[1]}`}
            >
              {language === "es" ? "Cómo funciona" : "How it works"}
            </Link>

            <div className="container-dropdown-quickship-page">
              <div className="dropdown-header-nav-quickships" onClick={toggleDropdown}>
                <p>{language === "es" ? "Ver colores" : "View colors"}</p>
                <img src={DropdownQuickshipPage} alt="" />
              </div>
              {isOpen && (
                <div className="dropdown-list-quickship-page" onMouseLeave={toggleDropdown}>
                  {collectionData.map(({ node }) => (
                    <Link
                      to={`/${language}${currentInstance}/${TRANSLATED_ROUTES_QUICKSHIP_PAGE[language].route}/${node?.slug}`}
                      key={node.id}
                      className="dropdown-item-quickship-page"
                      onClick={handleSelect}
                    >
                      {node.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="nav-mobile-quickship">
            <button onClick={toggleDropdown}>
              <img src={iconMenuMobileQuickship} alt="icon-menu-mobile" />
            </button>
            {isOpen && (
              <div className="dropdown-list-quickship-page" onMouseLeave={toggleDropdown}>
                <Link
                  to={`/${language}${currentInstance}/${TRANSLATED_ROUTES_QUICKSHIP_PAGE[language].route}`}
                >
                  {language === "es" ? "Sobre Quickship" : "About Quickship"}
                </Link>
                <Link
                  to={`/${language}${currentInstance}/${TRANSLATED_ROUTES_QUICKSHIP_PAGE[language].route}`}
                >
                  {language === "es" ? "Cómo funciona" : "How it works"}
                </Link>
                {collectionData.map(({ node }) => {
                  return (
                    <Link
                      to={`/${language}${currentInstance}/${TRANSLATED_ROUTES_QUICKSHIP_PAGE[language].route}/${node?.id}`}
                      key={node.id}
                      className="dropdown-item-quickship-page"
                      onClick={handleSelect}
                    >
                      {node.name}
                    </Link>
                  )
                })}
              </div>
            )}
          </div>
        )}
      </Nav>
    </div>
  )
}

export default HeaderQuickShip
