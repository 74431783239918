import { FormattedMessage } from "react-intl"

export const NAV_0 = "Inspired by nature"
export const NAV_1 = "Inspiration"
export const NAV_2 = "RPET"
export const NAV_3 = "Product Details"
export const NAV_4 = "Request Sample"

export const FOOTER_ITEMS = [
  { label: <FormattedMessage id="Rivulet.Footer.phone" />, value: "+1 800 333 0955" },
  { label: "Email", value: "sales@spradlingvinyl.com" },
  { label: "Fax", value: "+1 205 985 2354" }
]

export const RIVULET_GTAG = "GTM-MBHWXRJP"
