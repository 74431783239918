import { useEffect } from "react"
import PropTypes from "prop-types"
import Select, { components } from "react-select"
import { FormattedMessage } from "react-intl"
import { useStateMachine } from "little-state-machine"
import { updateAction } from "app/store"
import { handleAddedSamplesByTheUser } from "./utils"
import "./styles/colors-select.scss"
import { Controller } from "react-hook-form"

const CustomMultiValue = (props) => {
  return (
    // eslint-disable-next-line react/prop-types
    <components.MultiValue {...props} key={props.data.id}>
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </components.MultiValue>
  )
}

CustomMultiValue.propTypes = {
  props: PropTypes.shape({
    data: PropTypes.any,
    children: PropTypes.any
  })
}

const ColorsSelect = ({ name, id, control, setValue }) => {
  const { state, actions } = useStateMachine({ updateAction })
  const SELECTED_COLORS = handleAddedSamplesByTheUser(state?.sampleCount)
  let tempArray = []

  const handleChange = (value, context) => {
    let newValues = [...SELECTED_COLORS]
    const cloneSampleCount = [...SELECTED_COLORS]

    if (context?.action === "remove-value") {
      newValues = cloneSampleCount.filter(({ data }) => data.id !== context.removedValue.data.id)
    }

    tempArray = newValues.map(({ data }) => ({
      id: data.id,
      pattern: data.pattern,
      color: data.color,
      img: data.img,
      reference: data.reference,
      catalogue: data?.catalogue
    }))

    actions.updateAction({
      sampleCount: tempArray
    })

    setValue(name, newValues)
  }

  const formatOptionLabel = ({ label, data: { id: key } }) => (
    <div>
      <span>{label}</span> <small>({key})</small>
    </div>
  )

  useEffect(() => {
    if (SELECTED_COLORS && SELECTED_COLORS.length) {
      handleChange()
    }
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <Select
          id={id}
          name={name}
          isMulti
          isClearable={false}
          value={field.value}
          onChange={handleChange}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: (state.isFocused || !state.isFocused) && "2px solid #bdbdbd",
              ":hover": {
                borderColor: "gray"
              },
              boxShadow: "none",
              borderRadius: "none",
              padding: "2px 0px"
            })
          }}
          placeholder={
            <p className="p-0 m-0 fw-light fs-5 select-color">
              <FormattedMessage id="SamplingRequest.labelColor" defaultMessage="Color" />
            </p>
          }
          components={{ MultiValue: CustomMultiValue }}
          formatOptionLabel={formatOptionLabel}
        />
      )}
    />
  )
}

ColorsSelect.propTypes = {
  id: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
}

export default ColorsSelect
