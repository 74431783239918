import { FormattedMessage } from "react-intl"

export const CLEAING_PROCEDURE_ITEM_BOAT_SEATS = [
  {
    id: "CleaningMarine.General.BoatSeats.Procedures.One",
    text: (
      <FormattedMessage
        id="CleaningMarine.General.BoatSeats.Procedures.One"
        defaultMessage="For light soiling, a solution of 10% household liquid dish soap in warm water, applied with a soft damp cloth. Rub gently and rinse with a water-dampened cloth and pat dry."
      />
    )
  },
  {
    id: "CleaningMarine.General.BoatSeats.Procedures.Two",
    text: (
      <FormattedMessage
        id="CleaningMarine.General.BoatSeats.Procedures.Two"
        defaultMessage="For heavy soiling, dampen a soft white cloth with a one-to-one (1:1) solution of *Formula 409<sup>®</sup> and water or *Fantastik<sup>®</sup> and water. Rub gently and rinse with a water-dampened cloth and pat dry."
        values={{
          sup: (...chunks) => <sup>{chunks}</sup>
        }}
      />
    )
  },
  {
    id: "CleaningMarine.General.BoatSeats.Procedures.Three",
    text: (
      <FormattedMessage
        id="CleaningMarine.General.BoatSeats.Procedures.Three"
        defaultMessage="For more difficult stains, dampen a soft white cloth with a solution of household bleach (10% bleach and 90% water). Rub gently and rinse with a water-dampened cloth to remove bleach concentration and pat dry."
      />
    )
  }
]

export const CLEAING_PROCEDURE_ITEM_BIMI_TOPS_COVERS = [
  {
    id: "CleaningMarine.General.BimiTopsCovers.Procedures.One",
    text: (
      <FormattedMessage
        id="CleaningMarine.General.BimiTopsCovers.Procedures.One"
        defaultMessage="Brush off dirt & hose down thoroughly. Use a solution of 2 ounces mild soap to 1 gallon warm water, NOT hot water."
      />
    )
  },
  {
    id: "CleaningMarine.General.BimiTopsCovers.Procedures.Two",
    text: (
      <FormattedMessage
        id="CleaningMarine.General.BimiTopsCovers.Procedures.Two"
        defaultMessage="Use soft bristle brush or garden hose, NOT a power washer lvory<sup>®</sup> or Dawn<sup>®</sup> dish soap + water (1 part soap to 9 parts water)"
        values={{
          sup: (...chunks) => <sup>{chunks}</sup>
        }}
      />
    )
  },
  {
    id: "CleaningMarine.General.BimiTopsCovers.Procedures.Three",
    text: (
      <FormattedMessage
        id="CleaningMarine.General.BimiTopsCovers.Procedures.Three"
        defaultMessage="ALWAYS rinse thoroughly w/COLD water & air dry."
      />
    )
  }
]

export const CLEAING_PROCEDURE_ITEM_WOVEN_FLOORING = [
  {
    id: "CleaningMarine.General.wovenFloor.Procedures.One",
    text: (
      <FormattedMessage
        id="CleaningMarine.General.wovenFloor.Procedures.One"
        defaultMessage="Use low pressure water to hose down flooring. With a medium bristle deck brush & warm soapy water, work in a forward & back motion. Rinse off all soapy residue with water."
      />
    )
  },
  {
    id: "CleaningMarine.General.wovenFloor.Procedures.Two",
    text: (
      <FormattedMessage
        id="CleaningMarine.General.wovenFloor.Procedures.Two"
        defaultMessage="If additional cleaning is required, while still wet, spray affected area with small amount of spray-on degreaser. Use your fingers in a circular motion until the stain starts to lift."
        values={{
          sup: (...chunks) => <sup>{chunks}</sup>
        }}
      />
    )
  },
  {
    id: "CleaningMarine.General.wovenFloor.Procedures.Three",
    text: (
      <FormattedMessage
        id="CleaningMarine.General.wovenFloor.Procedures.Three"
        defaultMessage="Use deck brush again & rinse well with water. Repeat if necessary. Degreaser should only be used to spot clean with, not as a general cleaning solution."
      />
    )
  }
]
