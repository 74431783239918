/* CHECKER */
// SIDE A
import ACQUA from "../img/checker/side-a/ACQUA_CHK-B008.jpg"
import ATLANTIC from "../img/checker/side-a/ATLANTIC_CHK-N010.jpg"
import BOBCAT from "../img/checker/side-a/BOBCAT_CHK-B003.jpg"
import CARDAMOM from "../img/checker/side-a/CARDAMOM_CHK-B004.jpg"
import CEDRUS from "../img/checker/side-a/CEDRUS_CHK-B007.jpg"
import CREAMY from "../img/checker/side-a/CREAMY_CHK-B014.jpg"
import CRYSTAL from "../img/checker/side-a/CRYSTAL_CHK-G010.jpg"
import EMERALD from "../img/checker/side-a/EMERALD_CHK-N009.jpg"
import KHAKI from "../img/checker/side-a/KHAKI_CHK-G003.jpg"
import KSAMIL from "../img/checker/side-a/KSAMIL_CHK-G009.jpg"
import OVERCAST from "../img/checker/side-a/OVERCAST_CHK-B005.jpg"
import SALVIA from "../img/checker/side-a/SALVIA_CHK-N012.jpg"
import SOOT from "../img/checker/side-a/SOOT_CHK-N002.jpg"
import STORMY from "../img/checker/side-a/STORMY_CHK-G001.jpg"
import WINTER from "../img/checker/side-a/WINTER_CHK-B013.jpg"

/* ARTISAN */
// SIDE A
import BARK from "../img/artisan/side-a/BARK_ART-N014.jpg"
import CELESTE from "../img/artisan/side-a/CELESTE_ART-B01.jpg"
import LEADEN from "../img/artisan/side-a/LEADEN_ART-B015.jpg"
import MINT from "../img/artisan/side-a/MINT_ART-B007.jpg"
import SEAFOAM from "../img/artisan/side-a/SEAFOAM_ART-B008.jpg"
import SEAROCK from "../img/artisan/side-a/SEAROCK_ART-G010.jpg"
import TWILIGHT from "../img/artisan/side-a/TWILIGHT_ART-G002.jpg"
import WHEAT from "../img/artisan/side-a/WHEAT_ART-G014.jpg"

/* TWEED IMAGES */
// SIDE A
import ASHY from "../img/tweed/side-a/ASHY_TWD-G013.jpg"
import BUFF from "../img/tweed/side-a/BUFF_TWD-G006.jpg"
import CAPRI from "../img/tweed/side-a/CAPRI_TWD-N010.jpg"
import CEDAR from "../img/tweed/side-a/CEDAR_TWD-G003.jpg"
import DARKEST from "../img/tweed/side-a/DARKEST_TWD-G002.jpg"
import FOGGY from "../img/tweed/side-a/FOGGY_TWD-B016.jpg"
import JANUBIO from "../img/tweed/side-a/JANUBIO_TWD-N008.jpg"
import MANGROVE from "../img/tweed/side-a/MANGROVE_TWD-N004.jpg"
import OAT from "../img/tweed/side-a/OAT_TWD-B014.jpg"
import OTTER from "../img/tweed/side-a/OTTER_TWD-B003.jpg"
import SNOWY from "../img/tweed/side-a/SNOWY_TWD-B013.jpg"
import ZEBRA from "../img/tweed/side-a/ZEBRA_TWD-B001.jpg"

export const CHECKER_IMAGES = {
  ACQUA,
  ATLANTIC,
  BOBCAT,
  CARDAMOM,
  CEDRUS,
  CREAMY,
  CRYSTAL,
  EMERALD,
  KHAKI,
  KSAMIL,
  OVERCAST,
  SALVIA,
  SOOT,
  STORMY,
  WINTER
}

export const ARTISAN_IMAGES = {
  BARK,
  CELESTE,
  LEADEN,
  MINT,
  SEAFOAM,
  SEAROCK,
  TWILIGHT,
  WHEAT
}

export const TWEED_IMAGES = {
  ASHY,
  BUFF,
  CAPRI,
  CEDAR,
  DARKEST,
  FOGGY,
  JANUBIO,
  MANGROVE,
  OAT,
  OTTER,
  SNOWY,
  ZEBRA
}
