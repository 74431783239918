import { createStore } from "little-state-machine"

export const storeWithLittleStateMachine = () => createStore({
  isHome: true
})

export const updateAction = (globalState, payload) => {
  return {
    ...globalState,
    ...payload
  }
}

export const clearAction = (globalState, payload) => {
  return {}
}
