import gql from "graphql-tag"

export const patternNameQuery = gql`
  query PatternNameQuery($id: ID!) {
    pattern(id: $id) {
      id
      altName
    }
  }
`

export const cityNameQuery = gql`
  query PatternNameQuery($id: ID!) {
    city(id: $id) {
      id
      name
    }
  }
`
