export const STATIC_SLIDER = {
  arrows: false,
  dots: false,
  autoplay: false
}

export const SLIDER_OPTIONS = {
  ...STATIC_SLIDER,
  slidesToShow: 1,
  fade: true
}
