import { useState, useEffect } from "react"

const useArrowVisibility = (containerRef) => {
  const [isArrowVisible, setIsArrowVisible] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight } = containerRef.current
        // const isAtBottom = scrollHeight - scrollTop <= clientHeight * 2 // clientHeight from containerRef.current
        const isAtBottom = scrollHeight - scrollTop <= 1500
        setIsArrowVisible(isAtBottom) // Oculta la flecha si está en los últimos 2 elementos
      }
    }

    const containerElement = containerRef.current
    if (containerElement) {
      containerElement.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener("scroll", handleScroll)
      }
    }
  }, [containerRef])

  return isArrowVisible
}

export default useArrowVisibility
