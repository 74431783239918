import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import Slider from "react-slick"
import { LG } from "consts/breakpoints"
import useMediaQuery from "hooks/useMediaQuery"
import { SLIDER_OPTIONS, SLIDES_PROJECTS } from "./constants"
import "./styles/projects.scss"

const Projects = ({ id }) => {
  const isMobile = useMediaQuery(LG)

  return (
    <div id={id} className="container g-0 py-4">
      <div className="row g-0">
        <h2 className="title--segm-hotelery">
          <FormattedMessage
            id="Segments.Hotel.Projects.title"
            defaultMessage="Productos"
            values={{ br: <br /> }}
          />
        </h2>
        <p className="info--segm-hotelery info-project--segm-hotelery pt-4">
          <FormattedMessage
            id="Segments.Hotel.Projects.info"
            defaultMessage="Sabemos que el diseño y la calidad son fundamentales para una estancia innolvidable.{br}{br}Nuestros productos cuentan con características técnicas como:"
            values={{
              br: <br />
            }}
          />
        </p>
        <Slider className="mb-4" {...SLIDER_OPTIONS(isMobile)}>
          {SLIDES_PROJECTS.map(({ id, project, location, patterns, img }) => (
            <div key={`key_${id}`} className="slide-container-project--segm-hotelery ">
              <img className="slide-img-project--segm-hotelery" src={img} alt="project" />
              <div className="slide-img-info-project--segm-hotelery">
                <p className="m-0">
                  <span className="d-block">{project}</span>
                  <span className="d-block">{location}</span>
                  <span className="d-block">{patterns}</span>
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

Projects.propTypes = {
  id: PropTypes.string
}

export default Projects
