import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import { PRODUCT_DETAILS } from "./constants"
import ProductDetailsGeneric from "components/ProductDetail"

const ProductDetails = ({ id }) => (
  <div id={id}>
    <div className="container my-2 py-4">
      <div className="row justify-content-md-center">
        <div className="col-12 text-center">
          <TitleUnderlined className="mx-2 mt-2 mb-4" hTag={H2_TAG}>
            <FormattedMessage
              id="Pandora.ProductDetail.title"
              description="Pandora ProductDetail title"
              defaultMessage="Detalles del producto"
            />
          </TitleUnderlined>
        </div>
      </div>
    </div>
    <div className="row g-0 justify-content-center">
      <ProductDetailsGeneric className="col-6 col-md-2" items={PRODUCT_DETAILS} />
    </div>
  </div>
)

ProductDetails.propTypes = {
  id: PropTypes.string.isRequired
}

export default ProductDetails
