import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H3_TAG } from "components/TitleUnderlined/constants"
import { useDispatch } from "react-redux"
import { setPatternId } from "components/StoreFinder/actions"
import { useQuery } from "@apollo/client"
import PatternQuery from "modules/linetex/components/ColorPalette/query"
import LocationsListContainer from "components/StoreFinder/components/LocationsList/container"
import { SCRIPT_JS_BASE_PROPS } from "services/google-maps"
import StoresGoogleMapContainer from "components/StoreFinder/components/StoresGoogleMap/container"
import { getCurrentInstanceId } from "services/instances"

const WhereToBuy = ({ id }) => {
  const dispatch = useDispatch()
  useQuery(PatternQuery, {
    variables: { instanceIds: [getCurrentInstanceId()], slug: "impala" },
    onCompleted: (data) => dispatch(setPatternId(data.pattern.id))
  })

  return (
    <div id={id}>
      <div className="container mt-2 pt-4">
        <div className="row">
          <div className="col-12 container-text--where-to-buy">
            <TitleUnderlined hTag={H3_TAG} className="font-gill-sans">
              <FormattedMessage
                id="Impala.WhereToBuy.title"
                description="Petfriendly section title WhereToBuy"
                defaultMessage="¿Quieres conocer dónde adquirir esta referencia para tus espacios o proyectos?"
              />
            </TitleUnderlined>
          </div>
        </div>
      </div>
      <div className="locations-map-section">
        <div className="locations-map-section__finder-box">
          <LocationsListContainer />
        </div>
        <StoresGoogleMapContainer
          {...SCRIPT_JS_BASE_PROPS}
          containerElement={<div id="map" className="locations-map" />}
          mapElement={<div style={{ height: "674px", width: "100%" }} />}
        />
      </div>
    </div>
  )
}

WhereToBuy.propTypes = {
  id: PropTypes.string
}

export default WhereToBuy
