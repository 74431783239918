const emailTemplateAdmin = ({ fileUrl, excelUrl }) =>
  `
    <!DOCTYPE html>
    <html
      xmlns="http://www.w3.org/1999/xhtml"
      xmlns:v="urn:schemas-microsoft-com:vml"
      xmlns:o="urn:schemas-microsoft-com:office:office"
    >
      <head>
        <title> </title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <style type="text/css">
          body {
            margin: 0;
            padding: 0;
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
          }

          table,
          td {
            border-collapse: collapse;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
          }

          img {
            border: 0;
            height: auto;
            line-height: 100%;
            outline: none;
            text-decoration: none;
            -ms-interpolation-mode: bicubic;
          }

          p {
            display: block;
            margin: 13px 0;
          }
        </style>
        <style type="text/css">
          @media only screen and (max-width: 480px) {
            @-ms-viewport {
              width: 320px;
            }

            @viewport {
              width: 320px;
            }
          }
        </style>
        <link
          href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700"
          rel="stylesheet"
          type="text/css"
        />
        <style type="text/css">
          @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
        </style>
      </head>

      <body style="background-color: #eeeeee">
        <div style="background-color: #eeeeee">
          <table
            align="center"
            border="0"
            cellpadding="0"
            cellspacing="0"
            class=""
            style="width: 600px"
            width="600"
          >
            <tr>
              <td
                style="
                  line-height: 0px;
                  font-size: 0px;
                  mso-line-height-rule: exactly;
                "
              >
                <div
                  style="
                    background: #000;
                    background-color: #000;
                    margin: 0px auto;
                    max-width: 600px;
                  "
                >
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="
                      background: #000;
                      background-color: #000;
                      width: 100%;
                    "
                  >
                    <tbody>
                      <tr>
                        <td
                          style="
                            direction: ltr;
                            font-size: 0px;
                            padding: 16px 0;
                            text-align: center;
                          "
                        >
                          <table
                            role="presentation"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                          >
                            <tr>
                              <td
                                class=""
                                style="vertical-align: top; width: 300px"
                              >
                                <div
                                  style="
                                    font-size: 0px;
                                    text-align: left;
                                    direction: ltr;
                                    display: inline-block;
                                    vertical-align: top;
                                    width: 100%;
                                  "
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    role="presentation"
                                    style="vertical-align: top"
                                    width="100%"
                                  >
                                    <tr>
                                      <td
                                        align="left"
                                        style="
                                          font-size: 0px;
                                          padding: 16px 24px;
                                          word-break: break-word;
                                        "
                                      >
                                        <table
                                          border="0"
                                          cellpadding="0"
                                          cellspacing="0"
                                          role="presentation"
                                          style="
                                            border-collapse: collapse;
                                            border-spacing: 0px;
                                          "
                                        >
                                          <tbody>
                                            <tr>
                                              <td>
                                                <a
                                                  href="https://spradling.group"
                                                  target="_blank"
                                                >
                                                  <img
                                                    width="108"
                                                    src="https://spradling-emails.s3-us-west-2.amazonaws.com/images/logo-spradling-header.png"
                                                  />
                                                </a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </table>

          <table
            align="center"
            border="0"
            cellpadding="0"
            cellspacing="0"
            class=""
            style="width: 600px"
            width="600"
          >
            <tr>
              <td
                style="
                  line-height: 0px;
                  font-size: 0px;
                  mso-line-height-rule: exactly;
                "
              >
                <div
                  style="
                    background: #ffffff;
                    background-color: #ffffff;
                    margin: 0px auto;
                    max-width: 600px;
                  "
                >
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="
                      background: #ffffff;
                      background-color: #ffffff;
                      width: 100%;
                    "
                  >
                    <tbody>
                      <tr>
                        <td
                          style="
                            direction: ltr;
                            font-size: 0px;
                            padding: 20px 0;
                            text-align: center;
                          "
                        >
                          <table
                            role="presentation"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                          >
                            <tr>
                              <td
                                class=""
                                style="vertical-align: top; width: 600px"
                              >
                                <div
                                  style="
                                    font-size: 0px;
                                    text-align: left;
                                    direction: ltr;
                                    display: inline-block;
                                    vertical-align: top;
                                    width: 100%;
                                  "
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    role="presentation"
                                    style="vertical-align: top"
                                    width="100%"
                                  >
                                    <tr>
                                      <td
                                        align="left"
                                        style="
                                          font-size: 0px;
                                          padding: 10px 25px;
                                          word-break: break-word;
                                        "
                                      >
                                        <div
                                          style="
                                            font-family: Ubuntu, Helvetica, Arial,
                                              sans-serif;
                                            font-size: 14px;
                                            line-height: 1.5;
                                            text-align: left;
                                            color: #6b6b6b;
                                          "
                                        >
                                          <p>Hi,</p>
                                          <p>
                                            A new claim has been submitted view the
                                            attached pdf for details.
                                          </p>
                                          <p>
                                            Click
                                            <a href="${fileUrl}">here</a>
                                            to download uploaded evidences
                                          </p>
                                          <p>
                                            Click
                                            <a href="${excelUrl}">here</a>
                                            to see all claim records
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </table>

          <table
            align="center"
            border="0"
            cellpadding="0"
            cellspacing="0"
            class=""
            style="width: 600px"
            width="600"
          >
            <tr>
              <td
                style="
                  line-height: 0px;
                  font-size: 0px;
                  mso-line-height-rule: exactly;
                "
              >
                <div
                  style="
                    background: #000;
                    background-color: #000;
                    margin: 0px auto;
                    max-width: 600px;
                  "
                >
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="
                      background: #000;
                      background-color: #000;
                      width: 100%;
                    "
                  >
                    <tbody>
                      <tr>
                        <td
                          style="
                            direction: ltr;
                            font-size: 0px;
                            padding: 20px 0;
                            text-align: center;
                          "
                        >
                          <table
                            role="presentation"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                          >
                            <tr>
                              <td
                                class=""
                                style="vertical-align: top; width: 600px"
                              >
                                <div
                                  style="
                                    font-size: 0px;
                                    text-align: left;
                                    direction: ltr;
                                    display: inline-block;
                                    vertical-align: top;
                                    width: 100%;
                                  "
                                >
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    cellspacing="0"
                                    role="presentation"
                                    style="vertical-align: top"
                                    width="100%"
                                  >
                                    <tr>
                                      <td
                                        align="center"
                                        style="
                                          font-size: 0px;
                                          padding: 10px 25px;
                                          padding-top: 20px;
                                          word-break: break-word;
                                        "
                                      >
                                        <table
                                          align="center"
                                          border="0"
                                          cellpadding="0"
                                          cellspacing="0"
                                          role="presentation"
                                        >
                                          <tr>
                                            <td>
                                              <table
                                                align="center"
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  float: none;
                                                  display: inline-table;
                                                "
                                              >
                                                <tr>
                                                  <td style="padding: 4px">
                                                    <table
                                                      border="0"
                                                      cellpadding="0"
                                                      cellspacing="0"
                                                      role="presentation"
                                                      style="
                                                        background: transparent;
                                                        border-radius: 3px;
                                                        width: 30px;
                                                      "
                                                    >
                                                      <tr>
                                                        <td
                                                          style="
                                                            font-size: 0;
                                                            height: 30px;
                                                            vertical-align: middle;
                                                            width: 30px;
                                                          "
                                                        >
                                                          <a
                                                            href="https://www.facebook.com/SpradlingEurope"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              height="30"
                                                              src="https://www.mailjet.com/images/theme/v1/icons/ico-social/facebook.png"
                                                              style="
                                                                border-radius: 3px;
                                                                display: block;
                                                              "
                                                              width="30"
                                                            />
                                                          </a>
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>

                                            <td>
                                              <table
                                                align="center"
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  float: none;
                                                  display: inline-table;
                                                "
                                              >
                                                <tr>
                                                  <td style="padding: 4px">
                                                    <table
                                                      border="0"
                                                      cellpadding="0"
                                                      cellspacing="0"
                                                      role="presentation"
                                                      style="
                                                        background: transparent;
                                                        border-radius: 3px;
                                                        width: 30px;
                                                      "
                                                    >
                                                      <tr>
                                                        <td
                                                          style="
                                                            font-size: 0;
                                                            height: 30px;
                                                            vertical-align: middle;
                                                            width: 30px;
                                                          "
                                                        >
                                                          <a
                                                            href="https://www.instagram.com/spradlingeurope/"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              height="30"
                                                              src="https://www.mailjet.com/images/theme/v1/icons/ico-social/instagram.png"
                                                              style="
                                                                border-radius: 3px;
                                                                display: block;
                                                              "
                                                              width="30"
                                                            />
                                                          </a>
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>

                                            <td>
                                              <table
                                                align="center"
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                role="presentation"
                                                style="
                                                  float: none;
                                                  display: inline-table;
                                                "
                                              >
                                                <tr>
                                                  <td style="padding: 4px">
                                                    <table
                                                      border="0"
                                                      cellpadding="0"
                                                      cellspacing="0"
                                                      role="presentation"
                                                      style="
                                                        background: transparent;
                                                        border-radius: 3px;
                                                        width: 30px;
                                                      "
                                                    >
                                                      <tr>
                                                        <td
                                                          style="
                                                            font-size: 0;
                                                            height: 30px;
                                                            vertical-align: middle;
                                                            width: 30px;
                                                          "
                                                        >
                                                          <a
                                                            href="https://www.linkedin.com/company/spradling-europe"
                                                            target="_blank"
                                                          >
                                                            <img
                                                              height="30"
                                                              src="https://www.mailjet.com/images/theme/v1/icons/ico-social/linkedin.png"
                                                              style="
                                                                border-radius: 3px;
                                                                display: block;
                                                              "
                                                              width="30"
                                                            />
                                                          </a>
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </body>
    </html>
  `

export default emailTemplateAdmin
