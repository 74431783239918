import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import iconCatalog from "./img/icon-catalogue.svg"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import Video from "modules/floors/components/Video"
import posterIntalaction from "modules/floors/img/poster-instalation-guide.png"
import Header from "modules/floors/components/Header"
import { MENU_COMEBACK } from "modules/floors/constants"

const InstalationGuide = ({ noTitle = false }) => {
  const navigate = useNavigate()

  return (
    <>
      <Header noInspirations objItems={MENU_COMEBACK(navigate)} />
      <div className="container my-5 pt-5">
        <div className="row justify-content-center align-items-center">
          {!noTitle && (
            <TitleUnderlined hTag={H2_TAG}>
              <FormattedMessage
                id="Floors.HeaderButtons.instalation"
                description="guide HeaderButtons text"
                defaultMessage="instalation"
              />
            </TitleUnderlined>
          )}
          <div className="col">
            <Video video="https://www.youtube.com/embed/Rj5QOQqCJaI" poster={posterIntalaction} />
            <div className="text-center">
              <a
                className="btn btn-outline-dark text-capitalize"
                href="https://spradling-misc.s3.us-west-2.amazonaws.com/landing-attachments/instalacion_pisos_es.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <div className="d-flex justify-content-center align-items-center p-2">
                  <img className="img-fluid w-75" src={iconCatalog} alt="catalog" />
                  <h1 className="fs-5">
                    <FormattedMessage
                      id="Floors.InstalationGuide.btn"
                      description="page instalation guide btn download guide"
                      defaultMessage="Descargar cartilla"
                    />
                  </h1>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

InstalationGuide.propTypes = {
  noTitle: PropTypes.bool
}

export default InstalationGuide
