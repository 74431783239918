export const NAV_ITEMS = ["permablok plus+", "colors", "applications", "product details", "contact"]

export const FOOTER_ITEMS = [
  {
    label: "Phone",
    value: "+1 800 333 0955"
  },
  {
    label: "E-mail",
    value: "sales@spradlingvinyl.com"
  },
  {
    label: "Fax",
    value: "+1 205 985 2354"
  }
]

export const GLOBAL_SITE_TAG = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "GTM-MNFJGZP",
  [process.env.REACT_APP_MX_INSTANCE_ID]: "GTM-MNFJGZP",
  [process.env.REACT_APP_USA_INSTANCE_ID]: "GTM-MNFJGZP"
}
