import { forwardRef } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { useQuery } from "@apollo/client"
import companyTypesQuery from "./query"
import { getCurrentInstanceId } from "services/instances"

const SelectCompanyType = forwardRef(({ labelClassName, name, onChange, onBlur }, ref) => {
  const { loading, data } = useQuery(companyTypesQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()]
    }
  })

  return (
    <>
      <label htmlFor={name} className={labelClassName}>
        <FormattedMessage
          id="Form.field.CompanyType"
          description="Form field company type"
          defaultMessage="Company Type"
        />
      </label>
      <select
        className="form-select text-capitalize"
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
      >
        {loading ? (
          <FormattedMessage
            id="CompanyTypeField.loading"
            description="Loading label for CompanyType field"
            defaultMessage="Cargando tipos de compañia..."
          >
            {(label) => (
              <option value="" disabled>
                {label}
              </option>
            )}
          </FormattedMessage>
        ) : (
          <>
            <FormattedMessage
              id="CompanyTypeField.companyTypeDefault"
              description="Default of company type field of the Contact box form"
              defaultMessage="Select a company type"
            >
              {(label) => <option value="">{label}</option>}
            </FormattedMessage>
            {data.companyTypes.edges.map(({ node: { id, name } }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </>
        )}
      </select>
    </>
  )
})

SelectCompanyType.propTypes = {
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
}

SelectCompanyType.displayName = "SelectCompanyType"

export default SelectCompanyType
