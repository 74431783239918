import { useNavigate } from "react-router-dom"
import { useIntl, FormattedMessage } from "react-intl"
import classNames from "classnames"
import { useForm } from "react-hook-form"
import { useStateMachine } from "little-state-machine"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Layout from "modules/claims/components/Layout"
import ProgressBar from "modules/claims/components/StepForm/ProgressBar"
import InputText from "components/InputMUI/Text"
import { getCurrentLanguageAndInstanceCode } from "services/instances"
import { updateAction } from "utils/littleStateMachine"
import { GENERIC_MSGS, CUSTOMER_NUMBER_TOOLTIP } from "modules/claims/constants"
import { VALIDATION_MESSAGES } from "utils/forms"
import { BUTTON_MESSAGES } from "utils/buttons"
import { MSGS } from "./constants"
import "../styles/step-form.scss"
import contactDetails from "./img/1.contact-details.jpg"

const inputPlaceholder = GENERIC_MSGS.typeAnswer_placeholder

const Step1 = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { actions, state } = useStateMachine({ updateAction })
  const [lang, instance] = getCurrentLanguageAndInstanceCode()

  const defaultValues = { ...state }
  const schema = yup.object({
    companyName: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    customerNumber: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    fullName: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    email: yup.string().email().required(intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = (data) => {
    actions.updateAction(data)
    navigate(`../${lang}-${instance}/claims/step-2`, { replace: true })
  }

  return (
    <Layout
      title={
        <FormattedMessage
          id="Claims.ContactDetail.title"
          description="section claim contact detail title"
          defaultMessage="Contact Details"
        />
      }
      backgroundImage={contactDetails}
    >
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            name="companyName"
            register={register}
            label={MSGS.companyName}
            placeholder={inputPlaceholder}
            defaultValue={state.companyName}
            error={errors.companyName?.message}
          />
          <InputText
            name="customerNumber"
            register={register}
            label={MSGS.customerNumber}
            placeholder={inputPlaceholder}
            defaultValue={state.customerNumber}
            tooltipElem={CUSTOMER_NUMBER_TOOLTIP}
            error={errors.customerNumber?.message}
          />
          <InputText
            name="fullName"
            register={register}
            label={MSGS.fullName}
            placeholder={inputPlaceholder}
            defaultValue={state.fullName}
            error={errors.fullName?.message}
          />
          <InputText
            name="email"
            register={register}
            label={MSGS.email}
            placeholder={inputPlaceholder}
            defaultValue={state.email}
            error={errors.email?.message}
          />
          <div className="btn-container">
            <button
              className={classNames({
                "btn btn-dark text-uppercase": true,
                disabled: Object.values(errors).length
              })}
              type="submit"
            >
              {intl.formatMessage(BUTTON_MESSAGES.ok)}
            </button>
          </div>
        </form>
        <ProgressBar progress={10} handleNext={handleSubmit(onSubmit)} RoutePrev={`../${lang}-${instance}/claims`}/>
      </>
    </Layout>
  )
}

export default Step1
