import { useState } from "react"
import PropTypes from "prop-types"
import { Modal as ModalReactStrap, ModalHeader, ModalBody } from "reactstrap"
import "./styles/sustainable-modal.scss"

import phthalate from "./imgs/free-phthalate.svg"
import pfas from "./imgs/free-pfas.svg"
import heavyMetals from "./imgs/free-heavy-metals.svg"
import biocides from "./imgs/free-biocides.svg"
import flameRetardant from "./imgs/free-flame-retardant-additives.svg"
import section3Desktop from "./imgs/section-3-desktop.svg"
import section3Mobile from "./imgs/section-3-mobile.svg"
import animatedChart from "./imgs/animated-chart.gif"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"

const SustainableModal = ({ isOpen, handleToggle: toggleModal }) => {
  const isMobile = useMediaQuery(LG)
  const [gifKey, setGifKey] = useState(0)

  const handleToggle = () => {
    toggleModal()
    setGifKey(gifKey + 1) // Incrementar la clave �nica cada vez que se abre el modal
  }

  return (
    <ModalReactStrap isOpen={isOpen} centered size="xl">
      <ModalHeader className="border-0" toggle={handleToggle} />
      <ModalBody className="p-0">
        <div>
          <section
            id="section-1-gif"
            className="container m-0 sustainable-modal--produced--with-container"
          >
            <p>
              <span>Produced with carbon, hydrogen and chlorine, </span>
              <strong>the resin is composed of:</strong>
            </p>
            <div className="d-flex justify-content-center">
              <img
                key={gifKey}
                id="gif"
                className="animated-chart--masiala"
                src={animatedChart}
                alt="animated chart"
              />
            </div>
          </section>
          <section id="section-2-icons" className="bg-reseda-green container m-0">
            <h2 className="subtitle-sustainable-modal--masiala">Durability & Safety</h2>
            <div className="container mt-4">
              <div className="row container-sustainable-modal-imgs justify-content-between">
                <div className="d-flex justify-content-center col-4 col-lg-auto mb-3 mb-lg-6">
                  <img className="img-phthalate--masiala" src={phthalate} alt="phthalate free" />
                </div>
                <div className="d-flex justify-content-center col-4 col-lg-auto mb-3 mb-lg-6">
                  <img className="img-pfas--masiala" src={pfas} alt="phthalate free" />
                </div>
                <div className="d-flex justify-content-center col-4 col-lg-auto mb-3 mb-lg-6">
                  <img className="img-biocides--masiala" src={biocides} alt="biocides free" />
                </div>
                <div className="d-flex justify-content-center col-6 col-lg-auto">
                  <img
                    className="img-heavy-metals--masiala"
                    src={heavyMetals}
                    alt="heavy metals free"
                  />
                </div>
                <div className="d-flex justify-content-center col-6 col-lg-auto">
                  <img
                    className="img-flame-retardant--masiala"
                    src={flameRetardant}
                    alt="flame retardant additives free"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            id="section-3-recicled-bottles"
            className="container py-5 py-lg-0 pt-lg-5 m-0 sustainable-modal--recicled-bottles"
          >
            <h2 className="subtitle-section-3-sustainable-modal--masiala mb-lg-4">
              Your choice Matters
            </h2>
            <img
              className="img-fluid img-section-3"
              src={!isMobile ? section3Desktop : section3Mobile}
              alt="section-3"
            />
          </section>
        </div>
      </ModalBody>
    </ModalReactStrap>
  )
}

SustainableModal.propTypes = {
  isOpen: PropTypes.bool,
  handleToggle: PropTypes.func
}

export default SustainableModal
