import { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import StoreFinderBoxLayout from "components/StoreFinder/components/StoreFinderBoxLayout"
import { FormattedMessage } from "react-intl"
import LocationSelect from "components/StoreFinder/components/SearchOnlyByLocation/components/LocationSelect"
import { useDispatch } from "react-redux"
import { setCityId } from "components/StoreFinder/actions"
import { isMARINE } from "services/instances"

const SearchOnlyByLocation = ({ onClickNext, onClickPrevious }) => {
  const [btnIsDisabled, setBtnIsDisabled] = useState(true)
  const dispatch = useDispatch()

  const handleLocationSelectChange = (cityId) => {
    dispatch(setCityId(cityId))
    setBtnIsDisabled(false)
  }

  const handleBtnClick = () => {
    if (btnIsDisabled) return
    onClickNext()
  }

  return (
    <StoreFinderBoxLayout
      title={
        <FormattedMessage
          id="SearchByLocation.title"
          defaultMessage="Distribuidores por ubicación"
        />
      }
      icon="store"
      goBack={onClickPrevious}
    >
      <p className="mb-1">
        <FormattedMessage
          id="SearchOnlyByLocation.inputLabel1"
          defaultMessage="¿En que ciudad te encuentras?"
        />
      </p>
      <p className="mb-1">
        <FormattedMessage
          id="SearchByLocation.inputLabel2"
          defaultMessage="Ubicaciones disponibles:"
        />
      </p>
      <div className="row g-0 justify-content-between">
        <div className="col-12 col-md-9 mb-3 mb-md-0">
          <LocationSelect onChange={handleLocationSelectChange} />
        </div>
        <div className="col-auto">
          <button
            type="button"
            className={classNames({
              "btn btn-dark": true,
              "btn-prussian-blue": isMARINE()
            })}
            onClick={handleBtnClick}
            disabled={btnIsDisabled}
          >
            <FormattedMessage id="SearchByLocation.submitBtnLabel" defaultMessage="Buscar" />
          </button>
        </div>
      </div>
    </StoreFinderBoxLayout>
  )
}

SearchOnlyByLocation.propTypes = {
  onClickNext: PropTypes.func,
  onClickPrevious: PropTypes.func
}

export default SearchOnlyByLocation
