
export const PERFORMANCE_DATA = (values) => {
  return values.length === 2
    ? <>
      <span className="m-0 p-0 fs-6 fw-normal d-inline rivulet__description-title">
        {values[0]}
      </span>
      <span className="fw-light m-0 d-block rivulet__description-title" style={{ fontSize: "10px" }}>
        {values[1]}
      </span>
    </>
    : <>
      <span className="m-0 p-0 fs-6 fw-normal d-inline color-letter-rv">
        {values[0]}
      </span>
      <span className="fw-light m-0 d-block color-letter-rv" style={{ fontSize: "10px" }}>
        {values[1]}
      </span>
      <span className="fw-light m-0 d-block color-letter-rv" style={{ fontSize: "10px" }}>
        {values[2]}
      </span>
    </>
}
