import Footer from "components/Footer"
import Header from "components/Header"
import Banner from "./components/Banner"
import { GLOBAL_SITE_TAG, NAV_ITEMS, SLIDE } from "./constants"
import Video from "./components/Video"
import Breeze from "./components/Breeze"
import HowWorks from "./components/HowWorks"
import TextureSection from "./components/TextureSection"
import ContactForm from "components/ContactForm"
import { FormattedMessage } from "react-intl"
import Analytics from "components/Analytics"
import { COUNTRIES_FOR_USA } from "consts/countryCodes"
import "./styles/permacool.scss"

const Permacool = () => (
  <>
    <Analytics gtag={GLOBAL_SITE_TAG} />
    <Header items={NAV_ITEMS} />
    <div className="content">
      <Banner slides={SLIDE} align="right" />
      <Video />
      <HowWorks id={NAV_ITEMS[0]} />
      <Breeze id={NAV_ITEMS[1]} />
      <TextureSection />
      <div id={NAV_ITEMS[2]} className="bg-contact py-4">
        <ContactForm
          id={NAV_ITEMS[2]}
          theme="light"
          styleContainer="container-fluid"
          styleFormContainer="bg-form p-4"
          title={
            <FormattedMessage
              id="ContactForm.title"
              description="section title for ContactForm"
              defaultMessage="Want more information about {pattern} ?"
              values={{
                pattern: "Permacool or Breeze"
              }}
            />
          }
          countryOptions={COUNTRIES_FOR_USA}
          landingSource="permacool"
        />
      </div>
    </div>
    <Footer />
  </>
)

export default Permacool
