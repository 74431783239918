export const createPDF = async ({
  ticket,
  companyName,
  templateAdmin,
  templateClient,
  createPDFMutation
}) => {
  const fileName = `claim_${ticket}_${companyName}`

  const { data: dataOfPdfAdmin } = await createPDFMutation({
    variables: {
      input: {
        type: "Claimv2",
        html: templateAdmin,
        name: fileName
      }
    }
  })
  const { data: dataOfPdfClient } = await createPDFMutation({
    variables: {
      input: {
        type: "Claimv2",
        html: templateClient,
        name: fileName
      }
    }
  })

  if (dataOfPdfAdmin && dataOfPdfClient) {
    const {
      createPdfHtml: { pdf: urlAdmin }
    } = dataOfPdfAdmin
    const {
      createPdfHtml: { pdf: urlClient }
    } = dataOfPdfClient

    return { urlAdmin, urlClient }
  }
}
