/* eslint-disable */
import gql from "graphql-tag";

export const collectionsQuery = gql`
query quickship{
  quickshipCollections{
    edges{
      node{
        id
        name
        active
        order
        image
        imageThumb
        slug
      }
    }
  }
}
`;


export const collectionsQueryByID = gql`
query quickship2 ($slug: String) {
  quickshipCollection(slug: $slug){
    id
    name
    active
    order
    productonquickshipSet{
      edges{
        node{
          id
        	product{
            id
            color
            code
            stock
            imageThumbMedium
            imageThumbLarge
            imageForProduction
            pattern {
              name             
            }
          }
        }
      }
    }
  }
}
`;

export const filtersByCollectionID = gql`
  query GetFiltersByCollection ($quickshipCollection: ID!, $language: String!) {
    filtersByCollection(
    quickshipCollection: $quickshipCollection,
    language: $language
    )
  }
`

export const filteredProductsQuickshipPage = gql`
query filteredProductsQuickshipPage($quickshipCollection: ID!, $sectorIn: [ID], $designIn: [String],
    $waterResistance:Boolean,
    $formaldehydeFree:Boolean,
    $frFree:Boolean,
    $freeOfAntimicrobial:Boolean,
    $prop65:Boolean,
    $polycarbonateResin:Boolean,
    $ecosense:Boolean,
    $scratchResistant:Boolean,
    $cleanable:Boolean,
    $neo:Boolean,
    $ipaCleanable:Boolean,
    $bleachCleanable:Boolean,
    $ab1998Compliant:Boolean,
    $denimDyeResistant:Boolean,
    $antistatic:Boolean,
    $heatSealable:Boolean,
    $sulfideStainResistant:Boolean,
    $hydrolysis:Boolean,
    $mildewResistant:Boolean,
    $antibacterial:Boolean,
    $mpFree:Boolean,
 ) {
  quickshipProducts(
    quickshipCollection: $quickshipCollection,
    sectorIn: $sectorIn,
    designIn: $designIn,
    
    waterResistance: $waterResistance,
    formaldehydeFree: $formaldehydeFree,
    frFree: $frFree,
    freeOfAntimicrobial: $freeOfAntimicrobial,
    prop65: $prop65,
    polycarbonateResin: $polycarbonateResin,
    ecosense: $ecosense,
    scratchResistant: $scratchResistant,
    cleanable: $cleanable,
    neo: $neo,
    ipaCleanable: $ipaCleanable,
    bleachCleanable: $bleachCleanable,
    ab1998Compliant: $ab1998Compliant,
    denimDyeResistant: $denimDyeResistant,
    antistatic: $antistatic,
    heatSealable: $heatSealable,
    sulfideStainResistant: $sulfideStainResistant,
    hydrolysis: $hydrolysis,
    mildewResistant: $mildewResistant,
    antibacterial: $antibacterial,
    mpFree: $mpFree,
  ) {
    edges {
      node {
        quickshipCollection {
          id
          name
          active
        }
        product {
          id
          code
          color
          stock
          imageThumbMedium
          imageThumbLarge
          imageForProduction
          pattern {
            name
          }
        }
      }
    }
  }
}
`

export const GetCountriesQuickShipPage = gql`
  query GetCountries{
    countries {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`

export const GetCompanyTypes = gql`
  query GetCompanyTypes {
    companyTypes{
      edges{
        node{
          id
          name
          nameEn
        }
      }
    }
  }
`



export const mutationRequestFormQuickShipPage = gql`
  mutation storeAnonimalOrder($input: StoreAnonimalOrderInput!) {
    storeAnonimalOrder(input: $input) {
      success
    }
  }
`

