import PropTypes from "prop-types"
import BrandCell from "./components/BrandCell"
import { getCurrentLanguageAndInstanceCode, isLATAM, isMEXICO, isUSA } from "services/instances"

const RelatedBrandCell = ({ brand: { title, slug: brandSlug, logo, isVisible } }) => {
  const [lang, instance] = getCurrentLanguageAndInstanceCode()
  let page

  if (isLATAM() || isMEXICO()) {
    page = "marcas"
  } else if (isUSA()) {
    page = "brands"
  }

  const visibleBrandUrl = `https://spradling.group/${lang}-${instance}/${page}/${brandSlug}`

  return (
    <>
      {isVisible ? (
        <a href={visibleBrandUrl} target="_blank" rel="noreferrer">
          <BrandCell title={title} logo={logo} />
        </a>
      ) : (
        <BrandCell title={title} logo={logo} />
      )}
    </>
  )
}

RelatedBrandCell.propTypes = {
  brand: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    logo: PropTypes.string,
    isVisible: PropTypes.bool
  }).isRequired
}

export default RelatedBrandCell
