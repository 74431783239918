import { useQuery } from "@apollo/client"
import { singlePatternPublicPDFQuery } from "../query"
const useGetPublicPatternPDF = (patternId) => {
  const { data } = useQuery(singlePatternPublicPDFQuery, {
    variables: {
      id: patternId
    }
  })

  const handleDownloadPublicPDF = async () => {
    try {
      const link = document.createElement("a")
      link.href = data.pattern.pdfUrl
      link.download = "Masiala_product_details.pdf"
      link.click()

      document.body.removeChild(link)
    } catch (error) {
      console.error("Error during fetch/download operation:", error)
    }
  }

  const patternInformation = {
    id: patternId,
    productDetailsResourceUrl: data?.pattern?.pdfUrl
  }

  return {
    patternInformation,
    handleDownloadPublicPDF
  }
}

export default useGetPublicPatternPDF
