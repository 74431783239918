import { REQUEST_SAMPLES_INFO } from "modules/rivulet/components/RequestsSection/utils"
import { VALIDATION_MESSAGES } from "utils/forms"
import * as Yup from "yup"
import { COLOR_SCHEMA } from "./colorsSchemeYup"

export const GET_VALIDATION_SCHEMA = (intl, context) => {
  const validationSchema = Yup.object({
    colors: Yup.array().of(COLOR_SCHEMA).required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    firstName: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    lastName: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    companyName: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    companyType: Yup.object().nullable().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    email: Yup.string().email().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    phone: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    country: Yup.object().nullable().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    address:
      context === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
        : Yup.string(),
    zipCode:
      context === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
        : Yup.string(),
    city:
      context === REQUEST_SAMPLES_INFO
        ? Yup.string().nullable().required(intl.formatMessage(VALIDATION_MESSAGES.required))
        : Yup.string().nullable(),
    privacyPolicy: Yup.bool().oneOf([true], intl.formatMessage(VALIDATION_MESSAGES.required))
  })
  return validationSchema
}
