import { FormattedMessage } from "react-intl"
import saxonyBanner from "./img/new-saxony-banner.jpg"

export const BANNER_INFO = {
  id: "home-slide",
  imageThumb: saxonyBanner,
  title: "Saxony",
  description: "Exceptionally Soft, Stunningly Vibrant",
  buttonText: "Request Catalogue here!"
}
