import PropTypes from "prop-types"
import { useLocation, useNavigate } from "react-router-dom"

const Card = ({ id, altName, slug, imageThumbSmall }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleClick = () => {
    navigate(`..${pathname}/referencia/${slug}`)
  }

  return (
    <div className="container-reference" key={`key_${id}`} onClick={handleClick}>
      {imageThumbSmall && (
        <img className="img-fluid img-reference" src={imageThumbSmall} alt={slug} />
      )}
      <div className="pt-2 px-2">
        <h3 className="text-capitalize m-0">{altName}</h3>
      </div>
    </div>
  )
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  altName: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  imageThumbSmall: PropTypes.string.isRequired
}

export default Card
