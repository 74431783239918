import { FormattedMessage } from "react-intl"
import transporte from "../img/transporte.jpg"
import residencial from "../img/residencial.jpg"

export const ITEMS = [
  {
    id: "Transporte",
    title: (
      <FormattedMessage
        id="Pranna.Applications.transport"
        description="Pranna section applications item transport"
        defaultMessage="Transporte"
      />
    ),
    imageThumb: transporte
  },
  {
    id: "Residencial",
    title: (
      <FormattedMessage
        id="Pranna.Applications.residential"
        description="Pranna section applications item residential"
        defaultMessage="Residencial"
      />
    ),
    imageThumb: residencial
  }
]
