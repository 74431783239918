import { defineMessages } from "react-intl"
import * as yup from "yup"
import { VALIDATION_MESSAGES } from "utils/forms"
import { MATERIAL_CURRENT_STATE, MATERIAL_CURRENT_STATE_OPTIONS } from "./materialCurrentState"
import { REASONS, REASON_OPTIONS } from "./reason"

const MSGS = defineMessages({
  originalCondition_quantityClimedMaterial: {
    id: "Claims.OriginalCondition.quantityClimedMaterial",
    description: "field quantity climed material of section original condition of Claims form",
    defaultMessage: "Please indicate the quantity of the claimed material"
  },
  originalCondition_product: {
    id: "Claims.OriginalCondition.product",
    description: "field product of section original condition of Claims form",
    defaultMessage: "Please indicate the Product"
  },
  originalCondition_productDescription: {
    id: "Claims.OriginalCondition.product.description",
    description: "field product description of section original condition of Claims form",
    defaultMessage: "Can't find your product? Scroll to the end and choose Other"
  },
  originalCondition_color: {
    id: "Claims.OriginalCondition.color",
    description: "field color of section original condition of Claims form",
    defaultMessage: "Please indicate the Color"
  },
  batch: {
    id: "Claims.OriginalCondition.batch",
    description: "field batch of section original condition of Claims form",
    defaultMessage:
      "Please indicate the Batch (indicated on the original roll label or printed on the textile backing of the material and commencing with PT or CR)"
  },
  batchDescription: {
    id: "Claims.OriginalCondition.batch.description",
    description: "field batch description of section original condition of Claims form",
    defaultMessage: "if there is more than one batch affected add its separated by comma"
  },
  originalCondition_materialCurrentState: {
    id: "Claims.OriginalCondition.materialCurrentState",
    description: "field material current state of Claims form",
    defaultMessage: "Has the material already been transformed or installed?"
  },
  originalCondition_reason: {
    id: "Claims.OriginalCondition.reason.option",
    description: "select reason of section original condition of Claims form",
    defaultMessage: "Claim reason"
  }
})

export const PRODUCT_MSGS = defineMessages({
  discontinued: {
    id: "Claims.OriginalCondition.color.product.discontinued",
    description: "field color descontinued of section original condition of Claims form",
    defaultMessage: "{color} (Discontinued)"
  }
})

const DEFAULT_SCHEMA = (intl) => ({
  quantity: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  batch: yup
    .string()
    .required(intl.formatMessage(VALIDATION_MESSAGES.required))
    .matches(/((PT|pt|CR|cr)\d{6},?)/, intl.formatMessage(VALIDATION_MESSAGES.batch)),
  conditions: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  reasons: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
})

export const PRODUCT_BY_API_SCHEMA = (intl) => ({
  patternId: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  productId: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  ...DEFAULT_SCHEMA(intl)
})

export const PRODUCT_BY_TEXT_SCHEMA = (intl) => ({
  patternId: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  otherColor: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  otherProduct: yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
  ...DEFAULT_SCHEMA(intl)
})

export { MSGS, MATERIAL_CURRENT_STATE, MATERIAL_CURRENT_STATE_OPTIONS, REASONS, REASON_OPTIONS }
