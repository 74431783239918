import { defineMessages } from "react-intl"

export const MATERIAL_RESISTANCE_MSGS = defineMessages({
  adequateProductResistance: {
    id: "Claims.materialResitance.adequateProductResistance",
    description: "field adequate product resistance of material resitance",
    defaultMessage:
      "What type of resistance is in not in conformity with the product specification / quality?"
  },
  ifOtherDescription: {
    id: "Claims.materialResitance.ifOtherDescription",
    description: "field if other description of material resitance",
    defaultMessage: "If other description"
  },
  knowTheRecomendations: {
    id: "Claims.materialResitance.knowTheRecomendations",
    description: "field know the recomendations of material resitance",
    defaultMessage:
      "Are you aware of the manufacturer recommendations and/or precautions as per material specification?"
  },
  howDetectedNonConformity: {
    id: "Claims.materialResitance.howDetectedNonConformity",
    description: "field how detected non-conformity of material resitance",
    defaultMessage: "How is the non-conformity detected and measured?"
  },
  howItTransforms: {
    id: "Claims.materialResitance.howItTransforms",
    description: "field how it transforms of material resitance",
    defaultMessage:
      "How is the material used/transformed in the process? (E.g. Is the material in contact with or attached to another surface? What type of glue is used? What kind of needle is used for stitching purposes? How many stitches per inch are made? Etc.)"
  }
})

const ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS = defineMessages({
  crocking: {
    id: "Claims.materialResitance.adequateProductResistance.option.crocking",
    description: "option crocking of field adequate product resistance",
    defaultMessage: "Crocking"
  },
  shrinkage: {
    id: "Claims.materialResitance.adequateProductResistance.option.shrinkage",
    description: "option shrinkage of field adequate product resistance",
    defaultMessage: "Shrinkage"
  },
  elongation: {
    id: "Claims.materialResitance.adequateProductResistance.option.elongation",
    description: "option elongation of field adequate product resistance",
    defaultMessage: "Elongation"
  },
  tearingStrength: {
    id: "Claims.materialResitance.adequateProductResistance.option.tearingStrength",
    description: "option tearing strength of field adequate product resistance",
    defaultMessage: "Tearing strength"
  },
  stitchingStrength: {
    id: "Claims.materialResitance.adequateProductResistance.option.stitchingStrength",
    description: "option stitching strength of field adequate product resistance",
    defaultMessage: "Stitching strength"
  },
  adhesion: {
    id: "Claims.materialResitance.adequateProductResistance.option.adhesion",
    description: "option adhesion of field adequate product resistance",
    defaultMessage: "Adhesion"
  },
  uvResistance: {
    id: "Claims.materialResitance.adequateProductResistance.option.uvResistance",
    description: "option uv resistance of field adequate product resistance",
    defaultMessage: "UV resistance"
  },
  coldCrack: {
    id: "Claims.materialResitance.adequateProductResistance.option.coldCrack",
    description: "option cold crack of field adequate product resistance",
    defaultMessage: "Cold crack"
  },
  abrasionResistance: {
    id: "Claims.materialResitance.adequateProductResistance.option.abrasionResistance",
    description: "option abrasion resistance of field adequate product resistance",
    defaultMessage: "Abrasion resistance"
  },
  flameRetardancy: {
    id: "Claims.materialResitance.adequateProductResistance.option.flameRetardancy",
    description: "option flame resistance of field adequate product resistance",
    defaultMessage: "Flame retardancy. Please specify standard below:"
  },
  other: {
    id: "Claims.options.other",
    description: "option other on claim",
    defaultMessage: "Other"
  }
})

export const ADEQUATE_PRODUCT_RESISTANCE_OPTIONS = [
  {
    id: "crocking",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.crocking,
    value: "crocking"
  },
  {
    id: "shrinkage",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.shrinkage,
    value: "shrinkage"
  },
  {
    id: "elongation",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.elongation,
    value: "elongation"
  },
  {
    id: "tearingStrength",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.tearingStrength,
    value: "tearing strength"
  },
  {
    id: "stitchingStrength",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.stitchingStrength,
    value: "stitching strength"
  },
  {
    id: "adhesion",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.adhesion,
    value: "adhesion"
  },
  {
    id: "uvResistance",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.uvResistance,
    value: "uv resistance"
  },
  {
    id: "coldCrack",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.coldCrack,
    value: "cold crack"
  },
  {
    id: "abrasionResistance",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.abrasionResistance,
    value: "abrasion resistance"
  },
  {
    id: "flameRetardancy",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.flameRetardancy,
    value: "flame retardancy"
  },
  {
    id: "other",
    label: ADEQUATE_PRODUCT_RESISTANCE_OPTIONS_MSGS.other,
    value: "other"
  }
]
