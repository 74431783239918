import PropTypes from "prop-types"
import "./styles/product-information-detail.scss"
import classNames from "classnames/bind"

const ProductInformationDetail = ({ details }) => (
  <div>
    <table className="col-12 color-letter-rv">
      {details.info.map(({ id, description, value }) => (
        <tr key={`key_${id}`} className="info">
          {description && (
            <td className="col-6 text-capitalize">{description}</td>
          )}
          <td className={classNames({
            "col-6": description && value,
            "col-12": !description
          })}>{value}</td>
        </tr>
      ))}
    </table>
    {details?.disclaimer && details.disclaimer.length > 0 && (
      <div className="info">
        {details.disclaimer.map(({ id, value }) => (
          <p key={`key_${id}`} className="disclaimer">
            {value}
          </p>
        ))}
      </div>
    )}
  </div>
)

ProductInformationDetail.propTypes = {
  details: PropTypes.array.isRequired
}

export default ProductInformationDetail
