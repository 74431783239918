import { getConditionTemplate } from "modules/claims/components/StepForm/Step6/constants/conditionsTemplates"
import { getReasonTemplate } from "modules/claims/components/StepForm/Step6/constants/reasonsTemplates"

const emailBody = (values) => {
  const conditionsTemplateToShow = getConditionTemplate(values)
  const reasonTemplateToShow = getReasonTemplate(values)
  const {
    batch,
    conditions,
    companyName,
    customerNumber,
    email,
    fullName,
    quantity,
    ticket,
    productName,
    productColor,
    uploadedZip
  } = values

  const templateAdmin = `<!DOCTYPE html>
  <html lang="en">
  
  <head xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Template Email Claim</title>
    <style>
      @font-face {
        font-family: "Dala Moa";
        src: url("https://d39k3whfafgdlj.cloudfront.net/DalaMoa-Medium.woff2") format("woff2"),
        url("https://d39k3whfafgdlj.cloudfront.net/DalaMoa-Medium.woff") format("woff");
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: "Gill Sans";
        src: url("https://d39k3whfafgdlj.cloudfront.net/GillSans-Light.woff2") format("woff2"),
        url("https://d39k3whfafgdlj.cloudfront.net/GillSans-Light.woff") format("woff");
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: "Gill Sans bold";
        src: url("https://d39k3whfafgdlj.cloudfront.net/GillSans-SemiBold.woff2") format("woff2"),
        url("https://d39k3whfafgdlj.cloudfront.net/GillSans-SemiBold.woff") format("woff");
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: "Gill Sans regular";
        src: url("https://d39k3whfafgdlj.cloudfront.net/GillSans.woff2") format("woff2"),
        url("https://d39k3whfafgdlj.cloudfront.net/GillSans.woff") format("woff");
        font-weight: 500;
        font-style: normal;
      }
    </style>
    <style>
      body {
        padding: 0;
        margin: 0;
      }
  
      .main-title-container,
      .main-body-container {
        padding-left: 70px;
        padding-right: 70px;
      }
  
      .product-info-left-section {
        width: 20%;
      }
  
      .product-information-container {
        width: 80%;
        padding: 40px 50px;
        height: auto;
      }
  
      .quantity-text,
      .batch-text {
        width: 350px;
      }
  
      .additional-information {
        width: 450px;
      }
  
      .email-spradling {
        font-size: 3rem;
      }
  
      .property {
        font-family: "Gill Sans regular";
        font-weight: bold;
      }
  
      p {
        font-size: 15px;
        font-family: "Gill Sans";
        margin-top: 15px;
        margin-bottom: 0;
        line-height: 1.2rem;
      }
  
      h2 {
        font-family: "Dala Moa";
        font-size: 2rem;
      }
  
      .spacing-column {
        margin-left: 50px;
      }
  
      .details-product {
        display: flex;
        flex-wrap: wrap;
        height: 300px;
      }
    </style>
  
  </head>
  
  <body>
  
    <div class="main-body-container">
      <h2 style="
          margin-top: 70px;
          font-size: 2rem;
          font-weight: bold;
          font-family: 'Dala Moa';  
        ">
        Claim Registration</h2>
      <p style="font-size: 1.2rem; margin-top: 40px; font-family: Gill Sans bold;">
        Dear Valued Customer</p>  
      <p style="font-family: Gill Sans;">To download uploaded evidences 
        <a style="font-family: Gill Sans;" href="${uploadedZip}" target="_blank">
        Click Here!
        </a>
      </p>
      <p style="font-family: Gill Sans;">Excel Claims:
        <a href="https://proquinal-my.sharepoint.com/:x:/r/personal/juand_coronado_spradling_group/Documents/CLAIMS/Claims.xlsx?d=w339f98fe2e454641bee2d267d3f1dcd4&csf=1&web=1&e=Z3FWvs" target="_blank" style="font-family: Gill Sans;">
        Click Here!
        </a>
      </p>
  
      <hr style="
          width: 95%;
          margin-left: 0;
          margin-top: 40px;
        "></hr>
  
      <div class="product-information-container" style="
          background-color: #DDDDDD;
          margin-top: 40px;
          height: auto;
        ">
        <h2 style="margin: 0; margin-bottom: 40px;">1. Contact Details</h2>
        <table style="margin-bottom: 20px;">
          <tr>
            <p>Ticket:</p>
          </tr>
          <tr>
            <p class="property" style="font-weight: bold;">${ticket}</p>
          </tr>
          <tr>
            <td style="width: 350px;"><p>Name and Last Name</p></td>
            <td><p class="spacing-column">Your Customer Number</p></td>
            </td>
          </tr>
          <tr>
            <td><p class="property">${fullName}</p></td>
            <td><p class="spacing-column property">${customerNumber}</p></td>
            </td>
          </tr>
          <tr>
            <td><p>Company Name</p></td>
            <td><p class="spacing-column">Email</p></td>
            </td>
          </tr>
          <tr>
            <td><p class="property">${companyName}</p></td>
            <td><p class="spacing-column property">${email}</p></td>
            </td>
          </tr>
        </table>
      </div>

      <hr style="
          width: 95%;
          margin-left: 0;
          margin-top: 40px;
        "></hr>
  
      <div class="product-information-container" style="
          background-color: #DDDDDD;
          margin-top: 40px;
          height: auto;
        ">
        <h2 style="margin: 0;">2. Material Details</h2>
        <table style="margin-top: 20px; margin-bottom: 20px;">
          <tr>
            <td style="width: 350px;"><p>Name & colour of the claimed product</p></td>
            <td><p class="spacing-column">Condition</p></td>
            </td>
          </tr>
          <tr>
            <td><p class="property">${productName} - ${productColor}</p></td>
            <td><p class="spacing-column property">${conditions}</p></td>
            </td>
          </tr>
          <tr>
            <td><p>Quantity of the claimed product or <br> affected pieces</p></td>
            <td><p class="spacing-column">Production Batch No. <br> of the material</p></td>
            </td>
          </tr>
          <tr>
            <td><p class="property">${quantity}</p></td>
            <td><p class="spacing-column property">${batch}</p></td>
            </td>
          </tr>
        </table>
      </div>

      <hr style="
          width: 95%;
          margin-left: 0;
          margin-top: 40px;
          margin-bottom: 40px;
        "></hr>
  
      ${conditionsTemplateToShow} ${reasonTemplateToShow}     
  
      <p class="additional-information" style="font-family: Gill Sans">
        For additional information please contact us through<br> our website or your sales representative.</p>
  
      <h1 class="email-spradling" style="
          margin-top: 80px;
          margin-bottom: 150px;
          font-family: 'Dala Moa';
          font-size: 2rem;
        ">www.spradling.eu</h1>
  
    </div>
  </body>
  </html>`

  const templateClient = `<!DOCTYPE html>
  <html lang="en">
  
  <head xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Template Email Claim</title>
    <style>
      @font-face {
        font-family: "Dala Moa";
        src: url("https://d39k3whfafgdlj.cloudfront.net/DalaMoa-Medium.woff2") format("woff2"),
        url("https://d39k3whfafgdlj.cloudfront.net/DalaMoa-Medium.woff") format("woff");
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: "Gill Sans";
        src: url("https://d39k3whfafgdlj.cloudfront.net/GillSans-Light.woff2") format("woff2"),
        url("https://d39k3whfafgdlj.cloudfront.net/GillSans-Light.woff") format("woff");
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: "Gill Sans bold";
        src: url("https://d39k3whfafgdlj.cloudfront.net/GillSans-SemiBold.woff2") format("woff2"),
        url("https://d39k3whfafgdlj.cloudfront.net/GillSans-SemiBold.woff") format("woff");
        font-weight: 500;
        font-style: normal;
      }
      @font-face {
        font-family: "Gill Sans regular";
        src: url("https://d39k3whfafgdlj.cloudfront.net/GillSans.woff2") format("woff2"),
        url("https://d39k3whfafgdlj.cloudfront.net/GillSans.woff") format("woff");
        font-weight: 500;
        font-style: normal;
      }
    </style>
    <style>
      body {
        padding: 0;
        margin: 0;
      }
  
      .main-title-container,
      .main-body-container {
        padding-left: 70px;
        padding-right: 70px;
      }
  
      .product-info-left-section {
        width: 20%;
      }
  
      .product-information-container {
        width: 80%;
        padding: 40px 50px;
        height: auto;
      }
  
      .quantity-text,
      .batch-text {
        width: 350px;
      }
  
      .additional-information {
        width: 450px;
      }
  
      .email-spradling {
        font-size: 3rem;
      }
  
      .property {
        font-family: "Gill Sans regular";
        font-weight: bold;
      }
  
      p {
        font-size: 15px;
        font-family: "Gill Sans";
        margin-top: 15px;
        margin-bottom: 0;
        line-height: 1.2rem;
      }
  
      h2 {
        font-family: "Dala Moa";
        font-size: 2rem;
      }
  
      .spacing-column {
        margin-left: 50px;
      }
  
      .details-product {
        display: flex;
        flex-wrap: wrap;
        height: 300px;
      }
    </style>
  
  </head>
  
  <body>
  
    <div class="main-body-container">
      <h2 style="
          margin-top: 70px;
          font-size: 2rem;
          font-weight: bold;
          font-family: 'Dala Moa';  
        ">
        Claim Registration</h2>
      <p style="font-size: 1.2rem; margin-top: 40px; font-family: Gill Sans bold;">
        Dear Valued Customer:</p>
      <p 
        style="
          width: 92%;
          margin-top: 30px;
          font-family: Gill Sans;
        "
      >
        Your claim has been submitted and will be processed. Please allow 5-10 working days for an initial feedback. A Customer Service Representative will contact you.
        <br/>
        Your claim reference number is: ${ticket}
        <br/>
        Please indicate this number in all correspondence related with this claim.
        <br/>
        A copy of your claim registration is attached for your record.
      </p>

      <p style="font-family: Gill Sans;">To download your uploaded evidences:
        <a style="font-family: Gill Sans;" href="${uploadedZip}" target="_blank">
        Click Here!
        </a>
      </p>
  
      <hr style="
          width: 95%;
          margin-left: 0;
          margin-top: 40px;
        "></hr>
  
      <div class="product-information-container" style="
          background-color: #DDDDDD;
          margin-top: 40px;
          height: auto;
        ">
        <h2 style="margin: 0; margin-bottom: 40px;">1. Contact Details</h2>
        <table style="margin-bottom: 20px;">
          <tr>
            <p>Ticket:</p>
          </tr>
          <tr>
            <p class="property" style="font-weight: bold;">${ticket}</p>
          </tr>
          <tr>
            <td style="width: 350px;"><p>Name and Last Name</p></td>
            <td><p class="spacing-column">Your Customer Number</p></td>
            </td>
          </tr>
          <tr>
            <td><p class="property">${fullName}</p></td>
            <td><p class="spacing-column property">${customerNumber}</p></td>
            </td>
          </tr>
          <tr>
            <td><p>Company Name</p></td>
            <td><p class="spacing-column">Email</p></td>
            </td>
          </tr>
          <tr>
            <td><p class="property">${companyName}</p></td>
            <td><p class="spacing-column property">${email}</p></td>
            </td>
          </tr>
        </table>
      </div>

      <hr style="
          width: 95%;
          margin-left: 0;
          margin-top: 40px;
        "></hr>
  
      <div class="product-information-container" style="
          background-color: #DDDDDD;
          margin-top: 40px;
          height: auto;
        ">
        <h2 style="margin: 0;">2. Material Details</h2>
        <table style="margin-top: 20px; margin-bottom: 20px;">
          <tr>
            <td style="width: 350px;"><p>Name & colour of the claimed product</p></td>
            <td><p class="spacing-column">Condition</p></td>
            </td>
          </tr>
          <tr>
            <td><p class="property">${productName} - ${productColor}</p></td>
            <td><p class="spacing-column property">${conditions}</p></td>
            </td>
          </tr>
          <tr>
            <td><p>Quantity of the claimed product or <br> affected pieces</p></td>
            <td><p class="spacing-column">Production Batch No. <br> of the material</p></td>
            </td>
          </tr>
          <tr>
            <td><p class="property">${quantity}</p></td>
            <td><p class="spacing-column property">${batch}</p></td>
            </td>
          </tr>
        </table>
      </div>

      <hr style="
          width: 95%;
          margin-left: 0;
          margin-top: 40px;
          margin-bottom: 40px;
        "></hr>
  
      ${conditionsTemplateToShow} ${reasonTemplateToShow}     
  
      <p class="additional-information" style="font-family: Gill Sans">
        For additional information please contact us through<br> our website or your sales representative.</p>
  
      <h1 class="email-spradling" style="
          margin-top: 80px;
          margin-bottom: 150px;
          font-family: 'Dala Moa';
          font-size: 2rem;
        ">www.spradling.eu</h1>
  
    </div>
  </body>
  </html>`

  return { templateAdmin, templateClient }
}

export default emailBody
