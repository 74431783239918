import PropTypes from "prop-types"
import DoAndDoNotList from "../../../DoAndDoNotList"
import "./styles/do-and-do-not-for-desktop.scss"
import CleaningProcedure from "../../../CleaningProcedure"
import KeepInMind from "../../../KeepInMind"
import TriangleIndicatorSectionSelected from "./components/TriangleIndicatorSectionSelected"

const LayoutForDesktop = ({ sections, objSections, sectionId, handleChange, sectionIdToScroll, handleChangeSectionId }) => {
  const updateSectionAndSectionIdToHeader = (id) => {
    handleChange(id)
    handleChangeSectionId({
      id: id,
      clickInHeader: false
    })
  }

  return <>
    <section className="container g-0 pt-5">
      <nav>
        <ul className="d-flex justify-content-center p-0">
          {sections?.map(({ id, img, titleDesktop }) => (
            <li
              key={`key_${id}`}
              className="item-container--cleaning-sm--general grow-on-hover"
              onClick={() => updateSectionAndSectionIdToHeader(id)}
            >
              <img className="img-fluid h-100 w-100" src={img} alt={titleDesktop} />
              <div className="item-title--cleaning-sm--general">
                <p className=" text-center">{titleDesktop}</p>
              </div>
            </li>
          ))}
        </ul>
      </nav>
      <TriangleIndicatorSectionSelected sectionId={sectionId} />
      <DoAndDoNotList id={sectionIdToScroll} section={objSections[sectionId]?.doAndDoNots} />
    </section>
    {objSections[sectionId]?.cleaningProcedures && (
      <CleaningProcedure section={objSections[sectionId]?.cleaningProcedures} />
    )}
    {objSections[sectionId]?.keepInMind && <KeepInMind {...objSections[sectionId]?.keepInMind} />}
  </>
}

LayoutForDesktop.propTypes = {
  sections: PropTypes.array.isRequired,
  objSections: PropTypes.object.isRequired,
  sectionId: PropTypes.string.isRequired,
  sectionIdToScroll: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleChangeSectionId: PropTypes.func
}

export default LayoutForDesktop
