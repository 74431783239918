import { FormattedMessage } from "react-intl"
import banner from "../img/banner-test.jpg"
import ContactForm from "components/ContactForm"
import { COUNTRY_COLOMBIA } from "consts/countryCodes"
import "../styles/banner.scss"

export const SLIDE = [
  {
    id: "slide-vestuario-y-proteccion",
    slideFormClassName: "home-slide__form-vestuario",
    imageThumb: banner,
    titleClassName: "font-gill-sans",
    title: (
      <FormattedMessage
        id="VestuarioYProteccion.Banner.title"
        description="Vestuario Y Proteccion title banner"
        defaultMessage="Telas 100% impermeables"
      />
    ),
    description: (
      <FormattedMessage
        id="VestuarioYProteccion.Banner.description"
        description="Vestuario Y Proteccion description banner"
        defaultMessage="Aptas para la confección de prendas de protección"
      />
    ),
    form: (
      <ContactForm
        theme="light"
        styleContainer="container-fluid upholstery-glassmorphism my-3"
        styleFormContainer="p-4"
        title={
          <FormattedMessage
            id="Pranna.Contact.title"
            description="Floors section title contact"
            defaultMessage="¿ Quieres más información ?"
          />
        }
        countryOptions={COUNTRY_COLOMBIA}
        landingSource="vestuario-y-proteccion"
        isBasic
      />
    )
  }
]
