/* eslint-disable indent */
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

const Markets = ({ pattern }) => {
  const markets = pattern
    ? pattern.sectors.edges
        .map((sector) => ({
          name: sector.node.market.name,
          sector: sector.node.name
        }))
        .reduce((r, a) => {
          r[a.name] = r[a.name] || []
          r[a.name].push(a)
          return r
        }, Object.create(null))
    : {
        dummy: new Array(5).fill()
      }

  return (
    <div className="product-markets">
      <h2 className="h5 text-family-base">
        <FormattedMessage
          id="PatternMarkets.title"
          description="Title of sector list in Product details page"
          defaultMessage="Sectores"
        />
      </h2>
      {Object.keys(markets).map((key, index) => (
        <div key={index} className="product-markets__market-name">
          {pattern ? key : <span />}
          <hr />
          <ul className="product-markets__sector-lists">
            {markets[key].map((market, index) => (
              <li key={index}>{pattern ? market.sector : <span />}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

Markets.propTypes = {
  pattern: PropTypes.object.isRequired
}

export default Markets
