import PropTypes from "prop-types"
import { SETTINGS_CAROUSEL } from "modules/rivulet/constants/sliderOptions"
import { FormattedMessage } from "react-intl"
import Slider from "react-slick"
import ColorSample from "./components/ColorSample"
import { COLORS_INSPIRATION_SAMPLES } from "./constants"
import { Link } from "react-scroll"
import { NAV_3 } from "modules/rivulet/constants"
import useMediaQuery from "hooks/useMediaQuery"
import { LG } from "consts/breakpoints"
import "./styles/color-inspiration.scss"

const ColorInspirationSection = ({ id }) => {
  const isMobile = useMediaQuery(LG)

  return (
    <section id={id} className="bg-light py-lg-4">
      <div className="container g-lg-0">
        <div className="row g-lg-0">
          <div className="col">
            <h2 className="title--color-insp-rv">
              <FormattedMessage
                id="Rivulet.ColorInspiration.title"
                defaultMessage="Color Inspiration"
              />
            </h2>
            <div className="slider__main-container">
              <Slider {...SETTINGS_CAROUSEL(isMobile)}>
                {COLORS_INSPIRATION_SAMPLES.map(
                  ({
                    inspirationColorTitle,
                    inspirationColorDescription,
                    inspirationColorImage,
                    demostrationSamples
                  }) => (
                    <ColorSample
                      key={`${inspirationColorTitle}__${inspirationColorDescription}`}
                      title={inspirationColorTitle}
                      description={inspirationColorDescription}
                      inspirationImage={inspirationColorImage}
                      samples={demostrationSamples}
                    />
                  )
                )}
              </Slider>
              <div className="text-center mt-1 mb-5 mt-md-4 mb-md-4 mt-lg-3 mb-lg-3">
                <Link to={NAV_3}>
                  <button className="btn btn-rv btn--desc-rv">
                    <FormattedMessage
                      id="Riuvlet.ColorInspiration.buttonText"
                      defaultMessage="See All Colors"
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ColorInspirationSection.propTypes = {
  id: PropTypes.string.isRequired
}

export default ColorInspirationSection
