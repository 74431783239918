import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import { Controller } from "react-hook-form"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import ErrorMessage from "components/ErrorMessage"
import "./styles/date-input.scss"

const DateInput = ({
  name,
  control,
  label,
  defaultValue,
  readOnly = false,
  disabled = false,
  error
}) => {
  const intl = useIntl()

  return (
    <div>
      <label htmlFor={name} className="step-form--label pb-2">
        {intl.formatMessage(label)}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange: handleOnChange, value, ...field } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              desktopModeMediaQuery={"pointer"}
              views={["year", "month", "day"]}
              {...field}
              value={value && dayjs(value)}
              readOnly={readOnly}
              disabled={disabled}
              onChange={(e) => handleOnChange(e)}
              format="DD-MM-YYYY"
            />
          </LocalizationProvider>
        )}
      />
      <ErrorMessage weight="normal">{error}</ErrorMessage>
    </div>
  )
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  placeholder: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string
}

export default DateInput
