import Analytics from "components/Analytics"
import Footer from "components/Footer"
import Header from "components/Header"
import { COUNTRIES_FOR_LATAM } from "consts/countryCodes"
import { FormattedMessage } from "react-intl"
import Breeze from "./components/Breeze"
import ContactForm from "./components/ContactForm"
import HowWorks from "./components/HowWorks"
import Video from "./components/Video"
import { GLOBAL_SITE_TAG, NAV_ITEMS, SLIDE } from "./constants"
import "./styles/prannatex.scss"
import Banner from "./components/Banner"

const Prannatex = () => (
  <>
    <Analytics gtag={GLOBAL_SITE_TAG} />
    <Header items={NAV_ITEMS} />
    <div className="content">
      <Banner slides={SLIDE} align="center" />
      <Video />
      <HowWorks id={NAV_ITEMS[0]} />
      <Breeze />
      <div id={NAV_ITEMS[1]} className="container py-4">
        <ContactForm
          id={NAV_ITEMS[1]}
          theme="default"
          styleContainer="container-fluid"
          styleFormContainer="p-4"
          title={
            <FormattedMessage
              id="ContactForm.title"
              description="section title for ContactForm"
              defaultMessage="Want more information about {pattern} ?"
              values={{
                pattern: "Pranna®Tex"
              }}
            />
          }
          countryOptions={COUNTRIES_FOR_LATAM}
          landingSource="prannatex"
        />
      </div>
    </div>
    <Footer />
  </>
)

export default Prannatex
