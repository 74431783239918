import Footer from "components/Footer"
import Banner from "./components/Banner"
import SamplesDetails from "./components/SamplesDetails"
import Video from "./components/Video"
import { FOOTER_ITEMS, NAV_0, NAV_1, NAV_2, SAXONY_GTAG } from "./constants"
import "./styles/rivulet.scss"
import useRequestModal from "./hooks/useRequestModal"
import RequestsSection from "./components/RequestsSection"
import Analytics from "components/Analytics"
import useAccordion from "./hooks/useAccordion"
import SaxonyDescription from "./components/SaxonyDescription"
import Header from "./components/Header"
import SaxonyCharacteristics from "./components/SaxonyCharacteristics"

const Saxony = () => {
  const { toggable: toggableAccordeon, handleToggle: handleToggleAccordeon } = useAccordion()

  const {
    modalContext,
    toggable,
    toggle,
    handleClickInRequest,
    handleAddSample,
    handleOrderCatalogue
  } = useRequestModal()

  const NAV_OBJ_ITEMS = [
    {
      id: NAV_0,
      text: NAV_0,
      href: NAV_0
    },
    {
      id: NAV_1,
      text: NAV_1,
      href: NAV_1
    },
    {
      id: NAV_2,
      text: NAV_2,
      href: NAV_1
    }
  ]

  return (
    <>
      <Analytics gtag={SAXONY_GTAG} />
      <Header className="header-background--rv" objItems={NAV_OBJ_ITEMS} />
      <Banner handleOrderCatalogue={handleOrderCatalogue} />
      <SaxonyDescription />
      <Video />
      <SaxonyCharacteristics id={NAV_OBJ_ITEMS[0].id} />
      <SamplesDetails
        nav={NAV_OBJ_ITEMS[1].id}
        handleClickInRequest={handleClickInRequest}
        handleAddSample={handleAddSample}
        handleOrderCatalogue={handleOrderCatalogue}
        toggableAccordeon={toggableAccordeon}
        handleToggleAccordeon={handleToggleAccordeon}
      />
      <Footer className="footer-background--rv" hasItems items={FOOTER_ITEMS} />
      <RequestsSection modalContext={modalContext} toggable={toggable} toggle={toggle} />
    </>
  )
}

export default Saxony
