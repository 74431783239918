import { FormattedMessage } from "react-intl"

export const MODAL_TYPES = {
  REQUEST_SAMPLES: "REQUEST_SAMPLES"
}

const MODAL_TYPES_THANKS_URL = {
  REQUEST_SAMPLES: "request-samples"
}

export const REQUEST_SAMPLES_INFO = {
  id: MODAL_TYPES.REQUEST_SAMPLES,
  type: MODAL_TYPES.REQUEST_SAMPLES,
  thanksUrl: MODAL_TYPES_THANKS_URL.REQUEST_SAMPLES,
  title: "Samples Request",
  subtitle: "Request the samples of Saxony"
}
