import classNames from "classnames"
import "./assets/styles/footer.scss"
import spradlingMarineLogo from "./assets/img/logo-spradling-marine.png"
import { FormattedMessage } from "react-intl"

const Footer = () => (
  <footer
    className={classNames({
      "text-light": true,
      "footer-cleaning-guide": true
    })}
  >
    <div className="container">
      <div className="row align-items-center justify-content-between py-3">
        <div className="col-12 col-md-8 order-md-1">
          <ul className="row text-center text-md-end container-cleaning-produre footer-sections mb-0 p-0">
            <li className="col-12 col-md-12 py-2">
              <span
                className={classNames({
                  "m-0 fs-4": true,
                  "font-color-footer": true
                })}
              >
                <FormattedMessage
                  id="CleaningMarine.footer.info"
                  defaultMessage="Learn more about marine products <a>here</a>"
                  values={{
                    a: (...chunks) => (
                      <a
                        className="ms-2 fs-3 cleaning-sm-footer-link"
                        href="https://spradling.group/en-sm"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontWeight: "600" }}
                      >
                        {chunks}
                      </a>
                    )
                  }}
                />
              </span>
            </li>
          </ul>
        </div>
        <div className="col col-md-2 m-2 ms-3 order-md-0">
          <div className="logo-section">
            <img
              className="logo-instance"
              src={spradlingMarineLogo}
              alt="Spradling Group"
              title="Spradling Group"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
