import { isUSA } from "services/instances"

export const NAV_ITEMS = [
  {
    title: isUSA() ? "colors" : "colores"
  },
  {
    title: isUSA() ? "applications" : "aplicaciones"
  },
  {
    title: isUSA() ? "features" : "características"
  },
  {
    title: isUSA() ? "contact" : "contacto"
  }
]

export const GLOBAL_SITE_TAG = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "GTM-N8WW2G9",
  [process.env.REACT_APP_MX_INSTANCE_ID]: "GTM-N8WW2G9"
}

export const CONTACT_EVENT_TRACKING_DATA = {
  category: "Click",
  Accion: "Click en botón",
  label: "Contáctanos"
}
