import { FormattedMessage } from "react-intl"
import permablokSvg from "../imgs/permablok.svg"
import ApplicationsElements from "../components/ApplicationsElements"
import SustainableElements from "../components/SustainableElements"

const SUSTAINABLE_PRODUCT_PROFILE = "SUSTAINABLE_PRODUCT_PROFILE"
const CHARACTERISTICS = "CHARACTERISTICS"
const APPLICATIONS = "APPLICATIONS"
const PERFORMANCE = "PERFORMANCE"
const FLAME_RETARDANCY = "FLAME_RETARDANCY"
const WARRANTY = "WARRANTY"
export const DIGITAL_CATALOGUE = "DIGITAL_CATALOGUE"
export const CARE_AND_CLEANING = "CARE_AND_CLEANING"
export const PRODUCT_DETAILS = "PRODUCT_DETAILS"
const PASS = "Pass"

export const PRODUCT_SPECS = {
  [SUSTAINABLE_PRODUCT_PROFILE]: (
    <FormattedMessage
      id="Masiala.Spec.SustainableProductProfile"
      defaultMessage="Sustainable Product Profile"
    />
  ),
  [CHARACTERISTICS]: (
    <FormattedMessage id="Rivulet.specCharacteristics" defaultMessage="Characteristics" />
  ),
  [APPLICATIONS]: <FormattedMessage id="Rivulet.specApplications" defaultMessage="Applications" />,
  [PERFORMANCE]: <FormattedMessage id="Rivulet.specPerformance" defaultMessage="Performance" />,
  [FLAME_RETARDANCY]: (
    <FormattedMessage id="Rivulet.specFlameRetardancy" defaultMessage="Flame Retardancy" />
  ),
  [WARRANTY]: <FormattedMessage id="Rivulet.specWarranty" defaultMessage="Warranty" />,
  [DIGITAL_CATALOGUE]: (
    <FormattedMessage id="Masiala.Spec.DigitalCatalogue" defaultMessage="Digital Catalogue" />
  ),
  [CARE_AND_CLEANING]: (
    <FormattedMessage id="Masiala.Spec.CareCleaning" defaultMessage="Care & Cleaning" />
  ),
  [PRODUCT_DETAILS]: (
    <FormattedMessage id="Masiala.Spec.ProductDetail" defaultMessage="Product Detail" />
  )
}

export const productSpecDetailsInformationToShow = (isMobile = false) => {
  const PRODUCT_SPECS_DETAIL_INFORMATION = {
    [SUSTAINABLE_PRODUCT_PROFILE]: {
      info: [
        {
          id: "Masiala.Spec.SustainableProductProfile",
          value: <SustainableElements />
        }
      ]
    },
    [CHARACTERISTICS]: {
      info: [
        {
          id: "Masiala.Spec.Characteristics.composition",
          description: (
            <FormattedMessage
              id="Masiala.Spec.Characteristics.composition"
              defaultMessage="Composition"
            />
          ),
          value: "+50% bio-attributed polymer / urethane topcoat"
        },
        {
          id: "Masiala.Spec.Characteristics.specifics",
          description: (
            <strong>
              <FormattedMessage
                id="Masiala.Spec.Characteristics.specifics"
                defaultMessage="specifics"
              />
            </strong>
          )
        },
        {
          id: "Masiala.Spec.Characteristics.weight",
          description: (
            <FormattedMessage id="Masiala.Spec.Characteristics.weight" defaultMessage="Weight" />
          ),
          value: "31 ± 10% OZ/YD LIN."
        },
        {
          id: "Masiala.Spec.Characteristics.width",
          description: (
            <FormattedMessage id="Masiala.Spec.Characteristics.width" defaultMessage="Width" />
          ),
          value: "54 inch"
        },
        {
          id: "Masiala.Spec.Characteristics.rollSize",
          description: (
            <FormattedMessage
              id="Masiala.Spec.Characteristics.rollSize"
              defaultMessage="Roll Size"
            />
          ),
          value: "30 yd"
        },
        {
          id: "Masiala.Spec.Characteristics.gauge",
          description: (
            <FormattedMessage id="Masiala.Spec.Characteristics.gauge" defaultMessage="Gauge" />
          ),
          value: "43 +- 10% Mils"
        },
        {
          id: "Masiala.Spec.Characteristics.finishes",
          description: (
            <strong>
              <FormattedMessage
                id="Masiala.Spec.Characteristics.finishes"
                defaultMessage="Finishes"
              />
            </strong>
          )
        },
        {
          id: "Masiala.Spec.Characteristics.finishes.permablok",
          description: <img src={permablokSvg} alt="permablok" />
        }
      ]
    },
    [APPLICATIONS]: {
      info: [
        {
          id: "Masiala.Spec.Applications",
          value: <ApplicationsElements />
        }
      ]
    },
    [PERFORMANCE]: {
      info: [
        {
          id: "Masiala.Spec.Performance.supAbrasionResistant",
          description: (
            <FormattedMessage
              id="Masiala.Spec.Performance.supAbrasionResistant"
              defaultMessage="Superior Abrasion"
            />
          ),
          value: (
            <span>
              CFFA-1a/ASTM D-4157 100.000 {isMobile ? "" : <br />} Cycles Wyzenbeek #10 Cotton Duck
            </span>
          )
        },
        {
          id: "Masiala.Spec.Performance.antiStatic",
          description: (
            <FormattedMessage
              id="Masiala.Spec.Performance.antiStatic"
              defaultMessage="Anti Static"
              values={{
                br: isMobile ? <br /> : ""
              }}
            />
          ),
          value: "ASTM D257"
        },
        {
          id: "Masiala.Spec.Performance.blocking",
          description: (
            <FormattedMessage
              id="Masiala.Spec.Performance.blocking"
              defaultMessage="CFFA-4 - No.1 No Blocking; "
            />
          ),
          value: "No Adhesion"
        },
        {
          id: "Masiala.Spec.Performance.coldCrack",
          description: (
            <FormattedMessage
              id="Masiala.Spec.Performance.coldCrack"
              defaultMessage="Cold Crack"
              values={{
                br: isMobile ? <br /> : ""
              }}
            />
          ),
          value: "CFFA - 6a -10°F"
        },
        {
          id: "Masiala.Spec.Performance.sulfide",
          description: (
            <FormattedMessage
              id="Masiala.Spec.Performance.sulfide"
              defaultMessage="Sulfide Stan Resistance"
              values={{
                br: isMobile ? <br /> : ""
              }}
            />
          ),
          value: "FISHER BODY TM 31-12"
        },
        {
          id: "Masiala.Spec.Performance.uvStabilityResistant",
          description: (
            <FormattedMessage
              id="Masiala.Spec.Performance.uvStabilityResistant"
              defaultMessage="UV Stability Resistant"
            />
          ),
          value: (
            <span>
              Xenotest AATCC TM 16-3 /NTC 1479{isMobile ? "" : <br />} CFFA – 2 a – Blue Wool{" "}
              {isMobile ? "" : <br />} 1,000 Hours
            </span>
          )
        }
      ]
    },
    [FLAME_RETARDANCY]: {
      info: [
        {
          id: "CAL TB 117 - 2013",
          description: "CAL TB 117 - 2013",
          value: PASS
        },
        {
          id: "NFPA 260 - Cover fabric - Class 1",
          description: "NFPA 260 - Cover fabric - Class 1",
          value: PASS
        },
        {
          id: "UFAC Fabric - Class 1",
          description: "UFAC Fabric - Class 1",
          value: PASS
        }
      ],
      disclaimer: [
        {
          id: "Rivulet.specFlameRetardancyDisclaimer",
          value: (
            <p className="color-letter-rv">
              <FormattedMessage
                id="Rivulet.specFlameRetardancyDisclaimer"
                defaultMessage="*This term and any corresponding data refer to the typical performance in the specific tests indicated and should not be construed to imply the behavior of this or any other material under actual fire conditions."
              />
            </p>
          )
        }
      ]
    },
    [WARRANTY]: {
      info: [
        {
          id: "5years",
          description: "5 years"
        }
      ],
      disclaimer: [
        {
          id: "Rivulet.specFlameRetardancyDisclaimer",
          value: (
            <p className="color-letter-rv">
              *Terms and conditions apply. Warranty conditions are displayed on our website.
            </p>
          )
        }
      ]
    }
  }

  return PRODUCT_SPECS_DETAIL_INFORMATION
}
