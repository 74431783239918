import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import useToggle from "hooks/useToggle"

const TooltipElement = ({ renderElement }) => {
  const [toggableTooltip, toggleTooltip] = useToggle()

  return (
    <>
      <FontAwesomeIcon
        className="ms-1 cursor-pointer"
        icon={faQuestionCircle}
        fixedWidth
        onClick={toggleTooltip}
      />
      {renderElement({ isOpen: toggableTooltip, handleToggable: toggleTooltip })}
    </>
  )
}

TooltipElement.propTypes = {
  renderElement: PropTypes.func
}

export default TooltipElement
