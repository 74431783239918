import PropTypes from "prop-types"
import classNames from "classnames"
import { useLocation } from "react-router-dom"
import { REQUEST_SAMPLES_INFO } from "modules/kizuna/components/RequestsSection/utils"
import { XL } from "consts/breakpoints"
import useMediaQuery from "hooks/useMediaQuery"
import ScrollDown from "../ScrollDown"
import VimeoVideo from "./components/VimeoVideo"
import "./styles/Hero.scss"

const Hero = ({
  id,
  isAutoPlay,
  bannerVideo,
  bannerImg,
  bannerTitile,
  bannerDescription,
  handleShowInformationBox,
  handleOrderCatalogue
}) => {
  const location = useLocation()
  const isMobile = useMediaQuery(XL)

  const handleAddCatalogue = (requestInfo) => {
    handleOrderCatalogue(requestInfo)
    handleShowInformationBox()
  }

  return (
    <>
      <div id={id} className="hero">
        {!bannerVideo ? (
          <>
            <div
              className="img-banner-product--kizuna"
              style={{ backgroundImage: `url(${bannerImg})` }}
            />
            <div className="hero-description-container position-absolute">
              <h2 className="hero-kizuna-detail-sample-title text-white text-left">
                {bannerTitile}
              </h2>
              <p
                className={classNames({
                  "description-text": true,
                  "w-50": !isMobile
                })}
              >
                {bannerDescription}
              </p>
              <button
                type="button"
                className={classNames({
                  "btn btn-sample btn-sample-outline": true,
                  "btn-sm": isMobile
                })}
                onClick={() => handleAddCatalogue(REQUEST_SAMPLES_INFO)}
              >
                REQUEST FREE CATALOGUE!
              </button>
            </div>
          </>
        ) : (
          <>
            <VimeoVideo
              id="video-container"
              className="videoembed"
              video={bannerVideo}
              isAutoPlay={isAutoPlay}
            />
            <div className="hero-description-container">
              <p
                className={classNames({
                  "description-text--video": true,
                  "text-size-small": isMobile
                })}
              >
                Blending their expertise, quality, and design
                <br />
                <strong>
                  Spradling<sup>®</sup>
                </strong>{" "}
                and{" "}
                <strong>
                  Citel<sup>®</sup>
                </strong>{" "}
                introduce the Kizuna
                <br />
                Collection: new innovative fabrics that embody the
                <br />
                true essence of cooperation and togetherness.
              </p>
              <button
                type="button"
                className={classNames({
                  "btn btn-sample btn-sample-outline": true,
                  "btn-sm": isMobile
                })}
                onClick={() => handleAddCatalogue(REQUEST_SAMPLES_INFO)}
              >
                REQUEST FREE CATALOGUE!
              </button>
            </div>
          </>
        )}
      </div>
      {location.pathname === "/" && <ScrollDown />}
    </>
  )
}

Hero.propTypes = {
  id: PropTypes.string,
  isAutoPlay: PropTypes.bool,
  bannerImg: PropTypes.string,
  bannerTitile: PropTypes.string,
  bannerDescription: PropTypes.string,
  bannerVideo: PropTypes.string,
  handleShowInformationBox: PropTypes.func,
  handleOrderCatalogue: PropTypes.func
}

export default Hero
