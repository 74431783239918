import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import { PRODUCT_DETAILS } from "./constants"
import ProductDetailsGeneric from "components/ProductDetail"

const ProductDetails = ({ id }) => (
  <div id={id}>
    <div className="container pb-4">
      <div className="row justify-content-md-center">
        <div className="col-12 text-center">
          <TitleUnderlined className="mx-2 mt-2 mb-4" hTag={H2_TAG}>
            <FormattedMessage
              id="Floors.ProductDetail.title"
              description="Floors section title product detail"
              defaultMessage="Características técnicas"
            />
          </TitleUnderlined>
        </div>
        <div className="row">
          <ProductDetailsGeneric className="col-md-4" items={PRODUCT_DETAILS} />
        </div>
      </div>
    </div>
  </div>
)

ProductDetails.propTypes = {
  id: PropTypes.string
}

export default ProductDetails
