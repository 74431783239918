import classNames from "classnames"
import PropTypes from "prop-types"
import { useState } from "react"
import { Collapse } from "reactstrap"
import IconAccordion from "./components/IconAccordion"
import "./styles/SpecAccordion.scss"

const SpecAccordion = ({
  title,
  contentToShow,
  downloadIcon,
  onlyMobileStyle,
  id
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => setIsOpen(!isOpen)

  return (
    <div>
      <div className="accordion-specs-rv mt-1" id="accordion">
        <div
          className={classNames({
            "item-accordion-rv": !onlyMobileStyle,
            "item-accordion-mobile": onlyMobileStyle && !isOpen,
            "pe-5 pt-2": onlyMobileStyle && isOpen
          })}
        >
          <p
            className={classNames({
              "button-accordion w-100 p-0 text-secondary cursor-pointer fs-5": true,
              "fw-light": !isOpen,
              "font-bold": isOpen
            })}
            onClick={handleClick}
          >
            {title}
            {(isOpen || !isOpen) && (
              <IconAccordion
                isDownloadIcon={downloadIcon}
                isOpen={isOpen}
                onlySpecs={onlyMobileStyle}
              />
            )}
          </p>
        </div>
        <Collapse isOpen={isOpen}>{contentToShow}</Collapse>
      </div>
    </div>
  )
}

SpecAccordion.propTypes = {
  title: PropTypes.string,
  contentToShow: PropTypes.element,
  downloadIcon: PropTypes.bool,
  onlyMobileStyle: PropTypes.bool,
  id: PropTypes.string
}

export default SpecAccordion
