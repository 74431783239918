import gql from "graphql-tag"

const singlePatternFragment = gql`
  fragment SinglePatternFragment on PatternNode {
    id
    name
    altName
    slug
    isNew
  }
`
export const masialaSinglePatternQuery = gql`
  query SinglePatternQuery($slug: String) {
    pattern(slug: $slug) {
      ...SinglePatternFragment
    }
  }
  ${singlePatternFragment}
`

export const singlePatternPublicPDFQuery = gql`
  query SinglePatternPublicPDFQuery($id: ID!) {
    pattern (id: $id) {
      id
      pdfUrl (fullVersion: false)
    }
  }
`
