/* eslint-disable */

// import { useMutation } from 'react-apollo';
import { useMutation } from "@apollo/client"
import { mutationRequestFormQuickShipPage } from '../queries';

const useStoreAnonimalOrder = () => {
  const [storeAnonimalOrder, { data, loading, error }] = useMutation(mutationRequestFormQuickShipPage);
  
  const executeStoreAnonimalOrder = async (input) => {
    try {
      const response = await storeAnonimalOrder({ variables: { input } });
      return response?.data?.storeAnonimalOrder?.success;
    } catch (err) {
      console.error("Error executing the mutation:", err);
      throw err;
    }
  };

  return {
    executeStoreAnonimalOrder,
    data,
    loading,
    error
  };
};

export default useStoreAnonimalOrder;
