import { useState } from "react"
import PropTypes from "prop-types"
import { LG } from "consts/breakpoints"
import useMediaQuery from "hooks/useMediaQuery"
import LayoutForDesktop from "./components/ForDesktop"
import LayoutForMobile from "./components/ForMobile"
import useGeneralInfo from "modules/cleaning-guide-marine/modules/General/hooks/useGeneralInfo"

const Layout = ({ generalInfo, handleInfo, sectionIdToScroll, handleChangeSectionId }) => {
  const isMobile = useMediaQuery(LG)

  return !isMobile ? (
    <LayoutForDesktop
      sections={generalInfo?.sections}
      objSections={generalInfo?.info}
      sectionId={generalInfo?.sectionId}
      sectionIdToScroll={sectionIdToScroll}
      handleChangeSectionId={handleChangeSectionId}
      handleChange={handleInfo}
    />
  ) : (
    <LayoutForMobile
      sectionIdToScroll={sectionIdToScroll}
      sections={generalInfo?.sections}
    />
  )
}

Layout.propTypes = {
  generalInfo: PropTypes.object.isRequired,
  handleInfo: PropTypes.func.isRequired,
  sectionIdToScroll: PropTypes.string,
  handleChangeSectionId: PropTypes.func
}

export default Layout
