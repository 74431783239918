import { useState } from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import { STATIC_SLIDER, SLIDER_OPTIONS, SLIDER_OPTIONS_IMGS } from "./constants"
import "components/Applications/styles/applications.scss"
import "./styles/products-for-desktop.scss"

const ProductsForDesktop = ({ items }) => {
  const [slider, setSlider] = useState()
  const [selectSlider, setSelectSlider] = useState()

  return (
    <div className="container">
      <Slider
        className="row slider-sections--segm-hotelery my-4"
        {...STATIC_SLIDER}
        asNavFor={slider}
        ref={(slider) => setSelectSlider(slider)}
        slidesToShow={items.length}
        focusOnSelect={true}
      >
        {items.map(({ id, title }) => (
          <span key={`selectable_slider_${id}`}>{title}</span>
        ))}
      </Slider>
      <Slider
        className="container"
        {...SLIDER_OPTIONS}
        ref={(ref) => setSlider(ref)}
        asNavFor={selectSlider}
      >
        {items.map(({ id, info, imgs }) => (
          <div key={`key_${id}`} className="row d-flex justify-content-center align-items-center">
            <div className="col-10 d-flex align-items-center">
              <div className="col-5 align-items-center">
                <p className="info-section info--segm-hotelery w-75">{info}</p>
              </div>
              <div className="col-7">
                <Slider {...SLIDER_OPTIONS_IMGS}>
                  {imgs.map(({ name, img }) => (
                    <div key={`key_${name}`} className="d-flex flex-column justify-content-center">
                      <img id={name} className="individual-img" src={img} alt="application" />
                      <p className="info--segm-hotelery fw-normal mt-2">{name}</p>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

ProductsForDesktop.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      info: PropTypes.element,
      imgs: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
          img: PropTypes.string
        })
      )
    })
  ).isRequired
}

export default ProductsForDesktop
