import fabrics from "../img/galeria-1.jpeg"
import sample from "../img/galeria-2.jpeg"
import chair from "../img/galeria-3.jpeg"
import samples from "../img/galeria-4.jpeg"

export const SCENES = [
  {
    title: "fabrics",
    img: fabrics
  },
  {
    title: "sample",
    img: sample
  },
  {
    title: "chair",
    img: chair
  },
  {
    title: "samples",
    img: samples
  }
]
