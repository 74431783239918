import PropTypes from "prop-types"
import "./styles/product-information-detail.scss"

const ProductInformationDetail = ({ details }) => (
  <div className="container-table">
    <table className="col-12">
      {details.info.map(({ id, description, value }) => (
        <tr key={`key_${id}`} className="info">
          {description && <td className="col-6 text-capitalize">{description}</td>}
          <td className="col-6">{value}</td>
        </tr>
      ))}
    </table>
    {details?.disclaimer && details.disclaimer.length > 0 && (
      <div className="info mx-0">
        {details.disclaimer.map(({ id, value }) => (
          <p key={`key_${id}`} className="disclaimer">
            {value}
          </p>
        ))}
      </div>
    )}
  </div>
)

ProductInformationDetail.propTypes = {
  details: PropTypes.shape({
    info: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
        value: PropTypes.string
      })
    ),
    disclaimer: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string
      })
    )
  })
}

export default ProductInformationDetail
