import { connect } from "react-redux"
import SearchByLocation from "components/StoreFinder/components/SearchByLocation"
import { setCityId, setPatternId } from "components/StoreFinder/actions"

const getPatternId = (state) => state.locationReducer.patternId

const mapStateToProps = (state) => {
  return {
    patternId: getPatternId(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setPatternId: (patternId) => dispatch(setPatternId(patternId)),
    setCityId: (cityId) => dispatch(setCityId(cityId))
  }
}

const SearchByLocationContainer = connect(mapStateToProps, mapDispatchToProps)(SearchByLocation)

export default SearchByLocationContainer
