import { defineMessages } from "react-intl"

export const DIFFERENCES_IN_DIMENSION_MSGS = defineMessages({
  howMeasuredMaterial: {
    id: "Claims.DifferencesInDimensions.howMeasuredMaterial",
    description: "field how measured material for difference in dimensions claim reason",
    defaultMessage: "How did you measure the material?"
  },
  indicateDifference: {
    id: "Claims.DifferencesInDimensions.indicateDifference",
    description: "field indicate difference for difference in dimensions claim reason",
    defaultMessage: "Please indicate the difference"
  }
})
