import { FormattedMessage } from "react-intl"
import banner from "modules/breeze/components/Banner/img/banner.jpg"
import { NAV_ITEMS } from "modules/breeze/constants"

export const SLIDE = [
  {
    id: "slide-breeze",
    imageThumb: banner,
    title: (
      <FormattedMessage
        id="Breeze.Banner.title"
        description="Breeze title banner"
        defaultMessage="Breeze<sup>™</sup>"
        values={{
          sup: (...chunks) => <sup>{chunks}</sup>
        }}
      />
    ),
    description: (
      <FormattedMessage
        id="Breeze.Banner.description"
        description="Breeze description banner"
        defaultMessage="Feautring Permacool"
      />
    ),
    link: `#${NAV_ITEMS[NAV_ITEMS.length - 2]}`,
    buttonText: (
      <FormattedMessage
        id="Breeze.Banner.buttonText"
        description="Breeze buttonText banner"
        defaultMessage="Request more information"
      />
    )
  }
]
