/* eslint-disable */

import PropTypes from "prop-types"

export const HowitWorksList = ({ img, alt, text, textEn, lang }) => {
  return (
    <>
      <div className="text-center pt-4 md-pt-5">
        <img src={img} alt={alt} className="img-fluid mx-auto" width={30} height={30} />
      </div>
      <ul className="md-ml-4 md-pt-3 quickship--banner-description">
        <li className="col-12 quickship--banner-description--list">
          {lang === "es" ? text : textEn}
        </li>
      </ul>
    </>
  )
}

HowitWorksList.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.string,
  textEn: PropTypes.string,
  lang: PropTypes.string
}
