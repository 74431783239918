import { FormattedMessage } from "react-intl"

const title = (
  <FormattedMessage
    id="CleaningMarine.General.HowItWorks.title"
    defaultMessage="To Keep In Mind!"
  />
)

export const KEEP_IN_MIND_ITEM_BOAT_SEATS = {
  title,
  description: (
    <FormattedMessage
      id="CleaningMarine.General.BoatSeats.HowItWorks.descriptions"
      defaultMessage="Failure to care for your vinyl properly or use of improper cleaners will void your warranty and damage your vinyl."
    />
  )
}

export const KEEP_IN_MIND_ITEM_BIMI_TOPS_COVERS = {
  title: (
    <FormattedMessage
      id="CleaningMarine.General.HowItWorks.title"
      defaultMessage="To Keep In Mind!"
    />
  ),
  subtitle: (
    <FormattedMessage
      id="CleaningMarine.General.BimiTopsCovers.HowItWorks.title"
      defaultMessage="For Stubborn Stains/Mildew"
    />
  ),
  list: [
    <FormattedMessage
      key="CleaningMarine.General.BimiTopsCovers.HowItWorks.list.1"
      id="CleaningMarine.General.BimiTopsCovers.HowItWorks.list.1"
      defaultMessage="Mix | /2 cup of non-chlorine bleach, 1/4 cup mild-soap, & a gallon of warm water."
    />,
    <FormattedMessage
      key="CleaningMarine.General.BimiTopsCovers.HowItWorks.list.2"
      id="CleaningMarine.General.BimiTopsCovers.HowItWorks.list.2"
      defaultMessage="Apply with spray bottle directly on topping (avoid over-spraying)."
    />,
    <FormattedMessage
      key="CleaningMarine.General.BimiTopsCovers.HowItWorks.list.3"
      id="CleaningMarine.General.BimiTopsCovers.HowItWorks.list.3"
      defaultMessage="Let set for 20 min, then scrub lightly with soft bristle brush."
    />,
    <FormattedMessage
      key="CleaningMarine.General.BimiTopsCovers.HowItWorks.list.4"
      id="CleaningMarine.General.BimiTopsCovers.HowItWorks.list.4"
      defaultMessage="ALWAYS rinse thoroughly w/COLD water & air dry."
    />
  ]
}

export const KEEP_IN_MIND_ITEM_WOVEN_FLOORING = {
  title: (
    <FormattedMessage
      id="CleaningMarine.General.HowItWorks.title"
      defaultMessage="To Keep In Mind!"
    />
  ),
  description: (
    <FormattedMessage
      id="CleaningMarine.General.wovenFloor.HowItWorks.description"
      defaultMessage="Proper care will increase your performance flooring’s natural life cycle.{br}{br}Failure to care for your flooring properly, or use of improper cleaners, may void your warranty & damage your flooring."
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>,
        br: <br />
      }}
    />
  )
}
