import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const CharacteristicCardIcon = ({
  item,
  currentCharacteristic,
  handleUpdateCurrentCharacteristic
}) => {
  return (
    <div className={classNames({
      "saxony--card": item.key === currentCharacteristic.id
    })} key={item.key}>
      <div
        className="saxony--container-card-icon me-3 overflow-hidden d-flex justify-content-center align-items-center rounded-circle"
        onClick={() => handleUpdateCurrentCharacteristic(item.key)}>
        <img className="saxony--characteristic-single-icon" src={item.icon} alt="icons characterisctics" width="45" height="35" />
      </div>
    </div>
  )
}

CharacteristicCardIcon.propTypes = {
  item: PropTypes.object,
  currentCharacteristic: PropTypes.object,
  handleUpdateCurrentCharacteristic: PropTypes.func
}

export default CharacteristicCardIcon
