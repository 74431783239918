import PropTypes from "prop-types"
import { useLoadScript } from "@react-google-maps/api"
import Map from "./components/Map"

const StoresGoogleMap = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY
  })

  if (!isLoaded) return <div>Loading...</div>
  return <Map {...props} />
}

StoresGoogleMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }),
  zoom: PropTypes.number,
  nearbyStores: PropTypes.array,
  highlightedStoreId: PropTypes.string,
  setHighlightedStoreId: PropTypes.func
}

export default StoresGoogleMap
