import { FormattedMessage } from "react-intl"

const BOAT_SEATS_DO = [
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.Do.softBrush"
    id="CleaningMarine.General.BoatSeats.Do.softBrush"
    defaultMessage="Soft bristle brush"
  />,
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.Do.ivoryOrDawn"
    id="CleaningMarine.General.BoatSeats.Do.ivoryOrDawn"
    defaultMessage="Ivory® or Dawn® dish soap + water"
    values={{
      sup: (...chunks) => <sup>{chunks}</sup>
    }}
  />,
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.Do.fomurle409"
    id="CleaningMarine.General.BoatSeats.Do.fomurle409"
    defaultMessage="Formula 409® + water"
    values={{
      sup: (...chunks) => <sup>{chunks}</sup>
    }}
  />,
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.Do.fantastik"
    id="CleaningMarine.General.BoatSeats.Do.fantastik"
    defaultMessage="Fantastik® + water"
    values={{
      sup: (...chunks) => <sup>{chunks}</sup>
    }}
  />,
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.Do.boatBling"
    id="CleaningMarine.General.BoatSeats.Do.boatBling"
    defaultMessage="Bling Sauce Vinyl Sauce®"
    values={{
      sup: (...chunks) => <sup>{chunks}</sup>
    }}
  />,
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.Do.alwaysRinse"
    id="CleaningMarine.General.BoatSeats.Do.alwaysRinse"
    defaultMessage="Always rinse w/water and pat dry"
  />
]
const BOAT_SEATS_DO_NOT = [
  <FormattedMessage
    key="eraserSponges"
    id="CleaningMarine.General.BoatSeats.DoNot.eraserSponges"
    defaultMessage="NO Eraser sponges"
  />,
  <FormattedMessage
    key="powerWashers"
    id="CleaningMarine.General.BoatSeats.DoNot.powerWashers"
    defaultMessage="NO Power washers"
  />,
  <FormattedMessage
    key="abrasives"
    id="CleaningMarine.General.BoatSeats.DoNot.abrasives"
    defaultMessage="NO Abrasives cleaners"
  />,
  <FormattedMessage
    key="silicones"
    id="CleaningMarine.General.BoatSeats.DoNot.silicones"
    defaultMessage="NO Silicones"
  />,
  <FormattedMessage
    key="fullStrengthBleach"
    id="CleaningMarine.General.BoatSeats.DoNot.fullStrengthBleach"
    defaultMessage="NO Full Strength Bleach"
  />,
  <FormattedMessage
    key="hardBrushes"
    id="CleaningMarine.General.BoatSeats.DoNot.hardBrushes"
    defaultMessage="NO Hard brushes"
  />,
  <FormattedMessage
    key="conditionersOrProtectants"
    id="CleaningMarine.General.BoatSeats.DoNot.conditionersOrProtectants"
    defaultMessage="NO Conditioners or Protectants"
  />
]

const BIMI_TOPS_COVERS_DO = [
  <FormattedMessage
    key="CleaningMarine.General.BimiTopsCovers.Do.brushOff"
    id="CleaningMarine.General.BimiTopsCovers.Do.brushOff"
    defaultMessage="Soft bristle brush or garden hose"
  />,
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.Do.ivoryOrDawn"
    id="CleaningMarine.General.BoatSeats.Do.ivoryOrDawn"
    defaultMessage="lvory<sup>®</sup> or Dawn<sup>®</sup> dish soap + water"
    values={{
      sup: (...chunks) => <sup>{chunks}</sup>
    }}
  />,
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.Do.alwaysRinse"
    id="CleaningMarine.General.BoatSeats.Do.alwaysRinse"
    defaultMessage="ALWAYS rinse w/COLD water and air dry."
  />
]
const BIMI_TOPS_COVERS_DO_NOT = [
  <FormattedMessage
    key="powerWashers_bimi"
    id="CleaningMarine.General.BoatSeats.DoNot.powerWashers"
    defaultMessage="NO Power washers"
  />,
  <FormattedMessage
    key="hardBrushes_bimi"
    id="CleaningMarine.General.BoatSeats.DoNot.hardBrushes"
    defaultMessage="NO Hard brushes"
  />,
  <FormattedMessage
    key="CleaningMarine.General.BimiTopsCovers.DoNot.hotWater"
    id="CleaningMarine.General.BimiTopsCovers.DoNot.hotWater"
    defaultMessage="NO Hot water"
  />,
  <FormattedMessage
    key="CleaningMarine.General.BoatSeats.DoNot.fullStrengthBleach"
    id="CleaningMarine.General.BoatSeats.DoNot.fullStrengthBleach"
    defaultMessage="NO Full Strength Bleach"
  />,
  <FormattedMessage
    key="CleaningMarine.General.BimiTopsCovers.DoNot.detergents"
    id="CleaningMarine.General.BimiTopsCovers.DoNot.detergents"
    defaultMessage="NO Detergents"
  />
]

const WOVEN_FLOORING_DO = [
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.Do.regularySweep"
    id="CleaningMarine.General.wovenFloor.Do.regularySweep"
    defaultMessage="Regulary sweep, vacuum or hose off"
  />,
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.Do.lvory"
    id="CleaningMarine.General.wovenFloor.Do.lvory"
    defaultMessage="Use standard carpet cleaner or
    2 ounces of mild dish soap to 1 gallon of warm
    water (NOT hot)"
  />,
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.Do.dryFace"
    id="CleaningMarine.General.wovenFloor.Do.dryFace"
    defaultMessage="Dry face up or hang"
  />,
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.Do.dryCompletely"
    id="CleaningMarine.General.wovenFloor.Do.dryCompletely"
    defaultMessage="Dry completely before storing"
  />,
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.Do.rollFace"
    id="CleaningMarine.General.wovenFloor.Do.rollFace"
    defaultMessage="Roll face up when storing any mat"
  />
]
const WOVEN_FLOORING_DO_NOT = [
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.DoNot.foldMats"
    id="CleaningMarine.General.wovenFloor.DoNot.foldMats"
    defaultMessage="Fold Mats"
  />,
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.DoNot.rollWet"
    id="CleaningMarine.General.wovenFloor.DoNot.rollWet"
    defaultMessage="Roll or store wet"
  />,
  <FormattedMessage
    key="powerWashers_wovenFloor"
    id="CleaningMarine.General.BoatSeats.DoNot.powerWashers"
    defaultMessage="NO Power washers"
  />,
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.DoNot.mineralSpirits"
    id="CleaningMarine.General.wovenFloor.DoNot.mineralSpirits"
    defaultMessage="NO Mineral spirits"
  />,
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.DoNot.acetone"
    id="CleaningMarine.General.wovenFloor.DoNot.acetone"
    defaultMessage="NO Acetone"
  />,
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.DoNot.pineSol"
    id="CleaningMarine.General.wovenFloor.DoNot.pineSol"
    defaultMessage="NO Pine-Sol<sup>®</sup>"
    values={{
      sup: (...chunks) => <sup>{chunks}</sup>
    }}
  />,
  <FormattedMessage
    key="silicones_wovenFloor"
    id="CleaningMarine.General.wovenFloor.DoNot.silicone"
    defaultMessage="NO Silicone"
  />,
  <FormattedMessage
    key="CleaningMarine.General.wovenFloor.DoNot.citrusCleaners"
    id="CleaningMarine.General.wovenFloor.DoNot.citrusCleaners"
    defaultMessage="NO Citrus based cleaners"
  />
]

export const DO_AND_DO_NOT_ITEM_BOAT_SEATS = {
  do: BOAT_SEATS_DO,
  doNot: BOAT_SEATS_DO_NOT,
  disclaimer: (
    <FormattedMessage
      key="CleaningMarine.General.BoatSeats.Do.disclaimer"
      id="CleaningMarine.General.BoatSeats.Do.disclaimer"
      defaultMessage="Formula 409<sup>®</sup> is a registered trademark of the Clorox Company<sup>®</sup>.{br}Ivory<sup>®</sup> and Dawn<sup>®</sup> dish soap is a registered trademark of Proctor and Gamble<sup>®</sup>.{br}Boat Bling<sup>®</sup> Vinyl Sauce is a registered trademark of Bling Sauce<sup>®</sup> Inc.{br}Fantastik<sup>®</sup> & Formula 409<sup>®</sup> are safe to use if cleaner is diluted (1:1), then thoroughly rinsed and used following manufacturers' instructions."
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>,
        br: <br />
      }}
    />
  )
}

export const DO_AND_DO_NOT_ITEM_BIMI_TOPS_COVERS = {
  do: BIMI_TOPS_COVERS_DO,
  doNot: BIMI_TOPS_COVERS_DO_NOT
}

export const DO_AND_DO_NOT_ITEM_WOVEN_FLOORING = {
  do: WOVEN_FLOORING_DO,
  doNot: WOVEN_FLOORING_DO_NOT
  // disclaimer: (
  //   <FormattedMessage
  //     key="CleaningMarine.General.wovenFloor.Do.disclaimer"
  //     id="CleaningMarine.General.wovenFloor.Do.disclaimer"
  //     defaultMessage="Formula 409<sup>®</sup> is a registered trademark of the Clorox Company<sup>®</sup>.{br}Ivory<sup>®</sup> and Dawn<sup>®</sup> dishsoap is a registered trademark of Proctor and Gamble<sup>®</sup>.{br}Boat Bling<sup>®</sup> Vinyl Sauce is a registerd trademark of Bling Sauce<sup>®</sup>, Inc.{br}Babes Seat Soap<sup>®</sup> is a registered trademark of BABES Boat Care Products<sup>®</sup>, Inc.{br}Fantastik<sup>®</sup> & Formula 409<sup>®</sup> are safe to use if cleaner is diluted (1:1), then thorouhgly rinsesd and used following manufacturers' instructions."
  //     values={{
  //       sup: (...chunks) => <sup>{chunks}</sup>,
  //       br: <br />
  //     }}
  //   />
  // )
}
