import poster from "./assets/media/poster.png"
import VideoCommon from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import { getCurrentInstanceId } from "services/instances"

const VIDEO_URL = {
  [process.env.REACT_APP_LATAM_INSTANCE_ID]: "https://www.youtube.com/embed/YMekZ3mnZL0",
  [process.env.REACT_APP_MX_INSTANCE_ID]: "https://www.youtube.com/embed/zBA3DQYGOu8"
}

const Video = () => {
  const isMobile = useMediaQuery(SM)
  return (
    <div className="container my-2 py-4">
      <div className="row">
        <div className="col-12 align-self-center">
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="Pranna.Video.title"
              description="title section of Pranna page"
              defaultMessage="Beneficios"
            />
          </TitleUnderlined>
          <p className="text-center">
            <FormattedMessage
              id="Linetex.Video.description"
              description="description for title section of Dalí page"
              defaultMessage="Desarrollamos Linetex<sup>®</sup> en 16 colores con <strong>PermaCool</strong> nuestra última tecnología que reduce la energía radiante o el calor de ser absorbido por cualquier superficie tapizada.{br}{br}Te invitamos a ver este video donde podrás conocer sus beneficios."
              values={{
                br: <br />,
                strong: (...chunks) => <strong>{chunks}</strong>,
                sup: (...chunks) => <sup>{chunks}</sup>
              }}
            />
          </p>
          <VideoCommon className="img-fluid" poster={poster} fromYoutube>
            <iframe
              title="video"
              width="100%"
              height={isMobile ? "250" : "650"}
              src={VIDEO_URL[getCurrentInstanceId()]}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoCommon>
        </div>
      </div>
    </div>
  )
}

export default Video
