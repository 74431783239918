import putty from "../img/2-putty.jpg"
import cucumber from "../img/3-cucumber.jpg"
import sassafras from "../img/4-sassafras.jpg"
import zest from "../img/5-zest.jpg"
import bone from "../img/7-bone.jpg"
import camel from "../img/10-camel.jpg"
import cappuccino from "../img/11-cappuccino.jpg"
import gravel from "../img/12-gravel.jpg"
import cashew from "../img/13-cashew.jpg"
import cognac from "../img/14-cognac.jpg"
import luggage from "../img/15-luggage.jpg"
import carotene from "../img/16-carotene.jpg"
import salsa from "../img/17-salsa.jpg"
import bordeaux from "../img/18-bordeaux.jpg"
import molten from "../img/19-molten.jpg"
import merlot from "../img/20-merlot.jpg"
import antelope from "../img/21-antelope.jpg"
import elephant from "../img/22-elephant.jpg"
import ochre from "../img/24-ochre.jpg"
import patina from "../img/25-patina.jpg"
import brickRed from "../img/26-brick-red.jpg"
import forest from "../img/27-forest.jpg"
import mahogany from "../img/28-mahogany.jpg"
import sangre from "../img/30-sangre.jpg"
import cinnamon from "../img/31-cinnamon.jpg"
import fawn from "../img/33-fawn.jpg"
import expresso from "../img/34-expresso.jpg"
import truffle from "../img/35-truffle.jpg"
import charcoal from "../img/36-charcoal.jpg"
import cerulean from "../img/38-cerulean.jpg"
import mallard from "../img/39-mallard.jpg"
import paradise from "../img/40-paradise.jpg"
import indigo from "../img/41-indigo.jpg"
import fog from "../img/42-fog.jpg"
import pewter from "../img/43-pewter.jpg"
import storm from "../img/44-storm.jpg"
import navy from "../img/45-navy.jpg"
import black from "../img/46-black.jpg"

export const COLORS_BLACKLIST = [
  { node: { color: "Gravel", stock: "WHI-2119" } },
  { node: { color: "Carotene", stock: "WHI-2164" } },
  { node: { color: "Merlot", stock: "WHI-2127" } },
  { node: { color: "Forest", stock: "WHI-2129" } },
  { node: { color: "Sangre", stock: "WHI-2144" } },
  { node: { color: "Brick Red", stock: "WHI-2166" } },
  { node: { color: "Ochre", stock: "WHI-2151" } },
  { node: { color: "Putty", stock: "WHI-2156" } },
  { node: { color: "Cucumber", stock: "WHI-2158" } },
  { node: { color: "Zest", stock: "WHI-2159" } }
]

export const COLORS = [
  { node: { color: "Putty", stock: "WHI-2156", imageThumbSmall: putty, imageThumbMedium: putty } },
  {
    node: {
      color: "Cucumber",
      stock: "WHI-2158",
      imageThumbSmall: cucumber,
      imageThumbMedium: cucumber
    }
  },
  {
    node: {
      color: "Sassafras",
      stock: "WHI-2160",
      imageThumbSmall: sassafras,
      imageThumbMedium: sassafras
    }
  },
  { node: { color: "Zest", stock: "WHI-2159", imageThumbSmall: zest, imageThumbMedium: zest } },
  { node: { color: "Bone", stock: "WHI-2117", imageThumbSmall: bone, imageThumbMedium: bone } },
  { node: { color: "Camel", stock: "WHI-2118", imageThumbSmall: camel, imageThumbMedium: camel } },
  {
    node: {
      color: "Cappuccino",
      stock: "WHI-2121",
      imageThumbSmall: cappuccino,
      imageThumbMedium: cappuccino
    }
  },
  {
    node: { color: "Gravel", stock: "WHI-2119", imageThumbSmall: gravel, imageThumbMedium: gravel }
  },
  {
    node: { color: "Cashew", stock: "WHI-2141", imageThumbSmall: cashew, imageThumbMedium: cashew }
  },
  {
    node: { color: "Cognac", stock: "WHI-2142", imageThumbSmall: cognac, imageThumbMedium: cognac }
  },
  {
    node: {
      color: "Luggage",
      stock: "WHI-2123",
      imageThumbSmall: luggage,
      imageThumbMedium: luggage
    }
  },
  {
    node: {
      color: "Carotene",
      stock: "WHI-2164",
      imageThumbSmall: carotene,
      imageThumbMedium: carotene
    }
  },
  { node: { color: "Salsa", stock: "WHI-2124", imageThumbSmall: salsa, imageThumbMedium: salsa } },
  {
    node: {
      color: "Bordeaux",
      stock: "WHI-2126",
      imageThumbSmall: bordeaux,
      imageThumbMedium: bordeaux
    }
  },
  {
    node: { color: "Molten", stock: "WHI-2165", imageThumbSmall: molten, imageThumbMedium: molten }
  },
  {
    node: { color: "Merlot", stock: "WHI-2127", imageThumbSmall: merlot, imageThumbMedium: merlot }
  },
  {
    node: {
      color: "Antelope",
      stock: "WHI-2120",
      imageThumbSmall: antelope,
      imageThumbMedium: antelope
    }
  },
  {
    node: {
      color: "Elephant",
      stock: "WHI-2138",
      imageThumbSmall: elephant,
      imageThumbMedium: elephant
    }
  },
  { node: { color: "Ochre", stock: "WHI-2151", imageThumbSmall: ochre, imageThumbMedium: ochre } },
  {
    node: { color: "Patina", stock: "WHI-2134", imageThumbSmall: patina, imageThumbMedium: patina }
  },
  {
    node: {
      color: "Brick Red",
      stock: "WHI-2166",
      imageThumbSmall: brickRed,
      imageThumbMedium: brickRed
    }
  },
  {
    node: { color: "Forest", stock: "WHI-2129", imageThumbSmall: forest, imageThumbMedium: forest }
  },
  {
    node: {
      color: "Mahogany",
      stock: "WHI-2152",
      imageThumbSmall: mahogany,
      imageThumbMedium: mahogany
    }
  },
  {
    node: { color: "Sangre", stock: "WHI-2144", imageThumbSmall: sangre, imageThumbMedium: sangre }
  },
  {
    node: {
      color: "Cinnamon",
      stock: "WHI-2125",
      imageThumbSmall: cinnamon,
      imageThumbMedium: cinnamon
    }
  },
  { node: { color: "Fawn", stock: "WHI-2150", imageThumbSmall: fawn, imageThumbMedium: fawn } },
  {
    node: {
      color: "Expresso",
      stock: "WHI-2143",
      imageThumbSmall: expresso,
      imageThumbMedium: expresso
    }
  },
  {
    node: {
      color: "Truffle",
      stock: "WHI-2153",
      imageThumbSmall: truffle,
      imageThumbMedium: truffle
    }
  },
  {
    node: {
      color: "Charcoal",
      stock: "WHI-2139",
      imageThumbSmall: charcoal,
      imageThumbMedium: charcoal
    }
  },
  {
    node: {
      color: "Cerulean",
      stock: "WHI-2136",
      imageThumbSmall: cerulean,
      imageThumbMedium: cerulean
    }
  },
  {
    node: {
      color: "Mallard",
      stock: "WHI-2161",
      imageThumbSmall: mallard,
      imageThumbMedium: mallard
    }
  },
  {
    node: {
      color: "Paradise",
      stock: "WHI-2162",
      imageThumbSmall: paradise,
      imageThumbMedium: paradise
    }
  },
  {
    node: { color: "Indigo", stock: "WHI-2163", imageThumbSmall: indigo, imageThumbMedium: indigo }
  },
  { node: { color: "Fog", stock: "WHI-2157", imageThumbSmall: fog, imageThumbMedium: fog } },
  {
    node: { color: "Pewter", stock: "WHI-2154", imageThumbSmall: pewter, imageThumbMedium: pewter }
  },
  { node: { color: "Storm", stock: "WHI-2155", imageThumbSmall: storm, imageThumbMedium: storm } },
  { node: { color: "Navy", stock: "WHI-2137", imageThumbSmall: navy, imageThumbMedium: navy } },
  { node: { color: "Black", stock: "WHI-2140", imageThumbSmall: black, imageThumbMedium: black } }
]
