import { defineMessages } from "react-intl"
import prop65 from "../img/prop-65.svg"
import ab2998 from "../img/AB-2998.svg"
import denim from "../img/Demin.svg"
import noBiocides from "../img/No-biocides.svg"
import soft from "../img/soft.svg"
import noFlame from "../img/No-flame.svg"
import quaternary from "../img/Quaternary.svg"
import bleach from "../img/Bleach.svg"

export const MSGS = defineMessages({
  prop65: {
    id: "WhisperNeoPlus.ProductDetail.prop65",
    description: "WhisperNeoPlus section product detail item prop65",
    defaultMessage: "Prop 65 {br} Compliant"
  },
  ab2998: {
    id: "WhisperNeoPlus.ProductDetail.ab2998",
    description: "WhisperNeoPlus section product detail item ab2998",
    defaultMessage: "AB 2998 {br} Compliant"
  },
  denim: {
    id: "WhisperNeoPlus.ProductDetail.denim",
    description: "WhisperNeoPlus section product detail item denim",
    defaultMessage: "Denim {br} Cleanable"
  },
  noBiocides: {
    id: "WhisperNeoPlus.ProductDetail.noBiocides",
    description: "WhisperNeoPlus section product detail item noBiocides",
    defaultMessage: "No {br} Biocides"
  },
  soft: {
    id: "WhisperNeoPlus.ProductDetail.soft",
    description: "WhisperNeoPlus section product detail item soft",
    defaultMessage: "Soft {br} Hand"
  },
  noFlame: {
    id: "WhisperNeoPlus.ProductDetail.noFlame",
    description: "WhisperNeoPlus section product detail item noFlame",
    defaultMessage: "No Flame {br} Retardants"
  },
  quaternary: {
    id: "WhisperNeoPlus.ProductDetail.quaternary",
    description: "WhisperNeoPlus section product detail item quaternary",
    defaultMessage: "Quaternary Cleaners {br} Resistant"
  },
  bleach: {
    id: "WhisperNeoPlus.ProductDetail.bleach",
    description: "WhisperNeoPlus section product detail item bleach",
    defaultMessage: "Bleach {br} Cleanable"
  }
})

export const PRODUCT_DETAILS = [
  {
    id: "prop65",
    text: MSGS.prop65,
    icon: prop65
  },
  {
    id: "ab2998",
    text: MSGS.ab2998,
    icon: ab2998
  },
  {
    id: "denim",
    text: MSGS.denim,
    icon: denim
  },
  {
    id: "noBiocides",
    text: MSGS.noBiocides,
    icon: noBiocides
  },
  {
    id: "soft",
    text: MSGS.soft,
    icon: soft
  },
  {
    id: "noFlame",
    text: MSGS.noFlame,
    icon: noFlame
  },
  {
    id: "quaternary",
    text: MSGS.quaternary,
    icon: quaternary
  },
  {
    id: "bleach",
    text: MSGS.bleach,
    icon: bleach
  }
]
