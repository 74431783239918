import eggshell from "../img/0300-Eggshell.jpg"
import quiet from "../img/0301-Quiet.jpg"
import beachgrass from "../img/0302-Beachgrass.jpg"
import root from "../img/0303-Root.jpg"
import calm from "../img/0304-Calm.jpg"
import cabin from "../img/0305-Cabin.jpg"
import blackWalnut from "../img/0306-Black-Walnut.jpg"
import ballet from "../img/0307-Ballet.jpg"
import blossom from "../img/0308-Blossom.jpg"
import berryBush from "../img/0309-Berry-Bush.jpg"
import froth from "../img/0310-Froth.jpg"
import underwater from "../img/0311-Underwater.jpg"
import emerge from "../img/0312-Emerge.jpg"
import woodlet from "../img/0313-Woodlet.jpg"
import verdent from "../img/0314-Verdent.jpg"
import malachite from "../img/0315-Malachite.jpg"
import seamist from "../img/0316-Seamist.jpg"
import damascus from "../img/0317-Damascus.jpg"
import noire from "../img/0318-Noire.jpg"
import sapphire from "../img/0319-Sapphire.jpg"
import denim from "../img/0320-Denim.jpg"
import blueStar from "../img/0321-Blue-Star.jpg"
import inkpad from "../img/0322-Inkpad.jpg"

export const SAMPLES_TO_SHOW_IN_DESKTOP = [
  {
    id: "1_eggshell",
    color: "eggshell",
    reference: "MAI-0300",
    imgDetail: eggshell,
    img: eggshell
  },
  {
    id: "2_quiet",
    color: "quiet",
    reference: "MAI-0301",
    imgDetail: quiet,
    img: quiet
  },
  {
    id: "3_beachgrass",
    color: "beach\ngrass",
    reference: "MAI-0302",
    imgDetail: beachgrass,
    img: beachgrass
  },
  {
    id: "4_root",
    color: "root",
    reference: "MAI-0303",
    imgDetail: root,
    img: root
  },
  {
    id: "5_calm",
    color: "calm",
    reference: "MAI-0304",
    imgDetail: calm,
    img: calm
  },
  {
    id: "6_cabin",
    color: "cabin",
    reference: "MAI-0305",
    imgDetail: cabin,
    img: cabin
  },
  {
    id: "7_blackWalnut",
    color: "black\nWalnut",
    reference: "MAI-0306",
    imgDetail: blackWalnut,
    img: blackWalnut
  },
  {
    id: "8_ballet",
    color: "ballet",
    reference: "MAI-0307",
    imgDetail: ballet,
    img: ballet
  },
  {
    id: "9_blossom",
    color: "blossom",
    reference: "MAI-0308",
    imgDetail: blossom,
    img: blossom
  },
  {
    id: "10_berryBush",
    color: "berry\nBush",
    reference: "MAI-0309",
    imgDetail: berryBush,
    img: berryBush
  },
  {
    id: "11_froth",
    color: "froth",
    reference: "MAI-0310",
    imgDetail: froth,
    img: froth
  },
  {
    id: "12_underwater",
    color: "under\nwater",
    reference: "MAI-0311",
    imgDetail: underwater,
    img: underwater
  },
  {
    id: "13_emerge",
    color: "emerge",
    reference: "MAI-0312",
    imgDetail: emerge,
    img: emerge
  },
  {
    id: "14_woodlet",
    color: "woodlet",
    reference: "MAI-0313",
    imgDetail: woodlet,
    img: woodlet
  },
  {
    id: "15_verdent",
    color: "verdent",
    reference: "MAI-0314",
    imgDetail: verdent,
    img: verdent
  },
  {
    id: "16_malachite",
    color: "malachite",
    reference: "MAI-0315",
    imgDetail: malachite,
    img: malachite
  },
  {
    id: "17_seamist",
    color: "seamist",
    reference: "MAI-0316",
    imgDetail: seamist,
    img: seamist
  },
  {
    id: "18_damascus",
    color: "damascus",
    reference: "MAI-0317",
    imgDetail: damascus,
    img: damascus
  },
  {
    id: "19_noire",
    color: "noire",
    reference: "MAI-0318",
    imgDetail: noire,
    img: noire
  },
  {
    id: "20_sapphire",
    color: "sapphire",
    reference: "MAI-0319",
    imgDetail: sapphire,
    img: sapphire
  },
  {
    id: "23_denim",
    color: "denim",
    reference: "MAI-0320",
    imgDetail: denim,
    img: denim
  },
  {
    id: "21_blueStar",
    color: "blue Star",
    reference: "MAI-0321",
    imgDetail: blueStar,
    img: blueStar
  },
  {
    id: "22_inkpad",
    color: "inkpad",
    reference: "MAI-0322",
    imgDetail: inkpad,
    img: inkpad
  }
]

export const SAMPLES_TO_SHOW_IN_MOBILE = [
  {
    id: "1_eggshell",
    color: "eggshell",
    reference: "MAI-0300",
    imgDetail: eggshell,
    img: eggshell
  },
  {
    id: "3_beachgrass",
    color: "beach\ngrass",
    reference: "MAI-0302",
    imgDetail: beachgrass,
    img: beachgrass
  },
  {
    id: "2_quiet",
    color: "quiet",
    reference: "MAI-0301",
    imgDetail: quiet,
    img: quiet
  },
  {
    id: "4_root",
    color: "root",
    reference: "MAI-0303",
    imgDetail: root,
    img: root
  },
  {
    id: "5_calm",
    color: "calm",
    reference: "MAI-0304",
    imgDetail: calm,
    img: calm
  },
  {
    id: "7_blackWalnut",
    color: "black\nWalnut",
    reference: "MAI-0306",
    imgDetail: blackWalnut,
    img: blackWalnut
  },
  {
    id: "6_cabin",
    color: "cabin",
    reference: "MAI-0305",
    imgDetail: cabin,
    img: cabin
  },
  {
    id: "8_ballet",
    color: "ballet",
    reference: "MAI-0307",
    imgDetail: ballet,
    img: ballet
  },
  {
    id: "9_blossom",
    color: "blossom",
    reference: "MAI-0308",
    imgDetail: blossom,
    img: blossom
  },
  {
    id: "11_froth",
    color: "froth",
    reference: "MAI-0310",
    imgDetail: froth,
    img: froth
  },
  {
    id: "10_berryBush",
    color: "berry\nBush",
    reference: "MAI-0309",
    imgDetail: berryBush,
    img: berryBush
  },
  {
    id: "12_underwater",
    color: "under\nwater",
    reference: "MAI-0311",
    imgDetail: underwater,
    img: underwater
  },
  {
    id: "13_emerge",
    color: "emerge",
    reference: "MAI-0312",
    imgDetail: emerge,
    img: emerge
  },
  {
    id: "15_verdent",
    color: "verdent",
    reference: "MAI-0314",
    imgDetail: verdent,
    img: verdent
  },
  {
    id: "14_woodlet",
    color: "woodlet",
    reference: "MAI-0313",
    imgDetail: woodlet,
    img: woodlet
  },
  {
    id: "16_malachite",
    color: "malachite",
    reference: "MAI-0315",
    imgDetail: malachite,
    img: malachite
  },
  {
    id: "17_seamist",
    color: "seamist",
    reference: "MAI-0316",
    imgDetail: seamist,
    img: seamist
  },
  {
    id: "19_noire",
    color: "noire",
    reference: "MAI-0318",
    imgDetail: noire,
    img: noire
  },
  {
    id: "18_damascus",
    color: "damascus",
    reference: "MAI-0317",
    imgDetail: damascus,
    img: damascus
  },
  {
    id: "20_sapphire",
    color: "sapphire",
    reference: "MAI-0319",
    imgDetail: sapphire,
    img: sapphire
  },
  {
    id: "23_denim",
    color: "denim",
    reference: "MAI-0320",
    imgDetail: denim,
    img: denim
  },
  {
    id: "21_blueStar",
    color: "blue Star",
    reference: "MAI-0321",
    imgDetail: blueStar,
    img: blueStar
  },
  {
    id: "22_inkpad",
    color: "inkpad",
    reference: "MAI-0322",
    imgDetail: inkpad,
    img: inkpad
  }
]
