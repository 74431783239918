import "../FeaturesAndBenefitsElements/styles/features-and-benefits-elements.scss"
import { APPLICATIONS_ELEMENTS } from "./constants"

const ApplicationsElements = () => (
  <div className="container g-0">
    <div className="row">
      <div className="d-flex">
        {APPLICATIONS_ELEMENTS.map(({ id, img, description }) => (
          <div key={id} className="application-container">
            <img className="application-img" src={img} alt={description} />
            <p className="description-feature">{description}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default ApplicationsElements
