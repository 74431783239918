/* eslint-disable */

export const TRANSLATED_ROUTES_QUICKSHIP_PAGE = {
  es: {
    route: "quickship",
  },
  en: {
    route: "quickship",
  },
};
