import { ApolloProvider } from "@apollo/client"
import client from "api"
import ConnectedIntlProvider from "intl/ConnectedIntlProvider"
import { StateMachineProvider } from "little-state-machine"
import { Provider as ReduxProvider } from "react-redux"
import { store } from "stateManagement/redux"
import RouteManager from "routes"
import "react-loading-skeleton/dist/skeleton.css"

const SpradlingLandings = () => (
  <ReduxProvider store={store}>
    <ApolloProvider client={client}>
      <ConnectedIntlProvider>
        <StateMachineProvider>
          <RouteManager />
        </StateMachineProvider>
      </ConnectedIntlProvider>
    </ApolloProvider>
  </ReduxProvider>
)

export default SpradlingLandings
