import { LG } from "consts/breakpoints"
import useMediaQuery from "hooks/useMediaQuery"
import ProductsForDesktop from "./components/ProductsForDesktop"
import ProductsForMobile from "./components/ProductsForMobile"
import { ITEMS } from "./constants"

const ProductsLayout = () => {
  const isMobile = useMediaQuery(LG)

  return !isMobile ? <ProductsForDesktop items={ITEMS} /> : <ProductsForMobile items={ITEMS} />
}

export default ProductsLayout
