import PropTypes from "prop-types"
import { BANNERS } from "./constants"
import "./styles/banner.scss"
import { REQUEST_SAMPLES_INFO } from "modules/masiala/constants"
import Slider from "react-slick"
import { FormattedMessage } from "react-intl"
import useMediaQuery from "hooks/useMediaQuery"
import { LG, MD, SM } from "consts/breakpoints"
import rightArrow from "./img/right-arrow.svg"
import leftArrow from "./img/left-arrow.svg"

const SLIDER_OPTIONS = {
  dots: true,
  autoplay: false,
  lazyLoad: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  initialSlide: 0,
  arrows: true,
  nextArrow: (
    <div>
      <img alt="Arrow" className="slider-arrow" src={rightArrow} width={40} />
    </div>
  ),
  prevArrow: (
    <div>
      <img alt="Arrow" className="slider-arrow" src={leftArrow} width={40} />
    </div>
  )
}

const Banner = ({ handleOrderCatalogue }) => {
  const isMobile = useMediaQuery(SM)

  return (
    <section>
      <Slider className="banner-slider--masiala" {...SLIDER_OPTIONS}>
        {BANNERS(isMobile).map((banner) => (
          <div key={`key_banner_${banner.key}`}>
            <div
              id={banner.id}
              className="wrapper-banner--masiala"
              style={{
                backgroundImage: `url(${banner.imageThumb})`
              }}
            >
              <div className="container-banner--masiala">
                <div className="container-banner--masiala--title-and-decription h-100 d-flex align-items-end">
                  <div>
                    {banner.imgTitle &&
                      <img
                        src={banner.imgTitle}
                        className={`mb-3 mb-lg-4 mx-auto ${banner.imgTitleClassName}`}alt="banner-image" />
                    }
                    <h1 className="title-banner--masiala">{banner.title}</h1>
                    <p className="description-banner--masiala mx-auto col-9 col-md-7 col-lg-12">
                      {banner.description}
                    </p>
                    <div className="container--btn-masiala">
                      <button
                        className="btn btn-masiala"
                        onClick={() => handleOrderCatalogue(REQUEST_SAMPLES_INFO)}
                      >
                        {banner.buttonText}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="container--banner-info--masiala">
        <p className="description-banner--masiala mx-auto p-0 my-0">
          <FormattedMessage
            id="Masiala.Banner.info"
            defaultMessage="Our commitment to the environment continues, Masiala, the newest member of our EcoSense<sup>®</sup>{br}collection, now with a luxurious metallic finish that adds a touch of sophistication to any interior space."
            values={{
              br: <br />,
              sup: (...chunks) => <sup>{chunks}</sup>
            }}
          />
        </p>
      </div>
    </section>
  )
}

Banner.propTypes = {
  handleOrderCatalogue: PropTypes.func
}

export default Banner
