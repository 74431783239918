import gql from "graphql-tag"

const PatternQuery = gql`
  query PatternQuery($slug: String) {
    pattern(slug: $slug) {
      products {
        edges {
          node {
            id
            color
            imageThumbMedium
            imageThumbSmall
            stock
          }
        }
      }
    }
  }
`

export default PatternQuery
