import { useQuery } from "@apollo/client"
import { getCurrentInstanceId } from "services/instances"
import { homeSlidersQuery, sustainabilityReportsQuery } from "./query"
import Banner from "components/Banner"
import { SLIDE } from "./components/Banner/constants"
import Reports from "./components/Reports"
import { SUSTAINABILITY_REPORTS } from "./constants"

const SustainabilityReports = () => {
  const { data: sliders, loading: loadingSlider } = useQuery(homeSlidersQuery, {
    variables: {
      instanceIds: [getCurrentInstanceId()],
      tag: SUSTAINABILITY_REPORTS
    }
  })

  const { data, loading: loadingSustainabilityReports } = useQuery(sustainabilityReportsQuery, {
    variables: {
      orderBy: "-date"
    }
  })

  return (
    <div className="content">
      {!loadingSlider ? (
        <Banner
          slides={SLIDE(sliders.homeSliders.edges[0].node)}
          align="left"
          targetLink="_blank"
        />
      ) : (
        <Banner align="left" />
      )}
      <Reports reports={data?.sustainabilityReports} loading={loadingSustainabilityReports} />
    </div>
  )
}

export default SustainabilityReports
