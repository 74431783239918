import PropTypes from "prop-types"
import useToggle from "hooks/useToggle"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import "./styles/table-accordion.scss"

const TableAccordion = ({ children, title, openByDefault = false, isFullContainer = false }) => {
  const [toggable, toggle] = useToggle(openByDefault)

  return (
    <div className="product-specs-accordion">
      <h3 className="product-specs-accordion__title" onClick={toggle}>
        {title}
        <FontAwesomeIcon
          className="float-end me-3"
          icon={faAngleDown}
          size="lg"
          flip={toggable ? "vertical" : undefined}
        />
      </h3>
      <div
        className={classNames({
          "table-accordion--collapse": !toggable,
          "table-accordion--collapse-show": toggable,
          "full-container": toggable && isFullContainer
        })}
        isOpen={toggable}
      >
        {children}
      </div>
    </div>
  )
}

TableAccordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disclaimer: PropTypes.node,
  openByDefault: PropTypes.bool,
  isFullContainer: PropTypes.bool,
  children: PropTypes.element
}

export default TableAccordion
