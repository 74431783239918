import { connect } from "react-redux"
import StoresGoogleMap from "components/StoreFinder/components/StoresGoogleMap"
import { setHighlightedStoreId } from "components/StoreFinder/actions"

const getNearbyStores = (state) => state.locationReducer.nearbyStores
const getHighlightedStoreId = (state) => state.locationReducer.highlightedStoreId

const mapStateToProps = (state) => {
  return {
    nearbyStores: getNearbyStores(state),
    highlightedStoreId: getHighlightedStoreId(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setHighlightedStoreId: (highlightedStoreId) =>
      dispatch(setHighlightedStoreId(highlightedStoreId))
  }
}

const StoresGoogleMapContainer = connect(mapStateToProps, mapDispatchToProps)(StoresGoogleMap)

export default StoresGoogleMapContainer
