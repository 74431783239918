import PropTypes from "prop-types"
import classNames from "classnames/bind"
import "./styles/burger-btn.scss"

const Burger = ({ toggable, setToggable }) => (
  <div className="header-burger-saxony" onClick={setToggable}>
    <div
      className={classNames({
        burger: true,
        "is-active": toggable
      })}
    >
      {[1, 2, 3].map((number) => (
        <span key={number} className="bg-light line" />
      ))}
    </div>
  </div>
)

Burger.propTypes = {
  toggable: PropTypes.bool.isRequired,
  setToggable: PropTypes.func.isRequired
}

export default Burger
