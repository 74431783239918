import { FormattedMessage } from "react-intl"
import VideoCommon from "components/Video"
import useMediaQuery from "hooks/useMediaQuery"
import { SM } from "consts/breakpoints"

const Video = () => {
  const isMobile = useMediaQuery(SM)

  return (
    <div className="container my-2 py-md-5">
      <div className="row py-md-5">
        <div className="col-12 col-md-6">
          <div className="p-4">
            <h1>
              <FormattedMessage
                id="Prannatex.Video.title"
                description="title section video Prannatex"
                defaultMessage="A new chapter begins in the saga of Spradling® innovation."
                values={{ br: <br /> }}
              />
            </h1>
            <p className="mt-4 col-12 col-md-11">
              <FormattedMessage
                id="Prannatex.Video.description"
                description="description title section video Prannatex"
                defaultMessage="Almost 30 years ago we gave life to Pranna<sup>®</sup>, the most iconic and recognized leather-type covered upholstery on the market. Over the decades, Pranna<sup>®</sup> has built an impeccable reputation for its unmatched quality, versatility and the elegance it brings to every place where it is used. Now, in this chapter of our history, we present your new companion: Pranna<sup>®</sup>Tex."
                values={{
                  br: <br />,
                  sup: (...chunks) => <sup>{chunks}</sup>
                }}
              />
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 align-self-center">
          <VideoCommon className="col-12 img-fluid" fromYoutube>
            <iframe
              width="100%"
              height={isMobile ? "250" : "315"}
              src="https://www.youtube.com/embed/iDWgWBeJCkA?si=_vcPNCnqJK-X6dGF"
              title="video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
          </VideoCommon>
        </div>
      </div>
    </div>
  )
}

export default Video
