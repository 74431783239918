import * as Yup from "yup"

export const COLOR_SCHEMA = Yup.object().shape({
  label: Yup.string().required(),
  value: Yup.string().required(),
  data: Yup.object().shape({
    catalogue: Yup.string(),
    pattern: Yup.string(),
    color: Yup.string(),
    reference: Yup.string()
  })
})
