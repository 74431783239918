import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import TitleUnderlined from "components/TitleUnderlined"
import { H2_TAG } from "components/TitleUnderlined/constants"
import UsesList from "components/UsesList"
import applicatedPattern from "./img/aplication.jpg"
import { USES_ITEMS } from "modules/linetex/constants"

const Applications = ({ id }) => (
  <div id={id}>
    <div className="container my-2 py-4">
      <div className="row">
        <div className="col-12">
          <TitleUnderlined hTag={H2_TAG}>
            <FormattedMessage
              id="Pranna.Applications.title"
              description="Pranna section title applications"
              defaultMessage="Uso y aplicaciones"
            />
          </TitleUnderlined>
          <UsesList uses={USES_ITEMS} />
          <div className="text-center">
            <img className="img-fluid" src={applicatedPattern} alt="applicated product" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

Applications.propTypes = {
  id: PropTypes.string.isRequired
}

export default Applications
