import { useStateMachine } from "little-state-machine"
import { useIntl } from "react-intl"
import { updateAction } from "utils/littleStateMachine"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { VALIDATION_MESSAGES } from "utils/forms"
import InputTextArea from "components/InputMUI/TextArea"
import { GENERIC_MSGS } from "modules/claims/constants"
import { BUTTON_MESSAGES } from "utils/buttons"
import ProgressBar from "modules/claims/components/StepForm/ProgressBar"
import { useNavigate } from "react-router-dom"
import { redirectToNextStep } from "../../../utils"
import { COLOR_DIFFERENCE_MSGS } from "./constants"

const ColorDifference = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { actions, state } = useStateMachine({ updateAction })

  const defaultValues = { ...state }
  const schema = yup.object({
    colorDifferenceDescription: yup
      .string()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = (data) => {
    actions.updateAction(data)
    redirectToNextStep(navigate)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputTextArea
        name="colorDifferenceDescription"
        control={control}
        label={COLOR_DIFFERENCE_MSGS.colorDifferenceDescription}
        placeholder={GENERIC_MSGS.typeAnswer_placeholder}
        defaultValue={state.colorDifferenceDescription}
        error={errors.colorDifferenceDescription?.message}
      />
      <div className="btn-container">
        <button className="btn btn-dark" type="submit">
          {intl.formatMessage(BUTTON_MESSAGES.ok)}
        </button>
      </div>
      <ProgressBar
        progress={60}
        handleNext={handleSubmit(onSubmit)}
        RoutePrev={state.previousStep}
      />
    </form>
  )
}

export default ColorDifference
