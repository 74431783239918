import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import { instanceQuery } from "components/Footer/query"
import { useQuery } from "@apollo/client"
import { getCurrentInstanceId } from "services/instances"
import { Link as LinkScroll } from "react-scroll"
import { Link } from "react-router-dom"
import scssVars from "assets/styles/_variables.scss"
import "components/Header/styles/header.scss"
import useToggle from "hooks/useToggle"
import BurgerBtn from "components/Header/components/Menu/components/BurgerBtn"
import DropdownItem from "./components/DropdownItem"
import { FormattedMessage } from "react-intl"
import { trendsQuery } from "./query"

const NO_OP = () => {}

const Header = ({ objItems, isTransparent = false, isSticky = true, noInspirations = false }) => {
  const [scrolled, setScrolled] = useState(false)
  const [toggable, toggle] = useToggle()
  const { data: dataInstance } = useQuery(instanceQuery, {
    variables: {
      instanceId: getCurrentInstanceId()
    }
  })
  const { data: dataTrends } = useQuery(trendsQuery)

  useEffect(() => {
    isScrolled()
  })

  const isScrolled = () => {
    if (isSticky) {
      window.onscroll = () => {
        const headerHeight = parseFloat(scssVars.headerHeight.substring(0, 4)) / (1 / 16)
        if (window.scrollY > headerHeight) {
          setScrolled(true)
        } else if (window.scrollY < headerHeight) {
          setScrolled(false)
        }
      }
    }
  }

  const handleRedirectTrend = ({ slug }) => `/es-la/pisos-interno/tendencias/${slug}`

  return (
    <header
      className={classNames({
        "fixed-top": isSticky,
        "header-static": !isSticky,
        "bg-transparent": isTransparent && !scrolled,
        "bg-black": !isTransparent || scrolled
      })}
    >
      <div className="container">
        <div className="row g-0">
          <div className="col-3 col-md-10 order-md-1">
            <BurgerBtn toggable={toggable} setToggable={toggle} />
            <div
              className={classNames({
                "header-main-menu": true,
                "header-main-menu--open": toggable
              })}
            >
              <ul
                className={classNames({
                  "main-menu-sections": true,
                  "main-menu-sections--open": toggable
                })}
              >
                {!noInspirations && dataTrends && dataTrends.trends && (
                  <li className="main-menu-sections__section">
                    <DropdownItem
                      title={
                        <FormattedMessage
                          id="Floors.HeaderButtons.inspiration"
                          description="inspiration HeaderButtons text"
                          defaultMessage="Inspiración"
                        />
                      }
                      items={dataTrends.trends.edges}
                      handleRedirect={handleRedirectTrend}
                    />
                  </li>
                )}

                {objItems &&
                  objItems.map(({ id, text, href, internalLink, externalLink, callback }) => (
                    <li className="main-menu-sections__section" key={`key_${id}`}>
                      <div className="text-capitalize">
                        {!internalLink && !externalLink ? (
                          <LinkScroll
                            className="header-menu-button"
                            activeClass="header-menu-button--active"
                            to={href}
                            duration={100}
                            offset={-50}
                            smooth
                            spy
                            onClick={callback ? () => callback() : NO_OP}
                          >
                            {text}
                          </LinkScroll>
                        ) : !externalLink ? (
                          <Link className="header-menu-button" to={href}>
                            {text}
                          </Link>
                        ) : (
                          <a
                            className="header-menu-button"
                            href={href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {text}
                          </a>
                        )}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="col-8 col-md-2 order-md-0">
            <LinkScroll
              to="home-slide"
              className="logo cursor-pointer"
              duration={100}
              smooth={true}
            >
              {dataInstance && dataInstance.instance && (
                <img
                  className="img-fluid w-75"
                  src={dataInstance.instance.logo}
                  alt="Spradling Group"
                  title="Spradling Group"
                />
              )}
            </LinkScroll>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  objItems: PropTypes.arrayOf(PropTypes.object),
  isSticky: PropTypes.bool,
  isTransparent: PropTypes.bool,
  noInspirations: PropTypes.bool
}

export default Header
