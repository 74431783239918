import PropTypes from "prop-types"
import {
  CONTACT_INFO,
  DIGITAL_CATALOGUE_INFO,
  REQUEST_SAMPLES_INFO
} from "modules/kizuna/components/RequestsSection/utils"
import { FormattedMessage } from "react-intl"
import spradlingLogo from "./img/spradling_logo_gray.svg"
import "./style/footer.scss"

const Footer = ({ handleClickInRequest, handleOrderCatalogue, handleShowInformationBox }) => {
  const handleAddCatalogue = (requestInfo) => {
    handleOrderCatalogue(requestInfo)
    handleShowInformationBox()
  }

  return (
    <footer className="footer">
      <div className="container-form font-gill-sans py-3">
        <div className="d-md-flex align-items-center footer-logo">
          <div className="desktop d-md-flex">
            <p
              className="text-secondary mb-0 cursor-pointer"
              onClick={() => handleAddCatalogue(REQUEST_SAMPLES_INFO)}
            >
              <FormattedMessage
                id="Kizuna.orderSamples.footer"
                description="Order samples"
                defaultMessage="REQUEST CATALOGUE"
              />
              {" | "}
            </p>
            <p
              className="text-secondary ms-1 mb-0 cursor-pointer"
              onClick={() => handleClickInRequest(DIGITAL_CATALOGUE_INFO)}
            >
              <FormattedMessage
                id="Kizuna.digitalCatalogue"
                description="Digital Catalogue"
                defaultMessage="DOWNLOAD DIGITAL CATALOGUE"
              />
              {" | "}
            </p>
            <p
              className="text-secondary ms-1 cursor-pointer mb-0"
              onClick={() => handleClickInRequest(CONTACT_INFO)}
            >
              <FormattedMessage
                id="Kizuna.contactUs"
                description="Contact Us"
                defaultMessage="CONTACT US"
              />
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-lg-0 ">
            <span className="small me-3">
              <FormattedMessage
                id="Kizuna.allRightsReserved"
                description="All rights reserved"
                defaultMessage="All rights reserved."
              />
            </span>
            <img className="footer-logo-img w-50" src={spradlingLogo} alt="..." />
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  handleShowInformationBox: PropTypes.func,
  handleClickInRequest: PropTypes.func,
  handleOrderCatalogue: PropTypes.func
}

export default Footer
