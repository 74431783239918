import { FormattedMessage } from "react-intl"
import ApplicationsElements from "../components/ApplicationsElements"
import SustainableElements from "../components/SustainableElements"
import permablockImage from "../img/permablock-plus.svg"

const SUSTAINABLE_PRODUCT_PROFILE = "SUSTAINABLE_PRODUCT_PROFILE"
const CHARACTERISTICS = "CHARACTERISTICS"
const APPLICATIONS = "APPLICATIONS"
const PERFORMANCE = "PERFORMANCE"
const FLAME_RETARDANCY = "FLAME_RETARDANCY"
const WARRANTY = "WARRANTY"
const DIGITAL_CATALOGUE = "DIGITAL_CATALOGUE"
const CARE_AND_CLEANING = "CARE_AND_CLEANING"
const PASS = (
  <FormattedMessage
    id="Valencia.Spec.FlameRetardancy.pass"
    defaultMessage="Pass"
  />
)

export const PRODUCT_SPECS = {
  [SUSTAINABLE_PRODUCT_PROFILE]: (
    <FormattedMessage
      id="Valencia.Spec.SustainableProductProfile"
      defaultMessage="Sustainable Attributes"
    />
  ),
  [CHARACTERISTICS]: (
    <FormattedMessage
      id="Valencia.specCharacteristics"
      defaultMessage="Characteristics"
    />
  ),
  [APPLICATIONS]: (
    <FormattedMessage
      id="Valencia.specApplications"
      defaultMessage="Applications"
    />
  ),
  [PERFORMANCE]: (
    <FormattedMessage
      id="Valencia.specPerformance"
      defaultMessage="Performance"
    />
  ),
  [FLAME_RETARDANCY]: (
    <FormattedMessage
      id="Valencia.specFlameRetardancy"
      defaultMessage="Flame Retardancy*"
    />
  ),
  [WARRANTY]: (
    <FormattedMessage id="Valencia.specWarranty" defaultMessage="Warranty" />
  ),
  [DIGITAL_CATALOGUE]: (
    <FormattedMessage
      id="Valencia.Spec.DigitalCatalogue"
      defaultMessage="Digital Catalogue"
    />
  ),
  [CARE_AND_CLEANING]: (
    <FormattedMessage
      id="Valencia.Spec.CareCleaning"
      defaultMessage="Care & Cleaning"
    />
  )
}

export const productSpecDetailsInformationToShow = () => {
  const PRODUCT_SPECS_DETAIL_INFORMATION = {
    [SUSTAINABLE_PRODUCT_PROFILE]: {
      info: [
        {
          id: "Valencia.Spec.SustainableProductProfile",
          value: <SustainableElements />
        }
      ]
    },
    [CHARACTERISTICS]: {
      info: [
        {
          id: "Valencia.Spec.Characteristics.composition",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Characteristics.bleachCleanable"
              defaultMessage="Bleach Cleanable (1:9)"
            />
          )
        },
        {
          id: "Valencia.Spec.Characteristics.specifics",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Characteristics.noHeavyMetals"
              defaultMessage="No Heavy Metals"
            />
          )
        },
        {
          id: "Valencia.Spec.Characteristics.waterResistant",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Characteristics.waterResistant"
              defaultMessage="Water Resistant"
            />
          )
        },
        {
          id: "Valencia.Spec.Characteristics.width",
          description: (
            <strong>
              <FormattedMessage
                id="Valencia.Spec.Characteristics.content"
                defaultMessage="Content"
              />
            </strong>
          )
        },
        {
          id: "Valencia.Spec.Characteristics.rollSize",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Characteristics.vinyl"
              defaultMessage="Vinyl"
            />
          )
        },
        {
          id: "Valencia.Spec.Characteristics.gauge",
          description: (
            <strong>
              <FormattedMessage
                id="Valencia.Spec.Characteristics.backing"
                defaultMessage="backing"
              />
            </strong>
          )
        },
        {
          id: "Valencia.Spec.Characteristics.polyester",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Characteristics.polyester"
              defaultMessage="100% Polyester"
            />
          )
        },
        {
          id: "Valencia.Spec.Characteristics.specifics",
          description: (
            <strong>
              <FormattedMessage
                id="Valencia.Spec.Characteristics.specifics"
                defaultMessage="specifics"
              />
            </strong>
          )
        },
        {
          id: "Valencia.Spec.Characteristics.weight",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Characteristics.weight"
              defaultMessage="weight"
            />
          ),
          value: "29 oz"
        },
        {
          id: "Valencia.Spec.Characteristics.width",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Characteristics.width"
              defaultMessage="Width"
            />
          ),
          value: "54 in"
        },
        {
          id: "Valencia.Spec.Characteristics.rollSize",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Characteristics.rollSize"
              defaultMessage="roll size"
            />
          ),
          value: "30 yd"
        },
        {
          id: "Valencia.Spec.Characteristics.finishes",
          description: (
            <strong>
              <FormattedMessage
                id="Valencia.Spec.Characteristics.finishes"
                defaultMessage="Finishes"
              />
            </strong>
          )
        },
        {
          id: "Valencia.Spec.Characteristics.permablockImage",
          description: (
            <img src={permablockImage} alt="permablock-plus image" />
          )
        }
      ]
    },
    [APPLICATIONS]: {
      info: [
        {
          id: "Valencia.Spec.Applications",
          value: <ApplicationsElements />
        }
      ]
    },
    [PERFORMANCE]: {
      info: [
        {
          id: "Valencia.Spec.Performance.antiStatic",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Performance.antiStatic"
              defaultMessage="Anti Static"
            />
          ),
          value: (
            <FormattedMessage
              id="Valencia.Spec.Performance.ASTM_D257.value"
              defaultMessage="ASTM D257"
            />
          )
        },
        {
          id: "Valencia.Spec.Performance.coldCrack",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Performance.coldCrack"
              defaultMessage="Cold Crack -10°F"
            />
          ),
          value: (
            <FormattedMessage
              id="Valencia.Spec.Performance.coldCrack.value"
              defaultMessage="CFFA - 6a"
            />
          )
        },
        {
          id: "Valencia.Spec.Performance.denim",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Performance.denim"
              defaultMessage="Denim Dye Cleanable"
            />
          ),
          value: (
            <FormattedMessage
              id="Valencia.Spec.Performance.gmw.value"
              defaultMessage="GMW 15377"
            />
          )
        },
        {
          id: "Valencia.Spec.Performance.sulfide",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Performance.sulfide"
              defaultMessage="Sulfide Stain Resistant"
            />
          ),
          value: (
            <FormattedMessage
              id="Valencia.Spec.Performance.fisher.value"
              defaultMessage="Fisher Body TM 31-12"
            />
          )
        },
        {
          id: "Valencia.Spec.Performance.superAbrasion",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Performance.superAbrasion"
              defaultMessage="Super Abrasion"
            />
          ),
          value: (
            <FormattedMessage
              id="Valencia.Spec.Performance.superAbrasion.value"
              defaultMessage="CFFA-1a/ASTM D-4157 {br}
              100.000 Cycles  Wyzenbeek #10 Cotton Duck"
              values={{
                br: <br />
              }}
            />
          )
        },
        {
          id: "Valencia.Spec.Performance.uvStability",
          description: (
            <FormattedMessage
              id="Valencia.Spec.Performance.uvStability"
              defaultMessage="UV Stability"
            />
          ),
          value: (
            <FormattedMessage
              id="Valencia.Spec.Performance.uvStability.value"
              defaultMessage="Xenotest AATCC  TM 16-3 /NTC 1479 {br} CFFA – 2 a – Blue Wool {br} 1,000 Hours"
              values={{
                br: <br/>
              }}
            />
          )
        }
      ]
    },
    [FLAME_RETARDANCY]: {
      info: [
        {
          id: "CAL TB 117 - 2013",
          description: (
            <FormattedMessage
              id="Valencia.Spec.FlameRetardancy.CAL_TB_117-2013"
              defaultMessage="CAL TB 117 - 2013"
              values={{
                span: (...chunks) => <span className="me-3">{chunks}</span>
              }}
            />
          ),
          value: PASS
        },
        {
          id: "NFPA 260 - Cover fabric - Class 1",
          description: (
            <FormattedMessage
              id="Valencia.Spec.FlameRetardancy.NFPA_260-Cover_fabric-Class_1-2013"
              defaultMessage="NFPA 260 - Cover fabric - Class 1"
              values={{
                span: (...chunks) => <span className="me-3">{chunks}</span>
              }}
            />
          ),
          value: PASS
        },
        {
          id: "UFAC Fabric - Class 1",
          description: (
            <FormattedMessage
              id="Valencia.Spec.FlameRetardancy.UFAC_Fabric-Class_1"
              defaultMessage="UFAC Fabric - Class 1"
              values={{
                span: (...chunks) => <span className="me-3">{chunks}</span>
              }}
            />
          ),
          value: PASS
        }
      ],
      disclaimer: [
        {
          id: "Valencia.specFlameRetardancyDisclaimer",
          value: (
            <p className="color-letter-rv">
              <FormattedMessage
                id="Valencia.specFlameRetardancyDisclaimer"
                defaultMessage="*This term and any corresponding data refer to the typical performance in the specific tests indicated and should not be construed to imply the behavior of this or any other material under actual fire conditions."
              />
            </p>
          )
        }
      ]
    },
    [WARRANTY]: {
      info: [
        {
          id: "Valencia.specWarranty.info",
          description: (
            <FormattedMessage
              id="Valencia.specWarranty.info"
              defaultMessage="5 years"
            />
          )
        }
      ],
      disclaimer: [
        {
          id: "Valencia.specWarranty.disclaimer",
          value: (
            <p className="color-letter-rv">
              <FormattedMessage
                id="Valencia.specWarranty.disclaimer"
                defaultMessage="*Terms and conditions apply. Warranty conditions are displayed on
              our website."
              />
            </p>
          )
        }
      ]
    }
  }

  return PRODUCT_SPECS_DETAIL_INFORMATION
}
