import PropTypes from "prop-types"
import { socialMediaInstanceQuery } from "./query"
import { useQuery } from "@apollo/client"
import { getCurrentInstanceId } from "services/instances"
import facebookIcon from "./assets/img/facebook-icon.svg"
import instagramIcon from "./assets/img/instagram-icon.svg"
import linkedinIcon from "./assets/img/linkedin-icon.svg"
import "./assets/styles/footer.scss"
import classNames from "classnames"

const Footer = ({ className, onlyLogo = false, hasItems = false, items = [] }) => {
  const { data } = useQuery(socialMediaInstanceQuery, {
    variables: {
      instanceId: getCurrentInstanceId()
    }
  })

  return (
    <footer
      className={classNames({
        "text-light": true,
        [className]: className
      })}
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          {!onlyLogo ? (
            <>
              <div className="col-12 col-md-8 order-md-1">
                {!items.length && (
                  <div className="social-media-container">
                    {data && data.instance?.facebookUrl && (
                      <a href={data.instance.facebookUrl} target="_blank" rel="noopener noreferrer">
                        <img className="social-media-icon" src={facebookIcon} alt="facebook icon" />
                      </a>
                    )}
                    {data && data.instance?.instagramUrl && (
                      <a
                        href={data.instance.instagramUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="social-media-icon"
                          src={instagramIcon}
                          alt="instagram icon"
                        />
                      </a>
                    )}
                    {data && data.instance?.linkedinUrl && (
                      <a href={data.instance.linkedinUrl} target="_blank" rel="noopener noreferrer">
                        <img className="social-media-icon" src={linkedinIcon} alt="linkedin icon" />
                      </a>
                    )}
                  </div>
                )}
                {hasItems && items.length && (
                  <ul className="row p-0 text-center footer-sections mb-0">
                    {items.map(({ label, value }) => (
                      <li key={`key_${label}`} className="col-12 col-md-auto py-2">
                        <div>
                          <span className="label me-2">{label}:</span>
                          <span className="m-0">{value}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="col col-md-3 text-center m-2 order-md-0">
                <div className="logo-section">
                  {data && data.instance.logo && (
                    <img
                      className="logo-instance footer-saxony--spradling-img"
                      src={data.instance.logo}
                      alt="Spradling Group"
                      title="Spradling Group"
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="col-12 text-center m-0 p-0 g-0">
              {data && data.instance.logo && (
                <img
                  className="logo-instance-lonely"
                  src={data.instance.logo}
                  alt="Spradling Group"
                  title="Spradling Group"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  onlyLogo: PropTypes.bool,
  hasItems: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
}

export default Footer
