import PropTypes from "prop-types"
import Slider from "react-slick"
import SampleCard from "../../../SampleCard"

const SLIDER_OPTIONS = {
  arrows: false,
  dots: true,
  infinite: true,
  lazyLoad: true,
  slidesToShow: 4,
  slidesToScroll: 4
}

const SamplesListMobile = ({ samples, handleClick }) => (
  <Slider {...SLIDER_OPTIONS} className="my-4">
    {samples.map((item) => (
      <SampleCard sample={item} handleClick={handleClick} key={`item_${item.description}`} />
    ))}
  </Slider>
)

SamplesListMobile.propTypes = {
  samples: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default SamplesListMobile
