import Header from "components/Header"
import Footer from "components/Footer"
import Banner from "components/Banner"
import { GLOBAL_SITE_TAG, NAV_ITEMS } from "./constants"
import { SLIDE } from "./components/Banner/constants"
import Info from "components/Info"
import { FormattedMessage } from "react-intl"
import References from "./components/References"
import WhereToBuy from "./components/WhereToBuy"
import Uses from "./components/Applications/components/Uses"
import Features from "./components/Applications/components/Features"
import Analytics from "components/Analytics"
import { getCurrentInstanceId } from "services/instances"

const VestuarioYProteccion = () => {
  return (
    <>
      <Analytics gtag={GLOBAL_SITE_TAG[getCurrentInstanceId()]} />
      <Header items={NAV_ITEMS} />
      <Banner slides={SLIDE} noDescription />
      <Info
        text={
          <FormattedMessage
            id="VestuarioYProteccion.info"
            description="Impala section title info"
            defaultMessage="Impala cuenta con un diseño tipo piel con colores inspirados en las texturas de los tallos de los árboles y diversos materiales minerales dando así una paleta de tonalidades perfectas para crear diseños únicos."
            values={{ br: <br /> }}
          />
        }
      />
      {/* VIDEO */}
      <References id={NAV_ITEMS[0]} />
      <Uses id={NAV_ITEMS[1]} />
      <Features id={NAV_ITEMS[2]} />
      <WhereToBuy id={NAV_ITEMS[NAV_ITEMS.length - 1]} />
      <Footer />
    </>
  )
}

export default VestuarioYProteccion
