export const filterBlacklist = ({ originalArray, blacklist, filter }) => {
  if (originalArray) {
    const filteredArray = [...originalArray]

    const filteredObjects = filteredArray.filter(
      (obj) => !blacklist.some((blacklisted) => obj.node[filter] === blacklisted.node[filter])
    )

    return filteredObjects
  }
}
