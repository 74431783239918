/* eslint-disable */

import { useState, useEffect } from 'react';
// import { useQuery } from 'react-apollo';
import { useQuery } from "@apollo/client"
import { filteredProductsQuickshipPage } from '../queries';

const useGetFilteredProductQuickshipPage = (
  idCollection,
  sectorIn,
  designIn,
  filterCahracteristics
) => {

  const objVariables = {
    quickshipCollection: idCollection || '',
    sectorIn: sectorIn || [],
    designIn: designIn || [],
    [filterCahracteristics]: true
  }

  const { data, loading, error } = useQuery(filteredProductsQuickshipPage, {
    variables: objVariables,
  });

  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (data && data.quickshipProducts) {
      setFilteredProducts(data.quickshipProducts.edges || []);
    }
  }, [data]);

  return { filteredProducts, loading, error };
};

export default useGetFilteredProductQuickshipPage;
