import gql from "graphql-tag"

const singlePatternFragment = gql`
  fragment SinglePatternFragment on PatternNode {
    id
    name
    altName
    slug
    isNew
  }
`

export const singlePatternQuery = gql`
  query SinglePatternQuery($id: ID, $slug: String, $unitSystem: Int, $instanceIds: [ID]) {
    pattern(id: $id, slug: $slug) {
      ...SinglePatternFragment
      hasStock
      cataloguesAvailable
      code
      maintenanceGuide
      transformationGuide
      catalogStockNumber
      sliders
      productcategorization {
        design
      }
      products(instances: $instanceIds) {
        totalCount
        edges {
          node {
            id
            code
            color
            predominantColors
            hasStock
            samplesAvailable
            stock
            pantone
            imageThumbSmall
            imageThumbMedium
            imageThumbLarge
            isNew
          }
        }
      }
      specsSheetData(fullVersion: false, unitSystem: $unitSystem, limitAdditionalTests: true)
      sectors {
        edges {
          node {
            id
            name
            market {
              id
              name
            }
          }
        }
      }
      relatedPatterns(products_Instances: $instanceIds) {
        totalCount
      }
      inspiration {
        id
        text
        imageThumbMedium
      }
      cardOfColor {
        id
        text
      }
    }
  }
  ${singlePatternFragment}
`
